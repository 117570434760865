import ACTION_TYPES from '../../../actionTypes';
import { onAddPartsToAddedParts, onClearAddedParts } from '../../Parts';

const onNavigateToReviewPageInit = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.REVIEW.INIT,
        payload: null,
    };
};

const onNavigateToReviewPageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.REVIEW.DONE,
        payload: null,
    };
};

const onNavigateToReviewPage = (ids, history) => {
    return async (dispatch, getState) => {
        dispatch(onNavigateToReviewPageInit());
        await dispatch(onClearAddedParts());
        // await dispatch(onAddPartsToAddedParts(ids));
        // history.push(`/dashboard/ReviewPage/${ids.partIds[0]}`);
        history.push(`/dashboard/ReviewPage/`);
        dispatch(onNavigateToReviewPageDone());
    };
};

export default onNavigateToReviewPage;