import API, { graphqlOperation } from '@aws-amplify/api';

import { updateParts } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPart } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";

const onUpdatePartInit = () => {
    return {
        type: ACTION_TYPES.PART.UPDATE.INIT,
        payload: null,
    };
};

const onUpdatePartSuccess = (updatedPart: IPart) => {
    return {
        type: ACTION_TYPES.PART.UPDATE.SUCCESS,
        payload: updatedPart,
    };
};

const onUpdatePartFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on updating part');
    console.error('Failure on updating part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.UPDATE.FAIL,
        payload: errorMessage,
    };
};


const onUpdatePartDone = () => {
    return {
        type: ACTION_TYPES.PART.UPDATE.DONE,
        payload: null,
    };
};

const onUpdatePartAfterDelete = (partToBeUpdated, deletedFolder) => {
    return async (dispatch, getState) => {
        dispatch(onUpdatePartInit());
        try {
            const state = getState();
            partToBeUpdated.folders = partToBeUpdated.folders.filter(folder => JSON.parse(folder)['name'] !== deletedFolder.id);
            if (partToBeUpdated.folders && partToBeUpdated.folders.length === 0) partToBeUpdated.folders = null;
            
            delete partToBeUpdated._deleted;
            delete partToBeUpdated._lastChangedAt;
            delete partToBeUpdated.createdAt;
            delete partToBeUpdated.updatedAt;
            partToBeUpdated.modifier = state.creator as string;
            partToBeUpdated.modified_at = new Date().toISOString();

            const variables = {
                input: partToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updateParts, variables));
            const updatedPart = ((result as any)?.data?.updateParts) as IPart;
            dispatch(onUpdatePartSuccess(updatedPart));
        }
        catch (graphqlError) {
            const errorMessage = `Update part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdatePartFail(errorMessage));
        }
        dispatch(onUpdatePartDone());
    };
};

export default onUpdatePartAfterDelete;