import API, { graphqlOperation } from '@aws-amplify/api';

import { updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPlate } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onUnarchivePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.UNARCHIVE.INIT,
        payload: null,
    };
};

const onUnarchivePlateSuccess = (unarchivedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.UNARCHIVE.SUCCESS,
        payload: unarchivedPlate,
    };
};

const onUnarchivePlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Unarchive Plate');
    console.error('Failure on Unarchive Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.UNARCHIVE.FAIL,
        payload: errorMessage,
    };
};


const onUnarchivePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.UNARCHIVE.DONE,
        payload: null,
    };
};

const onUnarchivePlate = (plateId) => {
    return async (dispatch, getState) => {
        dispatch(onUnarchivePlateInit());
        try {
            const state = getState();
            const plateToBeUnarchived = state.data.plates.loadedPlates.find(p => p.id === plateId) as IPlate;
            plateToBeUnarchived.archive = null;
            delete plateToBeUnarchived._deleted;
            delete plateToBeUnarchived._lastChangedAt;
            delete plateToBeUnarchived.createdAt;
            delete plateToBeUnarchived.updatedAt;
            plateToBeUnarchived.modified_by = state.creator as string;
            plateToBeUnarchived.modified_at = new Date().toISOString();
            const variables = {
                input: plateToBeUnarchived,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const unarchivedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            dispatch(onUnarchivePlateSuccess(unarchivedPlate));
        }
        catch (graphqlError) {
            const errorMessage = `Unarchive plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUnarchivePlateFail(errorMessage));
        }
        dispatch(onUnarchivePlateDone());
    };
};

export default onUnarchivePlate;