import ACTION_TYPES from '../../actionTypes';

const onSubscriptionsSet = (action) => {
    return {
        type: ACTION_TYPES.SUBSCRIPTIONS.SET,
        payload: action,
    };
};

export default onSubscriptionsSet;
