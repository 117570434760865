import API, { graphqlOperation } from '@aws-amplify/api';

import { updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPlate } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onArchivePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.ARCHIVE.INIT,
        payload: null,
    };
};

const onArchivePlateSuccess = (archivedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.ARCHIVE.SUCCESS,
        payload: archivedPlate,
    };
};

const onArchivePlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Archive Plate');
    console.error('Failure on Archive Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.ARCHIVE.FAIL,
        payload: errorMessage,
    };
};


const onArchivePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.ARCHIVE.DONE,
        payload: null,
    };
};

const onArchivePlate = (plateId) => {
    return async (dispatch, getState) => {
        dispatch(onArchivePlateInit());
        try {
            const state = getState();
            const plateToBeArchived = state.data.plates.loadedPlates.find(p => p.id === plateId) as IPlate;
            plateToBeArchived.archive = true;
            delete plateToBeArchived._deleted;
            delete plateToBeArchived._lastChangedAt;
            delete plateToBeArchived.createdAt;
            delete plateToBeArchived.updatedAt;
            plateToBeArchived.modified_by = state.creator as string;
            plateToBeArchived.modified_at = new Date().toISOString();
            const variables = {
                input: plateToBeArchived,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const archivedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            dispatch(onArchivePlateSuccess(archivedPlate));
        }
        catch (graphqlError) {
            const errorMessage = `Archive plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onArchivePlateFail(errorMessage));
        }
        dispatch(onArchivePlateDone());
    };
};

export default onArchivePlate;