import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoadedPlate } from '.';
import { IState } from '../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const props = {

    };

    return props;
};

const Actions = {

};

class Component extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            showItems: true,
        };
    }

    public onToggle = () => {
        this.setState({
            showItems: !this.state.showItems,
        });
    };

    public render() {
        return (
            <>
                {
                    (this.props.loadedPlates.length) ?
                        (
                            <>
                                <dl>
                                    <dt>
                                        <div className='options left-justify'>
                                            <>
                                                {
                                                    (!this.state.showItems) ?
                                                        (
                                                            <>
                                                                <div className='collapse option'>
                                                                    <img className='icon right-arrow'
                                                                        alt=''
                                                                        onClick={() => this.onToggle()} />
                                                                    <label>{this.props.label}</label>
                                                                    <span>{` listing ${this.props.loadedPlates.length} plates`}</span>
                                                                </div>
                                                            </>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                            <>
                                                {
                                                    (this.state.showItems) ?
                                                        (
                                                            <>
                                                                <div className='expand option'>
                                                                    <img className='icon down-arrow'
                                                                        alt=''
                                                                        onClick={() => this.onToggle()} />
                                                                    <label>{this.props.label}</label>
                                                                    <span>{` listing ${this.props.loadedPlates.length} plates`}</span>
                                                                </div>
                                                            </>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                        </div>
                                    </dt>
                                    <>
                                        {
                                            (this.state.showItems) ?
                                                (
                                                    <>
                                                        <dd>
                                                            {
                                                                this.props.loadedPlates.map((dp, i) => (<LoadedPlate key={i} loadedPlate={dp} />))
                                                            }
                                                        </dd>
                                                    </>
                                                ) :
                                                (null)
                                        }
                                    </>
                                </dl>
                            </>
                        ) :
                        (null)
                }
            </>
        );
    }
}

const LoadedPlates = withRouter(connect(Props, Actions)(Component));

export default LoadedPlates;