import { IState, IPlate } from "../../../Interfaces";

const onUpdatePlateReducer = (state: IState, action) => {
    const updatedPlate = action.payload as IPlate;
    const indexToBeReplaced = state.data.plates.loadedPlates.findIndex(lp => lp.id === updatedPlate.id);
    const isPlateExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPlateExists) newState.data.plates.loadedPlates.splice(indexToBeReplaced, 1, updatedPlate);

    newState.data.plates.selectedPlateIds = newState.data.plates.selectedPlateIds.filter(id => id !== updatedPlate.id);

    return newState;
};

export default onUpdatePlateReducer;