import { Auth } from 'aws-amplify';

import ACTION_TYPES from '../../../actionTypes';

const onLoadCurrentUserInfoInit = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.CURRENT_USER_INFO.INIT,
        payload: null,
    };
};

const onLoadCurrentUserInfoDone = (creator) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.CURRENT_USER_INFO.DONE,
        payload: creator,
    };
};

const onLoadCurrentUserInfo = () => {
    return async (dispatch, getState) => {
        dispatch(onLoadCurrentUserInfoInit())
        const currentUserInfo = await Auth.currentUserInfo() as any;
        const creator = currentUserInfo?.attributes?.email as string;
        dispatch(onLoadCurrentUserInfoDone(creator));
    };
};

export default onLoadCurrentUserInfo;