import { Button } from "@material-ui/core";
import React, {useState, useEffect} from "react";
import MatrixParamsEditingModal from "./MatrixParamsEditingModal"
import IMatrix from '../interfaces/IMatrix'
import IRule, {ConfigType} from '../interfaces/IRule'
import { v4 as uuidv4 } from "uuid";
interface IProps {
    selectedMatrix: IMatrix;
    dispatch: any;
    setSizeEditing( arg: boolean): void
}

const MatrixParamsEditing: React.FunctionComponent<IProps> =
 (props) => {
   let defaultValueColumns = props.selectedMatrix.columns;
   let defaultValueColumnGap = props.selectedMatrix.columnGap;
   let defaultValueRows = props.selectedMatrix.rows;
   let defaultValueRowGap = props.selectedMatrix.rowGap
   let rules = props.selectedMatrix.configRules;

   const [selectedRule, setSelectedRule] = useState<IRule | null>(null);

    useEffect(() => {
    rules.forEach((rule)=> {
        if(defaultValueColumns !== rule.columns || defaultValueRows !==  rule.rows) {
            props.dispatch({
                type: "deleteRule",
                cargo: {
                UUID: rule.UUID
                },
            })
        }
        })
   }, [defaultValueColumns, defaultValueRows]);

   const rulesRender = props.selectedMatrix.configRules.map(rule =>{ 
      const displayName = rule.name;
      return (
          <div>
            {displayName}   
            <img
                src={"/pencil-sharp.svg"}
                style={{ width: "32px", height: "32px" }}
                onClick={() =>
                   setSelectedRule(rule)
                }
            />
            <img
                src={"/close-sharp.svg"}
                style={{ width: "32px", height: "32px" }}
                onClick={()=>{
                    props.dispatch({
                        type: "deleteRule",
                        cargo: {
                          UUID: rule.UUID
                        },
                      })
                }}
            />
        </div>
      )
        
    });
    return (
        <div>
        <MatrixParamsEditingModal 

            onClose={() => {setSelectedRule(null)}}
            dispatch={props.dispatch}
            rule={selectedRule}/>
        <div style={{display : "flex", justifyContent: "space-around"}}>
            <div style={{display : "flex"}}>
                Columns:
                <div style={{ width:32,
                        borderStyle:"none"
                             }}>

                    {defaultValueColumns}
                </div>
               
            </div>
            <div style={{display : "flex"}}>
                    ColumnGap:
                    <div                        
                        style={{ width:32,
                            borderStyle:"none"
                        }}
                    >
                        {defaultValueColumnGap}
                    </div>

            </div>
        </div>
        <div style={{display : "flex", justifyContent: "space-around"}}>
            <div style={{display : "flex"}}>
                Rows:
                <div
                    style={{ width:32,
                        borderStyle:"none"
                }}
                >
                    {defaultValueRows}
                </div>
            </div>
            <div style={{display : "flex"}}>
                RowGap:
                <div   
                    style={{ width:32,
                        borderStyle:"none"
                }}
                >
                    {defaultValueRowGap}
                </div>
            </div>
        </div>

        <div style={{display: "flex", marginTop: "20px"}}>
            Matrix configuration Rules: 
                <button style={{width: "70px", height: "30px", fontSize: "15px", background:"#ff9900", border:"none", borderRadius:"5px"}}
                    onClick = {()=> {
                        const tempRule: IRule = {
                            propertyPath: '',
                            configType: ConfigType.PartConfig,
                            name: '', 
                            UUID: uuidv4(),
                            columns: props.selectedMatrix.columns,
                            rows: props.selectedMatrix.rows,
                            values: []
                        }
                        setSelectedRule(tempRule);
                    }}
                >
                    AddNew
                </button>
        </div>
            <div style={{ overflow: "scroll", maxHeight: "100%" }}>
                 {rulesRender}
            </div>

        <div style={{ display:"flex", justifyContent:"space-around"}}>
                <Button variant="contained"
                    color="secondary"
                    onClick={()=> {
                        props.setSizeEditing(true)
                    }}
                >
                    Go back
                </Button>

                <Button variant="contained"
                    color="primary"
                    onClick={()=>{
                        props.dispatch({
                            type:"saveMatrix",
                        });
                    }}
                >
                    Create Matrix
                </Button> 
        </div>
    </div>
    )
}
 export default MatrixParamsEditing;