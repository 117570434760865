import API, { graphqlOperation } from '@aws-amplify/api';
import { union } from 'lodash';

import { foldersByCreatedAt } from '../../../../graphql/queries';

import ACTION_TYPES from '../../../actionTypes';
import { IFolder } from '../../../Interfaces';

const getAvailableFolders = (folders: IFolder[] = []) => {
    return folders.filter(p => Boolean(p && !p._deleted));
};

const onLoadFoldersStarted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.FOLDERS.STARTED,
        payload: null,
    };
};

const onLoadFoldersInProgress = (newlyLoadedFolders) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.FOLDERS.IN_PROGRESS,
        payload: newlyLoadedFolders,
    };
};

const onLoadFoldersCompleted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.FOLDERS.COMPLETED,
        payload: null,
    };
};

const loadFolders = async (dispatch, getState, response: { loadedFolders: IFolder[], nextToken: any } = { loadedFolders: [] as IFolder[], nextToken: undefined }) => {
    try {
        const variables = {
            limit: 50,
            dumb: 1,
            sortDirection: 'DESC',
            nextToken: response.nextToken,
        };
        const result = await API.graphql(graphqlOperation(foldersByCreatedAt, variables)) as any;
        const loadedFolders = getAvailableFolders(response?.loadedFolders as IFolder[]);
        const newlyLoadedFolders = getAvailableFolders((result as any)?.data?.foldersByCreatedAt?.items || [] as IFolder[]);
        dispatch(onLoadFoldersInProgress(newlyLoadedFolders));

        const folders = getAvailableFolders(union(loadedFolders, newlyLoadedFolders) as IFolder[]);
        const nextToken = (result as any)?.data?.foldersByCreatedAt?.nextToken || undefined;
        const newResponse = {
            loadedFolders: folders,
            nextToken: nextToken,
        };
        if (nextToken) await loadFolders(dispatch, getState, newResponse);
        return Promise.resolve();
    } catch (graphqlError) {
        return Promise.resolve();
    }
};

const onLoadFolders = () => {
    return async (dispatch, getState) => {
        dispatch(onLoadFoldersStarted());
        await loadFolders(dispatch, getState);
        dispatch(onLoadFoldersCompleted());
    };
};

export default onLoadFolders;