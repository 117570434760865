import { IState } from "../../../Interfaces";

const onUnselectPartReducer = (state: IState, action) => {
    const partId = action.payload as string;

    const newState = { ...state } as IState;
    const index = newState.data.parts.selectedPartIds.indexOf(partId)
    newState.data.parts.selectedPartIds.splice(index,1);

		if (!newState.data.parts.selectedPartIds.find((a) => a === partId)) {
			newState.data.parts.selectedParts = newState.data.parts.selectedParts.filter((a) => a.id !== partId);
		}

    return newState;
};

export default onUnselectPartReducer;
