import { IState } from "../../../Interfaces";

const onNewBuildSuccessOpenDialogReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.modals.newBuildSuccessModalStatus = true

    return newState;
};

export default onNewBuildSuccessOpenDialogReducer;