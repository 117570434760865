import {Storage} from "aws-amplify";
import {ActionTypes, IActionType} from "../reducer";

export class DynamicObject {
  [key: string]: any;
}

export class S3ObjectStore {
  name: string;
  keyPath: string;
  cache: any;
  onUpdate: (arg0: IActionType) => any;

  constructor(name: string, path: string, onUpdate: any) {
    this.name = name;
    this.keyPath = path;
    this.onUpdate = onUpdate;
    this.cache = {}
  }

  readJson = async (element: string): Promise<any> => {
    try {
      if (element in this.cache)
        return this.cache[element]
      let result = await Storage.get(element, {
        download: true,
        cacheControl: "max-age=0",
      });
      let object = await new Response((result as any).Body).json();
      object.id = element.split("/")[2].split(".").slice(0, -1).join(".");
      this.onUpdate({
        type: ActionTypes.Add,
        cargo: {
          tableName: this.name,
          data: object,
        },
      });
      this.cache[element] = object
      return object;
    }
    catch (e) {
      console.log(`Exeption: ${e}`)
    }
  };

  addFile = async (objectId: string, fileRole: string, file: File) => {
    if (!file.name || !fileRole) return;
    let obj = await this.getObject(objectId)
    if (obj) {
      if (!("files" in obj)) {
        obj.files = {};
      }
      let ext = "." + file.name.split(".").slice(-1);
      let objectFilePath = this.getObjectFilePath(objectId, fileRole + ext);

      await Storage.put(objectFilePath, file)
      obj.files[fileRole] = objectFilePath;
      await this.update(obj);
    } else {
      console.log("Object with this ID not exists: " + objectId);
    }
  };

  readObjects = async (startIndex = 0, count = 0, filter:any[] = [], fileList:String[] = []): Promise<any> => {
    let keys = await Storage.list(this.keyPath);
    if (keys.length < 2)
      return;

    let subKeysList = keys.slice(1);
    subKeysList = subKeysList.sort( (a, b) => {
      const aDate= a?.lastModified?.getTime() || 0
      const bDate= b?.lastModified?.getTime() || 0
      if ( aDate < bDate)
        return 1
      if (aDate > bDate)
        return -1
      return 0
    })
    let nameFilter = filter.find(val => {
      return val.field === "name"
    })
    let emailFilter = filter.find(val => {
      return val.field === "email"
    })
    let archiveFilter = filter.find(val => {
      return val.field === "archive"
    })


    if (nameFilter) {
      subKeysList = subKeysList.filter( element => {
        if (element.key) {
          let splitPath = element.key.split("/");
          return splitPath[2] && splitPath[2].indexOf(nameFilter.value) !== -1
        }
        else
          return false
      })
    }

    if (count === 0)
      count = subKeysList.length - startIndex

    if (fileList.length > 0) {
      let promises: Promise<any>[] = []

      fileList.forEach(element => {
        promises.push(this.readJson(`${this.keyPath}${element}.json`));
      });
      return { page: await Promise.all(promises), totalCount: fileList.length > 0 ? fileList.length : subKeysList.length }

    }
    else if (!emailFilter && !archiveFilter) {
      let promises: Promise<any>[] = []
      for (let ind = startIndex; ind < startIndex + count; ind++) {
        let element = subKeysList[ind]
        if (element.key) {
          promises.push(this.readJson(element.key));
        }
      }
      return { page: await Promise.all(promises), totalCount: subKeysList.length }
    }
    else{
      let currentStart = 0;
      let filteredIndex = 0;
      let filteredResult: any[] = [];
      let toProcess = startIndex+count;

      do{
        let promises: Promise<any> [] = []
        let currentEnd = Math.min(currentStart + Math.max(10, toProcess), subKeysList.length)
        for (let ind = currentStart; ind < currentEnd; ind++) {
          let element = subKeysList[ind]
          if (element.key) {
            promises.push(this.readJson(element.key));
          }
        }
        currentStart = currentEnd
        let candidatesArray = await Promise.all(promises)
        for (let i = 0; i < candidatesArray.length; i++){
          let val = candidatesArray[i]
          if (emailFilter && val.creator !== emailFilter.value){
            continue
          }
          if (archiveFilter){
            if (archiveFilter.value === "Active" && !!val['isArchive'])
              continue
            if (archiveFilter.value === "Archive" && !val['isArchive'])
              continue
          }
          filteredIndex++;
          if (filteredIndex > startIndex + count)
            break
          if (filteredIndex > startIndex){
            filteredResult.push(val)
          }
        }
        toProcess = startIndex + count - filteredIndex
      }while(currentStart < subKeysList.length && filteredResult.length !== count)
      return {page: filteredResult, totalCount: subKeysList.length}
    }
  };

  getObject = async (id: string) => {
    return await this.readJson(this.getKey(id)) ;
  };

  store = async (objectId: string, value: any, files = []) => {
    if (files.length !== 0) {
      if (!("files" in value)) value.files = {};
    }

    let promises = files.map(async (val: any) => {
      let ext = "." + val.localFile.name.split(".").slice(-1);
      let objectFilePath = this.getObjectFilePath(objectId, val.name + ext);
      await Storage.put(objectFilePath, val.localFile);
      (value.files as DynamicObject)[val.name] = objectFilePath;
    });
    await Promise.all(promises);
    await Storage.put(this.getKey(objectId), value);
    this.cache[this.getKey(objectId)] = value;
    return value;
  };

  private getKey(objectId: string) {
    return this.keyPath + objectId + ".json";
  }

  add = async (objectId: string, value: any, files = [], withUpdate = true) => {
    // let obj = this.objects.find((obj: any) => obj.id == objectId);
    // if (obj) {
    //   console.log("Object with this ID exists: " + objectId);
    // }
    await this.store(objectId, value, files);
    if (withUpdate) {
      this.onUpdate({
        type: ActionTypes.Add,
        cargo: {
          tableName: this.name,
          data: value,
        },
      });
    }
    return value;
  };

  del = async (objectId: string) => {
    const objectKey =  this.getKey(objectId)
    await Storage.remove(objectKey);
    if (objectKey in this.cache)
      delete this.cache[objectKey]
    this.onUpdate({
      type: ActionTypes.Delete,
      cargo: {
        tableName: this.name,
        id: objectId,
      },
    });
  };

  update = async (value: any, files = []) => {
    let objectId = value.id;
    await this.add(objectId, value, files, false);
    this.onUpdate({
      type: ActionTypes.Update,
      cargo: {
        tableName: this.name,
        id: objectId,
        data: value,
      },
    });
    return true;
  };

  private getObjectFilePath(objectId: string, name: any) {
    return "FileStorage/" + this.name + "/" + objectId + "_" + name;
  }
}

class S3DataStore {
  initialComplete = false;

  tableNames = [
    "MachineConfigs",
    "Recipes",
    "MaterialConfigs",
    "Parts",
    "PartRecipes",
    "Builds",
    "SlicerConfigs",
    "MillConfigs",
    "BuildFolders",
    "TableIndexes"
  ];
  objectStores: DynamicObject = {};
  private _dataBaseName: string;

  constructor(dataBaseName: string) {
    Storage.configure({ track: true });
    this._dataBaseName = dataBaseName;
    console.log('S3DataStore',this._dataBaseName,this.tableNames,dataBaseName);
    this.tableNames.forEach((tableName) => {
      this.objectStores[tableName] = new S3ObjectStore(
        tableName,
        dataBaseName + "/" + tableName + "/",
        this.updater
      );
    });
  }

  dispatchFunc = (updaterObject: any) => {
    console.log("dumb dispatch", updaterObject);
  };

  updater = (action: any) => {
    this.dispatchFunc(action);
  };

  getObjectFileName = async (tableName: string, objectId: string, fileType?: string) => {
    return (await this.getObjectFilePath(tableName, objectId,fileType)).split("/").pop();
  };

  getObjectFilePath = async (tableName: string, objectId: string, dataType?:string) => {
    if (!dataType){
      dataType = "data"
    }
    let object = await this.objectStores[tableName].getObject(objectId)
    if (typeof object !== "undefined") {
      return object.files[dataType];
    }
    return "";
  };


  downloadObjectFile = async (tableName: string, objectId: string, fileType?: string) => {
    if (!fileType){
      fileType = "data"
    }

    let object = await this.objectStores[tableName].getObject(objectId)
    const getResult = await Storage.get(object.files[fileType], {
      download: true,
    })
    return await (getResult as any).Body.arrayBuffer();
  };

  listTables = () => {
    if (!this.initialComplete) {
      this.tableNames.forEach((value) => {
        this.updater(value);
      });
      this.initialComplete = true;
    }
  };
}

let myDataStorage: S3DataStore;

const dataStorage = (): S3DataStore => {
  if (!myDataStorage) myDataStorage = new S3DataStore("VizApp");
  return myDataStorage;
};

export default dataStorage;
