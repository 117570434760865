import * as THREE from "three";

export class HullHelper{
  slices : any;
  tolerance: number;
  minY: number
  maxY: number
  constructor(tolerance = 0.1) {
    this.tolerance = tolerance
    this.slices = {}
    this.minY = NaN
    this.maxY = NaN
  }

  reset(tolerance = -1.0){
    if (tolerance >=0){
      this.tolerance = tolerance
      this.minY = this.maxY = NaN
    }
    this.slices = {}
  }

  addPoint(x, y){
    let slice = Math.round(x / this.tolerance)
    if (isNaN(this.minY)){
      this.minY = this.maxY = y
    }
    else {
      if (y < this.minY){
        this.minY = y
      }
      else if (y > this.maxY){
        this.maxY = y
      }

    }
    if (slice in this.slices){
      if (this.slices[slice].minY > y){
        this.slices[slice].minY = y;
      }
      else if (this.slices[slice].maxY < y){
        this.slices[slice].maxY = y;
      }
    }
    else{
      this.slices[slice] = {minY: y, maxY: y}
    }
  }
  getTopBottomPoints() {
    const orderedSlices = Object.keys(this.slices).map(value => parseInt(value, 10));
    orderedSlices.sort(function(a, b) {
      return a - b;
    });
    let indexOfBottom = 0;
    let x = orderedSlices[indexOfBottom];
    let bottomPoints: any [] = [{
      x: x,
      y: this.slices[x].minY,
      ky: -1000000000
    }];
    let indexOfTop = 0;
    let topPoints: any [] = [{
      x: x,
      y: this.slices[x].maxY,
      ky: 1000000000
    }];
    for (let i = 1; i < orderedSlices.length; i++) {
      let x = orderedSlices[i];
      let y = this.slices[x].minY;
      let currentMin = {
        x: x,
        y: y,
        ky: (y - bottomPoints[indexOfBottom].y) / (x - bottomPoints[indexOfBottom].x)
      };

      while (true) {
        if (currentMin.ky <= bottomPoints[indexOfBottom].ky) {
          indexOfBottom--;
          currentMin.ky = (currentMin.y - bottomPoints[indexOfBottom].y) / (currentMin.x - bottomPoints[indexOfBottom].x);
        } else {
          indexOfBottom++;
          bottomPoints[indexOfBottom] = currentMin;
          break;
        }
      }

      y = this.slices[x].maxY;
      let currentMax = {
        x: x,
        y: y,
        ky: (y - topPoints[indexOfTop].y) / (x - topPoints[indexOfTop].x)
      };

      while (true) {
        if (currentMax.ky >= topPoints[indexOfTop].ky) {
          indexOfTop--;
          currentMax.ky = (currentMax.y - topPoints[indexOfTop].y) / (currentMax.x - topPoints[indexOfTop].x);
        } else {
          indexOfTop++;
          topPoints[indexOfTop] = currentMax;
          break;
        }
      }
    }
    return {
      bottom: {
        index: indexOfBottom,
        points: bottomPoints.map(value => {
           value.x *= this.tolerance
           value.ky /=this.tolerance
          return value
        }),
        extremum: this.minY
      },
      top: {
        index: indexOfTop,
        points: topPoints.map(value => {
          value.x *= this.tolerance
          value.ky /=this.tolerance
          return value
        }),
        extremum: this.maxY
      }
    }
  }

  getHull(){
    let data = this.getTopBottomPoints();
    let minsEnd = data.bottom.points[0].y === data.top.points[0].y? 1: 0
    let minsStart = data.bottom.points[data.bottom.index].y === data.top.points[data.top.index].y? data.bottom.index-1: data.bottom.index
    let vertexes: THREE.Point[] = []

    for (let i = 0; i <= data.top.index; i++){
      vertexes.push(new THREE.Vector3(data.top.points[i].x, data.top.points[i].y, 0))
    }

    for (let i = minsStart; i >=minsEnd; i--){
      vertexes.push(new THREE.Vector3(data.bottom.points[i].x, data.bottom.points[i].y, 0))
    }

    return vertexes
    // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    // const mesh = new THREE.Mesh( geometry, material )
  }

}

export function hullApproximation(mesh: THREE.Object3D, tolerance:number, offset: number = 0 ){
  let hull = new HullHelper(tolerance)


  mesh.updateMatrixWorld( true );

  mesh.traverse( function ( node ) {

    const geometry = node.geometry;

    if ( geometry !== undefined ) {

      if ( geometry.isGeometry ) {

        console.error( 'THREE.ConvexHull no longer supports Geometry. Use THREE.BufferGeometry instead.' );
        return;

      } else if ( geometry.isBufferGeometry ) {

        const attribute = geometry.attributes.position;

        if ( attribute !== undefined ) {

          for ( let i = 0, l = attribute.count; i < l; i ++ ) {

            const point = new THREE.Vector3();

            point.fromBufferAttribute( attribute, i ).applyMatrix4( node.matrixWorld );

            hull.addPoint(point.x, point.y)
          }
        }
      }
    }
  } );
  // const geom = mesh.geometry as THREE.BufferGeometry
  // const points = geom.attributes.position.array;
  // for (let i = 0; i < points.length/3; i++) {
  //   hull.addPoint(points[i * 3], points[i * 3 + 1]);
  // }
  let vertexes = hull.getHull()
  if ( offset < 0.000001) {
    return hull.getTopBottomPoints()
  }
  hull.reset(tolerance)
  const r2 = offset * offset
  vertexes.forEach(val => {
    for (let dx = 0; dx <= offset; dx+=tolerance) {
      const dy = Math.sqrt(Math.max(r2- dx*dx, 0.0))
      hull.addPoint(val.x - dx, val.y+dy)
      hull.addPoint(val.x - dx, val.y-dy)
      hull.addPoint(val.x + dx, val.y+dy)
      hull.addPoint(val.x + dx, val.y-dy)
    }
  })
  return hull.getTopBottomPoints()
}

export class ApproxIterator{
  data:any
  currentIndex
  constructor(data){
    this.data = data
    this.currentIndex = data.index
  };

  getCurrentPoint(){
    return this.data.points[this.currentIndex]
  }

  getNextPoint(){
    if (this.currentIndex < 1)
      return this.data.points[0]
    else
      return this.data.points[this.currentIndex - 1]
  }

  getApproxForX(x){
    if (this.currentIndex < 1) {
      if (Math.abs(this.data.points[0].x - x) < 0.000001){
        return  this.data.points[0].y
      }
      return NaN
    }
    while (this.data.points[this.currentIndex - 1].x >= x) {
      this.currentIndex -= 1;
      // we do not have correspondent point
      if (this.currentIndex < 1) {
        if (Math.abs(this.data.points[0].x - x) <= 0.000001){
          return  this.data.points[0].y
        }
        return NaN
      }
    }
    let p = this.data.points[this.currentIndex]
    return p.y + (x-p.x)*p.ky
  }
}

export function checkCollisionAccurate(object1, object2){
  let id1  = object1
  let id2 =  object2

  //part2 left of part 1
  if (id2.top.points[id2.top.index].x < id1.top.points[0].x){
      return false
  }

  //part1 left of part 3
  if (id2.top.points[0].x > id1.top.points[id1.top.index].x){
    return false
  }

  // part2 under the part1
  if (id2.top.extremum < id1.bottom.extremum) {
    return false
  }

  // part1 under the part2
  if (id1.top.extremum < id2.bottom.extremum) {
    return false
  }

  let masterObject
  let serveObject
  if (id2.top.points[id2.top.index].x < id1.top.points[id1.top.index].x){
    masterObject = id2
    serveObject = id1
  }
  else{
    masterObject = id1
    serveObject = id2
  }

  let serveTopIterator = new ApproxIterator(serveObject.top)
  let masterTopStart = masterObject.top.points[masterObject.top.index]
  let serveTopStartY = serveTopIterator.getApproxForX(masterTopStart.x)

  let serveBottomIterator = new ApproxIterator(serveObject.bottom)
  let masterBottomStart = masterObject.bottom.points[masterObject.bottom.index]
  let serveBottomStartY = serveBottomIterator.getApproxForX(masterBottomStart.x)

  // check intersection for start X
  if (serveBottomStartY >= masterBottomStart.y && serveBottomStartY <= masterTopStart.y){
    // console.log(1)
    return true
  }
  if (serveTopStartY >= masterBottomStart.y && serveTopStartY <= masterTopStart.y){
    // console.log(2)
    return true
  }
  if(masterTopStart.y >= serveBottomStartY && masterTopStart.y <= serveTopStartY)
  {
    // console.log(3)
    return true
  }

  if(masterBottomStart.y >= serveBottomStartY && masterBottomStart.y <= serveTopStartY)
  {
    // console.log(4)
    return true
  }
  let upper
  let lower
  if (serveBottomStartY > masterTopStart.y){
    upper = serveBottomIterator
    lower = new ApproxIterator(masterObject.top)
  }
  else {
    upper = new ApproxIterator(masterObject.bottom)
    lower = serveTopIterator
  }

  let currentX = masterTopStart.x
  while (true){
    let nextUpper = upper.getNextPoint()
    let nextLower = lower.getNextPoint()
    let nextX = Math.max(nextUpper.x, nextLower.x)

    //X do not change. We are at the end of one of iterators
    if (currentX === nextX)
      return false

    let upperY = upper.getApproxForX(nextX)
    let lowerY = lower.getApproxForX(nextX);
    if (isNaN(upperY) || isNaN(lowerY))
      return false
    if ( upperY <= lowerY) {
      return true
    }
    if (nextX === nextUpper.x){
      upper.currentIndex--
    }
    if (nextX === nextLower.x){
      lower.currentIndex--
    }
    currentX = nextX
  }
}
