import { SortDirection } from '../../../Enums';
import { IPart, IState } from '../../../Interfaces';

const onLoadPartsStartedReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.parts = {
        ...newState.data.parts,
        highlightedPartId: '' as string,
        isLoadingStarted: true as boolean,
        isLoadingInProgress: true as boolean,
        isLoadingComplete: false as boolean,
    };

    return newState;
};

export default onLoadPartsStartedReducer;
