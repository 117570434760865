import { IState } from "../../../Interfaces";

const onNewBuildNameChangeReducer = (state: IState, action) => {
    const buildNameToBeCreated = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.newBuildModalStatus = {
        ...newState.data.modals.newBuildModalStatus,
        inputText: buildNameToBeCreated,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onNewBuildNameChangeReducer;