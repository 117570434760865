import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IPartInfo from '../../../../interfaces/IPartInfo';
import { onNavigateToPlatesPage } from '../../../Actions/Home';
import { IDisplayPlate, IPlate, IState } from '../../../Interfaces';
import { onHighlightPlate } from '../../../Actions/Plates';
import DisplayPlateOptions from './DisplayPlateOptions';

const Props = (state: IState, ownProps: any) => {
	const props = {
		loadedPlates: state.data.plates.loadedPlates as IPlate[],
		displayPlate: ownProps.displayPlate as IDisplayPlate,
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
};

class Component extends React.Component<any, any> {
	createdDate = (new Date(this.props.displayPlate.createdAt) as Date).toLocaleString() as string;

	onNavigateToPlatesPage = (plateId) => {

		this.props.onHighlightPlate(plateId);

		const plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;

		const partIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartID).filter(Boolean) as string[];
		const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
		const partConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartConfig['originalJson']['id']).filter(Boolean);
		const partSlicerConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.SlicerConfig['originalJson']['id']).filter(Boolean);
		const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

		const ids = {
			partIds,
			parts,
			partConfigurationIds,
			partSlicerConfigurationIds,
			plateConfigurationIds,
		};

		this.props.onNavigateToPlatesPage(ids, this.props.history, false);
	}

	public render() {
		return (
			<>
				<div
					className={`display-list is-selected-${this.props.displayPlate.isSelected}`}
					id={`plate-${this.props.displayPlate.index}`}
					key={`${this.props.displayPlate.index}`}
				>
					<div className='display-list-row'>
						<div className='display-list-sub-header'>
							<div className='options left-justify'>
								<div className='plate option'>
									<img className='icon plate' alt='plate' />
								</div>
							</div>
						</div>
						<div className='display-list-sub-header'>
							<label className='text-ellipsis-second-line'>
								{this.props.displayPlate.name}
							</label>
						</div>
						<div className='display-list-sub-header'>
							<label className='text-ellipsis-second-line'>
								{this.props.displayPlate.createdDate ?? this.createdDate}
							</label>
						</div>
						<div className='display-list-sub-header'>
							<label className='text-ellipsis-second-line'>
								{this.props.displayPlate.creator}
							</label>
						</div>
						<div className='display-list-sub-header'>
							{/* <label>{this.props.displayPlate.builds.length}</label> */}
						</div>
						<div className='display-list-sub-header'>
							<label className='text-ellipsis-second-line'>
								{this.props.displayPlate.isMovedToFolder ? this.props.displayPlate.folders.map(folder => <label>{folder}</label>) : null}
							</label>
						</div>
						<div className='display-list-sub-header'>
							<div className='display-list-more-action'
								onClick={(e) => e.stopPropagation()}>
								<DisplayPlateOptions
									{...{ displayPlate: this.props.displayPlate }}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const DisplayPlateListView = withRouter(connect(Props, Actions)(Component));

export default DisplayPlateListView;
