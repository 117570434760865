import IPartInfo from "../../../../interfaces/IPartInfo";
import { TabIndex } from "../../../Enums";
import { IPlate, IPart, IState } from "../../../Interfaces";

const onNewPlateSuccessReducer = (state: IState, action) => {
    const addedPlate = action.payload as IPlate;
    
    const newState = { ...state } as IState;
    newState.data.workspace.addedParts = [] as IPart[];
    newState.data.workspace.state.build.parts = [] as IPartInfo[];
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    newState.data.modals.newPlateModalStatus = {
        ...newState.data.modals.newPlateModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    newState.data.info.homePageTabIndex = TabIndex.PLATES;
    
    return newState;
};

export default onNewPlateSuccessReducer;
