import { IState } from '../../../Interfaces';

const onMovePlateOpenDialogReducer = (state: IState, action) => {
    const newState = { ...state } as IState;
    newState.data.modals.movePlateModalStatus = {
        ...newState.data.modals.movePlateModalStatus,
        inputText: '',
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    return newState;
};

export default onMovePlateOpenDialogReducer;