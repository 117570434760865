import { IState, IFolder } from "../../../Interfaces";

const onUnarchiveFolderReducer = (state: IState, action) => {
    const unarchivedFolder = action.payload as IFolder;
    const indexToBeReplaced = state.data.folders.loadedFolders.findIndex(lp => lp.id === unarchivedFolder.id);
    const isFolderExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isFolderExists) newState.data.folders.loadedFolders.splice(indexToBeReplaced, 1, unarchivedFolder);

    newState.data.folders.selectedFolderIds = newState.data.folders.selectedFolderIds.filter(id => id !== unarchivedFolder.id);

    return newState;
};

export default onUnarchiveFolderReducer;