import React from "react";

import "../../css/BuildPreparation.scss";
import Button from 'react-bootstrap/Button';
import API, { graphqlOperation } from "@aws-amplify/api";
import { getParts, listBuilds, buildByCreatedAt, buildByInitiated, configurationByCreatedAt } from "../../graphql/queries";
import Amplify, { Auth, Storage } from "aws-amplify";
import { createBuild, createConfigurations } from "../../graphql/mutations";
import MVPParts from "../../MVPParts/MVPParts";
import MVPPrinterSelection from "../../MVPPrinterSelection/MVPPrinterSelection"
import MVPBPConfiguration from "../MVPConfiguration/MVPBPConfiguration";
import WorkspaceScene from "../../WorkSpace/WorkspaceScene"
import workSpaceReducer from "../../WorkSpace/workspaceReducer";
import Modal from 'react-bootstrap/Modal';


import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import JSZip from "jszip";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";

import EditJsonModal from "../../EditJsonModal";
import dataStorage from "../../S3DataStore/S3DataStore";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import saveFile, { patchSlicer } from "../../utils";
import { schemas } from "../../Schemas";
import IPartInfo from "../../interfaces/IPartInfo";
import IMatrix from "../../interfaces/IMatrix";
import IBuild from '../../interfaces/IBuild';
import Scene from "../../Scene";
import { stringify } from "querystring";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import awsconfig from '../../aws-exports';
import { isTemplateExpression } from "typescript";
import ConfigSelector from "./ConfigSelector";
import PartConfigSelector from "./PartConfigSelector";
import PartSelector from "./PartSelector";

Amplify.configure(awsconfig);


const jsondiffpatch = require('jsondiffpatch').create();

type PrintableEntity = IPartInfo | IMatrix;
export interface IWorkSpaceState {
	initiator: any,
	build: IBuild;
	showLoading: string[];
	slicerText: string;
	combindedConfigEditor: Object | null;
	viewState: WorkspaceViewType;
	selectedMatrix: IMatrix | null;
	moho: string;
	selectedPart?: any[];
	selectedPart1?: any;
	configurationList?: any[];
	open?: boolean;
	configOpen?: boolean;
	selectedPrinter?: any;
	selectedConfig?: any;
	selectedConfigType?: string;
	isscreenChangedTog?: boolean;
	reviewLoading?: boolean
	matrixOpen?: boolean;
}

export enum WorkspaceViewType {
	SceneView,
	GrafanaView
}

export interface IWorkSpace {
	getResultJson(date: string): Promise<Object>;
	openJsonInEditor(json: Object): void;
	downloadBundle(): void;
	sendBundle(name: string): void;
	updateName(json: Object): void;
}

export class BuildPreperation extends React.Component<any, IWorkSpaceState> {
	dispatch: (action: any) => void;
	zip: JSZip;
	scene: Scene | null = null
	constructor(props: any) {
		super(props);
		this.state = {
			initiator: null,
			showLoading: ['hideLoading'],
			configurationList: [],
			selectedPart: [],
			selectedPart1: null,
			selectedPrinter: {},
			selectedConfig: {},
			selectedConfigType: '',
			open: false,
			configOpen: false,
			moho: "",
			build: {
				id: this.props.location.state.buildName,
				machineConfig: null,
				millConfig: null,
				materialConfig: null,
				recipe: null,
				parts: [],
				bundles: [],
			},
			slicerText: "",
			combindedConfigEditor: null,
			viewState: WorkspaceViewType.SceneView,
			selectedMatrix: null,
			matrixOpen: false
		};
		this.dispatch = (action: any) => {
			if (action?.type === "addLoading") {
				this.state.showLoading[0] = 'showLoading';
			} else if (action?.type === "removeLoading") {
				this.state.showLoading[0] = 'hideLoading';
			}
			const newState = workSpaceReducer(this.state, action);
			console.log('MVPBuildPreperation ~~~dispatch ~~~~', action, newState);
			this.setState(newState);
			//  console.log('MVPBuildPreperation~~~state~~', newState);
		};

		// this.getResultJson = this.getResultJson.bind(this);
		// this.openJsonInEditor = this.openJsonInEditor.bind(this);
		// this.downloadBundle = this.downloadBundle.bind(this);
		// this.sendBundle = this.sendBundle.bind(this);
		// this.updateName = this.updateName.bind(this);

		// this.generateBundle = this.generateBundle.bind(this);
		this.zip = new JSZip();

	}
	setScene(scene: Scene) {
		this.scene = scene
	}

	private loadConfigurationListPromise = () => {
		return new Promise(async (resolve, reject) => {
			const variables = {
				dumb: 1,
				limit: 100,
			};
			const result = await API.graphql(graphqlOperation(configurationByCreatedAt, variables));
			const configs = (result as any).data.configurationByCreatedAt.items;
			this.setState({
				configurationList: configs.filter(config => !config._deleted)
			});
			return resolve(true);
		});
	}

	private loadInitiatorPromise = () => {
		return new Promise(async (resolve, reject) => {
			const result = (await Auth.currentUserInfo());
			this.setState({
				initiator: result.attributes.email,
			})
			return resolve(true);
		});
	}

	private getPart = (part, index) => {
		const defaultPartConfig = this.state.configurationList?.find(a => a.id === 'defaultPartConfiguration') || {};
		const defaultPartSlicerConfig = this.state.configurationList?.find(a => a.id === 'defaultPartSlicerConfiguration') || {};
		const defaultPartConfigData = JSON.parse(defaultPartConfig['data'] || '{}');
		const defaultPartSlicerConfigData = JSON.parse(defaultPartSlicerConfig['data'] || '{}');

		return ({
			customLabel: `Part ${index + 1}`,
			buildErrors: false,
			UUID: uuidv4(),
			properties: {
				PartID: part.id,
				PartConfig: {
					originalJson: defaultPartConfigData,
					resultJson: defaultPartConfigData,
				},
				SlicerConfig: {
					originalJson: defaultPartSlicerConfigData,
					resultJson: defaultPartSlicerConfigData,
				},
			},
		} as IPartInfo);
	}

	private onPartChanged = (selectedPart1) => {
		this.setState({
			selectedPart1: selectedPart1,
		});
	}

	private onPartConfigChanged = (config) => {
		this.setState({
			build: {
				...this.state.build,
				parts: this.state.build.parts.map(part => {
					if (part.UUID === this.state.selectedPart1.UUID) {
						(part as IPartInfo).properties.PartConfig = {
							originalJson: JSON.parse(config['data']),
							resultJson: JSON.parse(config['data'])
						};
					}
					return part;
				}),
			},
		});
	}

	private onPartSlicerConfigChanged = (config) => {
		this.setState({
			build: {
				...this.state.build,
				parts: this.state.build.parts.map(part => {
					if (part.UUID === this.state.selectedPart1.UUID) {
						(part as IPartInfo).properties.SlicerConfig = {
							originalJson: JSON.parse(config['data']),
							resultJson: JSON.parse(config['data'])
						};
					}
					return part;
				}),
			},
		});
	}

	private onMaterialConfigChanged = (config) => {
		this.setState({
			build: {
				...this.state.build,
				materialConfig: {
					originalJson: JSON.parse(config['data']),
					resultJson: JSON.parse(config['data'])
				},
			},
		});
	}

	private onRecipeConfigChanged = (config) => {
		this.setState({
			build: {
				...this.state.build,
				recipe: {
					originalJson: JSON.parse(config['data']),
					resultJson: JSON.parse(config['data'])
				},
			},
		});
	}

	private onMillConfigChanged = (config) => {
		this.setState({
			build: {
				...this.state.build,
				millConfig: {
					originalJson: JSON.parse(config['data']),
					resultJson: JSON.parse(config['data'])
				},
			},
		});
	}

	private onPartConfigToBeEdited = (config) => {
		this.setState({
			selectedConfig: this.state.selectedPart1.properties.PartConfig,
			selectedConfigType: config['type'],
			configOpen: true,
		});
	}

	private onPartSlicerConfigToBeEdited = (config) => {
		this.setState({
			selectedConfig: this.state.selectedPart1.properties.SlicerConfig,
			selectedConfigType: config['type'],
			configOpen: true,
		});
	}

	private onMaterialConfigToBeEdited = (config) => {
		this.setState({
			selectedConfig: this.state.build.materialConfig,
			selectedConfigType: config['type'],
			configOpen: true,
		});
	}

	private onRecipeConfigToBeEdited = (config) => {
		this.setState({
			selectedConfig: this.state.build.recipe,
			selectedConfigType: config['type'],
			configOpen: true,
		});
	}

	private onMillConfigToBeEdited = (config) => {
		this.setState({
			selectedConfig: this.state.build.millConfig,
			selectedConfigType: config['type'],
			configOpen: true,
		});
	}

	private onCancelSelectedConfig = () => {
		this.setState({
			configOpen: false
		});
	}

	private onSaveSelectedConfig = async (data = {}, configType) => {
		switch (configType) {
			case 'partConfig': 
			this.setState({
				build: {
					...this.state.build,
					parts: this.state.build.parts.map(part => {
						if (part.UUID === this.state.selectedPart1.UUID) {
							(part as IPartInfo).properties.PartConfig = {
								originalJson: data,
								resultJson: data
							};
						}
						return part;
					}),
				},
				configOpen: false,
			});
			break;
			case 'partSlicerConfig': 
			this.setState({
				build: {
					...this.state.build,
					parts: this.state.build.parts.map(part => {
						if (part.UUID === this.state.selectedPart1.UUID) {
							(part as IPartInfo).properties.SlicerConfig = {
								originalJson: data,
								resultJson: data
							};
						}
						return part;
					}),
				},
				configOpen: false,
			});
			break;
			case 'material':
				this.setState({
					build: {
						...this.state.build,
						materialConfig: {
							originalJson: data,
							resultJson: data
						},
					},
					configOpen: false,
				});
				break;
			case 'recipe':
				this.setState({
					build: {
						...this.state.build,
						recipe: {
							originalJson: data,
							resultJson: data
						},
					},
					configOpen: false,
				});
				break;
			case 'millConfig':
				this.setState({
					build: {
						...this.state.build,
						millConfig: {
							originalJson: data,
							resultJson: data
						},
					},
					configOpen: false,
				});
				break;
			default:
				break;
		}
	}

	componentDidMount() {
		this.setState({
			selectedPart: this.props.location.state.selectedPart
		});

		const promises = [this.loadConfigurationListPromise(), this.loadInitiatorPromise()];

		Promise.allSettled(promises).then(this.setBuild);
	}

	public setBuild = () => {

		const selectedParts = this.props.location.state.selectedPart.map((part, index) => {
			return this.getPart(part, index);
		}) || [];

		this.setState({
			build: ({
				...this.state.build,
				id: this.props.location.state.buildName,
				name: this.props.location.state.buildName,
				dumb: 1,
				Min_Layer: -1,
				Max_Layer: -1,
				Max_Slab: -1,
				Contouring: true,
				overrides: [],
				current_status: "new",
				created_at: new Date().toISOString(),
				created_by: this.state.initiator,
				parts: (selectedParts as IPartInfo[]),
			} as IBuild),
		});
	}

	public handleClose = () => {
		this.props.history.push("/dashboard/home")
	}

	public showAddMorePart = () => {
		this.setState({
			isscreenChangedTog: !this.state.isscreenChangedTog

		});
	}
	public handleOnClose = (partsToBeAdded = []) => {
		const noOfPartsAlreadyPresent = this.state.build.parts.length;
		const selectedParts = partsToBeAdded.map((part, index) => {
			return this.getPart(part, noOfPartsAlreadyPresent + index);
		}) || [];
		this.setState({
			build: {
				...this.state.build,
				parts: this.state.build.parts.concat(selectedParts),
			},
			isscreenChangedTog: !this.state.isscreenChangedTog
		});
	}
	public handleSlice = () => {
		// this.setState({
		//   open: false
		// });
		if (this.state.build.parts.length > 0) {
			if (this.state?.selectedPrinter?.id) {
				if (this.state.build.machineConfig
					&& this.state.build.millConfig
					&& this.state.build.recipe
					&& this.state.build.materialConfig) {
					this.adddbuild();
				} else {
					NotificationManager.error(
						`MillConfig not selected`
					);
				}
			} else {
				NotificationManager.error(
					`Printer not selected`
				);
			}
		} else {
			NotificationManager.error(
				`No Part Selected`
			);
		}

	};

	public adddbuild = async () => {


		let partList: String[] = [];

		this.state.build.parts.forEach(a => {
			delete a['mesh'];
			partList.push(JSON.stringify(a))
		});
		let data: any = this.state.build;
		data.parts = partList;
		data.machineConfig = JSON.stringify(this.state.build.machineConfig);
		data.millConfig = JSON.stringify(this.state.build.millConfig);
		data.materialConfig = JSON.stringify(this.state.build.materialConfig);
		data.recipe = JSON.stringify(this.state.build.recipe);
		data['bundle'] = JSON.stringify(this.state.build.bundles.length > 0 ? this.state.build.bundles[0] : {});
		delete data['bundles'];

		const result = await API.graphql(
			graphqlOperation(createBuild, {
				input: data,
			})
		);
		this.props.history.push("/dashboard/home")
		console.log(result);
	};
	public handleMatrixDialogClose = () => {
		this.setState({
			matrixOpen: false
		});
	};
	public openMatrix = () => {
		this.setState({
			matrixOpen: true
		});
	};

	public saveMatrix = () => {
		this.dispatch({
			type: "saveMatrix"
		});
		this.setState({
			matrixOpen: false
		});
	}
	public removePart = (item) => {
		console.log('removePart~~', item, this.state);

		this.dispatch({
			type: "deletePart",
			cargo: {
				entityUUID: item.UUID,
			},
		})
	}

	/* section: components - start */

	/* component: printer selection - start */
	public openPrinters = () => {
		this.setState({
			open: true
		});
	}

	public PrinterSelectionComponent = () => {
		return <div className='bp-l-b-e printer'>
			<div className='p-14 c-292929'>Printer</div>
			<section>
				{
					this.state?.selectedPrinter?.id ?
						(<div className='bp-l-b-e-pr'>
							<div> {this.state?.selectedPrinter?.id}</div>
							<div onClick={this.openPrinters}> Change</div>
						</div>) :
						(<div className='bp-l-b-e-pr'>
							<Button variant="outline-secondary" className='outline-secondaryiconactive-btn' onClick={this.openPrinters}>
								<div className='btnIcon add-b-i'></div>
							</Button>{' '}
						</div>)
				}
			</section>
		</div>;
	}
	/* component: printer selection - end */

	/* component: printer selection modal - start */
	public onPrinterSelect = (item) => {
		console.log('MVPBuildPreperationprops~~onPrinterSelect~', item);
		const aItem = JSON.parse(item['data']);

		console.log('MVPBuildPreperationprops~~onPrinterSelect aItem~', aItem);

		this.dispatch({
			type: "setPrinter",
			cargo: {
				"item": aItem,
			},
		});
		this.dispatch({
			type: "setMachine",
			cargo: {
				"item": aItem,
			},
		});
		let materialconfig = this.state.configurationList?.filter(a => a.id === 'default_material');
		if (materialconfig && materialconfig.length > 0) {
			let slicerJobj = JSON.parse(materialconfig[0]['data']);
			this.dispatch({
				type: "setMaterial",
				cargo: {
					item: slicerJobj,
				},
			});
		}
	};
	public handleDialogClose = () => {
		this.setState({
			open: false
		});
	};
	public PrinterModalComponent = () => {
		return <Modal
			show={this.state.open}
			onHide={this.handleDialogClose}
			backdrop="static"
			keyboard={false}
			dialogClassName="printDialog"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header closeButton>
				<Modal.Title>Printers</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="each-dialog-content">
					<MVPPrinterSelection
						onClick={(item: any) => {
							this.onPrinterSelect(item);
							this.handleDialogClose();
						}}
						printerList={this.state.configurationList?.filter(a => a.type === 'printConfig')} />
				</div>
			</Modal.Body>
			<Modal.Footer className="justify-content-center">
			</Modal.Footer>
		</Modal>;
	}
	/* component: printer selection modal - end */

	/* section: components - end */

	public render() {
		return (
			<div className='buildPreperation'>
				<LoadingComponent visible={this.state.showLoading[0] === 'showLoading'} />
				<div className='bp-l'>
					<div className='bp-l-h'>
						<div className='back-icon' onClick={this.handleClose}></div>
						<label title={this.props.location.state.buildName}>{this.props.location.state.buildName}</label>
					</div>
					<div className='bp-l-b'>
						<div className='bp-l-b-h'>
							<label className='p-16 c-666'>Basic Details</label>
							<div className='bp-l-b-br'></div>
						</div>
						<div className='bp-l-b-e part'>
							<label className='p-14 c-292929'>Part Name</label>
							<section>
								{
									this.state.build.parts.map((item: any) => {
										return (
											<div className='bp-l-b-e-p' title={`${item.properties.PartID} as ${item.customLabel}`}>
												<Button variant="outline-secondary" className='outline-secondaryicon-btn'>
													<div className='btnIcon parts-b-i'></div>
												</Button>{' '}
												<div className='cancel-a-b-i' onClick={() => this.removePart(item)}></div>
											</div>
										)
									})
								}
								<div className='bp-l-b-e-p'>
									<Button variant="outline-secondary" className='outline-secondaryiconactive-btn' onClick={this.showAddMorePart}>
										<div className='btnIcon add-b-i'></div>
									</Button>{' '}
								</div>
							</section>
						</div>
						{
							this.state.build.parts ?
								(
									<>
										<PartSelector
											parts={this.state.build.parts || []}
											title={'Parts'}
											onPartChanged={this.onPartChanged.bind(this)} />
										<PartConfigSelector
											selectedPart={this.state.selectedPart1}
											configs={this.state.configurationList?.filter(c => c.type === 'partConfig') || []}
											configType={'partConfig'}
											title={'Part Config'}
											onConfigChanged={this.onPartConfigChanged.bind(this)}
											onConfigToBeEdited={this.onPartConfigToBeEdited.bind(this)} />
										<PartConfigSelector
											selectedPart={this.state.selectedPart1}
											configs={this.state.configurationList?.filter(c => c.type === 'partSlicerConfig') || []}
											configType={'partSlicerConfig'}
											title={'Part Slicer Config'}
											onConfigChanged={this.onPartSlicerConfigChanged.bind(this)}
											onConfigToBeEdited={this.onPartSlicerConfigToBeEdited.bind(this)} />
									</>
								)
								: null
						}

						<this.PrinterSelectionComponent />
						<ConfigSelector
							configs={this.state.configurationList?.filter(c => c.type === 'material') || []}
							title={'Material'}
							onConfigChanged={this.onMaterialConfigChanged.bind(this)}
							onConfigToBeEdited={this.onMaterialConfigToBeEdited.bind(this)} />
						<ConfigSelector
							configs={this.state.configurationList?.filter(c => c.type === 'recipe') || []}
							title={'Recipe'}
							onConfigChanged={this.onRecipeConfigChanged.bind(this)}
							onConfigToBeEdited={this.onRecipeConfigToBeEdited.bind(this)} />
						<ConfigSelector
							configs={this.state.configurationList?.filter(c => c.type === 'millConfig') || []}
							title={'Mill Config'}
							onConfigChanged={this.onMillConfigChanged.bind(this)}
							onConfigToBeEdited={this.onMillConfigToBeEdited.bind(this)} />
					</div>
					<div className='bp-l-f'>
						<Button variant="outline-secondary" className='outline-secondarytextgrey-btn' autoFocus onClick={this.handleClose}>
							Cancel
						</Button>
						<Button variant="outline-secondary" className='outline-secondarytextactive-btn' autoFocus onClick={this.handleSlice}>
							Slice
						</Button>
					</div>
				</div>
				<this.PrinterModalComponent />
				{
					this.state.configOpen ?
						(<MVPBPConfiguration
							show={this.state.configOpen}
							config={this.state.selectedConfig.resultJson}
							configType={this.state.selectedConfigType}
							onCancel={this.onCancelSelectedConfig}
							onSave={this.onSaveSelectedConfig} />)
						: null
				}

			</div>
		);
	};
}

export default withRouter(BuildPreperation);
