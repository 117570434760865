import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IAddDocumentModalStatus, IDocument, IState } from "../Interfaces";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import awsmobile from "../../aws-exports";
import { createHelp } from "../../graphql/mutations";
import { Storage } from "aws-amplify";
import { ProgressBar } from "react-bootstrap";
import { getHelp } from "../../graphql/queries";
import { NotificationManager } from "react-notifications";
import { HelpPageTabs } from "../Enums";
import ACTION_TYPES from "../actionTypes";
import moment from "moment";
interface filesToBeImported {
  progress: number;
  error: string;
  status: string;
  file: any;
  created_at: any;
}
const Props = (state: IState, ownProps: any) => {
  const creator = state.creator as string;
  const props = {
    creator: creator,
  };

  return props;
};

const onAddDocumentFail = (errorMessage: string) => {
  NotificationManager.error('Failure on adding document');
  console.error('Failure on adding document - ', errorMessage);
  return {
    type: ACTION_TYPES.DOCUMENT.ADD.FAIL,
    payload: errorMessage,
  };
};
const ImportDocuments = (props) => {
  let created_at = new Date().toISOString();

  const { showUploadDocumentModal, setShowUploadDocumentModal, mutateList , activeTab, documentsData } = props;

  const [filesToBeImported, setFilesToBeImported] = useState<
    filesToBeImported[]
  >([]);
  const [disableImport, setDisableImport] = useState<boolean>(true);
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [importStatus, setImportStatus] = useState<string>("");

  const [comments, setComments] = useState("");
  const [selectedHelpDocumentType, setSelectedHelpDocumentType] = useState("");

  const onAddFilesForImport = (e: any) => (
    
    e.persist(),
    setImportStatus(""),

    Array.from(e.target.files).map((file: any) =>
      !filesToBeImported.some((el) => el.file.name === file.name)
        ? setFilesToBeImported((prev) => [
          ...prev,
          { progress: 0, error: "", status: "", file: file, created_at: created_at },
        ])
        : setFilesToBeImported((prev) => [...prev])
    )
  );
  useEffect(() => {
    if (filesToBeImported.length === 0) {
      setDisableImport(true);
    } else if (importStatus === "progress") {
      setDisableImport(true);
    } else {
      setDisableImport(false);
    }
  }, [filesToBeImported]);

  const onDeleteFilesForImport = (fileName) =>
    setFilesToBeImported((filesToBeImported) =>
      filesToBeImported.filter((e: any) => e.file.name !== fileName)
    );

  const onPartNameChange = (file: any) => console.log(file);
  const onCancelModal = () => (setFilesToBeImported([]), setShowUploadDocumentModal(false));

  const REGEX = /.*-(\w+)/;

  const s3BucketName = awsmobile.aws_user_files_s3_bucket;
  var importS3Name = "";
  if (s3BucketName != null) console.log(s3BucketName);
  let arr = s3BucketName.match(REGEX);
  if (arr != null) {
    var env = arr[1];
    console.log("env is", env);
    importS3Name = "imports3-" + env;
  }

  const updateObject = (name, updatedProperties) => {
    const objMapper = (data) =>
      data.map((obj) => {
        return obj.file.name === name ? { ...obj, ...updatedProperties } : obj;
      });

    setFilesToBeImported((prev) => objMapper(prev));
  };
  const onAddDocument = async () => {
    // return async (dispatch, getState) => {

    // const state = getState() as IState;
    // const addDocumentModalStatus = state.data.modals
    //   .addDocumentModalStatus as IAddDocumentModalStatus;
    // const isPartNameExists = documentsData.some(
    //   (p) => p.name === addDocumentModalStatus.inputText
    // ) as boolean;
    // if (isPartNameExists) {
    //   const errorMessage = "Document Name already exists." as string;
    //   dispatch(onAddDocumentFail(errorMessage));
    //   return;
    // }
          // let isExists = documentsData.find(el => {el.id} === )

    setDisableImport(true);
    setDisableCancel(true);
    setImportStatus("progress");
    
    for (let i = 0; i < filesToBeImported.length; i++) {
      try {
        const result = await API.graphql(
          graphqlOperation(getHelp, {
            id: filesToBeImported[i]["file"]["name"],
          })
        );
        // const helpDocumentNames = documentsData.map(doc => doc.helpDocumentName);
        // const helpDocumentTypeName = documentsData.find((doc) => doc.helpDocumentNames !== filesToBeImported[i]["file"]["name"]);
        // const isDocumetExists = helpDocumentTypeName.helpDocumentName;
        // if (isDocumetExists === filesToBeImported[i]["file"]["name"]) {
        //   const errorMessage = "Document Name already exists." as string;
        //   console.error(errorMessage);
        //   updateObject(filesToBeImported[i]["file"]["name"], {
        //     error: errorMessage,
        //   });
        //   return;
        // }

        const addedDocumentId = (result as any)?.data?.getHelp?.name as IDocument;
        if (addedDocumentId !== filesToBeImported[i]["file"]["name"]) {
          const path = `Help/${activeTab.value}/${filesToBeImported[i]["file"]["name"]}`;
          await Storage.put(path, filesToBeImported[i]["file"]);
          let data: any = {
            helpDocumentName: filesToBeImported[i]["file"]["name"],
            helpDocumentComment: comments,
            helpDocumentTypeLink: JSON.stringify({ data: path }),
            created_by: props.creator,
            created_at: new Date().toISOString(),
            modified_at: new Date().toISOString(),
            modified_by: props.creator,
            helpDocumentType: activeTab.value,
          };

          const variables = {
            input: data,
          };
          const result = await API.graphql(
            graphqlOperation(createHelp, variables)
          );
          const addedDocument = (result as any)?.data?.createHelp as IDocument;
          if (addedDocument) {
            updateObject(filesToBeImported[i]["file"]["name"], {
              status: "success",
            });
            mutateList()
            setShowUploadDocumentModal(false)
            setComments("");
            setFilesToBeImported([]);
          }
         

        } else {
          const errorMessage = `Document name already exists. `;
          console.error(errorMessage);
          updateObject(filesToBeImported[i]["file"]["name"], {
            error: errorMessage,
          });
          continue;
        }

      } catch (graphqlError) {
       
        continue;
      }
    }
    setDisableCancel(false);
  //  };
};

  return (
    <div>
      <Modal centered show={showUploadDocumentModal} onHide={()=> setShowUploadDocumentModal(false)} 
      dialogClassName="partsDialog"
      >
        <Modal.Header>
          <Modal.Title>Import Document</Modal.Title>
          <span className="close-btn" onClick={onCancelModal}></span>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="modal-body"
        >
          <label>Add File (.pdf)</label>
          <div className="drop-zone mb-3">
            <input
              type="file"
              onChange={(event) => onAddFilesForImport(event)}
              multiple={false}
              accept=".pdf, .PDF"
            />
            <img src="/upload-icon.svg" alt="upload-file" />
            <p>Drag and Drop, or click to Browse.</p>
          </div>
          <div className="added-files-list">
            {filesToBeImported.length !== 0 && (
              <ul>
                {[...filesToBeImported].map((file: any, idx) => (
                  <li key={idx}>
                    <div className="d-flex">
                      {file.error ? (
                        <img
                          src="/exclamation.svg"
                          alt="error file"
                          className="pr-3"
                        />
                      ) : file.status === "success" ? (
                        <img
                          src="/tick-success.svg"
                          alt="success file"
                          className="pr-3"
                        />
                      ) : (
                        <img
                          src="/part-thumbnail.svg"
                          alt="import file"
                          className="pr-3"
                          style={{ width: "34px", height: "50px" }}
                        />
                      )}
                      <input
                        type="text"
                        onChange={() => onPartNameChange(file.file.name)}
                        value={file.file.name}
                        disabled
                      ></input>
                      <span
                        onClick={() => onDeleteFilesForImport(file.file.name)}
                        className="remove-import"
                      ></span>
                    </div>
                    {file.progress !== 0 &&
                      file.error === "" &&
                      file.status !== "success" && (
                        <div className="progress-bar-import">
                          <ProgressBar
                            label={`${file.progress}%`}
                            variant="success"
                            now={file.progress}
                          />

                          {/* <label>{`${file.progress}%`}</label> */}
                        </div>
                      )}
                    {file.error !== "" && (
                      <div
                        className="invalid-feedback d-block"
                        style={{
                          marginLeft: "6%",
                          width: "80%",
                        }}
                      >
                        {file.error}
                      </div>
                    )}
                    {file.status === "success" && (
                      <div
                        className="valid-feedback d-block"
                        style={{
                          marginLeft: "6%",
                        }}
                      >
                        Document has been successfully imported
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <label className="d-block">Comments</label>
            <textarea className="w-100"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              rows={4} cols={50}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={disableCancel}
            variant="secondary"
            onClick={onCancelModal}
          >
            Cancel
          </Button>

          <Button
            disabled={disableImport}
            className="btn btn-primary"
            onClick={() => onAddDocument()}
            autoFocus
          >
            Import
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const UploadDocument = withRouter(connect(Props)(ImportDocuments));
export default UploadDocument;
