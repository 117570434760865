import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPartCardView, DisplayPartsOptions, DisplayFolder } from '../..';
import { IPart, IState } from '../../../Interfaces';
import { partByCreatedAt } from '../../../../graphql/queries';
import LoadingComponent from '../../../../LoadingComponent/LoadingComponent';
import { updateParts } from '../../../../graphql/mutations';
import { onSearchPartsTextChange } from '../../../Actions/Parts';
import { onLoadParts } from "../../../Actions/Home";

const Props = (state: IState, ownProps: any) => {

	const hasDisplayItems =
		Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
		Boolean(ownProps.displayParts && ownProps.displayParts.length > 0);

	const props = {
		hasDisplayItems: hasDisplayItems as boolean,
		isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
		loading: !(state.data.parts.isLoadingComplete as boolean),
		nextToken: state.data.parts.nextToken,
		loadedParts: state.data.parts.loadedParts,
		searchText: state.data.parts.searchText as string,
		...ownProps
	};

	return props;
};

const Actions = {
	onSearchPartsTextChange: onSearchPartsTextChange,
	onLoadParts: onLoadParts,
};

const DisplayPartsMethod = (props) => {
	const [partResult, setPartResult] = useState<any[]>([]);
	const [token, setToken] = useState<string | null>(null);
	const loading = props.loading;

	const loadMoreParts = async () => {
		props.onLoadParts();
	};

	const getDisplayParts = () => {
		if (props.loadedParts.length === 0 && loading !== true) {
			return (
				<div className="d-block w-100 text-center">
					<p className="justify-content-center p-4">No Parts in the name of <b>{props.searchText}</b></p>
				</div>
			)

		}

		if (props.showParts) {
			return (props.loadedParts
				.map((dp, i) => {
					return (
						<DisplayPartCardView
							{...{
								key: i,
								index: i,
								displayPart: dp,
								isSelected: props.isSelected,
								onUnselect: props.onUnselect,
								onSelect: props.onSelect,
							}}
						/>
					)
				}))
		}
		else {
			return (props.loadedParts
				.map((dp, i) => {
					return (
						<DisplayPartCardView
							{...{
								key: i,
								index: i,
								displayPart: dp,
								isSelected: props.isSelected,
								onUnselect: props.onUnselect,
								onSelect: props.onSelect,
							}}
						/>
					)
				}))
		}
	}

	return (
		<>
			{
				props.hasDisplayItems ?
					(
						<>
							<LoadingComponent visible={loading} />
							<dl>
								<>
									{
										props.showItems ?
											(
												<>
													<dd>
														{
															props.displayFolders
																.map((df, i) => {
																	return <DisplayFolder
																		key={i}
																		displayFolder={df}
																		showParts={true}
																		showPlates={false}
																		showBuilds={false}
																	/>
																})
														}
													</dd>
													<dd>
														{getDisplayParts()}
														{
															props.nextToken ?
																(
																	<>
																		<div className="d-block w-100 text-center">
																			<button onClick={loadMoreParts} className="btn btn-primary justify-content-center">
																				Load More Parts<span className="btn-right-arrow"></span></button>
																		</div>
																	</>

																) :
																(
																	null
																)
														}
													</dd>
												</>
											)
											:
											null
									}
								</>
							</dl>
						</>
					)
					:
					(
						<div className="d-block w-100 text-center">
							<p className="justify-content-center p-4">
								Please wait while the page is loading ...</p>
						</div>
					)
			}
		</>
	);
}

const DisplayMoreParts = withRouter(connect(Props, Actions)(DisplayPartsMethod));

export default DisplayMoreParts;
