import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { IState, INewPlateModalStatus } from '../Interfaces';
import { onNewPlateCloseDialog, onNewPlateNameChange, onNewPlate } from '../Actions/Plates';

const Props = (state: IState, ownProps: any) => {

    const newPlateModalStatus = state.data.modals.newPlateModalStatus as INewPlateModalStatus;

    const props = {
        isModalOpen: newPlateModalStatus.isOpen,
        inputText: newPlateModalStatus.inputText,
        hasError: newPlateModalStatus.hasError,
        errorMessage: newPlateModalStatus.errorMessage,
        isValid: newPlateModalStatus.isValid,
    };

    return props;
};

const Actions = {
    onNewPlateCloseDialog: onNewPlateCloseDialog,
    onNewPlateNameChange: onNewPlateNameChange,
    onNewPlate: onNewPlate,
};

class Component extends React.Component<any, any> {

    private onNewPlateNameChange(event) {
        const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join('');
        this.props.onNewPlateNameChange(value);
    }

    public render() {
        return (
            <>
                {
                    (this.props.isModalOpen) ?
                        (
                            <>
                                <Modal
                                    show={this.props.isModalOpen}
                                    onHide={() => this.props.onNewPlateCloseDialog()}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='partsDialog'
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header>
                                        <Modal.Title>Start New Plate</Modal.Title>
                                        <span className="close-btn"
                                            onClick={() =>
                                                this.props.onNewPlateCloseDialog()
                                            }>
                                        </span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='each-dialog-content'>
                                            <div className='each-d-section'>
                                                Plate Name
                                            </div>
                                            <div className='each-d-section'>
                                                <input className='f-w'
                                                    type='text'
                                                    value={this.props.inputText}
                                                    onChange={(event) => this.onNewPlateNameChange(event)}
                                                    placeholder='Enter the new plate name here'
                                                />
                                            </div>
                                            <div className='hint-msg'>
                                                Name can contain only a-z, A-Z, 0-9, ._-
                                            </div>
                                            <div className='invalid-feedback d-block'
                                                hidden={!this.props.hasError}>
                                                {this.props.errorMessage}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <div className="row">
                                            <div className="col-sm-6 text-left">
                                                <Button
                                                    className='btn btn-secondary'
                                                    onClick={() => this.props.onNewPlateCloseDialog()}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="col-sm-6 text-left">

                                                <Button
                                                    className='btn btn-primary'
                                                    onClick={() => this.props.onNewPlate(this.props.history)}
                                                    autoFocus>
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>

                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const NewPlate = withRouter(connect(Props, Actions)(Component));

export default NewPlate;