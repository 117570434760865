
import { IState, IBuild, IPlate } from '../../Interfaces';

const onResetHighlightedPlateReducer = (state: IState, action) => {
    const newState = { ...state } as IState;

    newState.data.search = {
        ...newState.data.search,
        highlightPlateStatus: "",
        highlightedPlateData: {} as IPlate
    };
    
    return newState;
};

export default onResetHighlightedPlateReducer;