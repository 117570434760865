import { IState } from '../../../Interfaces';

const onRenameConfigurationNameChangeReducer = (state: IState, action) => {
    const configurationNameToBeRenamed = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.renameConfigurationModalStatus = {
        ...newState.data.modals.renameConfigurationModalStatus,
        inputText: configurationNameToBeRenamed,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onRenameConfigurationNameChangeReducer;