import * as Build from "./Build.json";
import * as CombinedConfig from "./CombinedConfig.json";
import * as Machine from "./Machine.json";
import * as Mill from "./Mill.json";
import * as PartConfig from "./PartConfig.json";
import * as Recipe from "./Recipe.json";
import * as Material from "./Material.json";

import * as blowdry from "./configEntries/blowdry.json";
import * as build from "./configEntries/build.json";
import * as checkBounds from "./configEntries/checkBounds.json";
import * as cutDeviationStatistics from "./configEntries/cutDeviationStatistics.json";
import * as dispense from "./configEntries/dispense.json";
import * as fan from "./configEntries/fan.json";
import * as heaters from "./configEntries/heaters.json";
import * as lamp from "./configEntries/lamp.json";
import * as mill from "./configEntries/mill.json";
import * as purge from "./configEntries/purge.json";
import * as slic3r from "./configEntries/slic3r.json";
import * as tagger from "./configEntries/tagger.json";
import * as thermalCamera from "./configEntries/thermalCamera.json";
import * as tipCleaner from "./configEntries/tipCleaner.json";
import * as toolFinder from "./configEntries/toolFinder.json";
import * as visionCamera from "./configEntries/visionCamera.json";


export const schemas = {
  "Build.json": (Build as any).default,
  "CombinedConfig.json": (CombinedConfig as any).default,
  "Machine.json": (Machine as any).default,
  "Mill.json": (Mill as any).default,
  "Material.json": (Material as any).default,
  "PartConfig.json": (PartConfig as any).default,
  "Recipe.json": (Recipe as any).default,
  "configEntries/blowdry.json": (blowdry as any).default,
  "configEntries/build.json": (build as any).default,
  "configEntries/checkBounds.json": (checkBounds as any).default,
  "configEntries/cutDeviationStatistics.json": (cutDeviationStatistics as any).default,
  "configEntries/dispense.json": (dispense as any).default,
  "configEntries/fan.json": (fan as any).default,
  "configEntries/heaters.json": (heaters as any).default,
  "configEntries/lamp.json": (lamp as any).default,
  "configEntries/mill.json": (mill as any).default,
  "configEntries/purge.json": (purge as any).default,
  "configEntries/slic3r.json": (slic3r as any).default,
  "configEntries/tagger.json": (tagger as any).default,
  "configEntries/thermalCamera.json": (thermalCamera as any).default,
  "configEntries/tipCleaner.json": (tipCleaner as any).default,
  "configEntries/toolFinder.json": (toolFinder as any).default,
  "configEntries/visionCamera.json": (visionCamera as any).default,
};

export function getListOfPosibleProperties(path: string[], schema: Object, schemaRefs: Object[]) {
  let currentSchema = resolveSchemaIssues(schema, schemaRefs) as any;

  for (let i = 0; i < path.length - 1; i++) {
    const entry = path[i];
    const schemaType = currentSchema.type;
    switch (schemaType) {
      case "array": {
        currentSchema = currentSchema.items;
        break;
      }
      case "object": {
        currentSchema = currentSchema.properties[entry];
        if (currentSchema === undefined) {
          return [];
        }
        break;
      }
      default: {
        return [];
      }
    }
    currentSchema = resolveSchemaIssues(currentSchema, schemaRefs);
  }

  if( 'properties' in currentSchema ){
    return Object.keys(currentSchema.properties);
  }

  return [];
}

export function getSetOfPresentProperties(json: Object, path: string[]) {
  let currentJsonSection = json;
  for (let i = 0; i < path.length - 1; i++) {
    const entry = path[i];
    currentJsonSection = currentJsonSection[entry];
  }
  return new Set(Object.keys(currentJsonSection));
}

export function resolveSchemaIssues(schema: Object, schemaRefs: Object[]) {
  function schemaIsResolved(schema) {
    return !(
      "anyOf" in schema ||
      "allOf" in schema ||
      "oneOf" in schema ||
      "$ref" in schema
    );
  }
  let result = { ...schema };
  while (!schemaIsResolved(result)) {
    for (const combineKeyWord of ["anyOf", "allOf", "oneOf"]) {
      if (schema[combineKeyWord] !== undefined) {
        for (let schemaToCombine of schema[combineKeyWord]) {
          schemaToCombine = resolveSchemaIssues(schemaToCombine, schemaRefs);
          Object.assign(result, schemaToCombine);
        }
        delete result[combineKeyWord];
      }
    }
    const reference = result["$ref"];
    if (typeof reference === "string") {
      let referencedSchema = {};
      if (reference[0] === "#") {
        console.log("local references not implemented");
      } else {
        referencedSchema = schemaRefs[reference];
      }
      referencedSchema = resolveSchemaIssues(referencedSchema, schemaRefs);
      Object.assign(result, referencedSchema);
      delete result["$ref"];
    }
  }
  return result;
}
