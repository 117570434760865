import { EditPlatePhase } from '../../../Enums';
import { IState } from '../../../Interfaces';

const onEditPlatePageTabChangeReducer = (state: IState, action) => {
    const selectedTabIndex = action.payload as EditPlatePhase;
    
    const newState = { ...state } as IState;
    newState.data.info.editPlatePageTabIndex = selectedTabIndex as EditPlatePhase;
    
    return newState;
};

export default onEditPlatePageTabChangeReducer;