import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
import rootLogger from './rootLogger';

const middlewares = [thunk, rootLogger];
const configureStore = (preloadedState?: any) => createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middlewares),
);

export default configureStore;