import { IPlate, IState } from '../../../Interfaces';

const onCopyPlateSuccessReducer = (state: IState, action) => {
    const copiedPlate = action.payload as IPlate;
    
    const newState = { ...state } as IState;
    
    newState.data.modals.copyPlateModalStatus = {
        ...newState.data.modals.copyPlateModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onCopyPlateSuccessReducer;
