import { IState } from '../../../Interfaces';

const onLoadTagsCompletedReducer = (state: IState, action) => {
    
    const newState = { ...state } as IState;
    newState.data.tags = {
        ...newState.data.tags,
        isLoadingStarted: false as boolean,
        isLoadingInProgress: false as boolean,
        isLoadingComplete: true as boolean,
    };
    
    return newState;
};

export default onLoadTagsCompletedReducer;