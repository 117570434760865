import ACTION_TYPES from '../../../actionTypes';

import { onArchivePart } from '..';
import { NotificationManager } from "react-notifications";
const onArchivePartsInit = () => {
    return {
        type: ACTION_TYPES.PARTS.ARCHIVE.INIT,
        payload: null,
    };
};

const onArchivePartsSuccess = () => {
    return {
        type: ACTION_TYPES.PARTS.ARCHIVE.SUCCESS,
        payload: null,
    };
};

const onArchivePartsFail = (errorMessage: string) => {
    NotificationManager.error('Failure on archiving parts');
    console.error('Failure on archiving parts - ', errorMessage);
    return {
        type: ACTION_TYPES.PARTS.ARCHIVE.FAIL,
        payload: errorMessage,
    };
};

const onArchivePartsDone = () => {
    return {
        type: ACTION_TYPES.PARTS.ARCHIVE.DONE,
        payload: null,
    };
};

const onArchiveParts = (partIds) => {
    return async (dispatch, getState) => {
        dispatch(onArchivePartsInit());
        try {
            const archivePromises = partIds.map(id => (onArchivePart(id)(dispatch, getState)));
            await Promise.allSettled(archivePromises);
            dispatch(onArchivePartsSuccess());
        } catch (graphqlError) {
            const errorMessage = `Archive parts failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onArchivePartsFail(errorMessage));
        }
        dispatch(onArchivePartsDone());
    };
};

export default onArchiveParts;