import React from "react";

import "./MVPPrinterSelection.scss";
import Amplify from "aws-amplify";
import { withRouter } from "react-router-dom";
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);


export class MVPPrinterSelection extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			showLoading: [],
			printers: this.props.printerList
		};
	}

	public render() {
		return (
			<div className='printers'>
				{
					this.state.printers.map((item: any) => {
						return (
							<div className='e-c-card' onClick={() => {
								this.props.onClick(item);
							}}>
								<div className='e-c-card-h'>
									<div/>
								</div>
								<div className='e-c-card-b'>

								</div>
								<div className='e-c-card-f'>
									<div>{item.id}</div>
									<div>Available</div>
								</div>
							</div>
						);
					})
				}
			</div>
		);

	};
}
export default withRouter(MVPPrinterSelection);
