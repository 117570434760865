import { IState } from '../../../Interfaces';

const onCopyPlateFailReducer = (state: IState, action) => {
    const copyPlateErrorMessage = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.copyPlateModalStatus = {
        ...newState.data.modals.copyPlateModalStatus,
        hasError: true,
        errorMessage: copyPlateErrorMessage,
    };

    return newState;
};

export default onCopyPlateFailReducer;