import { IState } from "../../Interfaces";

const onSubscriptionsSetBuilds = (state: IState, action) => {
    const subscriptions = action.payload;

    const newState = { ...state } as IState;
    try {
				if (subscriptions.buildCreate) {
					newState.subscriptions.buildCreate = subscriptions.buildCreate;
				}
				if (subscriptions.buildUpdate) {
					newState.subscriptions.buildUpdate = subscriptions.buildUpdate;
				}
				if (subscriptions.buildDelete) {
					newState.subscriptions.buildDelete = subscriptions.buildDelete;
				}
    }
    catch (e){
        console.error(e)
    }

    return newState;
};

export default onSubscriptionsSetBuilds;
