import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import EditJsonModal from "../EditJsonModal";
import dataStorage from "../S3DataStore/S3DataStore";
import { Auth } from "aws-amplify";
import { NotificationManager } from "react-notifications";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import {Checkbox, TextField} from "@material-ui/core";
import awsmobile from "../aws-exports";
import "./Catalog.css"
import {Pagination} from "@material-ui/lab";

import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import JSZip from "jszip";
import saveFile, { makeRequest } from "./../utils";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

interface ICatalogProps {
  onClick: (machine: any) => void;
  initFunc?: (newObject: any) => void;
  onNewObjectLink?: string;
  onSelectObjectLink?: string;
  schemaName?: string;
  dataType: string;
  elementsPerPage: number;
  filesInFolders?: String[];
  openFromFolder?: boolean;
  fileDataType?: string;
}

const FileBasedDataTypes = {
  Parts: {},
  SlicerConfigs: {},
};

interface ISelectedJson {
  id: string;
}


const Catalog = (props: ICatalogProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [selectedJson, selectJson] = useState<ISelectedJson | null>(null);
  const [currentName, setCurrentName] = useState(`new${props.dataType}`);
  const [loading, setLoading] = useState(false);

  const [filterOnlyMy, setFilterOnlyMy] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [email, setEmail] = useState("");
  const [elements, setElements] = useState<{values: String[], totalCount: number}>({values: [], totalCount: 0})

  const dataType = props.dataType;
  const [buildType, setBuildType] = React.useState('Active');
  const [fileList, setFileList] = React.useState<Array<string>>([]);
  const [files, setFiles] = React.useState({});

  const [checked, setChecked] = React.useState<Array<string>>([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const [pathName, setPathName] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [file, setFile] = useState(null);
  const [selectedPartItem, setSelectedPartItem] = useState({});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
  const [moveDialogOpen, setMoveDialogOpen] = React.useState(false);
  const [folderName, setFolderName] = useState(`new${props.dataType}Folder`);
  const [folderAndArchiveIndexes, setFolderAndArchiveIndexes] = 
  useState<
  {id: String,
  folder: String[],
  folders: String[],
  archive: String[] }>
  ({
    id: props.dataType,
    folder: [],
    folders: [],
    archive: []
    })
    const [folderChecked, setFolderChecked] = React.useState<Array<string>>([]);
    const [folderList, setFolderList] = React.useState<Array<string>>([]);

  const handleFolderToggle = (value) => () => {
    const currentIndex = folderChecked.indexOf(value);
    const newChecked = [...folderChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFolderChecked(newChecked);
  };

  const handleMoveDialogClose = () => {
    setSelectedPartItem({});
    setMoveDialogOpen(false);
  };

  const createBuildInFolderList = () => {
    dataStorage().objectStores["TableIndexes"].add(
      dataType,
      {
      id: dataType,
      folder: [],
      folders: [],
      archive: []
      }
      );
      getBuildInFolderList();
  }
  const createBuildFolders = () => {
    const creator = email;
    const create_time = new Date().toISOString();

    dataStorage().objectStores["BuildFolders"].add(
      folderName,
      {
        id: folderName,
        creator: creator,
        created_at: create_time,
        modifier: creator,
        modified_at: create_time,
        builds: []
      }
      );
     updateBuildInFolder(folderName);
  }
  const updateBuildInFolderList = () => {
    dataStorage().objectStores["TableIndexes"].update(
      {
      id: "Builds",
      folder: [],
      folders: ['2222','Folder1','newBuildFolders'],
      archive: []
      }
      )
  }
  const updateFoldersList = async (name) => {
    const indexes = await dataStorage().objectStores["TableIndexes"].getObject(props.fileDataType);
    if (indexes) {
      console.log('getBuildInFolderList  getFolders', indexes);
      let foldersList = indexes.folders;
      foldersList.push(name);
      dataStorage().objectStores["TableIndexes"].update(
        {
          id: props.fileDataType,
          folder: indexes.folder,
          folders: foldersList,
          archive: indexes.archive
        }
      );
    }
  }

  const getBuildInFolderList = async() =>{
    try{
      const indexes = await dataStorage().objectStores["TableIndexes"].getObject(dataType);
      if (indexes) {
        console.log('getBuildInFolderList',indexes);
        setFolderAndArchiveIndexes(indexes);
        // listUpdate(buildType);
        // getObjectsList();
      }
      else {
        createBuildInFolderList();
        console.log(`Can't get objectStore ${dataType}`)
      }
    }
    catch(e: any){
      createBuildInFolderList();
      console.log(`Error reading elements: ${e.toString()}`)
    }
  }


  const moveObject = (item: any, status: boolean) => {
    console.log('moveObject', item, status);
    setSelectedPartItem(item);
    setMoveDialogOpen(true);
  };
  const moveBuildFileToFolder = () => {
    console.log('moveBuildFileToFolder ~~~~~',folderChecked);
    let folders:any[] = folderAndArchiveIndexes.folders;
    let buildsInFolders:any[] = folderAndArchiveIndexes.folder;
    folderChecked.forEach(folder => {
      updateBuildInFolder(folder);
      if(!folders.includes(folder)) {
        folders.push(folder)
      }
    } );
    if(!buildsInFolders.includes(selectedPartItem['id'])) {
      buildsInFolders.push(selectedPartItem['id']);
    }
    dataStorage().objectStores["TableIndexes"].update(
      {
      id: props.dataType,
      folder: buildsInFolders,
      folders: folders,
      archive: folderAndArchiveIndexes.archive
      }
      );
    getBuildInFolderList();
    setSelectedPartItem({});

    setMoveDialogOpen(false);
  }

  const updateBuildInFolder = async(folder) => {
    console.log('updateBuildInFolder ~~~~~',folder);
    try{
      const findexes = await dataStorage().objectStores["BuildFolders"].getObject(folder);
      if(findexes){
        if(!findexes.builds.includes(selectedPartItem['id'])){
          findexes.builds.push(selectedPartItem['id']);
          dataStorage().objectStores["BuildFolders"].update(findexes);
        }
      }
      else {
        createBuildFolders();
        console.log(`Can't get objectStore ${dataType}`)
      }
    }
    catch(e: any){
      createBuildFolders();
      console.log(`Error reading elements: ${e.toString()}`)
    }
  }

  const getFolders = async() => {
    const indexes =  await dataStorage().objectStores["TableIndexes"].getObject(props.fileDataType);
      if (indexes) {
        console.log('getBuildInFolderList  getFolders',indexes);
        setFolderList(indexes.folders);
        getObjectsList(indexes.folders);
      }
  }
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedPartItem(item);
    setFileList(Object.keys(item.files));
    setFiles(item.files);
    // console.log('handleClick~~~~', event.currentTarget, item )
  };

  const [pageNumber, setPageNumber] = React.useState(1)

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addNewFile = () => {
    handleClose();
    handleClickOpen();
  }
  const downloadFile = () => {
    handleClose();
    setDownloadDialogOpen(true);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    handleClose();
    setOpen(false);
    setDownloadDialogOpen(false);
    setPathName('');
  };

  React.useEffect(() => {
    setCurrentName(`new${dataType}`);
    Auth.currentUserInfo().then(result => setEmail(result.attributes.email))
  }, [props.dataType]);

  const history = useHistory();

  const routeChange = (newPath: string) => {
    history.push(newPath);
  };

  const copyObject  = async (ObjectToCopy: any) => {
    const creator = email;
    const create_time = new Date().toISOString();

    let tst = dataStorage().objectStores;
    let obj = await tst[dataType].getObject(ObjectToCopy.id)
    let newObject:any = Object.assign({}, obj,{
      id: currentName,
          creator: creator,
          created_at: create_time,
          modifier: creator,
          modified_at: create_time,
    });
    if (props.dataType === "Builds") {
      newObject.bundles = []
    }
    return tst[dataType].add(newObject.id, newObject);
  };


  const archiveObject = (archiveObject: any, status: boolean) => {
    const selected = elements.values.filter(a => a['id'] === archiveObject['id']);
    if(selected.length >0 ) {
      selected[0]['isArchive'] = status;
    }
    dataStorage().objectStores[props.dataType].update(selected[0]);
    // listUpdate(buildType);
    getObjectsList();
  };


  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if(props.fileDataType) {
      getFolders();
    } else {
      getBuildInFolderList();
      console.log('props.filesInFolders~~~',props.filesInFolders);
      if(props.filesInFolders && props.filesInFolders.length > 0) {
        getfilesfromFolders();
      } else {
        getObjectsList();
      }
    }
  }, [buildType, filterOnlyMy, filterText, pageNumber, props.dataType]);

  const createNewCatalogEntry = async (localFile: File | null = null) => {
    if(props.fileDataType){
      updateFoldersList(currentName);
    }
    const creator = email;
    const create_time = new Date().toISOString();
    let newObject = {
      id: currentName,
      creator: creator,
      created_at: create_time,
      modifier: creator,
      modified_at: create_time,
    };

    let defaultValue = {};
    if (props.initFunc) {
      props.initFunc(defaultValue);
    }

    if (localFile !== null) {
      let extension = localFile.name.split(".").pop()?.toLowerCase();
      switch (extension) {
        case "json":
          if (dataType in FileBasedDataTypes) {
            NotificationManager.error("Couldn't upload json file");
            throw "choose another file";
          } else {
            return await new Promise((resolve) => {
              let reader = new FileReader();
              reader.onload = (e: ProgressEvent<FileReader>) => {
                let json = JSON.parse(e.target!.result as string);
                let combinedObject = Object.assign(
                  defaultValue,
                  json,
                  newObject
                );
                resolve(
                  dataStorage().objectStores[dataType].add(
                    combinedObject.id,
                    combinedObject
                  )
                );
              };
              reader.readAsText(localFile!);
            });
          }
        case "stl":
        case "ply":
        case "cfg":
        case "step":
        case "stp":
          if (dataType in FileBasedDataTypes) {
            setLoading(true);
            let toSend = {
              name: extension === "step" || extension ==="stp"? "data_cad" : "data",
              localFile
            }
            try {
              let object = await dataStorage().objectStores[dataType].add(newObject.id, newObject, [toSend])
              try {
                if (dataType === 'Parts'){
                  let response = await makeRequest('POST', 'https://lava.mantle3d.com/process_mesh/', {
                    type: dataType,
                    id: object.id,
                    bucket: awsmobile.aws_user_files_s3_bucket
                      }
                  )
                  let obj = JSON.parse(response.toString())
                  if (obj.id)
                    object = obj
                }
                await dataStorage().objectStores[dataType].update(object)
              }
              catch (e: any){
                NotificationManager.error("Remesh part and generating icon failed: " + e.toString())
              }
              return  object;
            }
            catch (e: any) {
              NotificationManager.error("Error add with mesh file");
            }
            finally
            {
              setLoading(false)
              localFile = null
            }
          }
          localFile = null;

          break
        default:
          NotificationManager.warning(
            "Unknown file format. It would be ignored"
          );
          localFile = null;
          break;
      }
    }

    if (dataType in FileBasedDataTypes) {
      NotificationManager.error("Couldn't proceed without file");
      throw "please add file";
    }

    Object.assign(newObject, defaultValue);
    let tst = dataStorage().objectStores;
    const new_object = await tst[dataType].add(newObject.id, newObject);
    await getObjectsList();
    return new_object;
  };

  const fileuplodeDataSet = (val) => {
    setFile(val.target.files[0]);
    let fileName = val.target.files[0].name;
    if(pathName === ""){
      setPathName(fileName.substring(fileName.lastIndexOf(".")+1)+'_'+fileName.substring(0, fileName.lastIndexOf(".")));
    }
  };
  const fileuplode = () => {
    smallFileChange(file, selectedPartItem );
    
  };
  function smallFileChange(val, item){
    dataStorage().objectStores[dataType].addFile(item.id, pathName, val).then(()=>{
      NotificationManager.info("Uploaded");
      handleDialogClose();
    }).catch( err => {
      NotificationManager.error(`Can't upload file: ${err.toString()}`)
      handleDialogClose();
    })
  }

  const fileDownload = () => {
   if(checked.length >0) {
    if(checked.length == 1) {
      let ext =  files[checked[0]].substring(files[checked[0]].lastIndexOf(".")+1)
      singleFileDownload(selectedPartItem['id'], checked[0],ext)
    } else {
      downloadFiles(selectedPartItem['id'], checked)
    }
  }
  };

  const singleFileDownload = (id, file,extn) => {
      dataStorage()
                .downloadObjectFile(dataType, id, file)
                .then((res) => {
                      saveFile((res as any).Body, `${file}.${extn}`);
                  })
   };

  async function downloadFiles(objectId: string, files: string[]){
    let zip = new JSZip();
    const promises = files.map( async( fileName) => { return  {
       data: await dataStorage().downloadObjectFile(dataType, objectId, fileName),
      filename: await dataStorage().getObjectFileName(dataType, objectId, fileName)}})
    const results = await Promise.all(promises)
    results.map( ( val) => {
      zip.file( val.filename, val.data)
    })
    zip.generateAsync({type: "blob"}).then((content: any) => {
      // consider using FileSaver.js
      const name =  `${objectId}_data.zip`
      saveFile(
          content,
          name,
          "application/zip"
      );
      zip = new JSZip()
    });
  }

  async function changePage(event, newPageNumber){
    setPageNumber(newPageNumber)
  }
  async function getObjectsList(folders:String[] = []) {
    let foldersList = folderList.length > 0 ? folderList :folders;

    try{
      const dataStore = dataStorage().objectStores[props.dataType]
      let listFilter: any [] = []

      if (filterText) {
        listFilter.push({field: "name", value: filterText})
      }

      if (filterOnlyMy) {
        listFilter.push({field: "email", value: email})
      }
      if (buildType == "Archive" || buildType == "Active"){
        listFilter.push({field: "archive", value: buildType})
      }


      if (dataStore) {
          const objects = foldersList.length > 0 ?
          await dataStore.readObjects(
            (pageNumber-1)*props.elementsPerPage,
            props.elementsPerPage,
            listFilter, foldersList) :
          await dataStore.readObjects(
            (pageNumber-1)*props.elementsPerPage,
            props.elementsPerPage,
            listFilter);
        setElements({values: objects.page, totalCount: objects.totalCount})
      }
      else {
        console.log(`Can't get objectStore ${dataType}`)
      }
    }
    catch(e: any){
      console.log(`Error reading elements: ${e.toString()}`)
    }
  }


  async function getfilesfromFolders() {
    try{
      const dataStore = dataStorage().objectStores[props.dataType]
      let listFilter: any [] = []

      if (dataStore) {
        const objects = await dataStore.readObjects(
            (pageNumber-1)*props.elementsPerPage,
            props.elementsPerPage,
            listFilter,props.filesInFolders)
        setElements({values: objects.page, totalCount: objects.totalCount})
      }
      else {
        console.log(`Can't get objectStore ${dataType}`)
      }
    }
    
    catch(e: any){
      console.log(`Error reading elements: ${e.toString()}`)
    }
  }

  return (
    <>
      <LoadingComponent visible={loading} />
      <EditJsonModal
        jsonToEdit={selectedJson}
        modalCloser={() => selectJson(null)}
        updateJson={(json) =>
          dataStorage().objectStores[props.dataType].update(json)
        }
        schemaName={props.schemaName}
      />
      <div>
        {props.filesInFolders && props.filesInFolders.length > 0 ? (
          <div>
            <button
              className="btn"
              onClick={() => {
                props.onClick({ 'action': 'folder', 'page': 'build' })
              }}
              style={{ width: "100%", borderRadius: "0 0 2px 2px" }}
              title="Edit"
            >
              <img
                src={"/arrow.svg"}
                style={{ width: "32px", height: "32px" }}
              /> {"Back to Folder(s)"}
            </button>
          </div>
        ) : (
          <React.Fragment>
        <div  className="header-section">
          <div className="each-section">
          <input type={"file"} ref={fileInputRef} multiple={false} />
          </div>
          <div className="each-section">
          <input
            type={"text"}
            value={currentName}
            onChange={(ev) => {
              let v = ev.target.value;
              setCurrentName(v);
            }}
          />
          </div>
          <div className="each-section">
          <button
            onClick={() => {
              let selectedFile: null | File = null;
              if (fileInputRef.current!.files!.length > 0) {
                selectedFile = fileInputRef.current!.files![0];
              }
              createNewCatalogEntry(selectedFile)
                .then((newObject) => {

                  if (
                    props.onNewObjectLink &&
                    props.onNewObjectLink.length > 0
                  ) {
                    props.onClick(newObject);
                    routeChange(props.onNewObjectLink);
                  } else {
                    selectJson(newObject);
                  }
                })
                .catch();
            }}
          >
            {"Create new " + props.dataType}
          </button>
          </div>
          <div className="each-section">
          onlyMy: <Checkbox value={filterOnlyMy} onClick={(ev)=>{
            ev.stopPropagation()
            setFilterOnlyMy(!filterOnlyMy)
          }}/>
          FilterText: <TextField value={filterText} onChange={ (ev) => {
            ev.stopPropagation()
            setFilterText(ev.target.value)
          }
        }/>
          </div>
          <div className="each-section">
          <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        {props.dataType + " Type"}
        </InputLabel>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={buildType}
          onChange={(event) => {
            setBuildType(event.target.value as string);
          }}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Archive"}>Archive</MenuItem>
        </Select>
      </FormControl>
      </div>
        </div>
        <br/><Pagination count={Math.ceil(elements.totalCount/props.elementsPerPage)} onChange={changePage}/>
        </React.Fragment>
        )}
        <React.Fragment>

        {
          elements.values.map((item: any) => {
            if(!(props.filesInFolders && props.filesInFolders.length > 0) &&  folderAndArchiveIndexes.folder.includes(item.id)) 
            return
              return (
                <div className="card" title={`Created at: ${new Date(item.created_at).toLocaleString()}` + '\n' + `Modified at: ${new Date(item.modified_at).toLocaleString()}`} >
                  <div
                    onClick={() => {
                      props.onClick(item);
                      if (
                        props.onSelectObjectLink &&
                        props.onSelectObjectLink.length > 0
                      ) {
                        routeChange(`${props.onSelectObjectLink}/${item.id}`);
                      }
                    }}
                  >
                    <div className={"card-img-container"}>
                    {props.dataType === "BuildFolders" ? (
                      <img
                        className="card-img-top"
                        src={"/folder.svg"}
                        alt="Folder image cap"
                      />):(
                        <img
                        className="card-img-top"
                        src={ item.icon|| "/thumbnail.png"}
                        alt="Card image cap"
                      />
                      )}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{item.id}</h5>
                      <p className="card-text">{item.desc}</p>
                    </div>
                  </div>
                  <div className="card-footer" style={{ padding: 0 }}>
                    <button
                      className="btn"
                      onClick={() => {
                        selectJson(item);
                      }}
                      style={{ width: "100%", borderRadius: "0 0 2px 2px" }}
                      title="Edit"
                    >
                     <img
                         alt={"edit"}
                         src={"/pencil-sharp.svg"}
                         style={{ width: "32px", height: "32px" }}
                    /> 
                    </button>
                    <button
                        className="btn"
                        onClick={() =>
                        {
                          copyObject(item).then( ()=> {
                            console.log("Object copied")
                            getObjectsList()
                          })
                        }
                        }
                        style={{ width: "100%", borderRadius: "0 0 2px 2px" }}
                        title="Copy"
                    >
                    <img
                        alt={"copy"}
                      src={"/copy.svg"}
                      style={{ width: "32px", height: "32px" }}
                    /> 
                    </button>
                    
                    {item.isArchive ? (
                    <button
                        className="btn"
                        onClick={() =>
                        {
                          archiveObject(item, false);
                          console.log("Object Unarchive")
                        }
                        }
                        style={{ width: "100%", borderRadius: "0 0 2px 2px" }}
                        title="Unarchive"
                    >
                    <img
                        alt={"undo"}
                      src={"/undo.svg"}
                      style={{ width: "32px", height: "32px" }}
                    /> 
                    </button>
                    ) : (
                      <button
                      className="btn"
                      onClick={() =>
                      {
                        archiveObject(item, true);
                        console.log("Object archived")
                      }
                      }
                      style={{ width: "100%", borderRadius: "0 0 2px 2px" }}
                      title="Archive"
                  >
                  <img
                      alt={"archive"}
                      src={"/inbox.svg"}
                      style={{ width: "32px", height: "32px" }}
                  /> 
                  </button>
                    )}
                    {props.dataType !== "BuildFolders" ? (
                      <button
                      className="btn"
                      onClick={() =>
                      {
                        moveObject(item, true);
                        console.log("Object moved")
                      }
                      }
                      style={{ width: "100%", borderRadius: "0 0 2px 2px" }}
                      title="Move to folder"
                  >
                  <img
                    src={"/folder_move.svg"}
                    style={{ width: "32px", height: "32px" }}
                  /> 
                  </button>
                    ):(null)}
                  {props.dataType === "Parts" ? (
                    <React.Fragment> 
                  <Button  className="btn" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) =>
                      {
                        handleClick(event,item);
                        console.log("Object archived")
                      }}
                  style={{ width: "100%", borderRadius: "0 0 2px 2px" }}
                  title="More">
                    <img
                        alt={"more"}
                        src={"/more.svg"}
                        style={{ width: "32px", height: "32px" }}
                  />                   
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() =>
                      {
                        addNewFile();
                        console.log("Object archived")
                      }
                      }>Add New File</MenuItem>
                      <MenuItem onClick={() =>
                      {
                        downloadFile();
                        console.log("Object archived")
                      }
                      }>Download File</MenuItem>
                  </Menu>
                  </React.Fragment>
                  ):null}
                  </div>
                </div>                 
              );
            })
        }
                  <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleDialogClose}
                    aria-labelledby="responsive-dialog-title"
                    className= "catalog-dialog"
                  >
                    <DialogTitle id="responsive-dialog-title">{"Upload Data " + (selectedPartItem['id']?"in " + selectedPartItem['id']: '') }</DialogTitle>
                    <DialogContent>
                    <div className="each-content"><input type={"file"} onChange={(val) => {fileuplodeDataSet(val)}}/></div>
                    <div className="each-content"><input
                                  type={"text"}
                                  value={pathName}
                                  onChange={(ev) => {
                                    let v = ev.target.value;
                                    setPathName(v);
                                  }}
                                /></div>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleDialogClose} color="primary">
                        cancel
                      </Button>
                      <Button onClick={fileuplode} color="primary" autoFocus>
                      Upload
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    fullScreen={fullScreen}
                    open={moveDialogOpen}
                    onClose={handleMoveDialogClose}
                    aria-labelledby="responsive-dialog-title"
                    className= "catalog-dialog"
                  >
                    <DialogTitle id="responsive-dialog-title">{"Move Build to Folder " }</DialogTitle>
                    <DialogContent>
                    <div className="each-download-content">
                    <List className={classes.root}>


                      {folderAndArchiveIndexes.folders.map((value:any) => {
                        const labelId = `checkbox-list-label-${value}`;

                        return (
                        <ListItem key={value} role={undefined} dense button onClick={handleFolderToggle(value)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={folderChecked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                            </ListItem>
                            );
                          })}
                          <ListItem key={folderName} role={undefined} dense button>
                          <ListItemIcon  onClick={handleFolderToggle(folderName)}>
                            <Checkbox
                              edge="start"
                              checked={folderChecked.indexOf(folderName) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': 'checkbox-list-label-newFolder' }}
                            />
                            </ListItemIcon>
                            <input
                        type={"text"}
                        value={folderName}
                        onChange={(ev) => {
                          // ev.stopPropagation();
                          let v = ev.target.value;
                          setFolderName(v);
                        }}
                      />
                            {/* <ListItemText id={'checkbox-list-label-newFolder'} primary={`${folderName}`} /> */}
                            </ListItem>
                    </List>
                    </div>
                    <div className="each-section">
                      
                     </div>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleMoveDialogClose} color="primary">
                        cancel
                      </Button>
                      <Button onClick={moveBuildFileToFolder} color="primary" autoFocus>
                      Move
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    fullScreen={fullScreen}
                    open={downloadDialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="responsive-dialog-title"
                    className= "catalog-dialog"
                  >
                    <DialogTitle id="responsive-dialog-title">{"Download Data " + (selectedPartItem['id']?"from " + selectedPartItem['id']: '') }</DialogTitle>
                    <DialogContent>
                    <div className="each-download-content">
                    <List className={classes.root}>
                      {fileList.map((value:any) => {
                        const labelId = `checkbox-list-label-${value}`;

                        return (
                        <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value} : ${files[value]}`} />
                            </ListItem>
                            );
                          })}
                    </List>
                    </div>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleDialogClose} color="primary">
                        cancel
                      </Button>
                      <Button onClick={fileDownload} color="primary" autoFocus>
                      Download
                      </Button>
                    </DialogActions>
                  </Dialog>
        </React.Fragment>
      </div>
    </>
  );
}

export default Catalog;
