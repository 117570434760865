import { IState } from "../../../Interfaces";

const onNewBuildSuccessCloseDialogReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.modals.newBuildSuccessModalStatus = false

    return newState;
};

export default onNewBuildSuccessCloseDialogReducer;