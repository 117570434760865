
import { IPart, IState } from "../../Interfaces";

const onGetHighlightedPartReducer = (state: IState, action) => {
    const highlightedPartData = action.payload as IPart;
    const newState = { ...state } as IState;
    newState.data.parts.loadedParts = [highlightedPartData, ...newState.data.parts.loadedParts]
    newState.data.search = {
        ...newState.data.search,
        highlightedPartData: highlightedPartData,
    };

    return newState;
};

export default onGetHighlightedPartReducer;