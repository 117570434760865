import { setPlate } from "../../../Instances";
import { IState } from "../../../Interfaces";

const onAddPartToAddedPartsReducer = (state: IState, action) => {
    
    const ids = action.payload as any;
    const newState = setPlate(state, ids, false) as IState;
    return newState;
};

export default onAddPartToAddedPartsReducer;