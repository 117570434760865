import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DisplayReviewBuildCardView, DisplayBuildListView, DisplayBuildsOptions, DisplayFolder } from "../..";
import { IState } from "../../../Interfaces";
import React from "react";
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import { onLoadBuilds } from "../../../Actions/Home";

const Props = (state: IState, ownProps: any) => {

    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
        Boolean(ownProps.displayBuilds && ownProps.displayBuilds.length > 0) as boolean;

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        showListView: state.data.info.showListView as boolean,
        isLoadingComplete: state.data.builds.isLoadingComplete as boolean,
        displayFolders: ownProps.displayFolders,
        displayBuilds: ownProps.displayBuilds,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
        nextToken: state.data.builds.nextToken,
        loading: !(state.data.builds.isLoadingComplete as boolean),
        searchText: state.data.builds.searchText as string,
    };

    return props;
};

const Actions = {
    onLoadBuilds: onLoadBuilds,
};

const DisplayReviewBuildsMethod = (props) => {

    const loading = props.loading;

    const searchResultNotFound = () => {
        if (props.displayBuilds.length === 0 && loading !== true) {
            return (
                <div className="d-block w-100 text-center">
                    <img src="/no-result-found.svg" alt="no-search-result" className="icon-small"/>
                    <p className="justify-content-center p-4">No Builds in the name of <b>{props.searchText}</b></p>
                </div>
            )

        }
    }
        return (
            <>
                <>
                    {
                        props.hasDisplayItems ?
                            (
                                <>
                                    <LoadingComponent visible={!props.isLoadingComplete} />
                                    <dl className={`${props.showListView ? "grid-view" : ""}`}>
                                        <dd>
                                        {
                                            props.displayBuilds
                                                .map((dp, i) => {
                                                    return (
                                                        <DisplayReviewBuildCardView
                                                            {...{
                                                                key: i,
                                                                index: i,
                                                                displayBuild: dp,
                                                                isSelected: props.isSelected,
                                                                onUnselect: props.onUnselect,
                                                                onSelect: props.onSelect,
                                                            }}
                                                        />
                                                    )
                                                })
                                        }
                                            {/* {
                                                <>
                                                    {
                                                        props.displayBuilds.map((dp, i) => (props.showListView ? <DisplayBuildListView key={i} displayBuild={dp} /> : <DisplayReviewBuildCardView key={i} displayBuild={dp} />))
                                                    }
                                                </>
                                            } */}
                                        </dd>
                                        {searchResultNotFound()}
                                        {
                                            props.nextToken ? (
                                                <div className="d-block w-100 text-center pb-3 align-items-center">
                                                    <button onClick={() => props.onLoadBuilds()} className="btn btn-primary justify-content-center">
                                                        Load More Builds<span className="btn-right-arrow"></span></button>
                                                </div>
                                            ) : (null)
                                        }
                                    </dl>
                                </>
                            )
                            :
                            (
                                null
                            )
                    }
                </>
            </>
        );
}

const DisplayReviewBuilds = withRouter(connect(Props, Actions)(DisplayReviewBuildsMethod));

export default DisplayReviewBuilds;
