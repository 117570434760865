import { IState } from "../../../Interfaces";

const onNewBuildFailReducer = (state: IState, action) => {
    const errorMessage = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.modals.newBuildModalStatus = {
        ...newState.data.modals.newBuildModalStatus,
        hasError: true,
        errorMessage: errorMessage,
    };
    
    return newState;
};

export default onNewBuildFailReducer;