import React from "react";
import JSZip from "jszip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { RouteComponentProps, withRouter } from "react-router";
import { NotificationManager } from "react-notifications";
import API, { graphqlOperation } from "@aws-amplify/api";
import { v4 as uuidv4 } from "uuid";

import EditJsonModal from "../../EditJsonModal";
import WorkspaceScene from "./WorkspaceScene";
import WorkspaceReducer from "./WorkspaceReducer";
import dataStorage from "../../S3DataStore/S3DataStore";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import saveFile, { patchSlicer } from "../../utils";
import { schemas } from "../../Schemas";
import IPartInfo from "../../interfaces/IPartInfo";
import IMatrix from "../../interfaces/IMatrix";
import IBuild from '../../interfaces/IBuild'
import { createBuild } from "../../graphql/mutations";
import Scene from "../../Scene";
import awsconfig from '../../aws-exports';

Amplify.configure(awsconfig);


const jsondiffpatch = require('jsondiffpatch').create();

type PrintableEntity = IPartInfo | IMatrix;
export interface IWorkSpaceState {
	build: IBuild;
	showLoading: string[];
	slicerText: string
	combindedConfigEditor: Object | null;
	viewState: WorkspaceViewType;
	selectedMatrix: IMatrix | null;
	moho: string,
	selectedPart?: any[],
	selectedPrinter?: any,
	reviewLoading?: boolean
}

export enum WorkspaceViewType {
	SceneView,
	GrafanaView
}


interface IWorkSpaceProps extends RouteComponentProps {
	dataStore: any;
}

export interface IWorkSpace {
	getResultJson(date: string): Promise<Object>;
	openJsonInEditor(json: Object): void;
	downloadBundle(): void;
	sendBundle(name: string): void;
	updateName(json: Object): void;
}

export class WorkSpace extends React.Component<
	IWorkSpaceProps,
	IWorkSpaceState
> {

	dispatch: (action: any) => void;
	zip: JSZip;
	scene: Scene | null = null
	constructor(props: any) {
		super(props);
		this.state = {
			showLoading: [],
			moho: "",
			build: {
				id: "loading",
				machineConfig: {},
				millConfig: {},
				materialConfig: {},
				recipe: {},
				parts: [],
				bundles: [],
			},
			slicerText: "",
			combindedConfigEditor: null,
			viewState: WorkspaceViewType.SceneView,
			selectedMatrix: null
		};

		this.dispatch = (action: any) => {
			const newState = WorkspaceReducer(this.state, action);
			this.setState(newState);
		};

		this.getResultJson = this.getResultJson.bind(this);
		this.openJsonInEditor = this.openJsonInEditor.bind(this);
		this.downloadBundle = this.downloadBundle.bind(this);
		this.sendBundle = this.sendBundle.bind(this);
		this.updateName = this.updateName.bind(this);

		this.generateBundle = this.generateBundle.bind(this);
		this.zip = new JSZip();
	}

	componentDidMount() {
		this.LoadBuild();
	}

	private async LoadBuild() {
		const uuidLoading = uuidv4();
		this.dispatch({
			type: 'addLoading',
			cargo: {
				uuidLoading: uuidLoading
			}
		})
		let { buildId } = this.props.match.params as any;

		async function processParameter(parameterType: string, objectRef, parameterName, index = -1) {
			try {
				let currentObject = index !== -1 ? objectRef[parameterName][index] : objectRef[parameterName]
				let config = await dataStorage().objectStores[parameterType].getObject(currentObject?.resultJson?.id)
				let parameter = objectRef[parameterName]
				if (parameter && typeof config !== 'undefined') {
					let parameterCopy = { ...parameter };
					if (JSON.stringify(parameterCopy.originalJson) !== JSON.stringify(config)) {
						NotificationManager.info(`${parameterType} have been changed`);
						parameterCopy.originalJson = { ...config };
						parameterCopy.resultJson = jsondiffpatch.patch(config, parameterCopy.diff)
						objectRef[parameterName] = parameterCopy
					}
				}
			} catch (reason: any) {
				NotificationManager.error(`${parameterType} read error: ${reason.toString()}`)
			}
		}

		try {
			let build = await dataStorage().objectStores["Builds"].getObject(buildId)
			this.setState({ ...this.state, build });
			let configsPromises: Promise<any>[] = []

			configsPromises.push(processParameter("MachineConfigs", build, "machineConfig"));
			configsPromises.push(processParameter("MillConfigs", build, "millConfig"));
			configsPromises.push(processParameter("Recipes", build, "recipe"));
			configsPromises.push(processParameter("MaterialConfigs", build, "materialConfig"));

			let partsList = (build.parts.flatMap((entity: IPartInfo | IMatrix) => {
				if ("children" in entity) {
					return entity.children.map((part: IPartInfo, index) => { return { part: part, index: index } })
				}
				return { part: entity, index: -1 };
			}));
			for (let part of partsList) {
				configsPromises.push(processParameter("PartRecipes", (part.part as IPartInfo).properties, "PartConfig", part.index))
			}
			await Promise.all(configsPromises)
		} catch (reason: any) {
			NotificationManager.error(`Load build error: ${reason.toString()}`)
		} finally {
			this.dispatch({
				type: 'removeLoading',
				cargo: {
					uuidLoading: uuidLoading
				}
			})
		}
	}
	componentWillUnmount() {
		this.clearObject(this.state.build)
	}

	openJsonInEditor(json: Object) {
		this.setState(Object.assign(this.state,
			{ combindedConfigEditor: json }));
	}

	async getResultJson(startTime: string) {
		const machineConfig = scrapName(this.state.build.machineConfig?.resultJson);
		const millConfig = scrapName(this.state.build.millConfig?.resultJson);
		const recipe = scrapName(this.state.build.recipe?.resultJson);
		const materialConfig = scrapName(this.state.build.materialConfig?.resultJson);

		const initiator = (await Auth.currentUserInfo()).attributes.email;
		let combinedConfig = mergeDeep(machineConfig, millConfig);
		combinedConfig = mergeDeep(combinedConfig, recipe);
		combinedConfig = mergeDeep(combinedConfig, materialConfig);

		let partsPromises: Promise<any>[] = []
		this.state.build.parts.flatMap(async (entity: PrintableEntity) =>
			"children" in entity
				? entity.children.map(part => partsPromises.push(preparePartJson(part)))
				: partsPromises.push(preparePartJson(entity))
		)
		let parts = await Promise.all(partsPromises)
		let build = prepareBuildJson(this.state.build)
		return Object.assign(
			{},
			{ initiated_by: initiator },
			{ initiated_at: startTime },
			{ Combined_Config: combinedConfig },
			build,
			{ Parts: parts }
		);
	}

	private saveConfigFiles() {
		["machineConfig", "millConfig", "recipe", "materialConfig"].forEach((configName: string) => {
			let config = this.state.build[configName];
			if (config.originalJson?.id === undefined) {
				throw `${configName} is undefined`;
			}
			this.zip.file(
				`Configs/${configName}.json`,
				JSON.stringify(this.state.build[configName].resultJson, null, "\t")
			);
		});
	}

	setScene(scene: Scene) {
		this.scene = scene
	}
	async generateBundle(startTime: string) {
		try {
			this.saveConfigFiles();
			const resultJson = await this.getResultJson(startTime);
			// const myInit = { // OPTIONAL
			//     headers: {}, // OPTIONAL
			//     body: JSON.stringify(resultJson)
			// };
			// const response =  await  API.get("VizAppRestAPI", '/bundles', myInit);
			// console.log(response)
			let uniquePartIDs = this.state.build.parts
				.flatMap((entity: PrintableEntity) => {
					if ("children" in entity) {
						return entity.children.map((part: IPartInfo) => part.properties.PartID);
					}
					return (entity as IPartInfo).properties.PartID;
				})
				.filter(
					(fileName: string, index: number, array: any[]) =>
						array.indexOf(fileName) === index
				);

			for (const partID of uniquePartIDs) {
				try {
					let fileName = await dataStorage().getObjectFileName("Parts", partID);
					//todo: create some storage in state to avoid duplicate downloading
					let meshData = await dataStorage().downloadObjectFile(
						"Parts",
						partID
					);
					this.zip.file("Meshes/" + fileName, meshData);
				} catch (error) {
					throw `Error during Mesh downloading for Part ${partID}`;
				}
			}

			let uniqueSlicerConfigs = this.state.build.parts
				.flatMap((entity: PrintableEntity) => {
					if ("children" in entity) {
						return entity.children.map((part: IPartInfo) => part.properties.SlicerConfig?.id);
					}
					return (entity as IPartInfo).properties.SlicerConfig?.id;
				})
				.filter(
					(SlicerID: string, index: number, array: any[]) =>
						array.indexOf(SlicerID) === index
				);

			const slicerConfigs = {}
			for (const SlicerID of uniqueSlicerConfigs) {
				try {
					let fileName = await dataStorage().getObjectFileName(
						"SlicerConfigs",
						SlicerID
					);
					//todo: create some storage in state to avoid duplicate downloading
					slicerConfigs[SlicerID] = await dataStorage().downloadObjectFile(
						"SlicerConfigs",
						SlicerID
					)
				} catch (error) {
					throw `Couldn't download SlicerConfig ${SlicerID}`;
				}
			}


			let processedSlicers: Array<any> = []
			let dec = new TextDecoder("utf-8");
			let parts = this.state.build.parts.flatMap((entity: PrintableEntity) => "children" in entity ? entity.children : entity);
			const partsPromises = parts
				.map(async (part: IPartInfo, index) => {
					const SlicerID = part.properties.SlicerConfig
						? part.properties.SlicerConfig.id
						: undefined
					let fileData = slicerConfigs[SlicerID]
					let fileName = await dataStorage().getObjectFileName(
						"SlicerConfigs",
						SlicerID
					);
					let partConfig = part.properties.PartConfig.resultJson ? part.properties.PartConfig.resultJson : part.properties.PartConfig
					if (partConfig['SlicerPatch']) {
						resultJson.Parts[index].Slicer_Config = `SlicerConfigs/${SlicerID}_${index}.cfg`
						this.zip.file(resultJson.Parts[index].Slicer_Config, patchSlicer(dec.decode(fileData), partConfig['SlicerPatch']));
					} else {
						if (!processedSlicers.includes(fileName)) {
							this.zip.file("SlicerConfigs/" + fileName, fileData);
							processedSlicers.push(fileName)
						}
					}
				})
			await Promise.all(partsPromises)
			const jsonData = JSON.stringify(resultJson, null, "\t");
			this.zip.file("state.json", jsonData);
		} catch (exception: any) {
			NotificationManager.error(`Genereting bundle error: ${exception.toString()}`);
			// throw `generationStopper`;
		}
	}

	downloadBundle() {
		this.saveBuild();
		const startTime = new Date().toISOString();
		const uuidLoading = uuidv4();
		this.dispatch({
			type: 'addLoading',
			cargo: {
				uuidLoading: uuidLoading
			}
		})
		this.generateBundle(startTime)
			.then(() => {
				this.zip.generateAsync({ type: "blob" }).then((content: any) => {
					// consider using FileSaver.js
					saveFile(
						content,
						`${this.state.build.id}_${startTime}.bundle`,
						"application/zip"
					);
				});
			})
			.finally(() => {
				this.dispatch({
					type: 'removeLoading',
					cargo: {
						uuidLoading: uuidLoading
					}
				})
			});
	}

	clearObject(build) {
		if (!build)
			return
		let parts = build.parts.flatMap((entity: PrintableEntity) => "children" in entity ? entity.children : entity);
		for (let part of parts) {
			delete part.mesh;
		}
	}

	private saveBuild(build?: any) {
		let buildCopy = build ? build : { ...this.state.build };

		try {
			//...drawing code...
			const sizeX = 300
			const sizeY = 230

			const tempCanvas = document.createElement('canvas');
			let ctx = tempCanvas.getContext('2d');
			tempCanvas.width = sizeX
			tempCanvas.height = sizeY
			if (this.scene && ctx) {
				ctx.drawImage(this.scene.renderer.getContext().canvas, 0, 0, sizeX, sizeY);
				buildCopy.icon = ctx.canvas.toDataURL("image/jpeg")
			}
		} catch (e) {
			console.log(e);
			return;
		}

		this.clearObject(buildCopy)
		dataStorage()
			.objectStores["Builds"].update(buildCopy)
			.then(() => {
				NotificationManager.success("Build configuration has been saved");
			});
	}

	private buildMarker() {

	}

	private async checkConfig() {
		return this.getResultJson(new Date().toISOString()).then((jsonToSend) => {
			// let ajv = getAjv();
			// let combinedConfig = jsonToSend;
			// combinedConfig.id = "Placeholder";
			// if (!ajv.validate(schemas["CombinedConfig.json"], combinedConfig)) {
			// 	if (
			// 		!window.confirm(
			// 			"Schema validation failed for combined config." +
			// 			"Are you sure you want to send it?"
			// 		)
			// 	) {
			// 		throw "aborted";
			// 	}
			// }
			return true;
		});
	}
	updateName(data) {
		this.saveBuild(data);
	}
	sendBundle(name) {
		this.saveBuild();
		const startTime = new Date().toISOString();
		const uuidLoading = uuidv4();
		this.dispatch({
			type: 'addLoading',
			cargo: {
				uuidLoading: uuidLoading
			}
		})
		this.checkConfig()
			.then(() =>
				this.generateBundle(startTime).then(() => {
					this.zip.generateAsync({ type: "blob" }).then((content: any) => {
						// consider using FileSaver.js
						Storage.put(
							`VizApp/ToProcess/${this.state.build.id}_${startTime.replaceAll(
								":",
								"_"
							)}.bundle`,
							content
						).then(async (bundleKey: any) => {
							try {
								const initiator = (await Auth.currentUserInfo()).attributes.email;
								const data = {
									id: this.state.build.id,
									initiated_by: initiator,
									initiated_at: new Date().toISOString(),
									machine: this.state.build.machineConfig.resultJson.id || this.state.build.machineConfig.id,
									recipe: this.state.build.recipe.resultJson.id || this.state.build.recipe.id,
									current_status: "waiting",
									path: bundleKey.key,
									dumb: 1,
									log_field: [],
									marker_name: name
								};

								const result = await API.graphql(
									graphqlOperation(createBuild, {
										input: data,
									})
								);
								console.log(result);
							} catch (error) {
								console.log(error);
								return false;
							}
							return true;
						});
					});
				})
			)
			.finally(() =>
				this.dispatch({
					type: 'removeLoading',
					cargo: {
						uuidLoading: uuidLoading
					}
				})
			);
	}

	render() {
		let sceneDisplay = "flex"
		let grafanaDisplay = "none"
		if (this.state.viewState === WorkspaceViewType.GrafanaView) {
			[sceneDisplay, grafanaDisplay] = [grafanaDisplay, sceneDisplay];
		}
		let parts = this.state.build.parts.flatMap((entity: PrintableEntity) => "children" in entity ? entity.children : entity);
		return (
			<>
				<LoadingComponent visible={
					this.state.showLoading.length > 0
				} />
				<EditJsonModal
					jsonToEdit={this.state.combindedConfigEditor}
					modalCloser={() => {
						this.setState(
							Object.assign(this.state, { combindedConfigEditor: null })
						);
					}}
					schemaName={"CombinedConfig"}
				/>
				<div style={{ display: "flex", height: "100vh" }}>
					<div style={{
						flexGrow: 1,
						display: sceneDisplay
					}}>
						<WorkspaceScene
							refProcessor={scene => (this.setScene(scene))}
							dispatch={this.dispatch}
							parts={parts}
							recipe={this.state.build.recipe?.resultJson}
							materialConfig={this.state.build.materialConfig?.resultJson}
							machineConfig={this.state.build.machineConfig?.resultJson}
						/>

					</div>

					<div style={{
						flexGrow: 1,
						display: grafanaDisplay
					}}>
						<iframe
							src="https://lava.mantle3d.com/grafana/d/zgMh8d2Gz/b4-machine?orgId=1"
							width="100%"
							height="100%"
							frameBorder="0">
						</iframe>
					</div>

				</div>
			</>
		)
	}
}

function isObject(item: any) {
	return item && typeof item === "object" && !Array.isArray(item);
}

function mergeDeep(target: any, source: any) {
	let output = Object.assign({}, target);
	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach((key) => {
			if (isObject(source[key])) {
				if (!(key in target)) {
					Object.assign(output, { [key]: source[key] });
				} else {
					output[key] = mergeDeep(target[key], source[key]);
				}
			} else {
				Object.assign(output, { [key]: source[key] });
			}
		});
	}
	return output;
}

function scrapName(target: any) {
	return Object.assign({}, target, { id: undefined });
}

async function preparePartJson(part: IPartInfo) {
	let result = Object.assign({}, part) as any;
	delete result.mesh;
	delete result.buildErrors;
	delete result.properties.userData;
	result.properties.File =
		"Meshes/" +
		await dataStorage().getObjectFileName("Parts", result.properties.PartID);
	result.properties.Recipe_Config = result.properties.PartConfig.resultJson;
	result.properties.Slicer_Config =
		"SlicerConfigs/" +
		await dataStorage().getObjectFileName(
			"SlicerConfigs",
			result.properties.SlicerConfig.id
		);

	Object.assign(result, result.properties);
	delete result.SlicerConfig;
	delete result.PartConfig;
	delete result.properties;

	return result;
}

function prepareBuildJson(build: any) {
	let result = { ...build };
	delete result.bundles;
	delete result.machineConfig;
	delete result.millConfig;
	delete result.overrides;
	delete result.parts;
	delete result.recipe;

	return result;
}

export default withRouter(WorkSpace);
