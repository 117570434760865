import React, { PureComponent } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import internal from 'stream';


function MVPDisplayPasteConsumptionModal(props) {
    const { showModal, hideModal } = props;
    const consumptionDetails = props.pasteConsumption;
    
    //AggregatePasteConsumption details added in object
    let aggregatePasteConsumption: any[] = []
    let sum = 0;
    for (let i = 0; i < consumptionDetails.length; i++) {
        sum += parseFloat(consumptionDetails[i].pasteConsumption);
        aggregatePasteConsumption.push({
            ...consumptionDetails[i], aggregatePasteConsumption: sum.toFixed(2)

        })
    }
    console.log('aggregatePasteConsumption' + JSON.stringify(aggregatePasteConsumption, null, 4))

    //Getting the max paste consumption value
    const pasteConsumptionValues = aggregatePasteConsumption.map(object => {
        return object.pasteConsumption;
    });

    const maxPasteConsumptionValue = Math.max(...pasteConsumptionValues);

    //Getting the max aggregate paste consumption value
    const aggregatePasteConsumptionValues = aggregatePasteConsumption.map(object => {
        return parseFloat(object.aggregatePasteConsumption)
    });
    const maxAggregatePasteConsumptionValue = Math.max(...aggregatePasteConsumptionValues);

    //Populating X cordinate values
    const getXValue = aggregatePasteConsumption => {
        let label = JSON.stringify(aggregatePasteConsumption.slab)
        return label.slice(6, label.length - 1)
    };

 // Tooltip with Required Values
    const CustomTooltip = ({ payload, label }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ border: 'red'}}>
                    <p className="label">{`Slab : ${label}`}</p>
                    <p className="label">{`Paste consumption : ${payload[0]["payload"].pasteConsumption} mL`}</p>
                    <p className="label">{`Aggregate paste consumption : ${payload[0]["payload"].aggregatePasteConsumption} mL`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <Modal size="xl" centered show={showModal} onHide={hideModal}>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "1px solid #DBDBDB",
                }}
            >
                <Modal.Title>Paste consumption</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    display: "flex",

                    flexDirection: "column",
                    //width:'100%',
                    height: '500px'
                }}
                className="modal-body"
            >
               

                <ResponsiveContainer width="100%" height="100%">
                    {/* <LineChart
                        width={500}
                        height={300}
                        data={aggregatePasteConsumption}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey={getXValue} dy={10} angle={-90} 
                            axisLine={false} >
                            <Label value="Slab" offset={10} position="bottom" />
                            </XAxis>
                        <YAxis dataKey="aggregatePasteConsumption" domain={[0, Math.round(maxVal+3)]} dy={10}>
                            <Label value="Paste consumption in mL" offset={0} position="insideBottomLeft" angle={-90}></Label>
                        </YAxis>
                        <Tooltip content={<CustomTooltip active=""  payload={consumptionDetails.pasteConsumption} label={consumptionDetails.pasteConsumption  } />} />
                        <Legend verticalAlign="bottom"  />
                        <Line type="monotone" name="Paste consumption" dataKey="aggregatePasteConsumption" stroke="#ff6900" activeDot={{ r: 10 }} />
                      
                    </LineChart> */}

                    <LineChart
                        width={500}
                        height={300}
                        data={aggregatePasteConsumption}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis interval={aggregatePasteConsumption.length < 25 ? 0 : 5} dataKey={getXValue} label={{ value: 'Slab', position: 'bottom' }} />
                        <YAxis padding={{ top: 20, bottom: 20 }}  yAxisId="left" domain={[0, Math.round(maxPasteConsumptionValue + 1)]} label={{ value: 'Paste consumption in mL', angle: -90, position: 'center'  }} />
                        <YAxis  domain={[0, Math.round(maxAggregatePasteConsumptionValue + 2)]} yAxisId="right" orientation="right" label={{ value: 'Aggregate paste consumption in mL', angle: -90, position: 'center' }}  />
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip payload={aggregatePasteConsumption} label={aggregatePasteConsumption} />} />
                        <Legend verticalAlign="bottom" />
                        <Legend />
                        <Line
                            name="Paste consumption"
                            yAxisId="left"
                            type="monotone"
                            dataKey="pasteConsumption"
                            stroke="#ff6900"
                            activeDot={{ r: 5 }}
                        />
                        <Line name="Aggregate paste consumption" activeDot={{ r: 5 }} yAxisId="right" type="monotone" dataKey="aggregatePasteConsumption" stroke="#0000FF" />
                    </LineChart>
                </ResponsiveContainer>


            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MVPDisplayPasteConsumptionModal;
