import { IPlate, IState } from '../../../Interfaces';

const onRenamePlateSuccessReducer = (state: IState, action) => {
    const renamedPlate = action.payload as IPlate;
    const indexToBeReplaced = state.data.plates.loadedPlates.findIndex(lp => lp.id === renamedPlate.id);
    const isPlateExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPlateExists) newState.data.plates.loadedPlates.splice(indexToBeReplaced, 1, renamedPlate);

    newState.data.modals.renamePlateModalStatus = {
        ...newState.data.modals.renamePlateModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onRenamePlateSuccessReducer;