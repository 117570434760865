import { IState } from '../../../Interfaces';

const onResetConfigurationScreenReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.configurations.highlightedConfigurationId = '';
    newState.data.configurations.highlightedConfigurationType = '';
    newState.data.configurations.highlightedConfigurationData = '';
    
    return newState;
};

export default onResetConfigurationScreenReducer;