import React, { FunctionComponent } from "react";
import Scene from "../Scene";
import WorkspaceMeshManager, {
	IWorkspaceMeshManager,
} from "./WorkspaceMeshManager";
import WorkspaceSceneController from "./WorkspaceSceneController";

import * as THREE from "three";
import IPartInfo from "../interfaces/IPartInfo";
import IMatrix from "../interfaces/IMatrix";

interface IWorkspaceSceneProps {
	parts: IPartInfo[];
	selectedMatrix: IMatrix | null;
	machineConfig?: any;
	materialConfig?: any;
	recipe?: any;
	millConfig?: any;
	dispatch: any;
	refProcessor: (ref: Scene) => void;
	screenName?: string;
	screenChangedTog?: boolean;
}

const WorkspaceScene: FunctionComponent<IWorkspaceSceneProps> = (props) => {
	const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(
		null
	);

	const [scene, setScene] = React.useState<Scene | null>(null);
	const [
		workspaceMeshManager,
		setWorkspaceMeshManager,
	] = React.useState<IWorkspaceMeshManager | null>(null);

	const setSceneContainer = React.useCallback((element) => {
		setContainerRef(element);
		if (element) {
			let scene = new Scene(element);
			let buildAreaVolume = new THREE.Box3(
				new THREE.Vector3(-75, -75, 0),
				new THREE.Vector3(75, 75, 100))
			setScene(scene);
			props.refProcessor(scene)
			let workspaceMeshManager = new WorkspaceMeshManager(
				scene,
				props.dispatch,
				buildAreaVolume
			);
			workspaceMeshManager.updateParts(props.parts, props.screenName);
			setWorkspaceMeshManager(workspaceMeshManager);
		}
	}, []);

	React.useEffect(() => {
		if (workspaceMeshManager) workspaceMeshManager.updateScreen(true);
	}, [(props.screenChangedTog)]);
	React.useEffect(() => {

		const buildVolume = props.machineConfig?.Build?.BuildVolumes[0];

		let minVector = new THREE.Vector3(
			buildVolume?.Size ? -(buildVolume.Size.x / 2) : buildVolume?.Min ? buildVolume?.Min.x : -75 / 2,
			buildVolume?.Size ? -(buildVolume.Size.y / 2) : buildVolume?.Min ? buildVolume?.Min.y : -75 / 2,
			buildVolume?.Size ? 0 : buildVolume?.Min ? buildVolume?.Min.z : 0,
		)
		let maxVector = new THREE.Vector3(
			buildVolume?.Size ? (buildVolume.Size.x / 2) : buildVolume?.Max ? buildVolume?.Max.x : 75 / 2,
			buildVolume?.Size ? (buildVolume.Size.y / 2) : buildVolume?.Max ? buildVolume?.Max.y : 75 / 2,
			buildVolume?.Size ? (buildVolume.Size.z) : buildVolume?.Max ? buildVolume?.Max.z : 100,
		)

		const buildAreaVolume = new THREE.Box3(minVector, maxVector)
		workspaceMeshManager?.setBuildAreaVolume(buildAreaVolume);
	}, [JSON.stringify(props.machineConfig)]);

	React.useEffect(() => {
		if (workspaceMeshManager) workspaceMeshManager.updateSelectedMatrix(props.selectedMatrix);
	}, [JSON.stringify(props.selectedMatrix)]);

	React.useEffect(() => {
		const recipe = props.recipe;
		let radius, offset;
		if (recipe !== undefined) {
			let purge = recipe.Purge;
			offset = purge?.Offset;
			radius = purge?.Radius;
		}
		if (workspaceMeshManager) {
			//workspaceMeshManager.setPurgeArea(radius, offset);
		}
	}, [JSON.stringify(props.recipe)]);

	React.useEffect(() => {
		const materialObj = props.materialConfig;
		let coeffXY = 1;
		let coeffZ = 1;
		if (materialObj !== undefined) {
			if (materialObj.shrinkage) {
				if (typeof (materialObj.shrinkage.xy) === 'string') {
					let parsed = parsePercentage(materialObj.shrinkage.xy);
					if (!Number.isNaN(parsed) && parsed < 100) {
						coeffXY = 1 / (1 - (parsed / 100));
					}
				}
				else if (typeof (materialObj.shrinkage.x) === 'string') {
					let parsed = parsePercentage(materialObj.shrinkage.x);
					if (!Number.isNaN(parsed) && parsed < 100) {
						coeffXY = 1 / (1 - (parsed / 100));
					}
				}
				if (typeof (materialObj.shrinkage.z) === 'string') {
					let parsed = parsePercentage(materialObj.shrinkage.z);
					if (!Number.isNaN(parsed) && parsed < 100) {
						coeffZ = 1 / (1 - (parsed / 100));
					}
				}
			}
		}
		if (workspaceMeshManager) {
			workspaceMeshManager.setMaterialScaling(coeffXY, coeffZ);
		}
	}, [JSON.stringify(props.materialConfig)]);
	return (
		<div ref={setSceneContainer} style={{ flexGrow: 1, position: "relative" }}>
			{scene && props.screenName !== 'build-review' ? (
				<WorkspaceSceneController
					scene={scene}
					meshManager={workspaceMeshManager!}
					dispatch={props.dispatch}
				/>
			) : null}
		</div>
	);
};

function parsePercentage(input: String): number {
	if (input[input.length - 1] === '%') {
		let parsed = Number(input.substr(0, input.length - 1));
		if (!Number.isNaN(parsed)) {
			return parsed;
		}
	}
	return NaN;
}
export default WorkspaceScene;
