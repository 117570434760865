import { EditPlatePhase } from '../../../Enums';
import ACTION_TYPES from '../../../actionTypes';
import { onAddPartsToAddedParts, onClearAddedParts } from '../../Parts';
import { onLoadPartIds } from '../Load/loadParts';

const onNavigateToPlatesPageInit = (isNewPlate) => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATES.INIT,
        payload: isNewPlate,
    };
};

const onNavigateToPlatesPageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATES.DONE,
        payload: null,
    };
};

const onNavigateToPlatesPage = (ids, history, isNewPlate, params = undefined) => {
    return async (dispatch, getState) => {
        const state = getState()
        const currentWorkspaces = state?.data?.workspace?.addedParts || []
        console.log(currentWorkspaces, ids, "currentWorkspaces")
        if (isNewPlate) {
            await dispatch(onClearAddedParts());
        }
        dispatch(onNavigateToPlatesPageInit(isNewPlate));
        await dispatch(onAddPartsToAddedParts(ids));
        history.push({
            pathname: '/dashboard/plates/edit',
            state: { params: params },
        })
        onLoadPartIds()(dispatch, getState);
        dispatch(onNavigateToPlatesPageDone());
    };
};

export default onNavigateToPlatesPage;
