import React, { useState } from 'react';
import { connect } from "react-redux";
import {
	HelpPageTabBar,
	DocumentsDataTable,
} from "../Components";

import { withRouter } from "react-router-dom";

const TabLists = [
	{
		label: 'Software Release Notes',
		value: 'softwarereleasenotes',
		key: 1
	},
	{
		label: 'Mantle User Guides',
		value: 'mantleuserguides',
		key: 2
	},
	{
		label: 'Customer Maintenance Documentation',
		value: 'customermaintenancedocumentation',
		key: 3
	},
	{
		label: 'Field Service Bulletins',
		value: 'fieldservicebulletins',
		key: 4
	},
	{
		label: 'SDSs',
		value: 'sdss',
		key: 5
	}
]



const MantleDocumentation = (props) => {
	const [activeTab, setActiveTab] = useState<any>(TabLists[0])

	return (
		<>
			<section className="home page">
				<header>
					<HelpPageTabBar TabLists={TabLists} activeTab={activeTab} setActiveTab={setActiveTab} />
				</header>

					<section>
						<div className="dashboard-page scrollabe-content">
							<DocumentsDataTable TabLists={TabLists}  activeTab={activeTab} setActiveTab={setActiveTab} />
						</div>
					</section>
			</section>
		</>
	);
};

// const MantleDocumentation = withRouter(MantleDocumentationMethod);

export default MantleDocumentation;

