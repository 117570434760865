import { IState } from "../../../Interfaces";

const onShowFolderViewReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.info.showFolderView = !state.data.info.showFolderView;
    
    return newState;
};

export default onShowFolderViewReducer;