import { IState } from '../../../Interfaces';

const onHighlightBuildReducer = (state: IState, action) => {
    const buildId = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.builds.highlightedBuildId = buildId;

    return newState;
};

export default onHighlightBuildReducer;