import React, {useState} from "react";
import Modal from "react-modal";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import {JsonEditor as Editor} from "jsoneditor-react";
import "./css/customJsonEditor.scss";
import {getListOfPosibleProperties, getSetOfPresentProperties, schemas} from "./Schemas";

import {formatters} from "jsondiffpatch";
import "jsondiffpatch/dist/formatters-styles/html.css";

interface IEditJsonModalProps {
  jsonToEdit: Object | null;
  diff?:Object;
  modalCloser: () => void;
  updateJson?: (arg0: Object) => void;
  schemaName?: string;
}


const EditJsonModal: React.FunctionComponent<IEditJsonModalProps> = (props) => {
  const editorRef = React.useRef(null);

  const refCallback = React.useCallback(
    (editor) => {
      editorRef.current = editor;
      if (editor) {
        let jsonEditor = (editor as any).jsonEditor;
        if (jsonEditor) {
          const refSchemas = schemas;
          const schema = schemas[props.schemaName! + ".json"];

          jsonEditor.setSchema(schema, refSchemas);
        }
      }
    },
    [props.schemaName]
  );
 
  const [openModal, setOpenModal] = useState(false);
  const setSceneContainer = React.useCallback((element) => {
    if( element ){
        element.innerHTML = formatters.html.format(props.diff as any, props.jsonToEdit);
    }
  }, [props.diff,props.jsonToEdit ]);
  let handleClose = () => {
    setOpenModal(false);
  };

  const editingButtons = props.updateJson ? (
    <>
      <button onClick={props.modalCloser}>close without saving</button>
      <button
        onClick={() => {
          let editorElement = editorRef.current;
          let jsonEditor = (editorElement as any).jsonEditor;
          try {
            let jsonModified = jsonEditor.get();
            props.updateJson!(jsonModified);
            props.modalCloser();
          } catch (error) {
            //error handling
          }
        }}
      >
        {" "}
        save and close
      </button>
   { props.diff ? <button onClick={() => setOpenModal(true)}>
                 show diff
           </button> : ''
    }
      <Dialog
        onClose={handleClose}
        open={openModal}
      >  
          <DialogTitle >
              Diff JSON
          </DialogTitle>
          <DialogContent>
            <div ref={setSceneContainer}>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
      </Dialog>
    </>
  ) : null;

  return (
    <Modal
      isOpen={props.jsonToEdit !== null}
      onRequestClose={() => props.modalCloser()}
    >
      <Editor
        ref={refCallback}
        value={props.jsonToEdit}
        allowedModes={["tree", "code"]}
        autocomplete={{
          trigger: "focus",
          getOptions: (
            text: string,
            path: string[],
            input: string,
            editor: any
          ) => {
            if (input === "value") return null;
            const schema = editor.options.schema;
            const schemaRefs = editor.options.schemaRefs;
            let propertiesList = getListOfPosibleProperties(
              path,
              schema,
              schemaRefs
            );
            let presentFields = getSetOfPresentProperties(editor.get(), path);
            let absentProperties = propertiesList.filter(
              (property) => !presentFields.has(property)
            );
            return absentProperties.sort();
          },
        }}
        onValidationError={(errors) => {
          for (let errorWrapper of errors) {
            let error = errorWrapper.error;
            if (error.keyword === "oneOf") {
              let message = "Only one schema of ";
              let subschemaNames = error.schema.map(
                (schema) => schema.name || ""
              );
              message += subschemaNames
                .filter((name) => name !== "")
                .join("||");
              message += " should be valid";

              error.message = message;
            }
          }
        }}
        onModeChange={(modeName)=>{
          if( modeName === 'code'){
            let editorElement = editorRef.current as any;
            editorElement.jsonEditor.aceEditor.setOptions({maxLines: 50});
          }
        }}
      />
      {editingButtons}
    </Modal>
  );
};


export default EditJsonModal;
