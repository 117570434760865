import React, { useEffect, useState, FunctionComponent } from "react";
import { render } from "react-dom";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createBuild, deleteBuild, updateBuild } from "./graphql/mutations";
import {listBuilds, buildByCreatedAt, buildByInitiated} from "./graphql/queries";
import { inspect } from "util";
import { onUpdateBuild } from "./graphql/subscriptions";
import "./WorkSpace/BundlesList.css";
import * as APIt from "./API";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import useInterval from "@use-it/interval";
import {Storage as s3} from "@aws-amplify/storage";
import saveFile, {zeroPad} from "./utils";
// import awsconfig from './aws-exports';
//
// Amplify.configure(awsconfig);

type Build = Omit<
  Exclude<APIt.GetBuildQuery["getBuild"], null>,
  "__typename"
>;

interface IBundlesListProps {}


const BundlesList: FunctionComponent<IBundlesListProps> = (props) => {
  const [bundles, setBundles] = useState<Build[]>([]);
  const [onlyMy, setOnlyMy] = useState<Boolean>(false);
  const [author, setAuthor] = useState<String>("");
  const [currentToken, setCurrentToken] = useState<String>("");
  const [nextToken, setNextToken] = useState<String>("");

  let get_current_list = async () => {
    let result
    let variables: any = {
        limit: 30
    };
    if (currentToken){
          variables.nextToken = currentToken
      }
    if (onlyMy && author) {
        variables.initiated_by = author;
        variables.sortDirection = "DESC"
        let lst = await API.graphql(graphqlOperation(buildByInitiated, variables));
        result = (lst as any).data.bundleByInitiated
    }
    else{
        variables.dumb = 1;
        variables.sortDirection = "DESC"
        let lst = await API.graphql(graphqlOperation(buildByCreatedAt, variables));
        result = (lst as any).data.bundleByCreatedAt
        //lst = await API.graphql(graphqlOperation(listBundles, variables));
        //result = (lst as any).data.listBundles
    }


    let array = result.items;
    // await Promise.all(array.map( async (myValue) => {
    //     try {
    //         if (myValue.dumb)
    //             return
    //         myValue.dumb = 1
    //         delete myValue._deleted;
    //         delete myValue._lastChangedAt;
    //         delete myValue.createdAt;
    //         delete myValue.updatedAt;
    //         await API.graphql(graphqlOperation(updateBundle, {input:myValue,}))
    //     }
    //     catch (e){
    //         console.error(e, e.stack)
    //     }
    // }))
    setNextToken(result.nextToken?result.nextToken:"")

    setBundles(array);
  };

  useInterval(get_current_list, 15000);

  useEffect(() => {
      Auth.currentUserInfo().then((userinfo) => {
          setAuthor(userinfo.attributes.email);
      });
      get_current_list();
  }, []);

    useEffect(() => {
        get_current_list();
    }, [currentToken, onlyMy]);

   return (
    <div className={'bundles-queue-page'}>
      <div>
        <input
          type={"checkbox"}
          defaultChecked={onlyMy.valueOf()}
          onClick={() => {
            Auth.currentUserInfo().then((result) => {
              const email = result.attributes.email;
              setAuthor(email);
              setCurrentToken("")
              setOnlyMy(!onlyMy);
            });
          }}
        />
        Only my
      </div>
      <br />
        {currentToken ? <button key={`HomeButton`} onClick={ev => {ev.stopPropagation(); setCurrentToken("")}}>Home</button>: null}
        {nextToken? <button key={"NextButton"} onClick={ ev => {ev.stopPropagation(); setCurrentToken(nextToken)}}>Next</button>: null}
      <table className={"bundles bundles-queue-table"}>
        <tr className={"bundles"}>
          <th className={"bundles"}>Build</th>
          <th className={"bundles"}>Machine</th>
          <th className={"bundles"}>Recipe</th>
          <th className={"bundles"}>Initiated by</th>
          <th className={"bundles"}>Initiated at</th>
          <th className={"bundles"}>Build_time(s)</th>
          <th className={"bundles"}>Bundle Notes</th>
          <th className={"bundles"}>Instance</th>
          <th className={"bundles"}>Status</th>
          <th className={"bundles"}>Cancel</th>
        </tr>
        {bundles.map((myValue: any, index: number) => {
            let machine_info =  JSON.parse(JSON.parse(myValue.machine_info));
            return (
                <tr className={"bundles"}>
              <td className={"bundles"}>
                <a href={`/item/${myValue.id}`}>{myValue.id}</a>
              </td>
              <td className={"bundles"}>{myValue.machine}</td>
              <td className={"bundles"}>{myValue.recipe}</td>
              <td className={"bundles"}><a href={""} onClick={(event)=>{
                  event.stopPropagation()
                  s3.get(myValue.path, {download:true}).then((content) => {
                      let file = myValue.path.replace(/^.*[\\\/]/, "")
                      saveFile((content as any).Body, file)
                  });
              }}>{myValue.initiated_by}</a></td>
              <td className={"bundles"}>
                {myValue.bundle ? (
                  <a href={`/build/${myValue.id}/bundle/${myValue.bundle}`}>
                    {new Date(myValue.initiated_at).toLocaleString()}
                  </a>
                ) : (
                  new Date(myValue.initiated_at).toLocaleString()
                )}
              </td>
              <td className={"bundles"}>{myValue.build_time?
                  `${zeroPad(Math.floor(myValue.build_time/(60*60)),2)}:${zeroPad(Math.floor(myValue.build_time/60%60),2)}:${zeroPad(myValue.build_time%60,2)}`:""}</td>
              <td className={"bundles"}>{myValue.marker_name}</td>
              <td className={"bundles"}><span className={"bundles-queue-ellipsis-text"}
              title={
                myValue.machine_info ? `${machine_info["instance_id"]}/${machine_info["public_ipv4"]}/${machine_info["instance_type"]}`: ""}
              >{
                myValue.machine_info ? `${machine_info["instance_id"]}/${machine_info["public_ipv4"]}/${machine_info["instance_type"]}`: ""}
                </span></td>
              <td className={"bundles"}>{myValue.current_status}</td>
              {myValue.current_status !== "fail" && myValue.current_status !== "completed" && myValue.current_status !== "canceled" ? (
                <td className={"bundles"}>
                  <button
                    onClick={async () => {
                      myValue.current_status = "canceled";
                      myValue.canceled_by = author;
                      delete myValue._deleted;
                      delete myValue._lastChangedAt;
                      delete myValue.createdAt;
                      delete myValue.updatedAt;
                      try {
                        const lst = await API.graphql(
                          graphqlOperation(updateBuild, {
                            input: myValue,
                          })
                        );
                        console.log(lst);
                      } catch (ex: any) {
                        console.log(
                          `Exception ${ex.toString()} updating failed`
                        );
                      }
                    }}
                  >
                    Cancel
                  </button>
                </td>
              ) : myValue.canceled_by ? (
                <td className={"bundles"}>{myValue.canceled_by}</td>
              ) : (
                <td className={"bundles"}></td>
              )}
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default BundlesList;
