import { IState } from "../../../Interfaces";
import { SortDirection } from "../../../Enums";

const onSortBuildsAscReducer = (state: IState, action) => {

		console.log("CHANGING SORT DIRECTION TO ASC", );
    const newState = { ...state } as IState;
    newState.data.builds.sortDirection = SortDirection.ASC;

    return newState;
};

export default onSortBuildsAscReducer;
