import ACTION_TYPES from '../../../actionTypes';
import { onAddPartsToAddedParts, onClearAddedParts } from '../../Parts';

const onNavigateToPlateReviewPageInit = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATE_REVIEW.INIT,
        payload: null,
    };
};

const onNavigateToPlateReviewPageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATE_REVIEW.DONE,
        payload: null,
    };
};

const onNavigateToPlateReviewPage = (plateId, history) => {
    return async (dispatch, getState) => {
        dispatch(onNavigateToPlateReviewPageInit());
        await dispatch(onClearAddedParts());
        await dispatch(onAddPartsToAddedParts(plateId));
        // history.push(`/dashboard/ReviewPage/Plates/${plateId.plateId}`);
        dispatch(onNavigateToPlateReviewPageDone());
    };
};

export default onNavigateToPlateReviewPage;