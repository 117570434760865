import { IState } from '../../../Interfaces';

const onCopyConfigurationNameChangeReducer = (state: IState, action) => {
    const configurationNameToBeCopied = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.copyConfigurationModalStatus = {
        ...newState.data.modals.copyConfigurationModalStatus,
        inputText: configurationNameToBeCopied,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onCopyConfigurationNameChangeReducer;