import { IPart, IState } from "../../../Interfaces";

const onAddPartSuccessReducer = (state: IState, action) => {
  const addedPart = action.payload as IPart;

  const newState = { ...state } as IState;

  newState.data.modals.addPartModalStatus = {
    ...newState.data.modals.addPartModalStatus,
    inputText: "",
    inputFile: null,
    isOpen: false,
    isValid: false,
    hasError: false,
    errorMessage: "",
    addPartProgress: null,
  };

  return newState;
};

export default onAddPartSuccessReducer;
