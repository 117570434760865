import { IPart, IState } from "../../../Interfaces";

const onDeletePartReducer = (state: IState, action) => {
    const deletedPart = action.payload as IPart;
    const indexToBeReplaced = state.data.parts.loadedParts.findIndex(lp => lp.id === deletedPart.id);
    const isPartExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPartExists) newState.data.parts.loadedParts.splice(indexToBeReplaced, 1);

    newState.data.parts.selectedPartIds = newState.data.parts.selectedPartIds.filter(id => id !== deletedPart.id);
    
    return newState;
};

export default onDeletePartReducer;