import React, { useEffect, useRef } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import Ajv from "ajv";
import ace from "brace";
import "jsoneditor-react/es/editor.min.css";

function JSONEditor({ value = {}, onChange, onValidationError = {} }) {
  const ajv = new Ajv({ allErrors: true, verbose: true });

  const jsonEditorRef = useRef(null);

  useEffect(() => {
    if (jsonEditorRef && jsonEditorRef.current) {
      // @ts-ignore: Object is possibly 'null'.
      jsonEditorRef.current.set(value);
    }
  }, [value]);

  const setRef = (instance: { jsonEditor: null }) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  return (
    <Editor
      ref={setRef}
      ajv={ajv}
      ace={ace}
      value={value}
      onChange={onChange}
      mode="text"
      allowedModes={["tree", "code"]}
      theme="ace/theme/monokai"
      onValidationError={onValidationError}
    />
  );
}

export default JSONEditor;
