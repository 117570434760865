import { IState } from "../../../Interfaces";

const onSearchBuildsTextChangeReducer = (state: IState, action) => {
    const searchText = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.builds.searchText = searchText.toLowerCase() as string;
    
    return newState;
    
};

export default onSearchBuildsTextChangeReducer;