import { IState, IFolder } from "../../../Interfaces";

const onArchiveFolderReducer = (state: IState, action) => {
    const archivedFolder = action.payload as IFolder;
    const indexToBeReplaced = state.data.folders.loadedFolders.findIndex(lp => lp.id === archivedFolder.id);
    const isFolderExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isFolderExists) newState.data.folders.loadedFolders.splice(indexToBeReplaced, 1, archivedFolder);

    newState.data.folders.selectedFolderIds = newState.data.folders.selectedFolderIds.filter(id => id !== archivedFolder.id);
    
    return newState;
};

export default onArchiveFolderReducer;