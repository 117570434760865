import API, { graphqlOperation } from '@aws-amplify/api';

import { updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPlate } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";

const onUpdatePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.INIT,
        payload: null,
    };
};

const onUpdatePlateSuccess = (updatedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.SUCCESS,
        payload: updatedPlate,
    };
};

const onUpdatePlateFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on Update Plate');
    console.error('Failure on Update Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.UPDATE.FAIL,
        payload: errorMessage,
    };
};


const onUpdatePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.DONE,
        payload: null,
    };
};

const onUpdatePlateAfterDelete = (plateToBeUpdated, deletedFolder) => {
    return async (dispatch, getState) => {
        dispatch(onUpdatePlateInit());
        try {
            const state = getState();
            plateToBeUpdated.folders = plateToBeUpdated.folders.filter(folder => JSON.parse(folder)['name'] !== deletedFolder.id);
            if (plateToBeUpdated.folders && plateToBeUpdated.folders.length === 0) plateToBeUpdated.folders = null;
            
            delete plateToBeUpdated._deleted;
            delete plateToBeUpdated._lastChangedAt;
            delete plateToBeUpdated.createdAt;
            delete plateToBeUpdated.updatedAt;
            plateToBeUpdated.modifier = state.creator as string;
            plateToBeUpdated.modified_at = new Date().toISOString();

            const variables = {
                input: plateToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const updatedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            dispatch(onUpdatePlateSuccess(updatedPlate));
        }
        catch (graphqlError) {
            const errorMessage = `Update plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdatePlateFail(errorMessage));
        }
        dispatch(onUpdatePlateDone());
    };
};

export default onUpdatePlateAfterDelete;