import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IState } from '../../../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const props = {

    };

    return props;
};

const Actions = {

};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                {
                    (this.props.showOptions) ?
                        (
                            <>
                                <div className='options right-justify'>
                                </div>
                            </>
                        ) :
                        (null)
                }
            </>
        );
    }
}

const DisplayPlatesOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayPlatesOptions;