import {IPlate, IState} from '../../../Interfaces';

const onLoadPlatesDeletedReducer = (state: IState, action) => {

    const deletedPlate = action.payload as IPlate;
    const newState = { ...state } as IState;
    newState.data.plates.loadedPlates = state.data.plates.loadedPlates.filter(a => a.id !== deletedPlate.id);

    return newState;
};

export default onLoadPlatesDeletedReducer;
