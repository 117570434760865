import ACTION_TYPES from '../../../actionTypes';
import { onAddPartsToAddedParts, onClearAddedParts } from '../../Parts';
import { onLoadPartIds } from '../../Home/Load/loadParts';

const onUpdatedNewPlate = (ids, history, isNewPlate) => {
    return async (dispatch, getState) => {
        const state = getState()
        const currentWorkspaces = state?.data?.workspace?.addedParts || []
        console.log(currentWorkspaces, ids, "currentWorkspaces")
        if (isNewPlate) {
            await dispatch(onClearAddedParts());
        }     
        await dispatch(onAddPartsToAddedParts(ids));
        onLoadPartIds()(dispatch, getState);
       
    };
};

export default onUpdatedNewPlate;