import { SortDirection } from "../../../Enums";
import { IState } from "../../../Interfaces";

const onSortPlatesAscReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.plates.sortDirection = SortDirection.ASC;

    return newState;
};

export default onSortPlatesAscReducer;