import { API, graphqlOperation } from 'aws-amplify';
import { createTags } from '../../../../../graphql/mutations';
import ACTION_TYPES from '../../../../actionTypes';
import { getNewTag } from '../../../../Instances';
import { INewTagModalStatus, ITag, IState } from '../../../../Interfaces';
import { onNavigateToHomePage } from '../../../Home';
import { NotificationManager } from "react-notifications";
const onNewTagSuccess = (addedTag) => {
    return {
        type: ACTION_TYPES.HOME.NEW.TAG.SUCCESS,
        payload: addedTag,
    };
};

const onNewTagFail = (errorMessage: string) => {
    NotificationManager.error('Failure on adding new tag');
    console.error('Failure on adding new tag- ', errorMessage);
    return {
        type: ACTION_TYPES.HOME.NEW.TAG.FAIL,
        payload: errorMessage,
    };
};

const onNewTag = (history) => {
    return async (dispatch, getState) => {

        const state = getState() as IState;

        const newTagModalStatus = state.data.modals.newTagModalStatus as INewTagModalStatus;

        const isTagNameEmpty = (!newTagModalStatus.inputText) as boolean;
        const isTagNameExists = state.data.tags.loadedTags.some(p => p.name === newTagModalStatus.inputText) as boolean;

        if (isTagNameEmpty) {
            const errorMessage = 'Tag Name is mandatory.' as string;
            dispatch(onNewTagFail(errorMessage));
            return;
        }
        if (isTagNameExists) {
            const errorMessage = 'Tag Name already exists.' as string;
            dispatch(onNewTagFail(errorMessage));
            return;
        }

        try {
            const state = getState() as IState;
            const newTag = getNewTag(state);
            const variables = {
                input: newTag,
            };
            const result = await API.graphql(graphqlOperation(createTags, variables));
            const addedTag = ((result as any)?.data?.createTags) as ITag;
            dispatch(onNavigateToHomePage(history));
            dispatch(onNewTagSuccess(addedTag));
        } catch (graphqlError) {
            const errorMessage = `Create tag failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onNewTagFail(errorMessage));
        }
    };
};

export default onNewTag;