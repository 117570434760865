import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IState } from '../../../Interfaces';
import { onResetSearch } from '../../Search';

const onNavigateToBuildReviewPageInit = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_REVIEW.INIT,
        payload: null,
    };
};

const onNavigateToBuildReviewPageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_REVIEW.DONE,
        payload: null,
    };
};

const onNavigateToBuildReviewPage = (buildId, history) => {
    return async (dispatch, getState) => {
        dispatch(onNavigateToBuildReviewPageInit());
        history.push({
            pathname: `/dashboard/BuildReview/${buildId}`,
            state: {
                selectedBuild: null,
                selectedPart: []
            }
        });
        dispatch(onNavigateToBuildReviewPageDone());
        dispatch(onResetSearch())
    };
};

export default onNavigateToBuildReviewPage;