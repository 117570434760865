import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onAddPart, onAddPartToAddedParts, onClearAddedParts, onSelectPart, onUnselectPart } from '../../../Actions/Parts';
import { IConfiguration, IState } from '../../../Interfaces';
import DisplayPartOptions from './DisplayPartOptions';
import { onNavigateToPartReviewPage, onNavigateToReviewPage } from "../../../Actions/Home";
import { getDefaultPrintConfiguration, getDefaultMillConfiguration, getDefaultMaterialConfiguration, getDefaultRecipeConfiguration, getDefaultPartConfiguration, getDefaultPartSlicerConfiguration } from '../../../DefaultConfigurations';
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {
    const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;
    const props = {
        selectedPartIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]] as string[],
        ids: {
            partIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]].filter(Boolean) as string[],
            partConfigurationIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]].filter(Boolean).map(id => getDefaultPartConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            partSlicerConfigurationIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]].filter(Boolean).map(id => getDefaultPartSlicerConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
        },
    };

    return props;
};

const Actions = {
    onSelectPart: onSelectPart,
    onUnselectPart: onUnselectPart,
    onNavigateToPartReviewPage: onNavigateToPartReviewPage,
    onClearAddedParts: onClearAddedParts,
    onNavigateToReviewPage: onNavigateToReviewPage,
    onAddPart: onAddPart,
    onAddPartToAddedParts: onAddPartToAddedParts,
};

class Component extends React.Component<any, any> {

    createdDate = this.props.displayPart.createdDate;
    formatedDate = navigator.userAgent.indexOf('Mac OS X') != -1 ? this.createdDate.substring(3, 5) + "/" + this.createdDate.slice(0, 3) + this.createdDate.substring(5, this.createdDate.length) : this.props.displayPart.createdDate

    public render() {
        return (
            <>
                <div
                    className={`display-card is-selected-${this.props.displayPart.isSelected} position-relative`}
                    id={`part-${this.props.displayPart.index}`}
                    key={`${this.props.displayPart.index}`}
                    onClick={() =>
                        !this.props.displayPart.isSelected
                            ? this.props.onSelectPart(this.props.displayPart.id)
                            : null
                    }
                    onDoubleClick={() => {
                            // this.props.onSelectPart(this.props.displayPart.id);
                            this.props.onNavigateToPartReviewPage(this.props.ids, this.props.history, true);
                        }
                    }
                >
                    <div className='display-card-select-action pt-2 pl-2 position-absolute select-part'>
                        <div className='options right-justify'>
                            <>
                                {
                                    this.props.displayPart.isSelected ?
                                        (
                                            <>
                                                <div className='check option'>
                                                    <img
                                                        className='icon check'
                                                        alt=''
                                                        style={{ margin: '0px', padding: '4px' }}
                                                        onClick={() =>
                                                            this.props.onUnselectPart(
                                                                this.props.displayPart.id,
                                                                this.props.onClearAddedParts()
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            <div className='check option'>
                                                <img className='icon uncheck' style={{ margin: '0px', padding: '4px' }} alt=''></img>
                                            </div>
                                        )
                                }
                            </>
                        </div>
                    </div>
                    <div>
                        <div className='thumbnail-preview'>
                            {
                                this.props.displayPart.icon ?
                                    <img className='part-image' src={this.props.displayPart.icon} />
                                    : <img src="/part-thumbnail.svg" alt="thumbnail image" className='default-thumb-image' />
                            }
                        </div>
                        {/* <img src='/img/parts-image.png' className='part-image' alt="PartImage" /> */}
                        <div className='display-card-row'>

                            <div className='display-card-header'>
                                <label title={this.props.displayPart.name}>{this.props.displayPart.name}</label>
                            </div>
                        </div>
                        <div className='display-card-row'>

                            <div className='display-card-sub-header'>
                                <div className='details'>
                                    <label>Imported Date:</label>
                                    <span>{this.props.displayPart.createdDate ? moment(new Date(this.formatedDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span>
                                </div>
                                <div className='details'>
                                    <label>Created By:</label>
                                    <span>{this.props.displayPart.creator}</span>
                                </div>
                                {/* <div className='details'>
                                    <label>File Size:</label>
                                    <span>34MB</span>
                                </div>
                                <div className='details'>
                                    <label>Dimensions:</label>
                                    <span>50mm x 50mm x 16mm</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const DisplayReviewPartCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayReviewPartCardView;
