import { IState, ITag } from '../../../Interfaces';

const onLoadTagsInProgressReducer = (state: IState, action) => {
    
    const newlyLoadedTags = action.payload as ITag[];
    const newState = { ...state } as IState;
    newState.data.tags.loadedTags.splice(newState.data.tags.loadedTags.length, 0, ...newlyLoadedTags);
    newState.data.tags.isLoadingInProgress = true;
    
    return newState;
};

export default onLoadTagsInProgressReducer;