import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onSelectPlateConfiguration } from '../Actions/Plates';
import { IState, IConfiguration } from '../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const selectedConfiguration = (ownProps?.selectedConfiguration || ownProps?.loadedConfigurations.find(lc => state.data.workspace.selectedConfigurations.some(sc => sc?.id?.toUpperCase() === lc?.id?.toUpperCase()))) as IConfiguration;
    const props = {
        isLoadingComplete: state.data.configurations.isLoadingComplete as boolean,
        loadedConfigurations: ownProps?.loadedConfigurations || [] as IConfiguration[],
        selectedConfigurationName: selectedConfiguration ? selectedConfiguration.name : 'None' as string,
    };

    return props;
};

const Actions = {
    onSelectPlateConfiguration: onSelectPlateConfiguration,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                <div className='options'>
                    <div className='plate-configuration option ml-0'>
                        <label>{this.props.label}</label>
                        <DropdownButton id='dropdown-basic-button'
                            disabled={!this.props.isLoadingComplete}
                            title={this.props.selectedConfigurationName}>
                            {
                                this.props.loadedConfigurations.map(lc => {
                                    return (
                                        <React.Fragment key={lc.id}>
                                            <Dropdown.Item
                                                onClick={() => this.props.onSelectPlateConfiguration(lc.id)}>
                                                {lc.id}
                                            </Dropdown.Item>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </DropdownButton>
                    </div>
                </div>
            </>
        );
    }
}

const PlateConfiguration = withRouter(connect(Props, Actions)(Component));

export default PlateConfiguration;
