import { IState } from "../../../Interfaces";

const onDownloadSuccessReducer = (state: IState, action) => {
    const dataSize = action.payload as any;

    const newState = { ...state } as IState;
    newState.downloadInProgressReceivedLength = state.downloadInProgressReceivedLength - dataSize.receivedLength;
    newState.downloadInProgressContentLength = state.downloadInProgressContentLength - dataSize.contentLength;

    return newState;
};

export default onDownloadSuccessReducer;