import { IConfiguration, IState } from '../../../Interfaces';

const onHighlightConfigurationReducer = (state: IState, action) => {
    
    const configurationId = action.payload as string;
    const configuration = state.data.configurations.loadedConfigurations.find(lc => lc.id === configurationId) as IConfiguration;
    
    const newState = { ...state } as IState;
    newState.data.configurations.highlightedConfigurationId = configurationId;
    newState.data.configurations.highlightedConfigurationData = configuration['data'];

    return newState;
};

export default onHighlightConfigurationReducer;