import { IBuild, IState } from '../../Interfaces';

const onResetSearchResultsReducer = (state: IState, action) => {
    const newState = { ...state } as IState;
    newState.data.search.searchString =""
    newState.data.search.searchPartsResults = []
    newState.data.search.searchPlatesResults = []
    newState.data.search.searchBuildsResults = []
    newState.data.search.partsSearchCompleted = false as boolean
    newState.data.search.platesSearchCompleted = false as boolean
    newState.data.search. buildsSearchCompleted = false as boolean
    newState.data.search.highlightedBuildData = {} as IBuild
    newState.data.search.highlightBuildStatus="" as string
   
    return newState;
};

export default onResetSearchResultsReducer;