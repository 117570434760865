import ACTION_TYPES from '../../../actionTypes';
import { onAddPartsToAddedParts, onAddPartToAddedParts, onClearAddedParts } from '../../Parts';
import { IBuild, IState } from '../../../Interfaces';

const onNavigateToBuildPreviewPageInit = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_PREVIEW.INIT,
        payload: null,
    };
};

const onNavigateToBuildPreviewPageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_PREVIEW.DONE,
        payload: null,
    };
};

/*  This Code will redirect to Build Review Screen */
const onNavigateToBuildPreviewPage = (buildId, history) => {
    return async (dispatch, getState) => {
        dispatch(onNavigateToBuildPreviewPageInit());
        history.push({
            pathname: `/dashboard/BuildReview/${buildId}`,
            state: {
                selectedBuild: null,
                selectedPart: []
            }
        });
        dispatch(onNavigateToBuildPreviewPageDone());
    };
}; 

export default onNavigateToBuildPreviewPage;