import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import UploadDocument from '../UploadDocument';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { helpByDocumentType } from '../../../graphql/queries';
import { S3 } from 'aws-sdk';
import awsmobile from "../../../aws-exports";
import moment from 'moment';
import { DataGrid } from "@material-ui/data-grid";
import { Typography } from '@material-ui/core';
import LoadingComponent from '../../../LoadingComponent/LoadingComponent';


const DocumentsDataTableMethod = (props) => {
  const { activeTab, setActiveTab, TabLists } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [showVideoDocumentModal, setshowVideoDocumentModal] = useState(false);
  const [documentsData, setDocumentsData] = useState([]);
  const [documentsDataRow, setDocumentsDataRow] = useState([]);
  const [searchedText, setSearchedText] = useState('')
  const [videoURL, setVideoURL] = useState('')
  const columns = [
    {
      field: 'helpDocumentName',
      headerName: 'Release Name',
      sortable: true,
      //flex: 1,
      width: 500,
      renderCell: (params) => {
        console.log(params, 'params');

        return (
          <Typography variant="subtitle1" title={params?.row?.helpDocumentName} onClick={() => viewDocs(params?.row?.helpDocumentTypeLink, params?.row?.id)}>
            {params?.row?.helpDocumentName}
          </Typography>
        )
      }
    },
    {
      field: 'created_at',
      headerName: 'Uploaded Date',
      width: 300,
      sortable: true,
      //flex: 1,
      renderCell: (params) => (
        <Typography className='table-date-format'>
          {params?.row?.created_at ? moment(params?.row?.created_at).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}
        </Typography>
      )
    },
    {
      field: 'created_by',
      headerName: 'Uploaded By',
      // flex: 1,
      width: 300,
      sortable: true,
    },
    {
      field: 'helpDocumentComment',
      headerName: 'Comments',
      sortable: true,
      flex: 1,
    },
  ];

  const fetchDocumentsData = async () => {
    try {
      setLoading(true)
      let variables = {
        helpDocumentType: activeTab?.value,
        sortDirection: "DESC", 
        limit: 10000,
      }
      const result = await API.graphql(graphqlOperation(helpByDocumentType, variables));
      let docData = ((result as any).data.helpByDocumentType.items)
      console.log("Docs" + JSON.stringify(docData, null, 4));
      setDocumentsData(docData)
      setDocumentsDataRow(docData)
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.error('Error fetching documents:', error);
    }

  };

  useEffect(() => {
    let isMounted = true;
    fetchDocumentsData();
    return () => {
      isMounted = false;
    }
  }, [activeTab]);


  const s3BucketName = awsmobile.aws_user_files_s3_bucket;

  const viewDocs = async (key, documentId) => {
    console.log("View Docs: Key - " + key + " Document ID - " + documentId);

    if (!key) {
      console.error('Key is missing or empty.');
      return;
    }

    try {
      setLoading(true)
      const s3 = new S3({
        credentials: await Auth.currentCredentials(),
        region: awsmobile.aws_user_files_s3_bucket_region,
      });

      if (typeof key === 'string') {
        const filename = key.split(':"');
        const pdfFileName = filename[1].slice(0, -2);
        const response = await s3.getObject({
          Bucket: s3BucketName,
          Key: 'public/' + pdfFileName,
        }).promise();

        const matchingDocument = documentsData
          .filter((doc: any) => doc.id === documentId)
          .map((matchingDoc: any) => matchingDoc.helpDocumentName);
        const fileType = matchingDocument.toString().split('.').pop()?.toLowerCase()

        if (fileType === "pdf") {
          if (response.Body) {
            console.log('Response Body' + response.Body)
            const blob = new Blob([response.Body as BlobPart], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          }
        }
        else {
          const blob = new Blob([response.Body as BlobPart], { type: 'application/media' });
          const url = window.URL.createObjectURL(blob);
          setVideoURL(url)
          onOpenVideoDialog()
        }
      } else {
        console.log("Value associated with 'data' key is not a string.");
      }
      setLoading(false)
    } catch (error) {
      console.error('Error downloading the file:', error);
      setLoading(false)
    }
  };


  const handleChangeHandler = (e) => {
    console.log(e?.target.value, "e");
    setSearchedText(e?.target.value)
    const filteredVal = documentsDataRow.filter((item: any) => item?.helpDocumentName?.toLowerCase()?.includes(e?.target.value?.toLowerCase()))
    setDocumentsData(filteredVal)
  }
  const onCloseVideoDialog = () => { setshowVideoDocumentModal(false); setVideoURL('') }
  const onOpenVideoDialog = () => { setshowVideoDocumentModal(true); }

  const mutateList = () => {
    fetchDocumentsData();
  }
  return (
    <>
      <section>
        <LoadingComponent visible={loading} />
        <div className='dataGrid-wrapper-filter'>
          <div className="search option w-75">

            <input
              className="form-control help-search-box"
              type="text"
              placeholder="Search By Document Name"
              value={searchedText}
              onChange={(e) => handleChangeHandler(e)}

            />
          </div>
          <Button
            variant="secondary"
            disabled={props.isLoadingInProgress}
            onClick={() => setShowUploadDocumentModal(true)}
            className='btn btn-secondary upload-btn'>
            <span className="pr-2"> + </span> Add/Update Document
          </Button>
        </div>
        <div className="help-page">
          <div className='table-wrapper-docs'>

            <DataGrid
              rows={documentsData}
              columns={columns}
              pageSize={10}
              autoHeight
              disableSelectionOnClick
              disableColumnMenu
            />
          </div>
        </div>
      </section>


      <UploadDocument
        showUploadDocumentModal={showUploadDocumentModal}
        setShowUploadDocumentModal={setShowUploadDocumentModal}
        mutateList={mutateList}
        activeTab={activeTab}
        documentsData={documentsData}
      />
      {
        showVideoDocumentModal &&
        (
          <>
            <Modal
              size="lg"
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              show={showVideoDocumentModal}
              onHide={() => onCloseVideoDialog()}
              backdrop='static'
              keyboard={false}
              centered>
              <Modal.Header>
                <Modal.Title></Modal.Title>
                <span className="close-btn"
                  onClick={() =>
                    onCloseVideoDialog()
                  }>
                </span>
              </Modal.Header>
              <Modal.Body>
                <div className='each-dialog-content'>
                  {videoURL !== "" && (
                    <div>
                      <video controls={true} autoPlay width="100%" >
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>

                    </div>

                  )}
                </div>
              </Modal.Body>

            </Modal>
          </>
        )
      }
    </>
  );
};

const DocumentsDataTable = withRouter(DocumentsDataTableMethod);

export default DocumentsDataTable;
