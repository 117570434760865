import { IState, } from '../../../Interfaces';

const onAddConfigurationFileChangeReducer = (state: IState, action) => {
    const configurationType = action.payload as any;

    const newState = { ...state } as IState;
    newState.data.modals.addConfigurationModalStatus = {
        ...newState.data.modals.addConfigurationModalStatus,
        configurationType: configurationType,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onAddConfigurationFileChangeReducer;