import onLoadCurrentUserInfo from './loadCurrentUserInfo';
import onLoadFolders from './loadFolders';
import onLoadTags from './loadTags';
import onLoadParts from './loadParts';
import onLoadPlates from './loadPlates';
import onLoadBuilds from './loadBuilds';
import onLoadConfigurations from './loadConfigurations';

const onLoad = () => {
    return async (dispatch, getState) => {
        await onLoadCurrentUserInfo()(dispatch, getState);
        await Promise.allSettled([onLoadFolders()(dispatch, getState), onLoadTags()(dispatch, getState), 
                                  onLoadParts(true)(dispatch, getState), onLoadPlates(true)(dispatch, getState),
                                  onLoadBuilds(true)(dispatch, getState), onLoadConfigurations()(dispatch, getState)]);
    };
};

export default onLoad;
