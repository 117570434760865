import { IFolder, IState } from '../../../Interfaces';

const onRenameFolderOpenDialogReducer = (state: IState, action) => {
    const folderIdToBeRenamed = action.payload as string;
    const folderTobeRenamed = state.data.folders.loadedFolders.find(p => p.id === folderIdToBeRenamed) as IFolder;
    const folderNameToBeRenamed = folderTobeRenamed.name as string;

    const newState = { ...state } as IState;
    newState.data.modals.renameFolderModalStatus = {
        ...newState.data.modals.renameFolderModalStatus,
        inputText: folderNameToBeRenamed,
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    return newState;
};

export default onRenameFolderOpenDialogReducer;