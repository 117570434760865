import {BufferGeometry, EllipseCurve, FileLoader, Group, LineSegments, Loader, Vector2, Vector3} from "three";
import { materials } from "./MaterialColors";
import { NotificationManager } from "react-notifications";

const GCodeLoader = function (manager) {
  this.splitLayer = true;
};

function getMaterialKeyName(material) {
  return Object.keys(materials)[
    Object.values(materials).findIndex((mat) => mat === material)
  ];
}

GCodeLoader.prototype = Object.assign(Object.create(Loader.prototype), {
  constructor: GCodeLoader,

  load: function (url, onLoad, onProgress, onError) {
    const loader = new FileLoader(this.manager);
    loader.setPath(this.path);
    loader.load(url, (text) => onLoad(this.parse(text)), onProgress, onError);
  },

  parse: async function (data, splitFastWork) {
    let result = { name: "gcode", children: [] };

    let state = {
      x: 0,
      y: 0,
      z: undefined,
      e: 0,
      f: 0,
      relative: false,
      currentG: "",
      plane: "xy",
      currentMaterial: materials.NON_FUNCTIONAL,
    };
    let stack = [].concat(result);

    let unknownCommands = [];
    const utf8Decoder = new TextDecoder("utf-8");

    let text = (typeof (data) === "string")? data : utf8Decoder.decode(data.Body)


    let currentTail = ""
    let index = 0;
    try {
      let lines = text.split('\n')
      if (text && lines.length) {
        let lastLineFull = text[text.length - 1] === '\n'
        parseLine(currentTail + lines[0], index);
        index++;
        const lastLineIndex = lastLineFull ? lines.length : lines.length - 1
        for (let ind = 1; ind < lastLineIndex; ind++) {
          parseLine(lines[ind], index);
          index++;
        }
      }
    }
    catch (e){
      console.log(e)
    }

    function parseLine(line, index) {
      if (line[0] === "(") {
        parseComment(line, index);
      } else {
        parseCommand(line, index);
      }
    }

    function createTag(tag, index, currentElement) {
      let newTag = {
        name: tag,
        children: [],
        firstLine: index,
        lastLine: undefined,
        parent: currentElement,
        vertices: [],
      };
      for (let materialName in materials) {
        if (tag === materialName) {
          state.currentMaterial = materials[materialName];
        }
      }
      if (tag.indexOf("CUT_slab") >= 0){
        let cutIndex = tag.split("_")[2][0]
        state.currentMaterial = materials["MILL_STEPS"+cutIndex]
      }
      currentElement.children.push(newTag);
      stack.push(newTag);
    }

    function closeTag(currentElement, index) {
      if (Object.keys(currentElement.vertices).length > 0) {
        currentElement.object = new Group();
        Object.entries(currentElement.vertices).forEach((entry) => {
          let materialName = entry[0];
          let vertices = entry[1];
          currentElement.object.add(
              new LineSegments(
                  new BufferGeometry().setFromPoints(vertices, 3),
                  materials[materialName]
              )
          );
        });

        currentElement.object.visible = true;
        delete currentElement.vertices;
      }
      if (
          currentElement.children.length === 0 &&
          currentElement.name !== "CUT_SLAB"
      ) {
        delete currentElement.children;
        if (currentElement.object === undefined) {
          // delete empty element
          currentElement.parent.children.pop();
        }
      }
      currentElement.lastLine = index;
      stack.pop();
    }

    function parseComment(line, index) {
      if (line[1] === "<" && line[2] !== "-") {
        let currentElement = stack[stack.length - 1];
        let tag = line.substring(2, line.lastIndexOf(">"));
        if (tag.includes(">")) {
          // double tags present only in comments
          return;
        }
        if (! (tag.includes("PERIMETER") || tag.includes("INFILL")|| tag.includes("_MILL")  || tag.includes("CALIBRATE") || tag.includes("CHIPFAN") || tag.includes("DISPENSE PREP") || tag.includes("TIPCLEAN") || tag.includes("SNAP_IMAGE") || tag.includes("SOAK") || tag.includes("LAMPBLOWDRY") || tag.includes("MEASURE_TEMPERATURE") || tag.includes("_data.stl") || tag.includes("BEFORE_FACING") || tag.includes("DO_FACING") || tag.includes("_FACING")) )
        {
          if (tag[0] !== "/") {
            createTag(tag, index, currentElement);
          } else {
            if (state.currentG !== ""){
              closeTag(currentElement);
              currentElement = stack[stack.length - 1];
              state.currentG = ""
            }
            closeTag(currentElement, index);
          }
        }
      } else {
        //comment
      }
    }

    function parseCommand(lineString, index) {
      lineString = lineString.replace("\t", " ")
      let tokens = lineString.split(" ");

      let cmd = tokens[0].toUpperCase();

      //Argumments
      let args = {};
      tokens.splice(1).forEach(function (token) {
        if (token[0] !== undefined) {
          const key = token[0].toLowerCase();
          args[key] = parseFloat(token.substring(1));
        }
      });

      function processCode(code) {
        if (state.currentG !== code) {
          let currentElement = stack[stack.length - 1]
          if (state.currentG) {
            closeTag(currentElement)
            currentElement = stack[stack.length - 1];
          }
          createTag(code, index, currentElement)
          state.currentG = code
        }
      }

      let currentStepName = stack[stack.length - 1].name
      if (splitFastWork && (currentStepName.indexOf("MILL") !== - 1 || ['FAST', 'WORK'].includes(currentStepName))) {
        if (cmd === "G0") {
          processCode('FAST');
        }

        if (["G1", "G2", "G3", "G9"].includes(cmd)) {
          processCode('WORK');
        }
      }

      if (cmd === "G17"){
        state.plane = "xy"
      }
      else if (cmd === "G18"){
        state.plane = "xz"
      }
      else if (cmd === "G19"){
        state.plane = "yz"
      }
      //Process commands
      //G0/G1 – Linear Movement
      else if (cmd === "G0" || cmd === "G1" || cmd === "G9") {
        const line = {...state};
        line.x = args.x !== undefined ? absolute(state.x, args.x) : state.x;
        line.y = args.y !== undefined ? absolute(state.y, args.y) : state.y;
        line.z = args.z !== undefined ? absolute(state.z, args.z) : state.z;
        line.e = args.e !== undefined ? absolute(state.e, args.e) : state.e;
        if (cmd === "G0") {
          if (line.z === undefined) {
            line.z = state.z = 150;
          }
          else if (state.z === undefined) {
            state.z = line.z + 5;
          }
          if (state.z != line.z) {
            const state0 = { ...state };
            if (state.z > line.z) {
              state.x = line.x;
              state.y = line.y;
            }
            else {
              state.z = line.z;
            }
            addSegment(state0, state, cmd === "G0" ? materials.JOG_MOVES : null);
          }
        }
        addSegment(state, line, cmd === "G0" ? materials.JOG_MOVES : null);
        state = line;
      }
      else if ( cmd === 'G2' || cmd === 'G3' ) {
        const clockWise =  (cmd === 'G2')
        const line = { ...state };
        line.x = args.x !== undefined ? absolute(state.x, args.x) : state.x;
        line.y = args.y !== undefined ? absolute(state.y, args.y) : state.y;
        line.z = args.z !== undefined ? absolute(state.z, args.z) : state.z;
        line.e = args.e !== undefined ? absolute(state.e, args.e) : state.e;
        let startX = state.x;
        let startY = state.y;
        let startZ = state.z;
        let endX = line.x;
        let endY = line.y;
        let endZ = line.z;
        if (state.plane === "xz") {
          [startY, startZ] = [startZ, startY];
          [endY, endZ] = [endZ, endY];
        }
        if (state.plane === "yz") {
          [startX, startY, startZ] = [startY, startZ, startX];
          [endX, endY, endZ] = [endY, endZ, endX];
        }
        // G2/G3 - Arc Movement ( G2 clock wise and G3 counter clock wise )
        let offsetX
        let offsetY
        let radius
        if (args.r){
          radius = Math.abs(args.r)
          const radiusSq = radius*radius
          if (endY === startY){
            offsetX = (endX - startX) / 2
            offsetY = 0
          }
          else if (endX === startX){
            offsetY = (endY - startY) / 2
            offsetX = 0
          }
          else{
            // let secCenterX = (startX + endX)/2
            // let secCenterY = (startY + endY)/2
            const diffX = endX - startX
            const diffY = endY - startY
            const halfSectorSq = Math.abs((diffX*diffX + diffY*diffY))/4
            const l = Math.sign(args.r)*Math.sqrt(radiusSq - halfSectorSq)
            const angle = -Math.atan2(diffY, diffX)
            offsetX = (endX - startX)/2 + l*Math.cos(angle)
            offsetY = (endY - startY)/2 + l*Math.sin(angle)
          }
        }
        else {
          offsetX = args.i
          offsetY = args.j
          radius = Math.sqrt(offsetX * offsetX + offsetY * offsetY)
        }
        const centerX = startX + offsetX;
        const centerY = startY + offsetY;
        const aStartAngle = Math.atan2(startY - centerY, startX - centerX);
        let aEndAngle = Math.atan2(endY - centerY, endX - centerX);
        if (clockWise && aEndAngle >= aStartAngle) {
          aEndAngle = aEndAngle - 2 * Math.PI;
        }
        else if (!clockWise && aEndAngle <= aStartAngle) {
          aEndAngle = aEndAngle + 2 * Math.PI;
        }
        const angleChange = aEndAngle - aStartAngle;

        // 5 points per mm (for small circle) or one per 10 degrees (for large circle)
        const midPoints = Math.round(Math.abs(angleChange * Math.min(radius * 5, 180 / Math.PI / 10)));
        const deltaAngle = angleChange / (midPoints + 1);
        const deltaZ = (endZ - startZ) / (midPoints + 1);
        for (let i = 0, angle = aStartAngle, ptZ = startZ; i < midPoints; i++) {
          angle = angle + deltaAngle;
          ptZ = ptZ + deltaZ;
          const ptX = centerX + radius * Math.cos(angle);
          const ptY = centerY + radius * Math.sin(angle);
          const pt = { ...line };
          if (state.plane === "xz") {
            pt.x = ptX;
            pt.z = ptY;
            pt.y = ptZ;
          }
          else if (state.plane === "yz") {
            pt.y = ptX;
            pt.z = ptY;
            pt.x = ptZ;
          }
          else {
            pt.x = ptX;
            pt.y = ptY;
            pt.z = ptZ;
          }
          addSegment(state, pt);
          state = pt;
        }
        addSegment(state, line);
        state = line;
      }
      else if (cmd === "G90") {
        //G90: Set to Absolute Positioning
        state.relative = false;
      } else if (cmd === "G91") {
        //G91: Set to state.relative Positioning
        state.relative = true;
      } else if (cmd === "G92") {
        //G92: Set Position
        state.x = args.x !== undefined ? args.x : state.x;
        state.y = args.y !== undefined ? args.y : state.y;
        state.z = args.z !== undefined ? args.z : state.z;
        state.e = args.e !== undefined ? args.e : state.e;
      } else {
        if (cmd) {
          if (!(cmd in unknownCommands))
            unknownCommands.push(cmd);
        }
      }
    }
    //Create lie segment between p1 and p2
    function addSegment(p1, p2, material = null) {
      if (material === null) {
        if (p1.x === p2.x && p1.y === p2.y && Math.abs(p1.z - p2.z) > 0.5) {
          material = materials.NON_FUNCTIONAL;
        } else {
          material = state.currentMaterial;
        }
      }

      let currentElement = stack[stack.length - 1];
      let materialName = getMaterialKeyName(material);

      let vec1 = new Vector3(p1.x, p1.y, p1.z);
      let vec2 = new Vector3(p2.x, p2.y, p2.z);

      if (currentElement.vertices[materialName] === undefined) {
        currentElement.vertices[materialName] = [vec1, vec2];
      } else {
        currentElement.vertices[materialName].push(vec1, vec2);
      }
    }

    function absolute(v1, v2) {
      return state.relative ? v1 + v2 : v2;
    }

    // if (Object.keys(unknownCommands).length > 0) {
      // console.warn(
      //   `GCodeLoader: Commands are not supported (in the next warning): ${unknownCommands}`
      // );
    // }

    return result;
  },
});

export { GCodeLoader, materials };
