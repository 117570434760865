import { IState } from '../../../Interfaces';

const onMovePartOpenDialogReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.modals.movePartModalStatus = {
        ...newState.data.modals.movePartModalStatus,
        inputText: '',
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    newState.data.folders.highlightedFolderId = '';

    return newState;
};

export default onMovePartOpenDialogReducer;