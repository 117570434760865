import "bootstrap/dist/css/bootstrap.min.css";
import "rc-slider/assets/index.css";

import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import WorkSpace from "./WorkSpace/WorkSpace";
import reducer from "./reducer";
import { withAuthenticator, SignOut } from "aws-amplify-react";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import dataStorage from "./S3DataStore/S3DataStore";
import Catalog from "./Catalog/Catalog";
import CatalogFolder from "./Catalog/CatalogFolder";
import BetaApp from "./Beta/index";
import BundleBuildLog from "./BundleBuildLog";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import BundlesList from "./BundlesList";
import Distributions from "./Distributions/Distributions";
import { AuthState } from "@aws-amplify/ui-components/dist/types/common/types/auth-types";
import { MVPBuildReview } from "./MVPBuildReview/MVPBuildReview";
import "@aws-amplify/ui/dist/style.css";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const App = (signOut, user) => {
  const initialState = {};

  const [state, dispatch] = React.useReducer(reducer, initialState);
  dataStorage().dispatchFunc = dispatch;
  // Auth.currentCredentials().then( credentials =>{
  //   AWS.config.credentials=credentials
  // })

  return (
    <>
      <NotificationContainer />
      <Router>
        <div>
          <Switch>
            <Route
              path="/item/:buildId"
              render={(props) => (
                <WorkSpace {...props} dataStore={state.dataStore} />
              )}
            />

            <Route
              path={"/build/:id/bundle/:bundleId"}
              component={MVPBuildReview}
            />

            <Route
              path={"buildlog/:logStreamName"}
              component={BundleBuildLog}
            />
            <Route path={"/bundlesQueue"} component={BundlesList}></Route>
            <Route path={"/distributions"} component={Distributions}></Route>
            <Route path="/dashboard">
              <BetaApp signOut={signOut} user={user} />
            </Route>
            <Route path="/builds">
              <CatalogFolder />
            </Route>

            <Route path="/buildFolderss">
              <Catalog
                /*onNewObjectLink="/printers"*/ onSelectObjectLink="/item"
                dataType={"BuildFolders"}
                elementsPerPage={20}
                onClick={() => {
                  // dispatch({
                  //     type: "setBuild",
                  //     cargo: {
                  //         build: build
                  //     }
                  // })
                }}
                initFunc={(buildFolder: any) => {
                  buildFolder.builds = [];
                }}
                schemaName={"BuildFolders"}
              />
            </Route>

            <Route path="/">
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
};

export default withAuthenticator(App);
