import {IBuild, IState} from '../../../Interfaces';
import getDisplayBuild from "../../../Mappers/mapBuildToDisplayBuild";

const onLoadBuildsDeletedReducer = (state: IState, action) => {

    const deletedBuild = action.payload as IBuild;
    const newState = { ...state } as IState;
    newState.data.builds.loadedBuilds = state.data.builds.loadedBuilds.filter(a => a.id !== deletedBuild.id);

    return newState;
};

export default onLoadBuildsDeletedReducer;
