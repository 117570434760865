import { IFolder, IState } from "../Interfaces";

const getNewFolder = (state: IState) => {

    const newFolder = ({
        id: state.data.modals.newFolderModalStatus.inputText,
        name: state.data.modals.newFolderModalStatus.inputText,
        hasParts: false,
        noOfParts: 0,
        hasPlates: false,
        noOfPlates: 0,
        hasBuilds: false,
        noOfBuilds: 0,
        creator: state.creator,
        created_at: new Date().toISOString(),
        modifier: state.creator,
        modified_at: new Date().toISOString(),
        dumb: 1,
    } as unknown as IFolder);

    return newFolder;
};

export default getNewFolder;