import { IState } from '../../../Interfaces';

const onHighlightPartReducer = (state: IState, action) => {
    const partId = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.parts.highlightedPartId = partId;
    
    return newState;
};

export default onHighlightPartReducer;