/* section: import - start */
import React, { useEffect, useState, FunctionComponent } from "react";
import "./MVPBPConfiguration.scss";
import "../../css/customJsonEditor.scss";
import API, { graphqlOperation } from "@aws-amplify/api";
import "jsondiffpatch/dist/formatters-styles/html.css";
import MVPJSONEditor from "../../MVPJSONEditor/MVPJSONEditor";
import { listBuilds, buildByCreatedAt, buildByInitiated, configurationByCreatedAt } from "../../graphql/queries";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
/* section: import - end */

const MVPBPConfiguration: FunctionComponent<any> = (props) => {
	/* useState - start */
	// const [show, setShow] = useState(props.show);
	const [selectedConfig, setSelectedConfig] = useState(props.config);
	const [viewJsonVal, setViewJsonVal] = useState(props.config || {});
	/* useState - end */

	/* functions - start */
	/* functions - end */

	return (
		<div className='MVPBPConfiguration' style={{ display: `${props.show ? 'block' : 'none'}` }}>
			<div className='bp-l'>
				<div className='bp-l-h'>
					<label title='Configuration'>Configuration</label>
				</div>
			</div>
			<div className='bp-r'>
				<div className="bp-r-h">
					<label>Config Name : {(selectedConfig || '') && selectedConfig['name']}</label>
				</div>
				<section className='bp-r-b bp-r-b-only block'>
					<Modal
						show={props.show}
						onHide={props.onCancel}
						backdrop="static"
						keyboard={false}
						dialogClassName="printDialog"
						aria-labelledby="contained-modal-title-vcenter"
						centered>
						<Modal.Header closeButton>
							<Modal.Title>Configuration</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="each-dialog-content">
								<MVPJSONEditor onChange={data => setViewJsonVal(data)} value={viewJsonVal} />
							</div>
						</Modal.Body>
						<Modal.Footer className="justify-content-center">
							<Button
								className='btn-primary'
								autoFocus
								onClick={() => props.onSave(viewJsonVal, props.configType)} >
								Save
							</Button>
						</Modal.Footer>
					</Modal>
				</section>
			</div>
		</div>
	);
};

export default MVPBPConfiguration;
