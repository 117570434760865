import { IState } from '../../../Interfaces';

const onCopyPlateNameChangeReducer = (state: IState, action) => {
    const plateNameToBeCopied = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.copyPlateModalStatus = {
        ...newState.data.modals.copyPlateModalStatus,
        inputText: plateNameToBeCopied,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onCopyPlateNameChangeReducer;