import { IState, IPart } from "../../../Interfaces";

const onArchivePartReducer = (state: IState, action) => {
    const archivedPart = action.payload as IPart;
    const indexToBeReplaced = state.data.parts.loadedParts.findIndex(lp => lp.id === archivedPart.id);
    const isPartExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPartExists) newState.data.parts.loadedParts.splice(indexToBeReplaced, 1, archivedPart);

    newState.data.parts.selectedPartIds = newState.data.parts.selectedPartIds.filter(id => id !== archivedPart.id);
    
    return newState;
};

export default onArchivePartReducer;