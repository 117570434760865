import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { onRenameConfigurationCloseDialog, onRenameConfigurationNameChange, onRenameConfiguration } from '../Actions/Configurations';
import { IState, IRenameConfigurationModalStatus } from '../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const renameConfigurationModalStatus = state.data.modals.renameConfigurationModalStatus as IRenameConfigurationModalStatus;

    const props = {
        isModalOpen: renameConfigurationModalStatus.isOpen,
        inputText: renameConfigurationModalStatus.inputText,
        hasError: renameConfigurationModalStatus.hasError,
        errorMessage: renameConfigurationModalStatus.errorMessage,
        isValid: renameConfigurationModalStatus.isValid,
    };

    return props;
};

const Actions = {
    onRenameConfigurationCloseDialog: onRenameConfigurationCloseDialog,
    onRenameConfigurationNameChange: onRenameConfigurationNameChange,
    onRenameConfiguration: onRenameConfiguration,
};

class Component extends React.Component<any, any> {

    private onRenameConfigurationNameChange(event) {
        const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join('');
        this.props.onRenameConfigurationNameChange(value);
    }

    public render() {
        return (
            <>
                {
                    (this.props.isModalOpen) ?
                        (
                            <>
                                <Modal
                                    show={this.props.isModalOpen}
                                    onHide={() => this.props.onRenameConfigurationCloseDialog()}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='configurationsDialog'
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header>
                                        <Modal.Title>Rename</Modal.Title>
                                        <span className="close-btn"
                                            onClick={() =>
                                            this.props.onRenameConfigurationCloseDialog()
                                            }>
                                        </span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='each-dialog-content'>
                                            <div className='each-d-section'>
                                                Enter the name for the new configuration
                                            </div>
                                            <div className='each-d-section'>
                                                <input className='f-w'
                                                    type='text'
                                                    value={this.props.inputText}
                                                    onChange={(event) => this.onRenameConfigurationNameChange(event)}
                                                />
                                            </div>
                                            <div className='hint-msg'>
                                                Name can contain only a-z, A-Z, 0-9, ._-
                                            </div>
                                            <div className='invalid-feedback d-block'
                                                hidden={!(this.props.hasError)}>
                                                {this.props.errorMessage}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <Button
                                            className='btn-primary'
                                            onClick={() => this.props.onRenameConfiguration()}
                                            autoFocus>
                                            Rename Configuration
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const RenameConfiguration = withRouter(connect(Props, Actions)(Component));

export default RenameConfiguration;