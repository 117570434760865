import {IState } from "../../Interfaces";

const onGetHighlightPartStatusReducer = (state: IState, action) => {
    const highlightPartStatus = action.payload as '';
    const newState = { ...state } as IState;
    newState.data.search = {
        ...newState.data.search,
        highlightPartStatus: highlightPartStatus,
    };

    return newState;
};

export default onGetHighlightPartStatusReducer;