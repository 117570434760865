import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IPart, IState } from "../Interfaces";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import awsmobile from "../../aws-exports";
import { createParts } from "../../graphql/mutations";
import { makeRequest } from "../../utils";
import { Storage } from "aws-amplify";
import { ProgressBar } from "react-bootstrap";
import { getParts } from "../../graphql/queries";
import { NotificationManager } from "react-notifications";
import { debounce } from "lodash";
import { onFolderItemActionStatus } from '../Actions/Folders';
import ACTION_TYPES from "../actionTypes";
interface filesToBeImported {
  progress: number;
  error: string;
  status: string;
  file: any;
}
const Props = (state: IState, ownProps: any) => {
  const loadedParts = state.data.parts.loadedParts as any;
  const folderName = state.data.folders.highlightedFolderId as string;
  const creator = state.creator as string;
  const props = {
    loadedParts: loadedParts,
    creator: creator,
    folderName:folderName
  };

  return props;
};
const Actions = {
  onFolderItemActionStatus: onFolderItemActionStatus,
};

let stopFreezeOnWarningModal = false;
let importPartsAccepted = true;

const ImportParts = (props) => {
  const { showModal, hideModal } = props;

  const dispatch = useDispatch();

  const [filesToBeImported, setFilesToBeImported] = useState<
    filesToBeImported[]
  >([]);
  const [disableImport, setDisableImport] = useState<boolean>(true);
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [importStatus, setImportStatus] = useState<string>("");

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const handleShowWarningModal = () => {stopFreezeOnWarningModal = true;setShowWarningModal(true)}
  const handleHideWarningModal = () => {stopFreezeOnWarningModal = false;setShowWarningModal(false)}
  const [currentPartsCount, setCurrentPartsCount] = useState(1);
  const [acceptImport, setAcceptImport] = useState<boolean>(true);
  const handleAcceptFileImport = () => {importPartsAccepted = true;setAcceptImport(true);handleHideWarningModal()}
  const handleCancelFileImport = () => {importPartsAccepted = false;setAcceptImport(false);handleHideWarningModal()}

  const [importPartsWarningFileName, setImportPartsWarningFileName] = useState("");

  const onAddFilesForImport = (e: any) => (
    e.persist(),
    setImportStatus(""),
    Array.from(e.target.files).map(async (file: any) => {
      if (!filesToBeImported.some((el) => el.file.name === file.name)) {
        try {
          const getSelectedPart = await API.graphql(
            graphqlOperation(getParts, {             
              id: props.folderName === "" ? file.name :  `${props.folderName}/${file.name}` 
            })
          );
          const getSelectedPartResult = (getSelectedPart as any).data.getParts;
          if (getSelectedPartResult !== null){
            setFilesToBeImported((prev) => [
              ...prev,
              { progress: 0, error: "Part name already exists", status: "", file: file },
            ])
          }
          else{
            setFilesToBeImported((prev) => [
              ...prev,
              { progress: 0, error: "", status: "", file: file },
            ])
          }              
        } catch (graphqlError) {
          const errorMessage = `${Object(
            (graphqlError as any)?.errors?.[0]?.message
          ).toString()}`;
          console.error(errorMessage);
        }
      }
      else {
        setFilesToBeImported((prev) => [...prev])
      }
    }
    )
  );
  useEffect(() => {
    if (filesToBeImported.length === 0) {
      setDisableImport(true);
    } else if (importStatus === "progress") {
      setDisableImport(true);
    } else if (filesToBeImported.some((el) => el.error !== "")){
      setDisableImport(true);
    }
     else {
      setDisableImport(false);
    }
  }, [filesToBeImported, importStatus]);




  const onRename = async (file, event) => {
    try {
      event.persist();
      const updatedPartName = event.target.value;

      const getSelectedPart = await API.graphql(
        graphqlOperation(getParts, {
          id: props.folderName === "" ? updatedPartName :  `${props.folderName}/${updatedPartName}` 
        })
      );
      const getSelectedPartResult = (getSelectedPart as any).data.getParts;

      let error = "";
      if (getSelectedPartResult !== null) {
        error = "Part name already exists";
      }
      const updatedItems = filesToBeImported.map(item => {
        if (item.file.name === file.file.name) {
          const renamedFile = new File([file.file], updatedPartName, { type: file.file.type });
          return { ...item, file: renamedFile, error };
        }
        return item;
      });

      setFilesToBeImported(updatedItems);
      console.log('filesToBeImported', updatedItems);
    } catch (graphqlError) {
      const errorMessage = `${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      console.error(errorMessage);
    }
  };

  const debouncedOnRename = debounce(onRename, 1000);

  const handleOnChange = (file,event) => {
    event.persist();
    debouncedOnRename(file, event);
  };


  const onDeleteFilesForImport = (fileName) =>
    setFilesToBeImported((filesToBeImported) =>
      filesToBeImported.filter((e: any) => e.file.name !== fileName)
    );


  const onCancelModal = () => (setFilesToBeImported([]), hideModal(), props.onFolderItemActionStatus(true));

  const REGEX = /.*-(\w+)/;

  const s3BucketName = awsmobile.aws_user_files_s3_bucket;
  var importS3Name = "";
  if (s3BucketName != null) console.log(s3BucketName);
  let arr = s3BucketName.match(REGEX);
  if (arr != null) {
    var env = arr[1];
    console.log("env is", env);
    importS3Name = "imports3-" + env;
  }

  const updateObject = (name, updatedProperties) => {
    const objMapper = (data) =>
      data.map((obj) => {
        return obj.file.name === name ? { ...obj, ...updatedProperties } : obj;
      });

    setFilesToBeImported((prev) => objMapper(prev));
  };
  const onAddPart = async () => {
    setDisableImport(true);
    setDisableCancel(true);
    setImportStatus("progress");
    for (let i = 0; i < filesToBeImported.length; i++) {
      try {
        try {
          const result = await API.graphql(
            graphqlOperation(getParts, {
              id: props.folderName === "" ? filesToBeImported[i]["file"]["name"] :  `${props.folderName}/${filesToBeImported[i]["file"]["name"]}` 
            })
          );
          const addedPartId = (result as any)?.data?.getParts?.id as IPart;
          if (addedPartId !== filesToBeImported[i]["file"]["name"]) {
            const path = `FileStorage/Parts/${filesToBeImported[i]["file"]["name"]}_${filesToBeImported[i]["file"]["name"]}`;
            await Storage.put(path, filesToBeImported[i]["file"], {
              progressCallback(progress) {
                const progressInPercentage = Math.round(
                  (progress.loaded / progress.total) * 100
                );

                updateObject(filesToBeImported[i]["file"]["name"], {
                  progress: progressInPercentage,
                });
                console.log(filesToBeImported[i]);
              },
            });
            const folderName = props.folderName !== "" ? [JSON.stringify({ name: props.folderName })] as string[]  :null
            let data: any = {              
              id: props.folderName !== "" 
              ? `${props.folderName}/${filesToBeImported[i]["file"]["name"]}` 
              : filesToBeImported[i]["file"]["name"],         
              name: filesToBeImported[i]["file"]["name"],
              creator: props.creator,
              created_at: new Date().toISOString(),
              modifier: props.creator,
              modified_at: new Date().toISOString(),
              files: JSON.stringify({ data: path }),
              dumb: 1,
              hasPlates: false,
              hasBuilds: false,
              plates: [],
              builds: [],          
              folders : folderName,
              parts_search_string: props.folderName !== "" 
              ? `${props.folderName.toLowerCase()}/${filesToBeImported[i]["file"]["name"].toLowerCase()}` 
              : filesToBeImported[i]["file"]["name"].toLowerCase(),
          
                
            };
        
            let partsCount = 1;
            
            try {
              const response = await makeRequest(
                "POST",
                "https://beta.mantle3d.com/process_mesh_multi/",
                {
                  type: "Parts",
                  partObject: data,
                  bucket: awsmobile.aws_user_files_s3_bucket,
                  importPartS3: importS3Name,
                  stepTolerance: 0.001
                }
              );
              const responseJSON = JSON.parse(response.toString());
              if (responseJSON.length > 1)
                partsCount = responseJSON.length;

              let partError = false;
              let failedParts = 0;
              let partsVariables : any[] = [];
              for (let partNum = 0; partNum < partsCount; partNum++) {
                const partJSON = responseJSON[partNum] != null ? responseJSON[partNum] : responseJSON;

                if (partJSON.warning) {
                  delete partJSON.warning;
                  NotificationManager.info(partJSON.warning);
                  console.warn(partJSON.warning);
                }
                if (partJSON.error) {
                  console.error(partJSON.error);
                  NotificationManager.info(partJSON.error);
                  updateObject(filesToBeImported[i]["file"]["name"], {
                    error: partJSON.error,
                  });
                  partError = true;
                  break;
                }
                if (partJSON.id) {
                  data = partJSON;
                  data.files = JSON.stringify(data.files);
                }
                
                const variables = {
                  input: data,
                };
                
                partsVariables.push(variables);
              }

              if (partError)
                continue;
              
              if (partsCount > 99)
              {
                const errorMessage = `Too many parts (${partsCount}).`;
                console.error(errorMessage);
                updateObject(filesToBeImported[i]["file"]["name"], {
                  error: errorMessage,
                });
                continue;
              }
              
              if (partsCount > 1)
              {
                setCurrentPartsCount(partsCount);
                handleShowWarningModal();
                setImportPartsWarningFileName(filesToBeImported[i]["file"]["name"]);
                await onWarnFreeze();

                if (!importPartsAccepted)
                {
                  const errorMessage = `User cancellation.`;
                  console.error(errorMessage);
                  updateObject(filesToBeImported[i]["file"]["name"], {
                    error: errorMessage,
                  });
                  continue;
                }
              }

              for (let partNum = 0; partNum < partsCount; partNum++) {
                try {
                  const variables = partsVariables[partNum];
                  const result = await API.graphql(
                    graphqlOperation(createParts, variables)
                  );
                  const addedPart = (result as any)?.data?.createParts as IPart;

                  if (addedPart) {
                    if(folderName) {
                      dispatch({
                        type: ACTION_TYPES.FOLDER.ITEM_ACTION_STATUS.ACTION,
                        payload: true,
                      })
                    }
                    updateObject(filesToBeImported[i]["file"]["name"], {
                      status: "success",
                    });
                  }
                } catch (graphqlError) {
                  if (partNum != 0) {
                    failedParts++;
                    continue;
                  }
                    
                  updateObject(filesToBeImported[i]["file"]["name"], {
                    error: `Part name validation failed: ${Object(
                      (graphqlError as any)?.errors?.[0]?.message
                    ).toString()}`
                  });
                  partError = true;
                  break;
                }
              }              
                
              if (partError)
                continue;

              const partsAdded = partsCount - failedParts;
              if (partsAdded > 1) {
                NotificationManager.info(`${partsAdded} parts have been added`);
              }
              
              if (failedParts > 0)
                NotificationManager.info(`${failedParts} parts have been skipped (names already exist)`);
            
            } catch (error) {
              const errorMessage = `Import part and generating icon failed.\nPlease try again later.`;
              console.error(errorMessage);
              updateObject(filesToBeImported[i]["file"]["name"], {
                error: errorMessage,
              });
              continue;
            }
          } else {
            const errorMessage = `Part name already exists. `;
            console.error(errorMessage);
            updateObject(filesToBeImported[i]["file"]["name"], {
              error: errorMessage,
            });
            continue;
          }
         
        } catch (graphqlError) {     
          updateObject(filesToBeImported[i]["file"]["name"], {
            error: `Part name validation failed: ${Object(
              (graphqlError as any)?.errors?.[0]?.message
            ).toString()}`
          });
          continue;
        }
      } catch (graphqlError) {
        const errorMessage = `Add part failed: ${Object(
          (graphqlError as any)?.errors?.[0]?.message
        ).toString()}`;
        updateObject(filesToBeImported[i]["file"]["name"], {
          error: errorMessage,
        });
        continue;
      }
    }
    setDisableCancel(false);
  };

  const onWarnFreeze = async () => {
    return new Promise<void>((resolve) => {
      let stop = false
      
      loop();
      function loop()
      {
        if (!stopFreezeOnWarningModal)
        {
          resolve();
          return;
        }

        // Keeps checking until dialog is open
        setTimeout(loop, 100);
      }
    });
  };
  
  return (
    <div>
      <Modal centered show={showModal} onHide={onCancelModal} dialogClassName="partsDialog">
        <Modal.Header>
          <Modal.Title>Import Parts</Modal.Title>
          <span className="close-btn" onClick={onCancelModal}></span>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="modal-body"
        >
          <label>Add Files (.STL)</label>
          <div className="drop-zone mb-3">
            <input
              type="file"
              onChange={(event) => onAddFilesForImport(event)}
              multiple={true}
              accept=".stl, .STL, .ply, .PLY, .STEP, .step, .stp, .STP"
            />
            <img src="/upload-icon.svg" alt="upload-file" />
            <p>Drag and Drop, or click to Browse.</p>
          </div>

          <div className="added-files-list">
            {filesToBeImported.length !== 0 && (
              <ul>
                {[...filesToBeImported].map((file: any, idx) => (
                  <li key={idx}>
                    <div className="d-flex">
                      {file.error ? (
                        <img
                          src="/exclamation.svg"
                          alt="error file"
                          className="pr-3"
                        />
                      ) : file.status === "success" ? (
                        <img
                          src="/tick-success.svg"
                          alt="success file"
                          className="pr-3"
                        />
                      ) : (
                        <img
                          src="/part-thumbnail.svg"
                          alt="import file"
                          className="pr-3"
                          style={{ width: "34px", height: "50px" }}
                        />
                      )}
                      <input
                        type="text"
                        
                        onChange={(e) => handleOnChange(file,e)}
                        defaultValue={file.file.name}
                      ></input>
                      <span
                        onClick={() => onDeleteFilesForImport(file.file.name)}
                        className="remove-import"
                      ></span>
                    </div>
                    {file.progress !== 0 &&
                      file.error === "" &&
                      file.status !== "success" && (
                        <div className="progress-bar-import">
                          <ProgressBar
                            label={`${file.progress}%`}
                            variant="success"
                            now={file.progress}
                          />

                          {/* <label>{`${file.progress}%`}</label> */}
                        </div>
                      )}
                    {file.error !== "" && (
                      <div
                        className="invalid-feedback d-block"
                        style={{
                          marginLeft: "6%",
                          width: "80%",
                        }}
                      >
                        {file.error}
                      </div>
                    )}
                    {file.status === "success" && (
                      <div
                        className="valid-feedback d-block"
                        style={{
                          marginLeft: "6%",
                        }}
                      >
                        Part has been successfully imported
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={disableCancel}
            variant="secondary"
            onClick={onCancelModal}
          >
            Cancel
          </Button>

          <Button
            disabled={disableImport}
            className="btn btn-primary"
            onClick={() => onAddPart()}
            autoFocus
          >
            Import
          </Button>
        </Modal.Footer>
      </Modal>
                
      <Modal show={showWarningModal} onHide={handleCancelFileImport}>
        <Modal.Header closeButton>
          <Modal.Title>{importPartsWarningFileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{display:'flex',flexDirection :'column'}}>
          <svg style={{ marginRight: '10px', width: '40px', height: '40px',alignSelf:'center',marginBottom:'20px' }} xmlns="http://www.w3.org/2000/svg" width="28.832" height="26.135" viewBox="0 0 28.832 26.135">
            <g id="warning" transform="translate(0 -23.946)">
              <path id="Path_36041" data-name="Path 36041" d="M201.439,26.125,211.5,43.558a4.35,4.35,0,0,1-3.77,6.523H197.67l-3.481-13.067,3.481-13.067A4.348,4.348,0,0,1,201.439,26.125Z" transform="translate(-183.254 0)" fill="#3b4145" />
              <path id="Path_36042" data-name="Path 36042" d="M10.646,26.125.581,43.558a4.35,4.35,0,0,0,3.77,6.523H14.416V23.946A4.348,4.348,0,0,0,10.646,26.125Z" transform="translate(0 0)" fill="#3a4145" />
              <path id="Path_36043" data-name="Path 36043" d="M269.582,73.619,259.517,56.186a2.619,2.619,0,0,0-2.188-1.308l8.608,22.653h1.382a2.61,2.61,0,0,0,2.262-3.912Z" transform="translate(-242.838 -29.19)" fill="#ffd749" />
              <path id="Path_36044" data-name="Path 36044" d="M54.216,73.589a2.931,2.931,0,0,1,.3,1.3,2.454,2.454,0,0,1-2.252,2.61H33.516a2.61,2.61,0,0,1-2.262-3.912L41.319,56.156a2.622,2.622,0,0,1,2.262-1.309l.075,0a2.238,2.238,0,0,1,1.877,1.308Z" transform="translate(-29.166 -29.161)" fill="#ffd764" />
              <path id="Path_36045" data-name="Path 36045" d="M256,354.131v2.9a1.45,1.45,0,1,0,0-2.9Z" transform="translate(-241.584 -311.592)" fill="#3b4145" />
              <path id="Path_36046" data-name="Path 36046" d="M231.7,354.131c.16,0,.29.649.29,1.45s-.13,1.45-.29,1.45a1.45,1.45,0,1,1,0-2.9Z" transform="translate(-217.279 -311.592)" fill="#3a4145" />
              <path id="Path_36047" data-name="Path 36047" d="M256,132.646v10.732a1.45,1.45,0,0,0,1.45-1.45V134.1A1.45,1.45,0,0,0,256,132.646Z" transform="translate(-241.584 -102.579)" fill="#3b4145" />
              <path id="Path_36048" data-name="Path 36048" d="M231.7,132.646c.16,0,.29.649.29,1.45v7.832c0,.8-.13,1.45-.29,1.45a1.45,1.45,0,0,1-1.45-1.45V134.1A1.45,1.45,0,0,1,231.7,132.646Z" transform="translate(-217.279 -102.579)" fill="#3a4145" />
            </g>
          </svg>
          <div>This file contains an assembly that will be uploaded as {currentPartsCount} separate parts. Do you want to continue?</div>
          </Modal.Body>
        <Modal.Footer className="warning-popup-footer" style={{display:'flex',justifyContent:'center'}}>
          <Button className="footer-btn" variant="secondary" onClick={handleCancelFileImport}>
            Cancel
          </Button>
          <Button className="footer-btn" variant="primary" onClick={handleAcceptFileImport}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const ImportPart = withRouter(connect(Props, Actions)(ImportParts));
export default ImportPart;


