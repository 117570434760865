import API, { graphqlOperation } from "@aws-amplify/api";
import { onResetConfigurationScreen } from "..";

import { deleteConfigurations } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import { IConfiguration, IState } from "../../../Interfaces";
import { NotificationManager } from "react-notifications";

const onDeleteConfigurationInit = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.DELETE.INIT,
    payload: null,
  };
};

const onDeleteConfigurationSuccess = (deletedConfiguration: IConfiguration) => {
  return {
    type: ACTION_TYPES.CONFIGURATION.DELETE.SUCCESS,
    payload: deletedConfiguration,
  };
};

const onDeleteConfigurationFail = (errorMessage: string) => {
  NotificationManager.error('Failure on Delete Configuration');
  console.error('Failure on Delete Configuration - ', errorMessage);
  return {
    type: ACTION_TYPES.CONFIGURATION.DELETE.FAIL,
    payload: errorMessage,
  };
};

const onDeleteConfigurationDone = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.DELETE.DONE,
    payload: null,
  };
};

const onDeleteConfiguration = () => {
  return async (dispatch, getState) => {
    dispatch(onDeleteConfigurationInit());
    try {
      const state = getState() as IState;
      const configurationToBeDeleted =
        state.data.configurations.loadedConfigurations.find(
          (p) => p.id === state.data.configurations.highlightedConfigurationId
        ) as IConfiguration;
      const dataJSON = JSON.parse(configurationToBeDeleted.data);
      const variables = {
        input: {
          id: configurationToBeDeleted.id,
          _version: configurationToBeDeleted._version,
          //   modifier: dataJSON.modifier,
          //   creator: dataJSON.creator,
        },
      };
      const result = await API.graphql(
        graphqlOperation(deleteConfigurations, variables)
      );
      const deletedConfiguration = (result as any)?.data
        ?.deleteConfigurations as IConfiguration;
      dispatch(onDeleteConfigurationSuccess(deletedConfiguration));
      dispatch(onResetConfigurationScreen());
    } catch (graphqlError) {
      const errorMessage = `Delete configuration failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onDeleteConfigurationFail(errorMessage));
    }
    dispatch(onDeleteConfigurationDone());
  };
};

export default onDeleteConfiguration;
