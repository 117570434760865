import { IState } from "../../../Interfaces";

const onNewPlateNameChangeReducer = (state: IState, action) => {
    const plateNameToBeCreated = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.newPlateModalStatus.inputText = plateNameToBeCreated;
    
    return newState;
};

export default onNewPlateNameChangeReducer;