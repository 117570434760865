import { IState, IBuild } from "../../../Interfaces";

const onCancelBuildReducer = (state: IState, action) => {
    const canceledBuild = action.payload as IBuild;

    const indexToBeReplaced = state.data.builds.loadedBuilds.findIndex(lb => lb.id === canceledBuild.id);
    const isBuildExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isBuildExists) newState.data.builds.loadedBuilds.splice(indexToBeReplaced, 1, canceledBuild);

    return newState;
};

export default onCancelBuildReducer;