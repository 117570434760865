import ACTION_TYPES from "../../../actionTypes";
import API, { graphqlOperation } from "@aws-amplify/api";
import { IPart, IState } from "../../../Interfaces";
import { NotificationManager } from "react-notifications";
import { getParts } from "../../../../graphql/queries";

const onHighlightedPartInit = () => {
  return {
    type: ACTION_TYPES.PART.HIGHLIGHTED_PART.INIT,
    payload: null,
  };
};

const onHighlightedPartSuccess = (part: IPart) => {
  return {
    type: ACTION_TYPES.PART.HIGHLIGHTED_PART.SUCCESS,
    payload: part as IPart,
  };
};

const onHighlightedPartFail = (errorMessage: string) => {
  return {
    type: ACTION_TYPES.PART.HIGHLIGHTED_PART.FAIL,
    payload: errorMessage,
  };
};

const onFetchHighlightedPart = (id) => {
  return async (dispatch, getState) => {
    const state = getState() as IState;
    const partAvailableInState = state.data.parts.loadedParts.filter(
      (item) => item.id === id
    );
    if (partAvailableInState.length) {
      dispatch(onHighlightedPartSuccess(partAvailableInState[0]));
    } else {
      try {
        dispatch(onHighlightedPartInit());
        const result = await API.graphql(
          graphqlOperation(getParts, {
            id: id,
          })
        );
        const getSelectedPartResult = (result as any).data.getParts as IPart;
        if (getSelectedPartResult !== null) {
          dispatch(onHighlightedPartSuccess(getSelectedPartResult));
        }
      } catch (error) {
        const graphqlError = error as { errors?: { message: string }[] };
        const errorMessage = `Failed to fetch parts: ${
          graphqlError.errors?.[0]?.message || "Unknown error"
        }`;
        NotificationManager.error(errorMessage);
        dispatch(onHighlightedPartFail(errorMessage));
        return [];
      }
    }
  };
};

export default onFetchHighlightedPart;
