import { IState } from '../../../Interfaces';

const onHighlightPlateReducer = (state: IState, action) => {
    const plateId = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.plates.highlightedPlateId = plateId;

    return newState;
};

export default onHighlightPlateReducer;