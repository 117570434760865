import { API, graphqlOperation } from "aws-amplify";
import { IState } from "../../Interfaces";
import ACTION_TYPES from "../../actionTypes";
import {getParts} from "../../../graphql/queries";

const onHighlightPartInit = () => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PART.INIT,
    payload: null,
  };
};

const onHighlightPartSucess = (selectedPart) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PART.SUCCESS,
    payload: selectedPart,
  };
};
const onHighlightPartFail = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PART.FAIL,
    payload: status,
  };
};
const onHighlightPartDone = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PART.DONE,
    payload: status,
  };
};
const onHighlightPartReset = () => {
    return {
      type: ACTION_TYPES.SEARCH.HIGHLIGHT_PART.RESET,
        payload: null,
    };
};

const onGetHighlightedPart = (partId) => {
  return async (dispatch, getState) => {
    const state = getState() as IState;
     // dispatch(onHighlightBuildReset())
    try {
      console.log((partId))
      dispatch(onHighlightPartInit())
      const getSelectedPart = await API.graphql(
        graphqlOperation(getParts, {
          id: partId,
        })
      );
      const getSelectedPartResult = (getSelectedPart as any).data.getParts;
      if (getSelectedPartResult){
          dispatch(onHighlightPartSucess(getSelectedPartResult))
        }
    } catch (graphqlError) {
      const errorMessage = `${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onHighlightPartFail('failed'))
      console.error(errorMessage);
    }
    dispatch(onHighlightPartDone('completed'))
  };

};

export default onGetHighlightedPart;
