import { IState } from "../../../Interfaces";

const onAddPartStatus = (state: IState, action) => {
  const addPartStatus = action.payload as boolean;

  const newState = { ...state } as IState;
  newState.data.modals.addPartModalStatus = {
    ...newState.data.modals.addPartModalStatus,

    addPartStatus: addPartStatus,
  };

  return newState;
};

export default onAddPartStatus;
