import { getPartInfo } from ".";
import IPartInfo from "../../interfaces/IPartInfo";
import { getDefaultPartConfiguration, getDefaultPartSlicerConfiguration } from "../DefaultConfigurations";
import { IConfiguration, IPart, IState } from "../Interfaces";

const setPlateParts = (state: IState, partIds: string[], parts: IPartInfo[],  resetPlate: boolean = false): IState => {
    const stateParts = state.data.parts;

    const partsToBeAdded = partIds.map(id => stateParts.loadedParts.concat(stateParts.selectedParts).find(lp => lp.id === id)) as IPart[];
    const partInfosToBeAdded = parts ? parts : partsToBeAdded.map((p, i) => getPartInfo(p, state.data.workspace.addedParts.length + i, state)) as IPartInfo[];

    const defaultPartConfiguration = getDefaultPartConfiguration(state) as IConfiguration;
    const defaultPartSlicerConfiguration = getDefaultPartSlicerConfiguration(state) as IConfiguration;

    const newState = state;

    if (resetPlate) {
        newState.data.workspace.addedParts = [] as IPart[];
        newState.data.workspace.addedPartsSelectedPartIndices = [] as number[];
        newState.data.workspace.state.build.parts = [] as IPartInfo[];
    }

    newState.data.workspace.addedParts.splice(newState.data.workspace.addedParts.length, 0, ...partsToBeAdded);
    newState.data.workspace.addedPartsSelectedPartIndices = [0];
    // newState.data.workspace.addedPartsSelectedPartIndices.splice(newState.data.workspace.addedPartsSelectedPartIndices.length, 0, ...partsToBeAdded.map((p, i) => i));

    const needInit = (data) => {
        return data === null || data === undefined;
    }
    const processPartInfo = (partInfo) => {
        if (needInit(partInfo.userData)) {
            partInfo.userData = {
                newPart: true
            };
        }
        else if (partInfo.userData.newPart && partInfo.properties.scale !== undefined) {
            console.log("Not a newPart")
            partInfo.userData.newPart = false;
        }
        if (needInit(partInfo.properties.PartConfig)) {
            partInfo.properties.PartConfig = {
                originalJson: JSON.parse(defaultPartConfiguration['data']),
                resultJson: JSON.parse(defaultPartConfiguration['data']),
            };
        }
        if (needInit(partInfo.properties.SlicerConfig)) {
            partInfo.properties.SlicerConfig = {
                originalJson: JSON.parse(defaultPartSlicerConfiguration['data']),
                resultJson: JSON.parse(defaultPartSlicerConfiguration['data']),
            };
        }
    }

    (partInfosToBeAdded as IPartInfo[]).forEach(partInfo => processPartInfo(partInfo));

    newState.data.workspace.state.build.parts.splice(state.data.workspace.state.build.parts.length, 0, ...partInfosToBeAdded);

    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    return newState;
};

export default setPlateParts;
