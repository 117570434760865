import { IState } from '../../../Interfaces';

const onLoadBuildsCompletedReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.builds = {
        ...newState.data.builds,
        isLoadingStarted: false as boolean,
        isLoadingInProgress: false as boolean,
        isLoadingComplete: true as boolean,
    };

    newState.data.builds.loadedBuilds.forEach((lb, i) => {
        switch (lb.current_status) {
            case 'fail':
                newState.data.builds.loadedBuilds[i]['current_status'] = 'failed';
                break;
            default:
                break;
        }
    });

    return newState;
};

export default onLoadBuildsCompletedReducer;