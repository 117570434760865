import { getDisplayParts, getDisplayPlates, getDisplayBuilds } from ".";
import { IFolder, IState, IDisplayFolder, IDisplayPart, IDisplayPlate, IDisplayBuild } from "../Interfaces";

const getDisplayFolder = (folder: IFolder, state: IState) => {
    const folderId = folder.id || '';
    const folderName = folder.name || '';
    const displayFolder = {
        id: folderId as string,
        name: folderName as string,
        index: state.data.folders.loadedFolders.indexOf(folder) as number,
        isValid: Boolean(folder) as boolean,
        isFiltered: Boolean(folder.name.toLowerCase().includes(state.data.folders.searchText.toLowerCase())) as boolean,
        isSelected: Boolean(state.data.folders.selectedFolderIds.some(id => id === folder.id)) as boolean,
        isHighlighted: Boolean(state.data.folders.highlightedFolderId === folder.id) as boolean,
        isRecent: Boolean((((new Date()).getTime() - (new Date(folder.createdAt)).getTime()) / (60 * 60 * 1000)) < 24) as boolean,
        isYours: Boolean((folder.creator === state.creator) && !Boolean(folder.archive)) as boolean,
        isOthers: Boolean((folder.creator !== state.creator) && !Boolean(folder.archive)) as boolean,
        isArchived: Boolean(folder.archive) as boolean,
        displayParts: getDisplayParts(state) as IDisplayPart[],
        displayPlates: getDisplayPlates(state) as IDisplayPlate[],
        displayBuilds: getDisplayBuilds(state) as IDisplayBuild[],
        createdDate: (new Date(folder.createdAt) as Date).toLocaleString() as string,
        creator: folder.creator as string,
    };
    return displayFolder as IDisplayFolder;
};

export default getDisplayFolder;