import { IState, IBuild } from "../../../Interfaces";

const onUpdateBuildReducer = (state: IState, action) => {
    const updatedBuild = action.payload as IBuild;
    const indexToBeReplaced = state.data.builds.loadedBuilds.findIndex(lb => lb.id === updatedBuild.id);
    const isBuildExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isBuildExists) newState.data.builds.loadedBuilds.splice(indexToBeReplaced, 1, updatedBuild);

    newState.data.builds.selectedBuildIds = newState.data.builds.selectedBuildIds.filter(id => id !== updatedBuild.id);

    return newState;
};

export default onUpdateBuildReducer;