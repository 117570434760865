import { IState } from '../../../Interfaces';

const onHighlightFolderReducer = (state: IState, action) => {
    const folderId = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.folders.highlightedFolderId = folderId;
    
    return newState;
};

export default onHighlightFolderReducer;