import { IConfiguration, IState } from '../../../Interfaces';

const onCopyConfigurationSuccessReducer = (state: IState, action) => {
    const copiedConfiguration = action.payload as IConfiguration;
    
    const newState = { ...state } as IState;
    newState.data.configurations.loadedConfigurations.splice(0, 0, copiedConfiguration);
    
    newState.data.modals.copyConfigurationModalStatus = {
        ...newState.data.modals.copyConfigurationModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onCopyConfigurationSuccessReducer;