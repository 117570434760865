import { difference } from "lodash";
import { IState } from "../../../Interfaces";

const onUnselectFoldersReducer = (state: IState, action) => {
    const folderIds = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.folders.selectedFolderIds = difference(newState.data.folders.selectedFolderIds, folderIds);
    
    return newState;
};

export default onUnselectFoldersReducer;