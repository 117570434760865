import { IState } from '../../../Interfaces';

const onEditConfigurationDataReducer = (state: IState, action) => {
    const data = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.configurations.highlightedConfigurationData = data;
    
    return newState;
};

export default onEditConfigurationDataReducer;