import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteConfirmationModal(props) {
  const { showModal, hideModal, highlightedConfigurationId, handleDelete } =
    props;
  return (
    <Modal centered show={showModal} onHide={hideModal}>
      <Modal.Header className="text-center d-block">
        <Modal.Title>Delete {highlightedConfigurationId}</Modal.Title>
        <span className="close-btn"
						onClick={hideModal}>
					</span>
      </Modal.Header>
      <Modal.Body className="modal-body text-center">
        <h4>Are you sure you want to delete this Config?</h4>
        <p>This may cause plates that use this configuration to fail.</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
