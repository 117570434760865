import { SortDirection } from "../../../Enums";
import { IState } from "../../../Interfaces";

const onSortPlatesDescReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.plates.sortDirection = SortDirection.DESC;

    return newState;
};

export default onSortPlatesDescReducer;