import { IBuild, IState } from '../../../Interfaces';

const onMoveBuildSuccessReducer = (state: IState, action) => {
    const movedBuild = action.payload as IBuild;
    const indexToBeReplaced = state.data.builds.loadedBuilds.findIndex(lp => lp.id === movedBuild.id);
    const isBuildExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isBuildExists) newState.data.builds.loadedBuilds.splice(indexToBeReplaced, 1, movedBuild);

    newState.data.modals.moveBuildModalStatus = {
        ...newState.data.modals.moveBuildModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onMoveBuildSuccessReducer;