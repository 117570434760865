import React, { useEffect, useState, FunctionComponent } from "react";

const ConfigSelector: FunctionComponent<any> = (props: { configs: any[], title: string, onConfigChanged: any, onConfigToBeEdited: any }) => {

    const [selectedConfig, setSelectedConfig] = useState<any>(null);

    const onConfigChanged = config => {
        setSelectedConfig(config);
        props.onConfigChanged(config);
    };

    useEffect(() => { }, []);

    return (<div className="bp-l-b-e btstrp dropdown">
        <div className='bp-l-b-e-h'>
            <label className='p-14 c-292929'>{props.title || 'Config'}</label>
            {
                selectedConfig ?
                    <div className='edit-a-b-i' onClick={() => props.onConfigToBeEdited(selectedConfig)} />
                    : null
            }
        </div>
        <div className="btn-group bp-l-b-e-dd">
            <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {selectedConfig ? selectedConfig['name'] : `Select the ${props.title || 'Config'}`}
            </button>
            <button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
            <div className="dropdown-menu">
                {
                    props.configs.map(config => <div id={config.id} className="dropdown-item" onClick={() => { onConfigChanged(config) }}> <label className='p-14 c-292929'>{config.name}</label> </div>)
                }
            </div>
        </div>
    </div>);
};

export default ConfigSelector;