import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onHighlightPlate } from '../Actions/Plates';
import { IState } from '../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const props = {

    };

    return props;
};

const Actions = {
    onHighlightPlate: onHighlightPlate,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                <div className='display-card'
                    id={`plate-${this.props.loadedPlate.index}`}
                    key={`${this.props.loadedPlate.index}`} >
                    <div className='display-card-row'>
                        <div className='display-card-header'>
                            <label>{this.props.loadedPlate.name}</label>
                        </div>
                        <div className='display-card-select-action'>
                            <div className='options right-justify'>
                                <>
                                    {
                                        (this.props.loadedPlate.isHighlighted) ?
                                            (
                                                <>
                                                    <div className='check option'>
                                                        <img className='icon check'
                                                            alt='' />
                                                    </div>
                                                </>
                                            ) :
                                            (null)
                                    }
                                </>
                                <>
                                    {
                                        (!this.props.loadedPlate.isHighlighted) ?
                                            (
                                                <>
                                                    <div className='uncheck option'>
                                                        <img className='icon uncheck'
                                                            alt=''
                                                            onClick={() => this.props.onHighlightPlate(this.props.loadedPlate.id)} />
                                                    </div>
                                                </>
                                            ) :
                                            (null)
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                    <div className='display-card-row'>
                        <div className='display-card-sub-header'>
                            <>
                                {
                                    (this.props.loadedPart.isMovedToFolder) ?
                                        (
                                            <>
                                                {
                                                    this.props.loadedPart.folders.map(folder => <label>{folder}</label>)
                                                }
                                            </>
                                        ) :
                                        (
                                            <label></label>
                                        )
                                }
                            </>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const LoadedPlate = withRouter(connect(Props, Actions)(Component));

export default LoadedPlate;