import { setReviewPlateParts } from ".";
import { IState } from "../Interfaces";

const setPart = (state: IState, ids: any, resetPlate: boolean = false): IState => {
    const newState = { ...state } as IState;

    const newStateAfterAddedParts = setReviewPlateParts(newState, ids.partIds, ids.parts, resetPlate) as IState;
   
    return newStateAfterAddedParts;
};

export default setPart;