import React from "react";
import Modal from "react-modal";
import Catalog from "./Catalog";
import "../css/customJsonEditor.scss";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface ICatalogModalProps {
  modalCloser: () => void;
  dataType: string;
  schemaName?: string;
  onCatalogEntrySelected: (arg0: Object) => void;
}

const CatalogModal: React.FunctionComponent<ICatalogModalProps> = (props) => {
  const [value, setValue] = React.useState(0);
  const [showFilesInFolder, setShowFilesInFolder] = React.useState(false);
  const [buildNameList, setBuildNameList] = React.useState([]);
  const tabHandleChange = (event, newValue) => {
      setValue(newValue);
      //   if(newValue === 1) {
      //     routeChange(`/buildFolderss`);
      //   } else {
      //     routeChange(`/builds`);
      //   }
  };
  const onFolderClick = (item) => {
      console.log('onFolderClick~~~', item);
      setBuildNameList(item['builds']);

  };
  const onBackFolderClick = (item) => {
      console.log('onBackFolderClick~~~', item);
      if (item['action']) {
        setBuildNameList([]);
      }else {
        setBuildNameList([]);
        props.onCatalogEntrySelected(item);
        props.modalCloser();
      }

  };

  return (
    <Modal isOpen={props.dataType} onRequestClose={props.modalCloser}>
      {/* <Catalog
        dataType={props.dataType}
        schemaName={props.schemaName}
        elementsPerPage={20}
        onClick={(item: any) => {
          props.onCatalogEntrySelected(item);
          props.modalCloser();
        }}
      /> */}
              <React.Fragment>
            <Paper square>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={tabHandleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label={props.dataType} />
                    <Tab label="Folders" />
                </Tabs>
            </Paper>
            {value === 0 ? (
                <Catalog
                dataType={props.dataType}
                schemaName={props.schemaName}
                elementsPerPage={20}
                onClick={(item: any) => {
                  props.onCatalogEntrySelected(item);
                  props.modalCloser();
                }}
              /> 
            ) : (<React.Fragment>
                {buildNameList.length>0 ? (
                    <Catalog
                        dataType={props.dataType}
                        filesInFolders = {buildNameList}
                        openFromFolder = {true}
                        elementsPerPage={20}
                        onClick={(item: any) => {
                            onBackFolderClick(item);
                        }}
                        schemaName={props.schemaName}
                    />
                ) : (<Catalog
                    dataType={"BuildFolders"}
                    fileDataType={props.dataType}
                    elementsPerPage={20}
                    onClick={(item: any) => {
                        onFolderClick(item);
                      }}
                    schemaName={"BuildFolders"}
                />)}
            </React.Fragment>)}
        </React.Fragment>
    </Modal>
  );
};

export default CatalogModal;
