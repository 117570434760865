
import { IBuild, IState } from "../../Interfaces";

const onGetHighlightedBuildReducer = (state: IState, action) => {
    const highlightedBuildData = action.payload as IBuild;
    const newState = { ...state } as IState;
    newState.data.builds.loadedBuilds = [highlightedBuildData, ...newState.data.builds.loadedBuilds]
    newState.data.search = {
        ...newState.data.search,
        highlightedBuildData: highlightedBuildData,
    };

    return newState;
};

export default onGetHighlightedBuildReducer;