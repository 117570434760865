import API, { graphqlOperation } from '@aws-amplify/api';
import { union } from 'lodash';

import { tagsByCreatedAt } from '../../../../graphql/queries';

import ACTION_TYPES from '../../../actionTypes';
import { ITag } from '../../../Interfaces';

const getAvailableTags = (tags: ITag[] = []) => {
    return tags.filter(p => Boolean(p && !p._deleted));
};

const onLoadTagsStarted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.TAGS.STARTED,
        payload: null,
    };
};

const onLoadTagsInProgress = (newlyLoadedTags) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.TAGS.IN_PROGRESS,
        payload: newlyLoadedTags,
    };
};

const onLoadTagsCompleted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.TAGS.COMPLETED,
        payload: null,
    };
};

const loadTags = async (dispatch, getState, response: { loadedTags: ITag[], nextToken: any } = { loadedTags: [] as ITag[], nextToken: undefined }) => {
    try {
        const variables = {
            limit: 50,
            dumb: 1,
            sortDirection: 'DESC',
            nextToken: response.nextToken,
        };
        const result = await API.graphql(graphqlOperation(tagsByCreatedAt, variables)) as any;
        const loadedTags = getAvailableTags(response?.loadedTags as ITag[]);
        const newlyLoadedTags = getAvailableTags((result as any)?.data?.tagsByCreatedAt?.items || [] as ITag[]);
        dispatch(onLoadTagsInProgress(newlyLoadedTags));

        const tags = getAvailableTags(union(loadedTags, newlyLoadedTags) as ITag[]);
        const nextToken = (result as any)?.data?.tagsByCreatedAt?.nextToken || undefined;
        const newResponse = {
            loadedTags: tags,
            nextToken: nextToken,
        };
        if (nextToken) await loadTags(dispatch, getState, newResponse);
        return Promise.resolve();
    } catch (graphqlError) {
        return Promise.resolve();
    }
};

const onLoadTags = () => {
    return async (dispatch, getState) => {
        dispatch(onLoadTagsStarted());
        await loadTags(dispatch, getState);
        dispatch(onLoadTagsCompleted());
    };
};

export default onLoadTags;