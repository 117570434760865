import React, { useRef, useEffect } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import "./MVPJSONEditor.scss";
import 'brace/ext/searchbox';

export default function MVPJSONEditor({ value = {}, onChange }) {
	const jsonEditorRef = useRef(null);

	useEffect(() => {
		if (jsonEditorRef && jsonEditorRef.current) {
			// @ts-ignore: Object is possibly 'null'.
			jsonEditorRef.current.set(value);
		}
	}, [value]);

	const setRef = instance => {
		if (instance) {
			jsonEditorRef.current = instance.jsonEditor;
		} else {
			jsonEditorRef.current = null;
		}
	};
	// return <Editor ref={setRef} allowedModes={["tree", "code"]} value={value} onChange={onChange} />;

	return <Editor ref={setRef} mode={"code"} value={value} onChange={onChange} />;
}