import { getPartInfo } from ".";
import IPartInfo from "../../interfaces/IPartInfo";
import { IPart, IState } from "../Interfaces";

const setPlateParts = (state: IState, partIds: string[], parts: IPartInfo[],  resetPlate: boolean = false): IState => {
    const partsToBeAdded = partIds.map(id => state.data.parts.loadedParts.find(lp => lp.id === id)) as IPart[];
    const partInfosToBeAdded = parts ? parts : partsToBeAdded.map((p, i) => getPartInfo(p, state.data.workspace.addedParts.length + i, state)) as IPartInfo[];

    const newState = { ...state } as IState;

    if (resetPlate) {
        newState.data.workspace.addedParts = [] as IPart[];
        newState.data.workspace.addedPartsSelectedPartIndices = [] as number[];
        newState.data.workspace.state.build.parts = [] as IPartInfo[];
    }

    newState.data.workspace.addedParts.splice(newState.data.parts.selectedPartIds.length - 1, 0, ...partsToBeAdded);

    (partInfosToBeAdded as IPartInfo[]).forEach(partInfo => {
        partInfo.properties.PartConfig = {
        };
    });

    (partInfosToBeAdded as IPartInfo[]).forEach(partInfo => {
        partInfo.properties.SlicerConfig = {
        };
    });

    newState.data.workspace.state.build.parts.splice(state.data.workspace.state.build.parts.length, 0, ...partInfosToBeAdded);

    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;

    return newState;
};

export default setPlateParts;