import IBuildInfo from '../../interfaces/IBuild';
import IPartInfo from "../../interfaces/IPartInfo";
import { WorkspaceViewType } from "../Components/WorkSpace";

const getDefaultWorkspaceState = () => {

    const defaultWorkspaceState = {
        initiator: '',
        showLoading: ['hideLoading'],
        open: false,
        configOpen: false,
        moho: '',
        build: {
            id: '',
            name: '',
            dumb: 1,
            Min_Layer: -1,
            Max_Layer: -1,
            Max_Slab: -1,
            Slab_Layers: 10,
            Contouring: true,
            Process: 'TS2',
            overrides: [],
            current_status: 'new',
            machineConfig: null,
            millConfig: null,
            materialConfig: null,
            recipe: null,
            parts: [] as IPartInfo[],
            bundles: [],
            created_at: '',
            created_by: '',
        } as IBuildInfo,
        slicerText: '',
        combindedConfigEditor: null,
        viewState: WorkspaceViewType.SceneView,
        selectedMatrix: null,
        matrixOpen: false,
    };
    
    return defaultWorkspaceState;
};

export default getDefaultWorkspaceState;