import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { IState, INewFolderModalStatus } from '../Interfaces';
import { onNewFolderCloseDialog, onNewFolderNameChange, onNewFolder } from '../Actions/Home';

const Props = (state: IState, ownProps: any) => {

    const newFolderModalStatus = state.data.modals.newFolderModalStatus as INewFolderModalStatus;

    const props = {
        isModalOpen: newFolderModalStatus.isOpen,
        inputText: newFolderModalStatus.inputText,
        hasError: newFolderModalStatus.hasError,
        errorMessage: newFolderModalStatus.errorMessage,
        isValid: newFolderModalStatus.isValid,
    };

    return props;
};

const Actions = {
    onNewFolderCloseDialog: onNewFolderCloseDialog,
    onNewFolderNameChange: onNewFolderNameChange,
    onNewFolder: onNewFolder,
};

class Component extends React.Component<any, any> {

    private onNewFolderNameChange(event) {
        const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join('');
        this.props.onNewFolderNameChange(value);
    }

    public render() {
        return (
            <>
                {
                    (this.props.isModalOpen) ?
                        (
                            <>
                                <Modal
                                    show={this.props.isModalOpen}
                                    onHide={() => this.props.onNewFolderCloseDialog()}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='partsDialog'
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header>
                                        <Modal.Title>Create New Folder</Modal.Title>
                                        <span className="close-btn"
                                            onClick={() => this.props.onNewFolderCloseDialog()}>
                                        </span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='each-dialog-content'>
                                            <div className='each-d-section'>
                                                Folder Name
                                            </div>
                                            <div className='each-d-section'>
                                                <input className='f-w'
                                                    type='text'
                                                    value={this.props.inputText}
                                                    onChange={(event) => this.onNewFolderNameChange(event)}
                                                    placeholder="Enter Name Here"
                                                />
                                            </div>
                                            <div className='hint-msg'>
                                                Name can contain only a-z, A-Z, 0-9, ._-
                                            </div>
                                            <div className='invalid-feedback d-block'
                                                hidden={!this.props.hasError}>
                                                {this.props.errorMessage}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <Button variant="secondary" onClick={() => this.props.onNewFolderCloseDialog()}>
                                            Cancel
                                        </Button>
                                        <Button
                                            className='btn-primary'
                                            onClick={() => this.props.onNewFolder(this.props.history)}
                                            autoFocus>
                                            Continue
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const NewFolder = withRouter(connect(Props, Actions)(Component));

export default NewFolder;