import { IFolder, IState } from "../../../Interfaces";

const onDeleteFolderReducer = (state: IState, action) => {
    const deletedFolder = action.payload as IFolder;
    const indexToBeReplaced = state.data.folders.loadedFolders.findIndex(lp => lp.id === deletedFolder.id);
    const isFolderExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isFolderExists) newState.data.folders.loadedFolders.splice(indexToBeReplaced, 1);

    newState.data.folders.selectedFolderIds = newState.data.folders.selectedFolderIds.filter(id => id !== deletedFolder.id);
    newState.data.folders.highlightedFolder=""
    
    return newState;
};

export default onDeleteFolderReducer;