import API, { graphqlOperation } from '@aws-amplify/api';

import { updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPlate } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";

const onUpdatePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.INIT,
        payload: null,
    };
};

const onUpdatePlateSuccess = (updatedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.SUCCESS,
        payload: updatedPlate,
    };
};

const onUpdatePlateFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on Updating a plate');
    console.error('Failure on Updating a plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.UPDATE.FAIL,
        payload: errorMessage,
    };
};


const onUpdatePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.DONE,
        payload: null,
    };
};

const onUpdatePlateAfterDelete = (plateToBeUpdated, addedBuild) => {
    
    return async (dispatch, getState) => {
        
        dispatch(onUpdatePlateInit());
        
        try {
            const state = getState();
           
            const stringfiedAddedBuild = JSON.stringify({ 'name': addedBuild.id });
            const strigifiedBuilds = plateToBeUpdated.builds && plateToBeUpdated.builds.length > 0 ? plateToBeUpdated.builds : [];

            const updatedStrigifiedBuilds = strigifiedBuilds.concat(stringfiedAddedBuild);
            const hasBuilds = updatedStrigifiedBuilds && updatedStrigifiedBuilds.length > 0;

            plateToBeUpdated.hasBuilds = hasBuilds;
            plateToBeUpdated.builds = hasBuilds ? updatedStrigifiedBuilds : null;

            delete plateToBeUpdated._deleted;
            delete plateToBeUpdated._lastChangedAt;
            delete plateToBeUpdated.createdAt;
            delete plateToBeUpdated.updatedAt;
            plateToBeUpdated.modified_by = state.creator as string;
            plateToBeUpdated.modified_at = new Date().toISOString();

            const variables = {
                input: plateToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const updatedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            dispatch(onUpdatePlateSuccess(updatedPlate));
        }
        catch (graphqlError) {
            const errorMessage = `Update plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdatePlateFail(errorMessage));
        }

        dispatch(onUpdatePlateDone());
    };
};

export default onUpdatePlateAfterDelete;