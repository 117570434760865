import API, { graphqlOperation } from "@aws-amplify/api";
import { Storage } from "aws-amplify";
import { createParts } from "../../../../graphql/mutations";

import awsmobile from "../../../../aws-exports";
import { makeRequest } from "../../../../utils";

import ACTION_TYPES from "../../../actionTypes";
import { IAddPartModalStatus, IPart, IState } from "../../../Interfaces";
import { NotificationManager } from "react-notifications";


const REGEX = /.*-(\w+)/

const s3BucketName = awsmobile.aws_user_files_s3_bucket
var importS3Name =''
if (s3BucketName != null ) 
  console.log(s3BucketName)
  let arr = s3BucketName.match(REGEX)
  if (arr !=null ){
    var env = arr[1];
    console.log("env is", env)
    importS3Name = "imports3-" + env
  }

const onAddPartSuccess = (copiedPart) => {
  return {
    type: ACTION_TYPES.PART.ADD.SUCCESS,
    payload: copiedPart,
  };
};

const onAddPartFail = (errorMessage: string) => {
  //NotificationManager.error('Failure on adding part');
  console.error('Failure on adding part - ', errorMessage);
  return {
    type: ACTION_TYPES.PART.ADD.FAIL,
    payload: errorMessage,
  };
};
const onAddPartStatus = (status: boolean) => {
  return {
    type: ACTION_TYPES.PART.ADD.STATUS,
    payload: status,
  };
};

const onAddPartProgress = (status: number) => {
  return {
    type: ACTION_TYPES.PART.ADD.PROGRESS,
    payload: status,
  };
};
const onAddPart = () => {
  return async (dispatch, getState) => {
    const state = getState() as IState;

    const addPartModalStatus = state.data.modals
      .addPartModalStatus as IAddPartModalStatus;

    const isPartNameEmpty = !addPartModalStatus.inputText as boolean;
    const isPartNameExists = state.data.parts.loadedParts.some(
      (p) => p.name === addPartModalStatus.inputText
    ) as boolean;
    const isPartFileUploaded = addPartModalStatus.inputFile as boolean;

    if (isPartNameEmpty) {
      const errorMessage = "Part Name is mandatory." as string;
      dispatch(onAddPartFail(errorMessage));
      return;
    }
    if (isPartNameExists) {
      const errorMessage = "Part Name already exists." as string;
      dispatch(onAddPartFail(errorMessage));
      return;
    }
    if (!isPartFileUploaded) {
      const errorMessage = "Please upload part file to be added." as string;
      dispatch(onAddPartFail(errorMessage));
      return;
    }

    try {
      dispatch(onAddPartStatus(true));
      const path = `FileStorage/Parts/${addPartModalStatus.inputText}_${addPartModalStatus.inputFile.name}`;
      await Storage.put(path, addPartModalStatus.inputFile, {
        progressCallback(progress) {
          const progressInPercentage = Math.round(
            (progress.loaded / progress.total) * 100
          );
          dispatch(onAddPartProgress(progressInPercentage));
          //console.log(`Progress: ${progressInPercentage}%`);
        },
      });

      let data: any = {
        id: addPartModalStatus.inputText,
        name: addPartModalStatus.inputText,
        creator: state.creator,
        created_at: new Date().toISOString(),
        modifier: state.creator,
        modified_at: new Date().toISOString(),
        files: JSON.stringify({ data: path }),
        dumb: 1,
        hasPlates: false,
        hasBuilds: false,
        plates: [],
        builds: [],
        parts_search_string: addPartModalStatus.inputText.toLowerCase(),
      };
      
      let partsCount = 1;

      try {
        const response = await makeRequest(
          "POST",
          "https://beta.mantle3d.com/process_mesh_multi/",
          {
            type: "Parts",
            partObject: data,
            bucket: awsmobile.aws_user_files_s3_bucket,
            importPartS3: importS3Name,
          }
        );
        const responseJSON = JSON.parse(response.toString());
        if (responseJSON.length > 1)
          partsCount = responseJSON.length;
        
        for (let i = 0; i < partsCount; i++) {
          const partJSON = responseJSON[i] != null ? responseJSON[i] : responseJSON;

          if (partJSON.warning) {
            NotificationManager.info(responseJSON.warning);
            delete responseJSON.warning;
          }
          if (responseJSON.error) {
            NotificationManager.error(responseJSON.error);
            dispatch(onAddPartFail(responseJSON.error));
            dispatch(onAddPartStatus(false));
            return;
          }
          if (partJSON.error) {
            NotificationManager.error(partJSON.error);
            dispatch(onAddPartFail(partJSON.error));
            dispatch(onAddPartStatus(false));
            return;
          }
          if (partJSON.id) {          
            data = partJSON;
            data.files = JSON.stringify(data.files);
          }
          
          try {
            const variables = {
              input: data,
            };
            const result = await API.graphql(
              graphqlOperation(createParts, variables)
            );
            const addedPart = (result as any)?.data?.createParts as IPart;
            dispatch(onAddPartSuccess(addedPart));
            dispatch(onAddPartStatus(false));
            
          } catch (graphqlError) {
            const errorMessage = `Add part failed: ${Object(
              (graphqlError as any)?.errors?.[0]?.message
            ).toString()}`;
            dispatch(onAddPartFail(errorMessage));
            dispatch(onAddPartStatus(false));
            return;
          }
        }
        
        if (partsCount > 1)
          NotificationManager.info(`${partsCount} parts have been added`);

      } catch (error) {
        const errorMessage = `Import part and generating icon failed.\nPlease try again later.`;
        NotificationManager.error(errorMessage);
        dispatch(onAddPartFail(errorMessage));
        dispatch(onAddPartStatus(false));
        return;
      }
    } catch (graphqlError) {
      const errorMessage = `Add part failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onAddPartFail(errorMessage));
      dispatch(onAddPartStatus(false));
    }
  };
};

export default onAddPart;
