import { IState } from "../../Interfaces";

const onSubscriptionsSetParts = (state: IState, action) => {
    const buildNameToBeCreated = action.payload;

    const newState = { ...state } as IState;
    newState.subscriptions.partUpdate = buildNameToBeCreated.partUpdate;
    newState.subscriptions.partCreate = buildNameToBeCreated.partCreate;

    return newState;
};

export default onSubscriptionsSetParts;