import { uniq } from "lodash";
import { IBuild } from "../Interfaces";

const getBuildStatusTitle = (current_status: string) => {
    let buildStatusTitle = '' as string;
    switch (current_status) {
        case 'all':
            buildStatusTitle = 'all';
            break;
        case 'completed':
            buildStatusTitle = 'completed';
            break;
        case 'failed':
            buildStatusTitle = 'failed';
            break;
        case 'canceled':
            buildStatusTitle = 'canceled';
            break;
        default:
            buildStatusTitle = 'processing';
            break;
    }
    return buildStatusTitle;
}

const getDisplayBuildStatusList = (builds: IBuild[]) => {
    return uniq(['all', 'processing', 'completed', 'failed', 'canceled', ...builds.map(lb => getBuildStatusTitle(lb.current_status))]) as string[];
};

export default getDisplayBuildStatusList;