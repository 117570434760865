import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { IState } from "../Interfaces";
import { onNavigateToHomePage } from '../Actions/Home';
import { onNewBuildSuccessOpenDialog, onNewBuildSuccessCloseDialog} from "../Actions/Builds";



const Props = (state: IState, ownProps: any) => {

    const props = {
        isModalOpen:state.data.modals.newBuildSuccessModalStatus,
        buildId: state.data.workspace.state.build.id
       
    };
    return props;
};

const Actions = {
    onNewBuildSuccessOpenDialog: onNewBuildSuccessOpenDialog,
    onNewBuildSuccessCloseDialog: onNewBuildSuccessCloseDialog,
    onNavigateToHomePage: onNavigateToHomePage
   
};

const NewBuildSuccessModal =(props)=>{
    return(
        <>
            <Modal
                show={props.isModalOpen}
                onHide={() => props.onNewBuildSuccessCloseDialog()}
                backdrop="static"
                keyboard={false}
                dialogClassName="partsDialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                    <span className="close-btn"
                        onClick={() => props.onNewBuildSuccessCloseDialog()}>
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <div className="each-dialog-content">
                        <div className="each-d-section text-center">
                            <div className="success-tick"></div>
                            <p className="success-build-message"> "{props.buildId}" build has been successfully initiated.</p>
                        </div>
                    
                      
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={() => props.onNewBuildSuccessCloseDialog()}>
                        Stay on the current page
                    </Button>
                    <Button
                        className="btn-primary"
                        onClick={() => { props.onNavigateToHomePage(props.history); props.onNewBuildSuccessCloseDialog() }}
                        autoFocus
                    >
                        Navigate to Home Page
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}



const NewBuildSuccess = withRouter(connect(Props, Actions)(NewBuildSuccessModal));

export default NewBuildSuccess;
