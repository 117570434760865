import { IState } from "../../../Interfaces";

const onDownloadRequestReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.totalDownloadRequested = state.totalDownloadRequested + 1;
    
    return newState;
};

export default onDownloadRequestReducer;