import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IState } from '../Interfaces';
import { onEditPlatePageTabChange, onSearchPlatesTextChange, onSortPlatesAsc, onSortPlatesDesc } from '../Actions/Plates';
import { SortDirection, EditPlatePhase } from '../Enums';

const Props = (state: IState, ownProps: any) => {

    const props = {
        isLoadingComplete: state.data.plates.isLoadingComplete as boolean,
        sortDirection: state.data.plates.sortDirection as SortDirection,
        isSortDirectionDesc: (state.data.plates.sortDirection === SortDirection.DESC),
        isSortDirectionAsc: (state.data.plates.sortDirection === SortDirection.ASC),
    };

    return props;
};

const Actions = {
    onEditPlatePageTabChange: onEditPlatePageTabChange,
    onSearchPlatesTextChange: onSearchPlatesTextChange,
    onSortPlatesAsc: onSortPlatesAsc,
    onSortPlatesDesc: onSortPlatesDesc,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                <header>
                    <div className='menu-bar options left-justify'>
                        <div
                            className={`tab ${(this.props.selectedTabIndex === EditPlatePhase.PARTS) ? 'active' : ''}`}
                            onClick={() => { this.props.onEditPlatePageTabChange(EditPlatePhase.PARTS); }}>
                            <label>Parts</label>
                        </div>
                        <div
                            className={`tab ${(this.props.selectedTabIndex === EditPlatePhase.CONFIGURATIONS) ? 'active' : ''}`}
                            onClick={() => { this.props.onEditPlatePageTabChange(EditPlatePhase.CONFIGURATIONS); }}>
                            <label>Configurations</label>
                        </div>
                    </div>
                    <div className='menu-bar options'>
                        <div className='search option'>
                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='search-box'>Search by plate name</Tooltip>} >
                                <span>
                                    <input className='form-control search-box'
                                        type='text'
                                        placeholder='search by name'
                                        disabled={!this.props.isLoadingComplete}
                                        defaultValue=''
                                        onChange={(event) => this.props.onSearchPlatesTextChange(event.target.value)} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <>
                            {
                                (!this.props.isSortDirectionAsc) ?
                                    (
                                        <>
                                            <div className='sort option'>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip id='icon-desc-sort'>Plates shown in desc order</Tooltip>} >
                                                    <img className='icon desc-sort'
                                                        aria-disabled={!this.props.isLoadingComplete}
                                                        alt=''
                                                        onClick={() => this.props.onSortPlatesAsc()} />
                                                </OverlayTrigger>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>
                        <>
                            {
                                (!this.props.isSortDirectionDesc) ?
                                    (
                                        <>
                                            <div className='sort option'>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip id='icon-asc-sort'>Plates shown in asc order</Tooltip>} >
                                                    <img className='icon asc-sort'
                                                        aria-disabled={!this.props.isLoadingComplete}
                                                        alt=''
                                                        onClick={() => this.props.onSortPlatesDesc()} />
                                                </OverlayTrigger>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>
                    </div>
                </header>
            </>
        );
    }
}

const PlatesHeaderMinified = withRouter(connect(Props, Actions)(Component));

export default PlatesHeaderMinified;