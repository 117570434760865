import { IState } from '../../Interfaces';

const onBuildsSearchCompletedReducer = (state: IState, action) => {
    const status = action.payload as boolean;
    const newState = { ...state } as IState;
    newState.data.search = {
        ...newState.data.search,
        buildsSearchCompleted: status,
    };

    return newState;
};

export default onBuildsSearchCompletedReducer;
