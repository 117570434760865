import { IPart, IState, IAddedPart } from "../Interfaces";

const getAddedPart = (part: IPart, index: number, state: IState) => {
    const addedPart = part?{
        id: part?.id as string,
        name: part?.name as string,
        label: `Part ${index + 1}` as string,
        index: index as number,
        icon: part?.icon as string,
        created_at: part?.created_at as string,
        creator: part?.creator as string,
    }:null;
    return addedPart as IAddedPart;
};

export default getAddedPart;