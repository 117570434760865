import API, { graphqlOperation } from '@aws-amplify/api';
import { updateConfigurations } from '../../../../graphql/mutations';
import ACTION_TYPES from '../../../actionTypes';
import { IConfiguration } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onUpdateConfigurationInit = () => {
    return {
        type: ACTION_TYPES.CONFIGURATION.UPDATE.INIT,
        payload: null,
    };
};

const onUpdateConfigurationSuccess = (updatedConfiguration: IConfiguration) => {
    return {
        type: ACTION_TYPES.CONFIGURATION.UPDATE.SUCCESS,
        payload: updatedConfiguration,
    };
};

const onUpdateConfigurationFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on Update Configuration');
    console.error('Failure on Update Configuration - ', errorMessage);
    return {
        type: ACTION_TYPES.CONFIGURATION.UPDATE.FAIL,
        payload: errorMessage,
    };
};

const onUpdateConfigurationDone = () => {
    return {
        type: ACTION_TYPES.CONFIGURATION.UPDATE.DONE,
        payload: null,
    };
};

const onUpdateConfigurationAfterNewPlate = (configToBeUpdated, addedPlate) => {

    return async (dispatch, getState) => {

        dispatch(onUpdateConfigurationInit());

        try {
            const state = getState();

            const stringfiedAddedPlate = JSON.stringify({ 'name': addedPlate.id });
            const strigifiedPlates = configToBeUpdated.plates && configToBeUpdated.plates.length > 0 ? configToBeUpdated.plates : [];

            const updatedStrigifiedPlates = strigifiedPlates.concat(stringfiedAddedPlate);
            const hasPlates = updatedStrigifiedPlates && updatedStrigifiedPlates.length > 0;

            configToBeUpdated.hasPlates = hasPlates;
            configToBeUpdated.plates = hasPlates ? updatedStrigifiedPlates : null;

            delete configToBeUpdated._deleted;
            delete configToBeUpdated._lastChangedAt;
            delete configToBeUpdated.createdAt;
            delete configToBeUpdated.updatedAt;
            configToBeUpdated.modifier = state.creator as string;
            configToBeUpdated.modified_at = new Date().toISOString();

            const variables = {
                input: configToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updateConfigurations, variables));
            const updatedConfiguration = ((result as any)?.data?.updateConfigurations) as IConfiguration;
            dispatch(onUpdateConfigurationSuccess(updatedConfiguration));
        }
        catch (graphqlError) {
            const errorMessage = `Update config failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdateConfigurationFail(errorMessage));
        }

        dispatch(onUpdateConfigurationDone());
    };
};

export default onUpdateConfigurationAfterNewPlate;