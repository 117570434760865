import API, { graphqlOperation } from "@aws-amplify/api";
import { onResetConfigurationScreen } from "..";

import { createConfigurations } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import {
  ICopyConfigurationModalStatus,
  IConfiguration,
  IState,
} from "../../../Interfaces";
import { NotificationManager } from "react-notifications";

const onCopyConfigurationInit = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.COPY.INIT,
    payload: null,
  };
};

const onCopyConfigurationSuccess = (copiedConfiguration) => {
  return {
    type: ACTION_TYPES.CONFIGURATION.COPY.SUCCESS,
    payload: copiedConfiguration,
  };
};

const onCopyConfigurationFail = (errorMessage: string) => {
  NotificationManager.error('Failure on copying configuration');
  console.error('Failure on copying configuration - ', errorMessage);
  return {
    type: ACTION_TYPES.CONFIGURATION.COPY.FAIL,
    payload: errorMessage,
  };
};

const onCopyConfigurationDone = () => {
  return {
    type: ACTION_TYPES.CONFIGURATION.COPY.DONE,
    payload: null,
  };
};

const onCopyConfiguration = () => {
  return async (dispatch, getState) => {
    dispatch(onCopyConfigurationInit());

    const state = getState() as IState;

    const copyConfigurationModalStatus = state.data.modals
      .copyConfigurationModalStatus as ICopyConfigurationModalStatus;

    const isConfigurationNameEmpty =
      !copyConfigurationModalStatus.inputText as boolean;
    const isConfigurationNameExists =
      state.data.configurations.loadedConfigurations.some(
        (p) => p.name === copyConfigurationModalStatus.inputText
      ) as boolean;

    if (isConfigurationNameEmpty) {
      const errorMessage = "Configuration Name is mandatory." as string;
      dispatch(onCopyConfigurationFail(errorMessage));
      dispatch(onCopyConfigurationDone());
      return;
    }
    if (isConfigurationNameExists) {
      const errorMessage = "Configuration Name already exists." as string;
      dispatch(onCopyConfigurationFail(errorMessage));
      dispatch(onCopyConfigurationDone());
      return;
    }

    try {
      const state = getState() as IState;
      const configurationToBeCopied = {
        ...state.data.configurations.loadedConfigurations.find(
          (p) => p.id === state.data.configurations.highlightedConfigurationId
        ),
      } as IConfiguration;
      const id = copyConfigurationModalStatus.inputText.replace(/\s/g, "");
      const dataJSON = {
        ...JSON.parse(configurationToBeCopied["data"]),
        id: id,
      };
      configurationToBeCopied.id = copyConfigurationModalStatus.inputText;
      configurationToBeCopied.name = copyConfigurationModalStatus.inputText;
      delete configurationToBeCopied._deleted;
      delete configurationToBeCopied.archive;
      delete configurationToBeCopied._version;
      delete configurationToBeCopied._lastChangedAt;
      delete configurationToBeCopied.createdAt;
      delete configurationToBeCopied.updatedAt;
      configurationToBeCopied.data = JSON.stringify(dataJSON);
      configurationToBeCopied.creator = JSON.parse(
        JSON.stringify(dataJSON).replace(/\\/g, "")
      ).creator;
      configurationToBeCopied.modifier = JSON.parse(
        JSON.stringify(dataJSON).replace(/\\/g, "")
      ).modifier;
      configurationToBeCopied.default = false;
      configurationToBeCopied.created_at = new Date().toISOString();
      configurationToBeCopied.dumb = 1;

      const variables = {
        input: configurationToBeCopied,
      };

      const result = await API.graphql(
        graphqlOperation(createConfigurations, variables)
      );
      const copiedConfiguration = (result as any)?.data
        ?.createConfigurations as IConfiguration;
      dispatch(onCopyConfigurationSuccess(copiedConfiguration));
      dispatch(onResetConfigurationScreen());
      dispatch(onCopyConfigurationDone());
    } catch (graphqlError) {
      const errorMessage = `Name already exists in the database. Please enter a different name.`;
      console.log(
        `Copy configuration failed: ${Object(
          (graphqlError as any)?.errors?.[0]?.message
        ).toString()}`
      );
      dispatch(onCopyConfigurationFail(errorMessage));
      dispatch(onCopyConfigurationDone());
    }
  };
};

export default onCopyConfiguration;
