import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onAddPartToAddedParts } from '../../Actions/Parts';
import onGetHighlightedPart from '../../Actions/Search/highlightPart';
import {
	getDefaultPrintConfiguration,
	getDefaultMillConfiguration,
	getDefaultMaterialConfiguration,
	getDefaultRecipeConfiguration,
	getDefaultPartConfiguration,
	getDefaultPartSlicerConfiguration,
} from '../../DefaultConfigurations';
import { IConfiguration, IState } from '../../Interfaces';
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {
	const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;

	const props = {
		ids: {
			partIds: [ownProps.partsSearchResult].map((part) => part.id) as string[],
			partConfigurationIds: [ownProps.partsSearchResult]
				.map((id) => getDefaultPartConfiguration(state))
				.map((configuration) => configuration.id) as string[],
			partSlicerConfigurationIds: [ownProps.partsSearchResult]
				.map((id) => getDefaultPartSlicerConfiguration(state))
				.map((configuration) => configuration.id) as string[],
			plateConfigurationIds: [
				defaultPrintConfiguration['id'],
				defaultMillConfiguration['id'],
				defaultMaterialConfiguration['id'],
				defaultRecipeConfiguration['id'],
			],
		},
		state: state
	};

	return props;
};

const Actions = {
	onAddPartToAddedParts: onAddPartToAddedParts,
	onGetHighlightedPart: onGetHighlightedPart
};

class Component extends React.Component<any, any> {
	
	udpatePlates = async (id) => {
		await this.props.onGetHighlightedPart(id);
		const payload = this.props.ids;
		payload.plateConfigurationIds = this.props.state.data.workspace.selectedConfigurations.map(item => item.id)
		if(this.props.state.data.workspace.isAllAddedPartsSelected)
		{
			payload.partConfigurationIds[0] = this.props.state.data.workspace.addedPartsSelectedPartConfigurations[0].id
		}
		this.props.onAddPartToAddedParts(payload)
	}
	

	public render() {
		return (
			<>
				<div
					className='display-card'
					id={`part-${this.props.partsSearchResult.index}`}
					key={`${this.props.partsSearchResult.index}`}
				>
					<div>
                        <div className='thumbnail-preview'>
                            {
                                this.props.partsSearchResult.icon ?
                                    <img className='part-image' src={this.props.partsSearchResult.icon} />
                                    : <img src="/part-thumbnail.svg" alt="thumbnail image" className='default-thumb-image' />
                            }
                        </div>
                        <div className='display-card-row'>

                            <div className='display-card-header'>
                            <label title={this.props.partsSearchResult.id}>{this.props.partsSearchResult.id}</label>
                            </div>
							<div className='display-card-select-action'>
							<div className='options right-justify'>
								<div className='add-to-plate option'>
									<div
										className='active'
											onClick={() => { this.udpatePlates(this.props.partsSearchResult.id)}}
									>+</div>
								</div>
							</div>
						</div>
                        </div>
                        <div className='display-card-row'>

                            <div className='display-card-sub-header'>
                                <div className='details'>
                                    <label>Imported Date:</label>
									<span>{this.props.partsSearchResult.created_at ? moment(new Date(this.props.partsSearchResult.created_at)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span>
                                </div>
								<div className='details'>
                                    <label>Created By:</label>
                                    <span>{this.props.partsSearchResult?.created_by}</span>
                                </div>
                            </div>
                        </div>
                    </div>
					
					<div className='display-card-row'>
						<div className='display-card-sub-header'>
							<>
								{
									this.props.partsSearchResult.isMovedToFolder ?
										(
											<>
												{
													this.props.partsSearchResult.folders.map(folder => <label>{folder}</label>)
												}
											</>
										) :
										(
											<label></label>
										)
								}
							</>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const SearchEditPlateCardView = withRouter(connect(Props, Actions)(Component));

export default SearchEditPlateCardView;
