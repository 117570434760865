import React, { useEffect, useState } from "react";
import { connect, useStore } from "react-redux";
import { withRouter } from "react-router-dom";
import { SortDirection } from "../../../Enums";
import { IState } from "../../../Interfaces";
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
	onBuildStatusFilterChange,
	onSearchBuildsTextChange,
	onSortBuildsAsc,
	onSortBuildsDesc
} from "../../../Actions/Builds";
import { getDisplayBuildStatusList } from "../../../Mappers";
import { onCollapseAccordions, onExpandAccordions } from "../../../Actions/Folders";
import { onShowFolderView, onShowListView } from "../../../Actions/Prepare";
import { onLoadBuilds } from "../../../Actions/Home";

const Props = (state: IState, ownProps: any) => {

	return {
		showFolderView: state.data.info.showFolderView as boolean,
		showListView: state.data.info.showListView as boolean,
		isLoadingComplete: state.data.builds.isLoadingComplete as boolean,
		noOfBuilds: state.data.builds.loadedBuilds.length as number,
		buildStatusList: getDisplayBuildStatusList(state.data.builds.loadedBuilds) as string[],
		selectedBuildStatus: state.data.builds.selectedBuildStatus as string,
		sortDirection: state.data.builds.sortDirection as SortDirection,
		isSortDirectionDesc: state.data.builds.sortDirection === SortDirection.DESC,
		isSortDirectionAsc: state.data.builds.sortDirection === SortDirection.ASC,
		searchText: state.data.builds.searchText as string,
		displayBuilds: state.data.builds.loadedBuilds.length as number,
		nextToken: state.data.buildByCreatedAt.items.length as number,
	};
};


const Actions = {
	onShowFolderView: onShowFolderView,
	onShowListView: onShowListView,
	onBuildStatusFilterChange: onBuildStatusFilterChange,
	onSearchBuildsTextChange: onSearchBuildsTextChange,
	onSortBuildsAsc: onSortBuildsAsc,
	onSortBuildsDesc: onSortBuildsDesc,
	onCollapseAccordions: onCollapseAccordions,
	onExpandAccordions: onExpandAccordions,
	onLoadBuilds: onLoadBuilds,
};

const BuildsHeaderMethod = (props) => {
	const store = useStore();

	//useEffect(() => {
		//props.parentCallback(props.loadedBuilds.length);
	//}, [props.loadedBuilds.length]);

	useEffect(() => {
		let oldProps = {...props};

		let listener = () => {
			let newProps = Props(store.getState(), {});
			let reloadProperties = [ "selectedBuildStatus", "searchText", "sortDirection" ];

			reloadProperties.every((e) => {
				if (oldProps[e] == newProps[e]) {
					return true;
				}

				oldProps = {...newProps };
				props.onLoadBuilds(true);
				return false;
			});
		}
		return store.subscribe(listener);
	});

	function handleChange(event) {
		const value = event?.target?.value;
		if ((event?.key === 'Enter' && value.length >= 3) || value === "") {
			props.onSearchBuildsTextChange(value);
		}
	}

	return (

		<>
			<header>
				<div className="menu-bar options">
					{
						props.showFolderView ?
							(
								<>
									<div className="check option">
										<img
											className="icon uncheck"
											alt=""
											onClick={() => props.onShowFolderView()}
										/>
										<span>View Folders</span>
									</div>
								</>
							)
							:
							(
								<div className="check option">
									<img className="icon check"
										alt=""
										onClick={() => props.onShowFolderView()}
									/>
									<span>View Folders</span>
								</div>
							)
					}
					<div className='flex-space'></div>
					<>
						{
							props.isLoadingComplete ? (
								<>
									<div className="loading-status info">
										<img
											className="icon loading-complete"
											alt="loading complete"
										/>
										<span>{`Loading completed, ${props.displayBuilds} builds loaded`} </span>
									</div>
								</>
							) :
								(
									<>
										<div className="loading-status info">
											<img
												className="icon loading-in-progress"
												alt="loading in progress"
											/>
											<span>{`Loading builds in progress, ${props.displayBuilds} builds loaded`}</span>
										</div>
									</>
								)
						}
					</>
					<div className="filter-by-build-status option">
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip id="dropdown-basic-button-filter">
									Filter by build status
								</Tooltip>
							}
						>
							<span>
								<DropdownButton
									id="dropdown-basic-button"
									disabled={!props.isLoadingComplete}
									title={props.selectedBuildStatus ? props.selectedBuildStatus : "All"}
								>
                  {
                    props.buildStatusList.map((bs) => {
                      return (
                          <Dropdown.Item
                            onClick={() => props.onBuildStatusFilterChange(bs)}
                            key={`dropdown_${bs}`}
                          >
                            {bs}
                          </Dropdown.Item>
                      );
                    })
                  }
								</DropdownButton>
							</span>
						</OverlayTrigger>
					</div>
					<div className="search option">
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip id="search-box">Search by build name</Tooltip>
							}
						>
							<span>
								<input
									className="form-control search-box"
									type="text"
									placeholder="Search by name with min 3 characters"
									disabled={!props.isLoadingComplete}
									defaultValue={props.searchText}
									onKeyUp={ handleChange }
								/>
							</span>
						</OverlayTrigger>
					</div>
					<>
						{!props.isSortDirectionAsc ? (
							<>
								<div className="sort option">
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip id="icon-desc-sort">
												Builds shown in desc order
											</Tooltip>
										}
									>
										<img
											className="icon desc-sort"
											aria-disabled={!props.isLoadingComplete}
											alt=""
											onClick={() => { console.log('SWITCH TO ASC'); props.onSortBuildsAsc(); }}
										/>
									</OverlayTrigger>
								</div>
							</>
						) : null}
					</>
					<>
						{!props.isSortDirectionDesc ? (
							<>
								<div className="sort option">
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip id="icon-asc-sort">
												Builds shown in asc order
											</Tooltip>
										}
									>
										<img
											className="icon asc-sort"
											aria-disabled={!props.isLoadingComplete}
											alt=""
											onClick={() => { console.log('SWITCH TO DESC'); props.onSortBuildsDesc(); }}
										/>
									</OverlayTrigger>
								</div>
							</>
						) : null}
					</>
					<>
						{
							props.showListView ?
								(
									<>
										<div className='list-view option'>
											<OverlayTrigger
												placement='top'
												overlay={
													<Tooltip id='icon-list-view'>
														Card View
													</Tooltip>
												}
											>
												<img
													className='icon list-view'
													aria-disabled={!props.isLoadingComplete}
													alt=''
													onClick={() => props.onShowListView()}
												/>
											</OverlayTrigger>
										</div>
									</>
								)
								:
								(
									<>
										<div className='card-view option'>
											<OverlayTrigger
												placement='top'
												overlay={
													<Tooltip id='icon-card-view'>
														List View
													</Tooltip>
												}
											>
												<img
													className='icon card-view'
													aria-disabled={!props.isLoadingComplete}
													alt=''
													onClick={() => props.onShowListView()}
												/>
											</OverlayTrigger>
										</div>
									</>
								)
						}
					</>
					<>
						<div className='collapse-all option'>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-collapse-all'>
										Collapse all sections
									</Tooltip>
								}
							>
								<img
									className='icon collapse-all'
									aria-disabled={!props.isLoadingComplete}
									alt=''
									onClick={() => props.onCollapseAccordions()}
								/>
							</OverlayTrigger>
						</div>
					</>
					<>
						<div className='expand-all option'>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-expand-all'>
										Expand all sections
									</Tooltip>
								}
							>
								<img
									className='icon expand-all'
									aria-disabled={!props.isLoadingComplete}
									alt=''
									onClick={() => props.onExpandAccordions()}
								/>
							</OverlayTrigger>
						</div>
					</>
				</div>
			</header>
		</>
	);

}

const BuildsHeader = withRouter(connect(Props, Actions)(BuildsHeaderMethod));

export default BuildsHeader;


