import { IFolder, IState } from "../../../../Interfaces";

const onNewFolderSuccessReducer = (state: IState, action) => {
    const addedFolder = action.payload as IFolder;
    
    const newState = { ...state } as IState;
    newState.data.folders.loadedFolders.splice(0, 0, addedFolder);
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    newState.data.modals.newFolderModalStatus = {
        ...newState.data.modals.newFolderModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onNewFolderSuccessReducer;