import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onSelectPart, onUnselectPart } from "../../../Actions/Parts";
import { IState } from "../../../Interfaces";
import { onNavigateToPartReviewPage } from "../../../Actions/Home";
import { onFetchHighlightedPart } from '../../../Actions/Parts';
import DisplayPartOptions from "./DisplayPartOptions";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { API, graphqlOperation } from "aws-amplify";
import { getParts } from "../../../../graphql/queries";

const Props = (state: IState, ownProps: any) => {
  const props = {
    // displayFolder: ownProps.displayFolder,
    // displayPlate: ownProps.displayPlate as IDisplayPlate,
    // loadedPlates: state.data.plates.loadedPlates as IPlate[],
    isSelected: ownProps.isSelected,
    // onUnselect: ownProps.onUnselect,
    // onSelect: ownProps.onSelect,
    creator: state.creator,
    folderName: state.data.folders.highlightedFolder as string,
    selectedPartsIds: state.data.parts.selectedPartIds,
    enableMultiSelect: state.enableBatchDelete,
    selectedFolder: state.data.folders.highlightedFolder as string,
  };

  return props;
};

const Actions = {
  onSelectPart: onSelectPart,
  onUnselectPart: onUnselectPart,
  onNavigateToPartReviewPage: onNavigateToPartReviewPage,
  onFetchHighlightedPart:onFetchHighlightedPart
};

class Component extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isPartSelected: false,
      partsInFolder: [],
    };
  }

  componentDidMount() {
    this.setState({ isPartSelected: this.props.isSelected || false });
  }

  componentWillReceiveProps() {
    if (
      this.props.selectedPartsIds.some(
        (item) => item === this.props.displayPart.id
      ) &&
      this.props.enableMultiSelect &&
      !this.state.isPartSelected
    ) {
      this.setState({ isPartSelected: true });
    }
    if (
      !this.props.enableMultiSelect &&
      this.state.isPartSelected &&
      !this.props.selectedPartsIds.length
    ) {
      this.setState({ isPartSelected: false });
    }
    if (
      this.props.selectedPartsIds.length === 0 &&
      this.props.enableMultiSelect &&
      this.state.isPartSelected
    ) {
      this.setState({ isPartSelected: false });
    }
  }

  onToggleSelectPart = (id) => {
    if (!this.state.isPartSelected && !this.props.enableMultiSelect) {
      this.props.onSelectPart(id);
    } else {
      if (
        this.props.displayPart.creator !== this.props.creator &&
        this.props.enableMultiSelect
      ) {
        NotificationManager.error(
          "You lack the authorization to delete parts created by other users."
        );
      } else {
        this.props.enableMultiSelect &&
          this.setState({ isPartSelected: !this.state.isPartSelected });
        this.props.isSelected && this.state.isPartSelected
          ? this.props.onUnselectPart(id)
          : !this.state.isPartSelected && this.props.enableMultiSelect
          ? this.props.onSelectPart(id)
          : this.props.onUnselectPart(id);
      }
    }
  };

  public render() {
    return (
      <>
        <>
          {this.props.displayPart ? (
            <div
              className={`display-card is-selected-${this.state.onSelectPart} position-relative p-0`}
              id={`part-${this.props.displayPart.index}`}
              style={{ paddingLeft: "10px" }}
            >
              <div className="display-card-select-action pt-2 pl-2 position-absolute">
                <div className="options right-justify">
                  <>
                    {
                      <>
                        <div
                          className="check option"
                          onClick={(e) => {
                            this.onToggleSelectPart(this.props.displayPart.id);
                            e.stopPropagation();
                          }}
                        >
                          <img
                            className={`icon ${
                              this.state.isPartSelected || this.props.isSelected
                                ? "check"
                                : "uncheck"
                            }`}
                            alt="part-card"
                            style={{ margin: "0px", padding: "4px" }}
                          />
                        </div>
                      </>
                    }
                  </>
                </div>
              </div>
              <div className="img-col plate-thumb">
                {this.props.displayPart.icon ? (
                  <img
                    src={this.props.displayPart.icon}
                    alt="thumbnail image"
                    className="thumbnail-img plate-thumbnail"
                  />
                ) : (
                  <img
                    src="/part-thumbnail.svg"
                    alt="thumbnail image"
                    className="thumbnail-img plate-thumb"
                  />
                )}
              </div>
              <div className="display-card-row justify-content-center pt-0">
                <div className="display-card-header d-flex">
                 
                  <label title= {this.props.selectedFolder !==""? this.props.displayPart.id : this.props.displayPart.name}>
                    {this.props.selectedFolder !==""? (this.props.displayPart.id).split('/').length && this.props.displayPart.id.split('/')[1] : this.props.displayPart.name}
                  </label>
                </div>
                <div
                  className="display-card-more-action"
                  onClick={(e) => {e.stopPropagation(); this.props.onFetchHighlightedPart(this.props.displayPart.id)}}
                >
                  <DisplayPartOptions
                    {...{ displayPart: this.props.displayPart }}
                  />
                </div>
              </div>
              <div className="display-card-row">
                <div className="display-card-sub-header">
                  <>
                    <div className="details">
                      <label>Imported Date:</label>

                      <span
                        className="data-format"
                        title={
                          this.props.displayPart?.created_at
                            ? moment(this.props.displayPart.created_at).format(
                                "MMMM Do YYYY, HH:mm:ss A"
                              )
                            : this.props.displayPart?.createdDate
                            ? moment(
                                this.props.displayPart.createdDate,
                                "DD/MM/YYYY, HH:mm:ss"
                              ).format("MMMM Do YYYY, HH:mm:ss A")
                            : "Not Available"
                        }
                      >
                        {this.props.displayPart?.created_at
                          ? moment(this.props.displayPart.created_at).format(
                              "MMMM Do YYYY, HH:mm:ss A"
                            )
                          : this.props.displayPart?.createdDate
                          ? moment(
                              this.props.displayPart.createdDate,
                              "DD/MM/YYYY, HH:mm:ss"
                            ).format("MMMM Do YYYY, HH:mm:ss A")
                          : "Not Available"}
                      </span>
                    </div>
                    <div className="details">
                      <label>Created By:</label>
                      <span title={this.props.selectedFolder !==""? this.props.displayPart.created_by : this.props.displayPart.creator}>
                      {this.props.selectedFolder !==""? this.props.displayPart.created_by : this.props.displayPart.creator}                        
                      </span>
                    </div>
                  </>
                </div>
              </div>
            </div>
          ) : null}
        </>
      </>
    );
  }
}

const DisplayDashboardPartCardView = withRouter(
  connect(Props, Actions)(Component)
);

export default DisplayDashboardPartCardView;
