import React, { useEffect, useState } from "react";
import { connect, useStore } from "react-redux";
import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip, } from "react-bootstrap";
import { SortDirection } from "../../../Enums";
import { IState } from "../../../Interfaces";
import { onSearchPlatesTextChange, onSortPlatesAsc, onSortPlatesDesc, } from "../../../Actions/Plates";
import { onLoadPlates } from "../../../Actions/Home";
import { onShowFolderView, onShowListView } from "../../../Actions/Prepare";
import { onCollapseAccordions, onExpandAccordions } from '../../../Actions/Folders';

const Props = (state: IState, ownProps: any) => {
	
	const props = {
		showFolderView: state.data.info.showFolderView as boolean,
		showListView: state.data.info.showListView as boolean,
		isLoadingComplete: state.data.plates.isLoadingComplete as boolean,
		noOfPlates: state.data.plates.loadedPlates.length as number,
		searchText: state.data.plates.searchText as string,
		sortDirection: state.data.plates.sortDirection as SortDirection,
		isSortDirectionDesc: state.data.plates.sortDirection === SortDirection.DESC,
		isSortDirectionAsc: state.data.plates.sortDirection === SortDirection.ASC,
		displayPlates: state.data.plates.loadedPlates.length as number,
	};

	return props;
};

const Actions = {
	onShowFolderView: onShowFolderView,
	onShowListView: onShowListView,
	onSearchPlatesTextChange: onSearchPlatesTextChange,
	onSortPlatesAsc: onSortPlatesAsc,
	onSortPlatesDesc: onSortPlatesDesc,
	onCollapseAccordions: onCollapseAccordions,
	onExpandAccordions: onExpandAccordions,
	onLoadPlates: onLoadPlates,
};

const PlatesHeaderMethod = (props) => {
	const store = useStore();

	useEffect(() => {
		let oldProps = {...props};

		let listener = () => {
			let newProps = Props(store.getState(), {});
			let reloadProperties = [ "searchText", "sortDirection" ];

			reloadProperties.every((e) => {
				if (oldProps[e] == newProps[e]) {
					return true;
				}

				oldProps = {...newProps };
				props.onLoadPlates(true);
				return false;
			});
		}
		return store.subscribe(listener);
	});

	function handleChange(event) {
		const value = event?.target?.value;
		if ((event?.key === 'Enter' && value.length >= 3) || value === "") {
			props.onSearchPlatesTextChange(value);
		}
	}

	return (
		<>
			<header>
				<div className="menu-bar options">
					{
						props.showFolderView ?
							(
								<>
									<div className="check option">
										<img
											className="icon uncheck"
											alt=""
											onClick={(e) => props.onShowFolderView()}
										/>
										<span>View Folders</span>
									</div>
								</>
							) :
							(
								<div className="check option">
									<img className="icon check"
										alt=""
										onClick={(e) => props.onShowFolderView()}
									/>
									<span>View Folders</span>
								</div>
							)
					}
					<div className='flex-space'></div>
					<>
						{
							props.isLoadingComplete ?
								(
									<>
										<div className="loading-status info">
											<img
												className="icon loading-complete"
												alt="loading complete"
											/>
											<span>{`Loading complete, ${props.displayPlates} plates loaded`}</span>
										</div>
									</>
								)
								:
								(
									<>
										<div className="loading-status info">
											<img
												className="icon loading-in-progress"
												alt="loading in progress"
											/>
											<span>{`Loading plates in progress, ${props.displayPlates} plates loaded`}</span>
										</div>
									</>
								)
						}
					</>
					<div className="search option">
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip id="search-box">Search by plate name</Tooltip>
							}
						>
							<span>
								<input
									className="form-control search-box"
									type="text"
									placeholder="Search by name with min 3 characters"
									disabled={!props.isLoadingComplete}
									defaultValue={props.searchText}
									onKeyUp={ handleChange }
								/>
							</span>
						</OverlayTrigger>
					</div>
					<>
						{!props.isSortDirectionAsc ? (
							<>
								<div className="sort option">
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip id="icon-desc-sort">
												Plates shown in desc order
											</Tooltip>
										}
									>
										<img
											className="icon desc-sort"
											aria-disabled={!props.isLoadingComplete}
											alt=""
											onClick={() => props.onSortPlatesAsc()}
										/>
									</OverlayTrigger>
								</div>
							</>
						) : null}
					</>
					<>
						{!props.isSortDirectionDesc ? (
							<>
								<div className="sort option">
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip id="icon-asc-sort">
												Plates shown in asc order
											</Tooltip>
										}
									>
										<img
											className="icon asc-sort"
											aria-disabled={!props.isLoadingComplete}
											alt=""
											onClick={() => props.onSortPlatesDesc()}
										/>
									</OverlayTrigger>
								</div>
							</>
						) : null}
					</>
					<>
						{
							props.showListView ?
								(
									<>
										<div className='list-view option'>
											<OverlayTrigger
												placement='top'
												overlay={
													<Tooltip id='icon-list-view'>
														Card View
													</Tooltip>
												}
											>
												<img
													className='icon list-view'
													aria-disabled={!props.isLoadingComplete}
													alt=''
													onClick={() => props.onShowListView()}
												/>
											</OverlayTrigger>
										</div>
									</>
								)
								:
								(
									<>
										<div className='card-view option'>
											<OverlayTrigger
												placement='top'
												overlay={
													<Tooltip id='icon-card-view'>
														List View
													</Tooltip>
												}
											>
												<img
													className='icon card-view'
													aria-disabled={!props.isLoadingComplete}
													alt=''
													onClick={() => props.onShowListView()}
												/>
											</OverlayTrigger>
										</div>
									</>
								)
						}
					</>
					<>
						<div className='collapse-all option'>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-collapse-all'>
										Collapse all sections
									</Tooltip>
								}
							>
								<img
									className='icon collapse-all'
									aria-disabled={!props.isLoadingComplete}
									alt=''
									onClick={() => props.onCollapseAccordions()}
								/>
							</OverlayTrigger>
						</div>
					</>
					<>
						<div className='expand-all option'>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-expand-all'>
										Expand all sections
									</Tooltip>
								}
							>
								<img
									className='icon expand-all'
									aria-disabled={!props.isLoadingComplete}
									alt=''
									onClick={() => props.onExpandAccordions()}
								/>
							</OverlayTrigger>
						</div>
					</>
				</div>
			</header>
		</>
	);
}

const PlatesHeader = withRouter(connect(Props, Actions)(PlatesHeaderMethod));

export default PlatesHeader;
