import { LineBasicMaterial } from "three";

export const materials = {
  DISPENSE: new LineBasicMaterial({ color: "rgb(0, 70,0)" }),
  DISPENSE_PERIMS: new LineBasicMaterial({ color: "rgb(0, 70,0)" }),
  DRY: new LineBasicMaterial({ color: 0xbf6300 }),
  DRY_PERIMS: new LineBasicMaterial({ color: 0xbf6300 }),
  CUT: new LineBasicMaterial({ color: 0xff0000 }),
  CUT_PERIMS: new LineBasicMaterial({ color: 0xff0000 }),

  NON_FUNCTIONAL: new LineBasicMaterial({ color: 0x000000 }),
  JOG_MOVES: new LineBasicMaterial({ color: 0x9c9c9c }),
  MILL_STEPS0: new LineBasicMaterial({ color: "rgb(247,91,0)" }),
  MILL_STEPS1: new LineBasicMaterial({ color: "rgb(156,97,64)" }),
  MILL_STEPS2: new LineBasicMaterial({ color: "rgb(53,166,166)" }),
  MILL_STEPS3: new LineBasicMaterial({ color: "rgb(103,122,7)" }),
  MILL_STEPS4: new LineBasicMaterial({ color: "rgb(230,76,196)" }),
  MILL_STEPS5: new LineBasicMaterial({ color: "rgb(108,63,181)" }),
  MILL_STEPS6: new LineBasicMaterial({ color: "rgb(42,150,15)" }),
  MILL_STEPS7: new LineBasicMaterial({ color: "rgb(18,48,219)" }),
  MILL_STEPS8: new LineBasicMaterial({ color: "rgb(148,44,58)" }),
  MILL_STEPS9: new LineBasicMaterial({ color: "rgb(163,130,8)" }),
  MILL_STEPS10: new LineBasicMaterial({ color: "rgb(5,160,227)" }),
  MILL_STEPS11: new LineBasicMaterial({ color: "rgb(89,81,77)" })
};
