import { IPart, IState } from '../../../Interfaces';
import { setPlateParts } from "../../../Instances";

const onLoadPartsInProgressReducer = (state: IState, action) => {
    const newlyLoadedParts = action.payload.parts as IPart[];
    const nextToken = action.payload.nextToken;
    
    const newState = { ...state } as IState;

    if (action.payload.type !== 'load') {
      newState.data.parts.selectedParts.splice(0, newState.data.parts.selectedParts.length, ...newlyLoadedParts);
      const parts = [...state.data.workspace.state.build.parts] as any[];
      newState.data.plates.partsScaledVolume = parts?.reduce((a, b) => a * (b?.properties?.scale?.x * b?.properties?.scale?.y * b.properties?.scale?.z), 1) || 0;
      newState.data.plates.partsScaledArea = parts?.reduce((a, b) => a * (b?.properties?.scale?.x * b?.properties?.scale?.y ), 1) || 0;
      return setPlateParts(newState, parts.map(a => a.properties.PartID), parts, true);
    }

    newState.data.parts.loadedParts.splice(0, newState.data.parts.loadedParts.length, ...newlyLoadedParts);
    newState.data.parts.isLoadingInProgress = true;
    newState.data.parts.nextToken = nextToken;
    
    return newState;
};

export default onLoadPartsInProgressReducer;
