import {IState } from '../../../Interfaces';

const onFetchDeletedConfigurationsSuccessreducer = (state: IState, action) => {
     const deletedConfigurations = action.payload;
    
    const newState = { ...state } as IState;
    newState.data.configurations.deletedConfigurations = deletedConfigurations
    
    return newState;
};

export default onFetchDeletedConfigurationsSuccessreducer;