import { IState } from '../../../Interfaces';

const onAddConfigurationFailReducer = (state: IState, action) => {
    const addConfigurationErrorMessage = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.modals.addConfigurationModalStatus = {
        ...newState.data.modals.addConfigurationModalStatus,
        hasError: true,
        errorMessage: addConfigurationErrorMessage,
    };
    
    return newState;
};

export default onAddConfigurationFailReducer;