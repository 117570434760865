import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IAddedPart, IConfiguration, IDisplayPlate, IPlate, IState } from "../Interfaces";
import { Button } from "react-bootstrap";
import { onNavigateToHomePage, onNavigateToPlatesPage } from "../Actions/Home";
import { onNewPlateOpenDialog, onUpdatePlate, onHighlightPlate } from "../Actions/Plates";
import { onAddPartOpenDialog } from '../Actions/Parts';
import { TabIndex } from "../Enums";
import { getDefaultMillConfiguration, getDefaultPrintConfiguration, getDefaultMaterialConfiguration, getDefaultRecipeConfiguration, getDefaultPartConfiguration, getDefaultPartSlicerConfiguration } from "../DefaultConfigurations";
import IPartInfo from "../../interfaces/IPartInfo";


const Props = (state: IState, ownProps: any) => {
    const addedParts = ownProps?.addedParts || [] as IAddedPart[];
    const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
	const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
	const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
	const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;

    const props = {
        displayPlate: ownProps.displayPlate as IDisplayPlate,
        loadedPlates: state.data.plates.loadedPlates as IPlate[],
        addedParts: addedParts as IAddedPart[],
        plateName: state.data.plates.highlightedPlateId as string,
        isNewPlate: state.data.workspace.isNewPlate as boolean,
        isEditPlate: state.data.workspace.isEditPlate as boolean,
        isReviewPart: state.data.workspace.isReviewPart as boolean,
        selectedTabIndex: state.data.info.homePageTabIndex as number,
        selectedPartIds: state.data.parts.selectedPartIds as string[],
        ids: {
            partIds: [...state.data.parts.selectedPartIds].filter(Boolean) as string[],
            partConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            partSlicerConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartSlicerConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
        },

    };

    return props;
};

const Actions = {
    onNavigateToHomePage: onNavigateToHomePage,
    onAddPartOpenDialog: onAddPartOpenDialog,
    onUpdatePlate: onUpdatePlate,
    onNewPlateOpenDialog: onNewPlateOpenDialog,
    onNavigateToPlatesPage: onNavigateToPlatesPage,
    onHighlightPlate: onHighlightPlate,
};

class Component extends React.Component<any, any> {
    onNavigateToPlatesPage = (plateId) => {

		this.props.onHighlightPlate(plateId);

		const plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;

		const partIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartID).filter(Boolean) as string[];
		const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
		const partConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartConfig['originalJson']['id']).filter(Boolean);
		const partSlicerConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.SlicerConfig['originalJson']['id']).filter(Boolean);
		const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

		const ids = {
			partIds,
			parts,
			partConfigurationIds,
			partSlicerConfigurationIds,
			plateConfigurationIds,
		};

		this.props.onNavigateToPlatesPage(ids, this.props.history, false);
	}

    public render() {
        return (
            <>
                <div className="tab-bar">
                    <div className="measure-btn">
                    <img src="/measure-icon.svg" alt="Measurement" />
                    <label>Measure</label>
                    </div>
                    <div className="flex-space"></div>
                    <div className="options right-justify review-options">
                        {/* Parts Tab Header buttons */}

                        <>
                            {
                                (this.props.selectedTabIndex === TabIndex.PARTS) ?
                                    (
                                        <>
                                            <div className='import'>
                                                <Button
                                                    variant="secondary"
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={() => this.props.onAddPartOpenDialog()}
                                                >Import Parts <img src="/import-part-icon.png" className='pl-2' alt="Import part button icon"/>
                                                </Button>
                                            </div>

                                            <div className='create-new-plate'>
                                                <Button className="btn-space"
                                                    onClick={() => this.props.onNavigateToPlatesPage(this.props.ids, this.props.history, true)}>
                                                    Add to Existing Plate <img src="/existing-plate-icon.png" className='pl-2' alt="Add Existing plate button icon" />
                                                </Button>
                                            </div>

                                            <div className='create-new-plate'>
                                                <Button  className="btn-space ml-0"
                                                    onClick={() => this.props.onNavigateToPlatesPage(this.props.ids, this.props.history, true)}>
                                                    Start New Plate <span className='pr-2'> + </span>
                                                </Button>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>

                        {/* Plates Tab Header buttons */}
                        <>
                            {
                                (this.props.selectedTabIndex === TabIndex.PLATES) ?
                                    (
                                        <>
                                            <div className='import'>
                                                <Button
                                                    variant="secondary"
                                                    className="btn-inactive"
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={() => this.props.onNavigateToPlatesPage(this.props.ids, this.props.history, true)}
                                                    // onClick={() => { this.props.onNavigateToPlatesPage(this.props.displayPlate.id)} }
                                                >Edit Build Plate <img src="/edit-build-icon.svg" className='pl-2' alt="Edit Build Plate Icon" />
                                                </Button>
                                            </div>

                                            <div className='create-new-plate'>
                                                <Button className="ml-4 btn-inactive"
                                                    onClick={() => {}}>
                                                    Configure Printer Settings <span className='btn-right-arrow pl-2'></span>
                                                </Button>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>

                        {/* Builds Tab Header buttons  */}
                        <>
                            {
                                (this.props.selectedTabIndex === TabIndex.BUILDS) ?
                                    (
                                        <>
                                            <div className='import'>
                                                <Button className="disabled"
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={() => {}}
                                                >Review Slice <span className='btn-right-arrow pl-2'></span>
                                                </Button>
                                            </div>

                                            <div className='create-new-plate'>
                                                <Button className="ml-4 disabled"
                                                    onClick={() => {}}>
                                                    Send to Printer <span className='btn-right-arrow pl-2'></span>
                                                </Button>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>
                    </div>
                </div>
            </>
        );
    }
}

const ReviewPageTabBar = withRouter(connect(Props, Actions)(Component));

export default ReviewPageTabBar;
