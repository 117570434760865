import { IState } from '../../../Interfaces';

const onMoveBuildCloseDialogReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.modals.moveBuildModalStatus = {
        ...newState.data.modals.moveBuildModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onMoveBuildCloseDialogReducer;