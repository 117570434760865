import { SortDirection } from "../../../Enums";
import { IState } from "../../../Interfaces";

const onSortPartsAscReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.parts.sortDirection = SortDirection.ASC;

    return newState;
};

export default onSortPartsAscReducer;