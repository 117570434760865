import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IState } from '../../../Interfaces';

import onDownload from './download';
import onResetSearch from "../../Search/reset"
import onGetHighlightedBuild from "../../Search/highlightBuild";
import { NotificationManager } from "react-notifications";

const onDownloadBundleRequest = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.REQUEST,
        payload: null,
    };
};

const onDownloadBundleStart = (contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.START,
        payload: contentLength,
    };
};

const onDownloadBundleInProgress = (processedLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.IN_PROGRESS,
        payload: processedLength,
    };
};

const onDownloadBundleSuccess = (receivedLength: number, contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.SUCCESS,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadBundleFail = (receivedLength: number, contentLength: number) => {
    NotificationManager.error('Failure on downloading bundle');
    console.error('Failure on downloading bundle - receivedLength', receivedLength, 'contentLength', contentLength);
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.FAIL,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadBundleDone = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.DONE,
        payload: null,
    };
};

const onDownloadBundle = (buildId) => {
    return async (dispatch, getState) => {
        const state = getState() as IState;
        const selectedBuild = !state.data.search.isSearchEnabled ? (typeof (buildId) === "string" ? state.data.builds.loadedBuilds.find(b => b.id === buildId) as IBuild : buildId) : state.data.search.highlightedBuildData as IBuild
        
        const storagePath = selectedBuild?.path?.replace('public/', '') || '' as string;
        const saveAsFileName = (storagePath.replace(/^.*[\\/]/, '') + '.zip') || 'download_error.txt' as string;
        const dispatchables = {
            onDownloadRequest: onDownloadBundleRequest,
            onDownloadStart: onDownloadBundleStart,
            onDownloadInProgress: onDownloadBundleInProgress,
            onDownloadSuccess: onDownloadBundleSuccess,
            onDownloadFail: onDownloadBundleFail,
            onDownloadDone: onDownloadBundleDone,
        };
        await onDownload(storagePath, saveAsFileName, dispatchables)(dispatch, getState);
        // if (state.data.search.isSearchEnabled) {
        //     dispatch(onResetSearch())
        // }
    };
};

export default onDownloadBundle;