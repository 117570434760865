import { HelpPageTabs } from '../../../Enums';
import { IState, } from '../../../Interfaces';

const onHelpPageTabChangeReducer = (state: IState, action) => {
    const selectedTabIndex = action.payload as HelpPageTabs;

    const newState = { ...state } as IState;
    newState.data.info.helpPageTabIndex = selectedTabIndex as HelpPageTabs;
    
    return newState;
};

export default onHelpPageTabChangeReducer;