import { API, graphqlOperation } from "aws-amplify";
import { IState } from "../../Interfaces";
import ACTION_TYPES from "../../actionTypes";
import {
  buildSearchByCreatedAt,
  partsSearchByCreatedAt,
  plateSearchByCreatedAt,
} from "../../../graphql/queries";

export const onGetSearchString = (searchString) => {
  return {
    type: ACTION_TYPES.SEARCH.GETSEARCHSTRING,
    payload: searchString,
  };
};

export const onGetSearchCategory = (searchCategory) => {
  return {
    type: ACTION_TYPES.SEARCH.GETSEARCHCATEGORY,
    payload: searchCategory,
  };
};

const onPartSearchSuccess = (partsSearchResults) => {
  return {
    type: ACTION_TYPES.SEARCH.PARTS_SUCCESS,
    payload: partsSearchResults,
  };
};
const onPlateSearchSuccess = (plateSearchResults) => {
  return {
    type: ACTION_TYPES.SEARCH.PLATES_SUCCESS,
    payload: plateSearchResults,
  };
};
const onBuildSearchSuccess = (buildSearchResults) => {
  return {
    type: ACTION_TYPES.SEARCH.BUILDS_SUCCESS,
    payload: buildSearchResults,
  };
};

const onResetSearchResults = () => {
  return {
    type: ACTION_TYPES.SEARCH.RESET_RESULTS,
    payload: null,
  };
};

const onPartSearchCompleted = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.PARTS_SEARCH_COMPLETED,
    payload: status,
  };
};

const onPlateSearchCompleted = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.PLATES_SEARCH_COMPLETED,
    payload: status,
  };
};

const onBuildSearchCompleted = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.BUILDS_SEARCH_COMPLETED,
    payload: status,
  };
};
const isSearchEnabled = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.IS_SEARCH_ENABLED,
    payload: status,
  };
};



// const  onSearchFail = (errorMessage: string) => {
//   return {
//     type: ACTION_TYPES.SEARCH.FAIL,
//     payload: errorMessage,
//   };
// };
// const  onSearchStatus = (status: boolean) => {
//   return {
//     type: ACTION_TYPES.SEARCH.STATUS,
//     payload: status,
//   };
// };

// const  onSearchProgress = () => {
//   return {
//     type: ACTION_TYPES.SEARCH.PROGRESS,
//     payload: null,
//   };
// };

const fetchBuildResults = async (searchString, dispatch, state?) => {

  try {
    const nextToken = undefined;
    const variables = {
      dumb: 1,
      sortDirection: "DESC",
      // filter: { build_search_string: { contains: searchString.toLowerCase() } },
      limit: 10000,
      nextToken: nextToken,
      items: {
        searchString,
      },
    };
    const currentUser = state ? state.creator : false;
    const showOwnedByFilter = state ? state.data?.info.showOwnedByBuilds :  false;
    if (searchString && searchString !== "" && showOwnedByFilter) {
      (variables as any).filter = 
      {
          created_by: {eq: currentUser},
          build_search_string: { contains: searchString.toLowerCase() }
      };
    } 
    if (searchString && searchString !== "" && !showOwnedByFilter) {
      (variables as any).filter = 
      {
          build_search_string: { contains: searchString.toLowerCase() }
      };
    } 
    if (!searchString && showOwnedByFilter) {
      (variables as any).filter = 
      {
          created_by: {eq: currentUser}
      };
    }
    do {
      const result = (await API.graphql(
        graphqlOperation(buildSearchByCreatedAt, variables)
      )) as any;
      const buildSearchResults = (result as any)?.data
        ?.buildSearchByCreatedAt?.items;
      dispatch(onBuildSearchSuccess(buildSearchResults))
      const newNextToken =
        (result as any)?.data?.buildSearchByCreatedAt?.nextToken || undefined;

      if (!newNextToken || buildSearchResults.length === 0) {
        break;
      } else {
        variables.nextToken = newNextToken;
      }
    } while (true);
  } catch (graphqlError) {
    const errorMessage = `failed to fetch build search results: ${Object(
      (graphqlError as any)?.errors?.[0]?.message
    ).toString()}`;
    console.log(errorMessage);
  }
  dispatch(onBuildSearchCompleted(true))
};

const fetchPlateResults = async (searchString, dispatch, state?) => {

  try {
    const nextToken = undefined;
    const variables = {
      dumb: 1,
      sortDirection: "DESC",
      // filter: { plate_search_string: { contains: searchString.toLowerCase() } },
      limit: 10000,
      nextToken: nextToken,
      items: {
        searchString,
      },
    };
    const currentUser = state ? state.creator : false;
    const showOwnedByFilter = state ? state.data?.info.showOwnedByPlates : false;
    if (searchString && searchString !== "" && showOwnedByFilter) {
      (variables as any).filter = 
      {
          created_by: {eq: currentUser},
          plate_search_string: { contains: searchString.toLowerCase() }
      };
    } 
    if (searchString && searchString !== "" && !showOwnedByFilter) {
      (variables as any).filter = 
      {
        plate_search_string: { contains: searchString.toLowerCase() }
      };
    } 
    if (!searchString && showOwnedByFilter) {
      (variables as any).filter = 
      {
          created_by: {eq: currentUser}
      };
    }
    do {
      const result = (await API.graphql(
        graphqlOperation(plateSearchByCreatedAt, variables)
      )) as any;
      const plateSearchResults = (result as any)?.data
        ?.plateSearchByCreatedAt?.items;
      dispatch(onPlateSearchSuccess(plateSearchResults))
      const newNextToken =
        (result as any)?.data?.plateSearchByCreatedAt?.nextToken || undefined;

      if (!newNextToken || (plateSearchResults.length === 0)) {
        break;
      } else {
        variables.nextToken = newNextToken;
      }
    } while (true);
  } catch (graphqlError) {
    const errorMessage = `failed to fetch Plate search results: ${Object(
      (graphqlError as any)?.errors?.[0]?.message
    ).toString()}`;
    console.log(errorMessage);
  }
  dispatch(onPlateSearchCompleted(true))
};

const fetchPartResults = async (searchString, dispatch, state?) => {
  try {
    const nextToken = undefined;
    const variables = {
      dumb: 1,
      sortDirection: "DESC",
      // filter: { parts_search_string: { contains: searchString.toLowerCase() } },
      limit: 10000,
      nextToken: nextToken,
      items: {
        searchString,
      },
    };
    const currentUser = state ? state.creator : false;
    const showOwnedByFilter = state ? state.data?.info?.showOwnedByParts : false;
    if (searchString && searchString !== "" && showOwnedByFilter) {
      (variables as any).filter = 
      {
          created_by: {eq: currentUser},
          parts_search_string: { contains: searchString.toLowerCase() }
      };
    } 
    if (searchString && searchString !== "" && !showOwnedByFilter) {
      (variables as any).filter = 
      {
         parts_search_string: { contains: searchString.toLowerCase() }
      };
    } 
    if (!searchString && showOwnedByFilter) {
      (variables as any).filter = 
      {
          created_by: {eq: currentUser}
      };
    }
    do {
      const result = (await API.graphql(
        graphqlOperation(partsSearchByCreatedAt, variables)
      )) as any;
      const partSearchResults = (result as any)?.data?.partsSearchByCreatedAt?.items;

      dispatch(onPartSearchSuccess(partSearchResults))

      const newNextToken =
        (result as any)?.data?.partsSearchByCreatedAt?.nextToken || undefined;
      if (!newNextToken || (partSearchResults.length === 0)) {
        break;
      } else {
        variables.nextToken = newNextToken;
      }
    } while (true);
  } catch (graphqlError) {
    const errorMessage = `failed to fetch Part search results: ${Object(
      (graphqlError as any)?.errors?.[0]?.message
    ).toString()}`;
    console.log(errorMessage);
  }

  dispatch(onPartSearchCompleted(true))
};

const onSearch = (searchString, searchCategory) => {
  return async (dispatch, getState) => {
    dispatch(isSearchEnabled(true))
    dispatch(onResetSearchResults())
    dispatch(onGetSearchString(searchString));
    dispatch(onGetSearchCategory(searchCategory));
    const state = getState() as IState;
    const category = state.data.info.homePageTabIndex;
    if (
      state.data.search.searchString !== null &&
      state.data.search.searchString !== undefined &&
      state.data.search.searchString.length >= 3
    ) {

      if (category === 0) {
        await Promise.allSettled([fetchPartResults(searchString, dispatch, getState()), fetchPlateResults(searchString, dispatch, getState()), fetchBuildResults(searchString, dispatch, getState())])
      }
      else if (category === 1) {
        await fetchPartResults(searchString, dispatch)
      }
      else if (category === 2) {
        await fetchPlateResults(searchString, dispatch)
      }
      else if (category === 3) {
        await fetchBuildResults(searchString, dispatch, getState())
      }
      else {
        return false;
      }
    }
  };
};

const onPartPlateSearch = (searchString, searchCategory) => { 
  return async (dispatch, getState) => {
    dispatch(isSearchEnabled(true))
    dispatch(onResetSearchResults())
    dispatch(onGetSearchString(searchString));
    dispatch(onGetSearchCategory(searchCategory));
    const state = getState() as IState;
    const category = state.data.info.homePageTabIndex;
    if (
      state.data.search.searchString !== null &&
      state.data.search.searchString !== undefined &&
      state.data.search.searchString.length >= 3
    ) {

      if (category === 1) {
        await fetchPartResults(searchString, dispatch)
      }
      else if (category === 2) {
        await fetchPlateResults(searchString, dispatch)
      }
      else {
        return false;
      }
    }
  };
}

export default onSearch;
export { onPartPlateSearch };
