import React, {useRef, useState} from "react";
import Modal from "react-modal";
import { Button } from "@material-ui/core";
import { useTable } from 'react-table'
import IRule, {ConfigType} from '../interfaces/IRule'
import { NotificationManager } from 'react-notifications';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { schemas, getListOfPosibleProperties, getSetOfPresentProperties, resolveSchemaIssues } from "../Schemas";

interface IProps {
    rule: IRule | null,
    onClose(): void,
    dispatch: any,
}

enum Type{
	String,
	Number,
	Boolean
}

function getFieldType( schema, propertyPath ){
	let type = Type.String;

	const path = propertyPath.split('/');
	let entry = schema;
	for( let subpath of path){
		entry = resolveSchemaIssues(entry, schemas as any);
		if( 'properties' in entry && `${subpath}` in entry.properties ){
			entry = entry.properties[subpath];
		}else{
			return type
		}
	}
	if( 'type' in entry ){
		switch( entry.type ){
			case 'boolean':
				type = Type.Boolean;
				break;
			case 'number':
				type = Type.Number;
				break;
	}
	return type;
}
}

const MatrixParamsEditingModal: React.FunctionComponent <IProps> = (props) => {
    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        }
      };

    const updatedValues = React.useMemo( () => {
      let values = [] as any;
      if( props.rule !== null ){
        if( props.rule.values.length >= 0){
          values = props.rule.values;
        } else {
          values = new Array( props.rule.rows * props.rule.columns );
        }
      }
      return values;
    }, [ props.rule?.values ]);
    
    const data = React.useMemo(() => parseRuleToData(props.rule), [props.rule?.values])

    const columns = React.useMemo(() => parseRuleToColumnDescriptor(props.rule), [props.rule?.values])
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ 
          columns, 
          data,
          defaultColumn: {
              Cell: ({
                value: initialValue,
                row: { index },
                column: { id }}) => {
                    const [value, setValue] = React.useState(initialValue);
                    
                    React.useEffect( () => {
                      if( props.rule?.columns && id !== "rowNumber"){
                        const arrayIndex = index * props.rule.columns + Number(id);
                        updatedValues[arrayIndex] = value;
                      }
                    }, [value]);

                    if( id === "rowNumber" ){
                        return <>{value}</>
                    }


                     const onChange = e => {
                       setValue(e.target.value)
                     } 
                     return <input value={value} onChange={onChange}/>
              }

          } })

    const nameInputRef = useRef<HTMLInputElement>(null);
    const configTypeSelectRef = useRef<HTMLSelectElement>(null);
    const propertyPathInputRef = useRef<HTMLInputElement>(null);


    const [options, setOptions] = useState(getAutocompleteOptions(props.rule?.propertyPath || ''));

     return <Modal  isOpen={props.rule !== null}
                    style={customStyles}
            >
            <div style={{display:"flex"}}>
                <TextField inputRef={nameInputRef} 
                            type="text" 
                            defaultValue={props.rule?.name || "" }
                            variant="outlined"
                            style={{width: 300, marginBottom: 15}}
                            label="Rule name"
                            />
            </div>

            <div style={{display:"flex"}}>
                <FormControl>
                <InputLabel >Rule type</InputLabel>
                  <Select
                    style={{width: 300}}
                    inputRef={configTypeSelectRef}
                    defaultValue={typeof(props.rule?.configType) !== 'undefined'? props.rule.configType : ConfigType.PartConfig}
                    variant="outlined"
                    label="Rule type"
                  >
                    <MenuItem selected={ConfigType.SlicerConfig === props.rule?.configType} value={ConfigType.SlicerConfig }>SlicerConfig</MenuItem>
                    <MenuItem selected={ConfigType.PartConfig === props.rule?.configType} value={ConfigType.PartConfig }>PartConfig</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{display:"flex"}}>
                <Autocomplete               
                    options={options}
                    freeSolo
                    disableCloseOnSelect
                    style={{ width: 300 }}
                    defaultValue={props.rule?.propertyPath || ""}
                    onChange={(e, value)=> {
                      setOptions(getAutocompleteOptions(value || ''));
                    }}
                    renderInput={(params) => 
                    <TextField {...params} 
                                margin='normal' 
                                label="Property path" 
                                variant="outlined"
                                inputRef={propertyPathInputRef}   
                                onChange={(e)=> {
                                  let newPath = e.target.value;
                                  setOptions(getAutocompleteOptions(newPath));
                                }}
                                              />}
      />
                 </div>


     <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th
                 {...column.getHeaderProps()}
                 style={{
                   borderBottom: 'solid 3px black',
                   background: 'aliceblue',
                   color: 'black',
                   fontWeight: 'bold',
                 }}
               >
                 {column.render('Header')}
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (
                   <td
                     {...cell.getCellProps()}
                     style={{
                       padding: '10px',
                       border: 'solid 1px gray',
                       background: 'papayawhip',
                     }}
                   >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
     </table>


            <div style={{ display:"flex", justifyContent:"space-around"}}>
                <Button variant="contained"
                    color="secondary"
                    onClick= {() => {
                        props.onClose()
                    }}
                >
                    Cancel
                </Button>

                <Button variant="contained"
                    color="primary"
                    onClick = {() => {
                      if(nameInputRef.current!.value === '') {
                        NotificationManager.error("Can't create rule because name is undefined");
                      } else {
						let propertyPath = propertyPathInputRef.current!.value;
   						const schema = schemas["PartConfig.json"];
						

						let currentType = getFieldType( schema, propertyPath );

						let typeCorrectValues = updatedValues.map( ( stringValue: string ) =>{
							switch (currentType){
								case Type.Number:
									return Number( stringValue );
								case Type.Boolean:
									return stringValue.toLowerCase() === 'true' ;
								default:
									return stringValue;
							}
						});
                        props.dispatch({
                            type: "updateRule",
                            cargo: {
                                updatedRule:{
                                    ...props.rule,
                                    name: nameInputRef.current!.value,
                                    configType: Number(configTypeSelectRef.current!.value),
                                    propertyPath: propertyPath,
                                    values: typeCorrectValues,
                                 },
                                UUID: props.rule!.UUID
                            }
                        })
                        props.onClose()
                      }
                    }}
                  
                >
                    Save
                </Button> 
            </div>
        </Modal>
}

function parseRuleToData (rule: IRule | null) {
  if (rule === null) {
    return [];
  }
  let data = [] as any;

    for(let row = 0; row < rule.rows; row++ ) {
      let rowData = {rowNumber: row + 1};
      for(let column = 0; column < rule.columns; column++) {
        const valueIndex = row * rule.columns + column; 
        if( valueIndex < rule.values.length) {
          rowData[column] = rule.values[valueIndex];
        } else {
          rowData[column] = '';
        }
      }
      data.push(rowData);
    }

 return data; 
}

function parseRuleToColumnDescriptor (rule: IRule | null) {
  if(rule === null) {
    return [];
  }
  let columnDescriptors = [
    {
      Header: 'Row/Column',
      accessor: 'rowNumber',
    }];


     for (let column = 0; column < rule.columns; column++) {
      const columnLetter = String.fromCharCode( 'A'.charCodeAt(0) + column );
      let columnDescriptor = {
        Header: columnLetter,
        accessor: column.toString(),
      }
      columnDescriptors.push(columnDescriptor);
     }

  return columnDescriptors;
}

function getAutocompleteOptions (path: string) {
  let options = [] as any;

   let pathArray = path.split('/');

   const refSchemas = schemas as any;
   const schemaName = "PartConfig";
   const schema = schemas[schemaName! + ".json"];
  
   options = getListOfPosibleProperties(pathArray, schema, refSchemas)

  pathArray.pop();
   let prefix = pathArray.join('/');
   if(prefix !== '') {
     prefix = prefix + '/'
   }
   
  options = options.map((el) => {
    let subOptionsPath = [...pathArray, el, '' ];
    let subOptions = getListOfPosibleProperties(subOptionsPath, schema, refSchemas)
    return prefix + el + (subOptions.length > 0 ?'/' : '');
  })

 return options;
}
export default MatrixParamsEditingModal;
