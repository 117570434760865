import { IState } from "../../../Interfaces";

const onSelectFolderReducer = (state: IState, action) => {
    const folderId = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.folders.selectedFolderIds = newState.data.folders.selectedFolderIds.concat(folderId);
    
    return newState;
};

export default onSelectFolderReducer;