import { IState } from "../../Interfaces";

const onSubscriptionsSetPrinters = (state: IState, action) => {
    const buildNameToBeCreated = action;

    const newState = { ...state } as IState;
    try {
        newState.subscriptions.printerUpdate = buildNameToBeCreated.printerUpdate;
        newState.subscriptions.printerCreate = buildNameToBeCreated.printerCreate;
    }
    catch (e){
        console.error(e)
    }

    return newState;
};

export default onSubscriptionsSetPrinters;