import API, { graphqlOperation } from '@aws-amplify/api';

import { updateBuild } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IMoveBuildModalStatus, IState } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";

const onMoveBuildInit = () => {
    return {
        type: ACTION_TYPES.BUILD.MOVE.INIT,
        payload: null,
    };
};

const onMoveBuildSuccess = (movedBuild) => {
    return {
        type: ACTION_TYPES.BUILD.MOVE.SUCCESS,
        payload: movedBuild,
    };
};

const onMoveBuildFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Move Build');
    console.error('Failure on Move Build - ', errorMessage);
    return {
        type: ACTION_TYPES.BUILD.MOVE.FAIL,
        payload: errorMessage,
    };
};

const onMoveBuildDone = () => {
    return {
        type: ACTION_TYPES.BUILD.MOVE.DONE,
        payload: null,
    };
};

const onMoveBuild = () => {
    return async (dispatch, getState) => {

        dispatch(onMoveBuildInit());

        const state = getState() as IState;

        const moveBuildModalStatus = state.data.modals.moveBuildModalStatus as IMoveBuildModalStatus;

        const isFolderNameEmpty = (!moveBuildModalStatus.inputText) as boolean;

        try {
            const state = getState() as IState;
            const buildToBeMoved = { ...state.data.builds.loadedBuilds.find(b => b.id === state.data.builds.highlightedBuildId) } as IBuild;
            const folderName = JSON.stringify({ name: moveBuildModalStatus.inputText }) as string;
            buildToBeMoved.folders = isFolderNameEmpty ? [] : [folderName] as string[];
            delete buildToBeMoved._deleted;
            delete buildToBeMoved._lastChangedAt;
            delete buildToBeMoved.createdAt;
            delete buildToBeMoved.updatedAt;
            const variables = {
                input: buildToBeMoved,
            };
            const result = await API.graphql(graphqlOperation(updateBuild, variables));
            const movedBuild = ((result as any)?.data?.updateBuild) as IBuild;
            dispatch(onMoveBuildSuccess(movedBuild));
            dispatch(onMoveBuildDone());
        } catch (graphqlError) {
            const errorMessage = `Move build failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            console.error(errorMessage);
            dispatch(onMoveBuildFail(errorMessage));
            dispatch(onMoveBuildDone());
        }
    };
};

export default onMoveBuild;