import { IState } from "../../../Interfaces";

const onDownloadStartReducer = (state: IState, action) => {
    const contentLength = action.payload as number;

    const newState = { ...state } as IState;
    newState.downloadInProgressContentLength = state.downloadInProgressContentLength + state.downloadInProgressContentLength + contentLength;

    return newState;
};

export default onDownloadStartReducer;