import { API, graphqlOperation } from "aws-amplify";
import { IState } from "../../Interfaces";
import ACTION_TYPES from "../../actionTypes";
import {getBuild} from "../../../graphql/queries";

const onHighlightBuildInit = () => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.INIT,
    payload: null,
  };
};

const onHighlightBuildSucess = (selectedBuild) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.SUCCESS,
      payload: selectedBuild,
  };
};
const onHighlightBuildFail = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.FAIL,
    payload: status,
  };
};
const onHighlightBuildDone = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.DONE,
    payload: status,
  };
};
const onHighlightBuildReset = () => {
    return {
        type: ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.RESET,
        payload: null,
    };
};

const onGetHighlightedBuild = (buildId) => {
  return async (dispatch, getState) => {
    const state = getState() as IState;
     // dispatch(onHighlightBuildReset())
    try {
        dispatch(onHighlightBuildInit())
      const getSelectedBuild = await API.graphql(
        graphqlOperation(getBuild, {
          id: buildId,
        })
      );
      const getSelectedBuildResult = (getSelectedBuild as any).data.getBuild;
        if (getSelectedBuildResult){
            dispatch(onHighlightBuildSucess(getSelectedBuildResult))
        }
    } catch (graphqlError) {
      const errorMessage = `${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
        dispatch(onHighlightBuildFail('failed'))
      console.error(errorMessage);
    }
      dispatch(onHighlightBuildDone('completed'))
  };

};

export default onGetHighlightedBuild;
