import ACTION_TYPES from '../../../actionTypes';
import { onAddPartToAddedParts, onClearAddedParts } from '../../Parts';

const onNavigateToPartReviewPageInit = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PART_REVIEW.INIT,
        payload: null,
    };
};

const onNavigateToPartReviewPageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PART_REVIEW.DONE,
        payload: null,
    };
};

const onNavigateToPartReviewPage = (ids, history) => {
    return async (dispatch, getState) => {
        dispatch(onNavigateToPartReviewPageInit());
        await dispatch(onClearAddedParts());
        await dispatch(onAddPartToAddedParts(ids));
        // history.push(`/dashboard/ReviewPage/Parts/${ids.partIds[0]}`);
        dispatch(onNavigateToPartReviewPageDone());
    };
};

export default onNavigateToPartReviewPage;