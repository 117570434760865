import React, { useEffect, useState } from "react";
import { connect, useStore } from "react-redux";
import { withRouter } from 'react-router-dom';
import { OverlayTrigger, Tooltip, } from 'react-bootstrap';
import { SortDirection } from '../../../Enums';
import { IConfiguration, IState } from '../../../Interfaces';
import { onSearchPartsTextChange, onSortPartsAsc, onSortPartsDesc, } from '../../../Actions/Parts';
import { onLoadParts } from "../../../Actions/Home";
import { onShowFolderView, onShowListView } from '../../../Actions/Prepare';
import { onCollapseAccordions, onExpandAccordions } from '../../../Actions/Folders';
import {processPart} from "../../../../MVPParts/MVPParts";
import { onNavigateToReviewPage } from "../../../Actions/Home";
import { getDefaultMaterialConfiguration, getDefaultMillConfiguration, getDefaultPartConfiguration, getDefaultPartSlicerConfiguration, getDefaultPrintConfiguration, getDefaultRecipeConfiguration } from '../../../DefaultConfigurations';

const Props = (state: IState, ownProps: any) => {
	const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;
	
	const props = {
		showFolderView: state.data.info.showFolderView as boolean,
		showListView: state.data.info.showListView as boolean,
		isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
		loadedParts: state.data.parts.loadedParts,
		loadedPartsCount: state.data.parts.loadedParts.length,
		noOfParts: state.data.parts.loadedParts.length as number,
		searchText: state.data.parts.searchText as string,
		sortDirection: state.data.parts.sortDirection as SortDirection,
		isSortDirectionDesc: state.data.parts.sortDirection === SortDirection.DESC,
		isSortDirectionAsc: state.data.parts.sortDirection === SortDirection.ASC,
		
		ids: {
            partIds: [...state.data.parts.selectedPartIds].filter(Boolean) as string[],
            partConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            partSlicerConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartSlicerConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
        },
	};

	return props;
};

const Actions = {
	onShowFolderView: onShowFolderView,
	onShowListView: onShowListView,
	onSearchPartsTextChange: onSearchPartsTextChange,
	onSortPartsAsc: onSortPartsAsc,
	onSortPartsDesc: onSortPartsDesc,
	onCollapseAccordions: onCollapseAccordions,
	onExpandAccordions: onExpandAccordions,
	onLoadParts: onLoadParts,
};

const PartsHeaderMethod = (props) => {
	const store = useStore();

	useEffect(() => {
		let oldProps = {...props};

		let listener = () => {
			let newProps = Props(store.getState(), {});
			let reloadProperties = [ "searchText", "sortDirection" ];

			reloadProperties.every((e) => {
				if (oldProps[e] == newProps[e]) {
					return true;
				}

				oldProps = {...newProps };
				props.onLoadParts(true);
				return false;
			});
		}
		return store.subscribe(listener);
	});

	function handleChange(event) {
		const value = event?.target?.value;
		if ((event?.key === 'Enter' && value.length >= 3) || value === "") {
			props.onSearchPartsTextChange(value);
		}
	}

  return (
    <>
      <header>
        <div className='menu-bar options'>
          {
            props.showFolderView ?
              (
                <>
                  <div className='check option'>
                    <img
                      className='icon uncheck'
                      alt=''
                      onClick={(e) => props.onShowFolderView()}
                    />
                    <span>View Folders</span>
                  </div>
                </>
              ) :
              (
                <>
                  <div className='check option'>
                    <img className='icon check'
                      alt=''
                      onClick={(e) => props.onShowFolderView()}
                    />
                    <span>View Folders</span>
                  </div>
                </>
              )
          }
          <div className='flex-space'></div>
          <>
            {
              props.isLoadingComplete ?
                (
                  <>
                    <div className='loading-status info'>
                      <img
                        className='icon loading-complete'
                        alt='loading complete'
                      />
                      <span>{`Loading complete, ${props.loadedPartsCount} parts loaded`}</span>
                      {/*<button onClick={async (ev)=> {*/}
                      {/*	for (const value of  props?.loadedParts){*/}
                      {/*		if (value.icon) {*/}
                      {/*			await processPart(value)*/}
                      {/*		}*/}
                      {/*	}*/}
                      {/*}}>Generate images</button>*/}
                    </div>
                  </>
                )
                :
                (
                  <>
                    <div className='loading-status info'>
                      <img
                        className='icon loading-in-progress'
                        alt='loading in progress'
                      />
                      <span>{`Loading parts in progress, ${props.loadedPartsCount} parts loaded`}</span>
                    </div>
                  </>
                )
            }
          </>
          <div className='search option'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id='search-box'>Search by part name</Tooltip>}
            >
              <span>
                <input
                  className='form-control search-box'
                  type='text'
                  placeholder='Search by name with min 3 characters'
                  disabled={!props.isLoadingComplete}
									defaultValue={props.searchText}
									onKeyUp={ handleChange }
                />
              </span>
            </OverlayTrigger>
          </div>
          <>
            {!props.isSortDirectionAsc ? (
              <>
                <div className='sort option'>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='icon-desc-sort'>
                        Parts shown in desc order
                      </Tooltip>
                    }
                  >
                    <img
                      className='icon desc-sort'
                      aria-disabled={!props.isLoadingComplete}
                      alt=''
                      onClick={() => props.onSortPartsAsc()}
                    />
                  </OverlayTrigger>
                </div>
              </>
            ) : null}
          </>
          <>
            {!props.isSortDirectionDesc ? (
              <>
                <div className='sort option'>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='icon-asc-sort'>
                        Parts shown in asc order
                      </Tooltip>
                    }
                  >
                    <img
                      className='icon asc-sort'
                      aria-disabled={!props.isLoadingComplete}
                      alt=''
                      onClick={() => props.onSortPartsDesc()}
                    />
                  </OverlayTrigger>
                </div>
              </>
            ) : null}
          </>
          <>
            {
              props.showListView ?
                (
                  <>
                    <div className='list-view option'>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id='icon-list-view'>
                            Card View
                          </Tooltip>
                        }
                      >
                        <img
                          className='icon list-view'
                          aria-disabled={!props.isLoadingComplete}
                          alt=''
                          onClick={() => props.onShowListView()}
                        />
                      </OverlayTrigger>
                    </div>
                  </>
                )
                :
                (
                  <>
                    <div className='card-view option'>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id='icon-card-view'>
                            List View
                          </Tooltip>
                        }
                      >
                        <img
                          className='icon card-view'
                          aria-disabled={!props.isLoadingComplete}
                          alt=''
                          onClick={() => props.onShowListView()}
                        />
                      </OverlayTrigger>
                    </div>
                  </>
                )
            }
          </>
          <>
            <div className='collapse-all option'>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='icon-collapse-all'>
                    Collapse all sections
                  </Tooltip>
                }
              >
                <img
                  className='icon collapse-all'
                  aria-disabled={!props.isLoadingComplete}
                  alt=''
                  onClick={() => props.onCollapseAccordions()}
                />
              </OverlayTrigger>
            </div>
          </>
          <>
            <div className='expand-all option'>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='icon-expand-all'>
                    Expand all sections
                  </Tooltip>
                }
              >
                <img
                  className='icon expand-all'
                  aria-disabled={!props.isLoadingComplete}
                  alt=''
                  onClick={() => props.onExpandAccordions()}
                />
              </OverlayTrigger>
            </div>
          </>
        </div>
      </header>
    </>
  );
}

const PartsHeader = withRouter(connect(Props, Actions)(PartsHeaderMethod));

export default PartsHeader;
