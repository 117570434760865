import { IState, IPlate } from "../../../Interfaces";

const onArchivePlateReducer = (state: IState, action) => {
    const archivedPlate = action.payload as IPlate;
    const indexToBeReplaced = state.data.plates.loadedPlates.findIndex(lp => lp.id === archivedPlate.id);
    const isPlateExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPlateExists) newState.data.plates.loadedPlates.splice(indexToBeReplaced, 1, archivedPlate);

    newState.data.plates.selectedPlateIds = newState.data.plates.selectedPlateIds.filter(id => id !== archivedPlate.id);
    
    return newState;
};

export default onArchivePlateReducer;