import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayBuilds, NewBuild, MoveBuild, DisplayBuildCardView, DisplayBuildListView, DisplayReviewBuilds } from '../..';
import { onShowRecentBuilds, onHideRecentBuilds, onShowAllBuilds, onHideAllBuilds, onShowArchivedBuilds, onHideArchivedBuilds } from '../../../Actions/Builds';
import { onUnselectFolder } from '../../../Actions/Home';
import { SortDirection } from '../../../Enums';
import { IDisplayFolder, IDisplayBuild, IState } from '../../../Interfaces';
import { getDisplayFolders, getDisplayBuilds } from '../../../Mappers';
import NewBuildSuccess from '../../NewBuildSuccess';

const Props = (state: IState, ownProps: any) => {

	const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

	const totalFolders = (
		(state.data.folders.sortDirection === SortDirection.ASC) ?
			displayFolders.reverse() :
			displayFolders
	) as IDisplayFolder[];

	const filteredFolders = (
		totalFolders.filter(df => df.isFiltered)
	) as IDisplayFolder[];

	const selectedFolders = (
		filteredFolders.filter(ff => ff.isSelected)
	) as IDisplayFolder[];

	const recentFolders = (filteredFolders.filter(ff => !ff.isSelected && ff.isRecent)) as IDisplayFolder[];

	const allFolders = (filteredFolders.filter(ff => !ff.isSelected && !ff.isArchived)) as IDisplayFolder[];

	const archivedFolders = (
		filteredFolders.filter(ff => !ff.isSelected && ff.isArchived)
	) as IDisplayFolder[];

	const hasSelectedFolders = Boolean(selectedFolders.length > 0) as boolean;

	const displayBuilds = getDisplayBuilds(state) as IDisplayBuild[];

	const totalBuilds = (
		(state.data.builds.sortDirection === SortDirection.ASC) ?
			displayBuilds.reverse() :
			displayBuilds
	) as IDisplayBuild[];

	const filteredBuilds = (
		totalBuilds.filter(db => db.isFiltered)
	) as IDisplayBuild[];

	const selectedBuilds = (
		filteredBuilds.filter(fb => fb.isSelected)
	) as IDisplayBuild[];

	const recentBuilds = (
		filteredBuilds.filter(fb => !fb.isSelected && !fb.isArchived && fb.isRecent)
	) as IDisplayBuild[];

	const allBuilds = (
		filteredBuilds.filter(fb => !fb.isSelected && !fb.isArchived)
	) as IDisplayBuild[];

	const archivedBuilds = (
		filteredBuilds.filter(fb => !fb.isSelected && fb.isArchived)
	) as IDisplayBuild[];

	const hasSelectedBuilds = Boolean(selectedBuilds.length > 0) as boolean;

	const showSelectedBuilds = Boolean(state.data.sections.recentBuilds.showItems) as boolean;
	const showRecentBuilds = Boolean(state.data.sections.recentBuilds.showItems) as boolean;
	const showAllBuilds = Boolean(state.data.sections.allBuilds.showItems) as boolean;
	const showArchivedBuilds = Boolean(state.data.sections.archivedBuilds.showItems) as boolean;

	const props = {
		showListView: state.data.info.showListView as boolean,
		filteredFolders: filteredFolders as IDisplayFolder[],
		selectedFolders: selectedFolders as IDisplayFolder[],
		recentFolders: recentFolders as IDisplayFolder[],
		allFolders: allFolders as IDisplayFolder[],
		archivedFolders: archivedFolders as IDisplayFolder[],
		hasSelectedFolders: hasSelectedFolders as Boolean,
		filteredBuilds: filteredBuilds as IDisplayBuild[],
		selectedBuilds: selectedBuilds as IDisplayBuild[],
		recentBuilds: recentBuilds as IDisplayBuild[],
		allBuilds: allBuilds as IDisplayBuild[],
		archivedBuilds: archivedBuilds as IDisplayBuild[],
		hasSelectedBuilds: hasSelectedBuilds as Boolean,
		showSelectedBuilds: showSelectedBuilds as Boolean,
		showRecentBuilds: showRecentBuilds as Boolean,
		showAllBuilds: showAllBuilds as Boolean,
		showArchivedBuilds: showArchivedBuilds as Boolean,
		loadedBuilds: state.data.builds.loadedBuilds
	};

	return props;
};

const Actions = {
	onUnselectFolder: onUnselectFolder,
	onShowRecentBuilds: onShowRecentBuilds,
	onHideRecentBuilds: onHideRecentBuilds,
	onShowAllBuilds: onShowAllBuilds,
	onHideAllBuilds: onHideAllBuilds,
	onShowArchivedBuilds: onShowArchivedBuilds,
	onHideArchivedBuilds: onHideArchivedBuilds,
};

class Component extends React.Component<any, any> {
	// componentWillMount() {
	// 	this.props.history.push(`/dashboard/ReviewPage/Builds`);
	// }

	public buildListView() {
		return (
			<>
				<dl className='grid-view'>
					<dd>
						<>
							<div className="display-list">
								<div className="display-list-row">
									<div className="display-list-header">
										<label>Type</label>
									</div>
									<div className="display-list-header">
										<label>Name</label>
									</div>
									<div className="display-list-header">
										<label>Build Date</label>
									</div>
									<div className="display-list-header">
										<label>Owner</label>
									</div>
									<div className="display-list-header">
										<label>Status</label>
									</div>
									<div className="display-list-header">
										<label>Folder</label>
									</div>
									<div className="display-list-header">
										<label>Actions</label>
									</div>
								</div>
							</div>
						</>
						<>
							{
								this.props.filteredBuilds.map((fb, i) => {
									return (
										<>
											<DisplayBuildListView
												{...{
													key: i,
													index: i,
													displayBuild: fb,
													isSelected: this.props.isSelected,
													onUnselect: this.props.onUnselect,
													onSelect: this.props.onSelect,
												}}
											/>
										</>
									)
								})
							}
						</>
					</dd>
				</dl>
			</>
		)
	}

	public buildCardView() {
		return (
			<>
				<>
					<DisplayReviewBuilds
						{...{
							label: 'Selected Builds',
							displayFolders: [],
							displayBuilds: this.props.selectedBuilds,
							showOptions: true,
							showItems: this.props.showSelectedBuilds,
							onShowItems: this.props.onShowRecentBuilds,
							onHideItems: this.props.onHideRecentBuilds,
						}}
					/>
				</>
				<>
					{
						this.props.hasSelectedFolders ?
							this.props.selectedFolders.map(selectedFolder => this.selectedFolderView(selectedFolder)) :
							this.buildsView()
					}
				</>
			</>
		)
	}

	public selectedFolderView(selectedFolder) {
		return (
			<>
				<Breadcrumb>

					<Breadcrumb.Item
						onClick={() => this.props.onUnselectFolder(selectedFolder["id"])}
					>
						Builds
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{selectedFolder["name"]}
					</Breadcrumb.Item>
				</Breadcrumb>
				<dl className={`${this.props.showListView ? "grid-view" : ""}`}>
					<dd>
						{
							this.props.showListView ?
								(
									<>
										<div className="display-list">
											<div className="display-list-row">
												<div className="display-list-header">
													<label>Type</label>
												</div>
												<div className="display-list-header">
													<label>Name</label>
												</div>
												<div className="display-list-header">
													<label>Build Date</label>
												</div>
												<div className="display-list-header">
													<label>Owner</label>
												</div>
												<div className="display-list-header">
													<label>Status</label>
												</div>
												<div className="display-list-header">
													<label>Folder</label>
												</div>
												<div className="display-list-header">
													<label>Actions</label>
												</div>
											</div>
										</div>
									</>
								)
								:
								(
									null
								)
						}
						{
							selectedFolder["displayBuilds"]
								.filter(db => db.folders.some(f => f === selectedFolder["name"]))
								.map((db, i) => this.props.showListView ? <DisplayBuildListView key={i} displayBuild={db} /> : <DisplayBuildCardView key={i} displayBuild={db} />)
						}
					</dd>
				</dl>
			</>
		)
	}

	public buildsView() {
		return (
			<>
				{/* <DisplayReviewBuilds
					{...{
						label: 'Recently Created Builds',
						displayFolders: this.props.recentFolders,
						displayBuilds: this.props.recentBuilds,
						showOptions: false,
						showItems: this.props.showRecentBuilds,
						onShowItems: this.props.onShowRecentBuilds,
						onHideItems: this.props.onHideRecentBuilds,
					}}
				/> */}
				<DisplayReviewBuilds
					{...{
						label: 'All Builds',
						displayFolders: this.props.allFolders,
						displayBuilds: this.props.allBuilds,
						showOptions: false,
						showItems: this.props.showAllBuilds,
						onShowItems: this.props.onShowAllBuilds,
						onHideItems: this.props.onHideAllBuilds,
					}}
				/>
				{/* <DisplayReviewBuilds
					{...{
						label: 'Archived Builds ',
						displayFolders: this.props.archivedFolders,
						displayBuilds: this.props.archivedBuilds,
						showOptions: false,
						showItems: this.props.showArchivedBuilds,
						onShowItems: this.props.onShowArchivedBuilds,
						onHideItems: this.props.onHideArchivedBuilds,
					}}
				/> */}
			</>
		)
	}

	public render() {
		return (
			<>
				{
					// this.props.showListView ?
					// 	this.buildListView() :
						this.buildCardView()
				}
				<NewBuild />
				<MoveBuild />
				<NewBuildSuccess />
			</>
		);
	}
}

const ReviewBuildsSection = withRouter(connect(Props, Actions)(Component));

export default ReviewBuildsSection;
