import React, { useEffect, useState, FunctionComponent } from "react";

const PartSelector: FunctionComponent<any> = (props: { parts: any[], title: string, onPartChanged: any }) => {

    const [selectedPart, setSelectedPart] = useState<any>(null);

    const onPartChanged = part => {
        setSelectedPart(part);
        props.onPartChanged(part);
    };

    useEffect(() => { }, []);

    return (<div className="bp-l-b-e btstrp dropdown">
        <div className='bp-l-b-e-h'>
            <label className='p-14 c-292929'>{props.title || 'Config'}</label>
        </div>
        <div className="btn-group bp-l-b-e-dd">
            <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {selectedPart ? selectedPart['customLabel'] : `Select the ${props.title || 'Part'}`}
            </button>
            <button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
            <div className="dropdown-menu">
                {
                    props.parts.map(part => <div id={part.id} className="dropdown-item" onClick={() => { onPartChanged(part) }}> <label className='p-14 c-292929'>{part.customLabel}</label> </div>)
                }
            </div>
        </div>
    </div>);
};

export default PartSelector;