import { IState } from '../../../Interfaces';

const onAddConfigurationOpenDialogReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.modals.addConfigurationModalStatus = {
        ...newState.data.modals.addConfigurationModalStatus,
        inputText: '',
        configurationType: '',
        data: {
            id: 'newconfiguration',
            creator: state.creator,
            created_at: new Date().toISOString(),
            modifier: state.creator,
            modified_at: new Date().toISOString(),
        },
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onAddConfigurationOpenDialogReducer;