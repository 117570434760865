import API, { graphqlOperation } from '@aws-amplify/api';

import { updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPlate, IRenamePlateModalStatus, IState } from '../../../Interfaces';
import { onDisplayLoader, onResetSearch } from '../../Search';
import { onNavigateToHomePage } from '../../Home';

import { NotificationManager } from "react-notifications";
const onRenamePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.RENAME.INIT,
        payload: null,
    };
};

const onRenamePlateSuccess = (renamedPlate) => {
    return {
        type: ACTION_TYPES.PLATE.RENAME.SUCCESS,
        payload: renamedPlate,
    };
};

const onRenamePlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Rename Plate');
    console.error('Failure on Rename Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.RENAME.FAIL,
        payload: errorMessage,
    };
};

const onRenamePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.RENAME.DONE,
        payload: null,
    };
};

const onRenamePlate = (history) => {
    return async (dispatch, getState) => {

        dispatch(onRenamePlateInit());

        const state = getState() as IState;

        const renamePlateModalStatus = state.data.modals.renamePlateModalStatus as IRenamePlateModalStatus;

        const isPlateNameEmpty = (!renamePlateModalStatus.inputText) as boolean;

        const isPlateNameExists = !state.data.search.isSearchEnabled ? (state.data.plates.loadedPlates.some(p => p.name === renamePlateModalStatus.inputText) as boolean
        ) : (state.data.search.highlightedPlateData.name === renamePlateModalStatus.inputText) as boolean

       

        if (isPlateNameEmpty) {
            const errorMessage = 'Plate Name is mandatory.' as string;
            dispatch(onRenamePlateFail(errorMessage));
            dispatch(onRenamePlateDone());
            return;
        }
        if (isPlateNameExists) {
            const errorMessage = 'Plate Name already exists.' as string;
            dispatch(onRenamePlateFail(errorMessage));
            dispatch(onRenamePlateDone());
            return;
        }

        try {
            const state = getState() as IState;
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(true))
            }

        //    const plateToBeRenamed = { ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId) } as IPlate;

            const plateToBeRenamed = !state.data.search.isSearchEnabled ? {...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId)} as IPlate
            : state.data.search.highlightedPlateData as IPlate


            plateToBeRenamed.name = renamePlateModalStatus.inputText;
            plateToBeRenamed.plate_search_string = renamePlateModalStatus.inputText;
            delete plateToBeRenamed._deleted;
            delete plateToBeRenamed._lastChangedAt;
            delete plateToBeRenamed.createdAt;
            delete plateToBeRenamed.updatedAt;
            plateToBeRenamed.modified_by = state.creator as string;
            plateToBeRenamed.modified_at = new Date().toISOString();
            const variables = {
                input: plateToBeRenamed,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const renamedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            dispatch(onRenamePlateSuccess(renamedPlate));
            dispatch(onRenamePlateDone());
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))
                dispatch(onNavigateToHomePage(history))
                dispatch(onResetSearch())
            }
        } catch (graphqlError) {
            const errorMessage = `Rename plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onDisplayLoader(false))
            dispatch(onRenamePlateFail(errorMessage));
            dispatch(onRenamePlateDone());
        }
    };
};

export default onRenamePlate;