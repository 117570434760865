import { IState } from '../../../Interfaces';

const onItemActionReducer = (state: IState, action) => {
    const status = action.payload as boolean;
    
    const newState = { ...state } as IState;
    newState.data.folders.isFolderItemActionComplete = status;
    
    return newState;
};

export default onItemActionReducer;