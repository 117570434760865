import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	WorkspaceSection,
	PlateConfiguration,
	PlateParts,
	AddedParts,
	LoadedParts,
	PartsHeaderMinified,
} from "../Components";
import { onEditPlatePageTabChange, onSelectPlateConfiguration } from "../Actions/Plates";
import {
	IState,
	IConfiguration,
	IAddedPart,
	IDisplayPart,
} from "../Interfaces";
import {
	getAddedParts,
	getConfigurationsBasedOnType,
	getDisplayParts,
} from "../Mappers";
import { EditPlatePhase, SortDirection } from "../Enums";
import { onClearAddedParts } from "../Actions/Parts";
import { onNavigateToHomePage } from "../Actions/Home";

const Props = (state: IState, ownProps: any) => {
	const addedParts = getAddedParts(state) as IAddedPart[];
	const displayParts = getDisplayParts(state) as IDisplayPart[];
	const totalParts =
		state.data.parts.sortDirection === SortDirection.ASC
			? displayParts.reverse()
			: (displayParts as IDisplayPart[]);
	const filteredParts = totalParts.filter(dp => dp.isFiltered) as IDisplayPart[];

	const printConfigurations = getConfigurationsBasedOnType(
		state,
		"printConfig"
	) as IConfiguration[];
	const millConfigurations = getConfigurationsBasedOnType(
		state,
		"millConfig"
	) as IConfiguration[];
	const recipeConfigurations = getConfigurationsBasedOnType(
		state,
		"recipe"
	) as IConfiguration[];
	const materialConfigurations = getConfigurationsBasedOnType(
		state,
		"material"
	) as IConfiguration[];

	const props = {
		plateName: state.data.plates.highlightedPlateId as string,
		selectedTabIndex: state.data.info.editPlatePageTabIndex as EditPlatePhase,

		addedParts: addedParts as IAddedPart[],
		filteredParts: filteredParts as IDisplayPart[],

		selectedConfigurations: state.data.workspace.selectedConfigurations || [] as IConfiguration[],
		printConfigurations: printConfigurations as IConfiguration[],
		millConfigurations: millConfigurations as IConfiguration[],
		recipeConfigurations: recipeConfigurations as IConfiguration[],
		materialConfigurations: materialConfigurations as IConfiguration[],
	};

	return props;
};

const Actions = {
	onEditPlatePageTabChange: onEditPlatePageTabChange,
	onSelectPlateConfiguration: onSelectPlateConfiguration,
	onClearAddedParts: onClearAddedParts,
	onNavigateToHomePage: onNavigateToHomePage,
};

class Component extends React.Component<any, any> {

	componentDidMount() {
		this.props.selectedConfigurations.forEach(selectedConfiguration => {
			this.props.onSelectPlateConfiguration(selectedConfiguration?.id);
		});
	}

	componentWillUnmount() {
		this.props.onClearAddedParts();
	}

	public render() {
		return (
			<>
				<section className="plates page">
					<header>
						<div className="options left-justify">
							<div className="back option">
								<img
									className="icon back"
									alt="back"
									onClick={() =>
										this.props.onNavigateToHomePage(this.props.history)
									}
								/>
							</div>
							<div className="plate-status info">
								<label>Review Plate</label>
								{this.props.plateName && (
									<label className="plate-name">
										{this.props.plateName}
									</label>
								)}
							</div>
						</div>
					</header>
					<section className="plate view">
					<div className="left-menu">
							<header>
								<div className="tab-bar options left-justify">
									<div
										className={`tab ${this.props.selectedTabIndex === EditPlatePhase.PARTS
											? "active"
											: ""
											}`}
										onClick={() => {
											this.props.onEditPlatePageTabChange(EditPlatePhase.PARTS);
										}}
									>
										<label>Parts</label>
									</div>
									<div
										className={`tab ${this.props.selectedTabIndex ===
											EditPlatePhase.CONFIGURATIONS
											? "active"
											: ""
											}`}
										onClick={() => {
											this.props.onEditPlatePageTabChange(
												EditPlatePhase.CONFIGURATIONS
											);
										}}
									>
										<label>Configurations</label>
									</div>
								</div>
							</header>
							<div className="display-parts">
								{this.props.selectedTabIndex === EditPlatePhase.PARTS ? (
									<>
										<PartsHeaderMinified />
										<AddedParts
											{...{
												label: "Added Parts",
												addedParts: this.props.addedParts,
												showOptions: true,
											}}
										/>
										<LoadedParts
											{...{
												label: "Loaded Parts",
												loadedParts: this.props.filteredParts,
											}}
										/>
									</>
								) : null}

								<>
									{this.props.selectedTabIndex ===
										EditPlatePhase.CONFIGURATIONS ? (
										<>
											<PlateConfiguration
												{...{
													label: "Printer",
													loadedConfigurations: this.props.printConfigurations,
													selectedConfiguration: this.props.selectedConfigurations.find(val => val.type === "printConfig")
												}}
											/>
											<PlateConfiguration
												{...{
													label: "Subtractive Strategy",
													loadedConfigurations: this.props.millConfigurations,
													selectedConfiguration: this.props.selectedConfigurations.find(val => val.type === "millConfig")
												}}
											/>
											<PlateConfiguration
												{...{
													label: "Drying Strategy",
													loadedConfigurations: this.props.recipeConfigurations,
													selectedConfiguration: this.props.selectedConfigurations.find(val => val.type === "recipe")
												}}
											/>
											<PlateConfiguration
												{...{
													label: "Material + Scaling",
													loadedConfigurations:
														this.props.materialConfigurations,
													selectedConfiguration: this.props.selectedConfigurations.find(val => val.type === "material")
												}}
											/>
											<PlateParts
												{...{
													label: "Part Selection",
													addedParts: this.props.addedParts,
												}}
											/>
										</>
									) : null}
								</>
							</div>
						</div>
						<div className="right-view">
							<WorkspaceSection />
						</div>
					</section>
				</section>
			</>
		);
	}
}

const PlateReviewPage = withRouter(
	connect(Props, Actions)(Component)
);

export default PlateReviewPage;
