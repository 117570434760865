import React, { useEffect, useState } from "react";

import { PieChart, Pie, Cell, Tooltip } from "recharts";
import "./MVPAnalyticsDashboard.scss";
import API, { graphqlOperation } from "@aws-amplify/api";
import { useHistory } from "react-router-dom";
import JSZip from "jszip";
import { createPrinter } from "../graphql/mutations";
import { listPrinters } from "../graphql/queries";
import { NotificationManager } from "react-notifications";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MenuItem, Select, Tooltip as MUIToolTip } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
//import Button from '@material-ui/core/Button';

import LoadingComponent from "../LoadingComponent/LoadingComponent";
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import TextField from '@mui/material/TextField';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const MVPAnalyticsDashboard: React.FunctionComponent = (props) => {
  const [recentElements, setRecentElements] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>("");
  const [grafanaName, setGrafanaName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [printDataList, setPrintDataList] = useState<any>({});
  const [openP, setOpenP] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any[]>([1, 2]);
  const [allZero, setallZero] = useState<boolean>(false);
  const [selectedPrinter, setSelectedPrinter] = useState<string>("");
  const [selectedPrinterObj, setSelectedPrinterObj] = useState<any>({});
  const [stats, setStats] = useState<any>({});
  const [printerData, setPrinterData] = useState<boolean>(false);
  const currentDate = new Date();
  const [selectedSDate, setSelectedSDate] = React.useState(
    new Date(currentDate.setDate(currentDate.getDate() - 8))
  );
  const [selectedEDate, setSelectedEDate] = React.useState(
    new Date(currentDate.setDate(currentDate.getDate() + 7))
  );

  const classes = useStyles();

  const history = useHistory();
  let zip = new JSZip();

  const routeChange = (newPath: string) => {
    history.push(newPath);
  };
  const goTolist = (buildItem) => {
    if (printDataList[buildItem.id]) {
      setSelectedPrinter(printDataList[buildItem.id]);
      setOpenP(true);
    }
  };
  const handleDialogCloseP = () => {
    setOpenP(false);
  };

  const goToBulidReview = (buildItem) => {
    // let selectedPartt = printDataList[buildItem.id][0]

    history.push({
      pathname: "/dashboard/printReview",
      state: {
        selectedBuild: buildItem,
        selectedPart: printDataList[buildItem.id],
      },
    });
  };
  const handleSDateChange = (date) => {
    setSelectedSDate(date);
  };
  const handleEDateChange = (date) => {
    setSelectedEDate(date);
  };
  const get_current_list = async () => {
    setLoading(true);
    let requestResult = await API.graphql(
      graphqlOperation(listPrinters, { limit: 100 })
    );
    console.log("MVPBuildReview ~~~requestResult ~~~~", requestResult);
    let array = (requestResult as any).data.listPrinters.items;

    setRecentElements(array);

    setLoading(false);
  };
  const getFiles = async (printerId) => {
    setLoading(true);
    let files = await API.get(
      "lavaRestApi",
      `/queue/printers/${printerId}`,
      {}
    );

    setLoading(false);
    return files;
  };

  const getPrinterStats = async () => {
    if (selectedPrinter && selectedSDate && selectedEDate) {
      setLoading(true);
      try {
        const currentDate = new Date();

        const startT = selectedSDate;
        const stopT = selectedEDate;
        if (selectedPrinter !== "") {
          const response = await API.get("lavaRestApi", "/printers/stats", {
            // OPTIONAL
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {
              bucket: selectedPrinter,
              start: startT.toISOString().slice(0, 11) + "00:00:00.000Z",
              stop: stopT.toISOString().slice(0, 11) + "23:59:59.999Z",
            },
          });
          // {"Alarm":0,"Running":0.03908895502645503,"Stopped":0.006268187830687831,"Down":0.9546428571428571,"Interval":604800}
          let status = response.data;
          console.log("getPrinterStats ~~~status ~~~~", status);
          console.log("status" + status.stoppedTotalTime);     
          setSelectedPrinterObj(recentElements?.find((el) => el.GrafanaBundle.toLowerCase() === selectedPrinter?.toLowerCase()))
          setChartData([
            { name: "Running", value: status.utilization },
            { name: "Stopped", value: status.stoppedTotalTime },
            { name: "Offline", value: status.offlineTime },
            { name: "Alarm", value: status.alarmTotalTime },
          ]);
          setPrinterData(true);

          if (status.utilization == 0 && status.stoppedTotalTime == 0 && status.offlineTime == 0 && status.alarmTotalTime == 0) {
            setallZero(true);
            setChartData([
              { name: "Running", value: 0 },
              { name: "Stopped", value: 0 },
              { name: "Offline", value: 100 },
              { name: "Alarm", value: 0 },
            ]);
          }
          console.log("char dataaaaa", chartData);


          setLoading(false);



          if (status.error) {
            console.error(`Printer stats error: ${status.error}`);
            NotificationManager.info(`Printer stats error: ${status.error}`);
            setStats({
              Alarm: 0,
              Running: 0,
              Stopped: 0,
              Down: 0,
              Interval: 0,
            });
            setLoading(false);
            setPrinterData(false);
          } else {
            setStats(status);
            console.log("STATS" + stats.alarmCount);
          }
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    } else {
      setPrinterData(false);
    }
  };

  const optionValueChange = (e, caseType) => {
    get_current_list();
    //  console.log('MVPAnalyticsDashboard~optionValueChange~~', e.target.value);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  const addPrinter = () => {
    setOpen(true);
  };
  const aaddPrint = async (ev) => {
    ev.stopPropagation();
    let dt = new Date() + "";
    let iid = Date.parse(dt);
    const data = {
      id: `bm-${iid}`,
      name: currentName,
      GrafanaBundle: grafanaName,
    };

    try {
      let result = await API.graphql(
        graphqlOperation(createPrinter, { input: data })
      );
      console.log(result);
      LoadMachine();
    } catch (e) {
      console.log(e);
    }
  };
  const LoadMachine = async () => {
    let requestResult = await API.graphql(
      graphqlOperation(listPrinters, { limit: 30 })
    );
    console.log("LoadMachine ~~~requestResult ~~~~", requestResult);
  };
  const printIdVal = (id) => {
    let requestResult = printDataList
      ? printDataList[id]
        ? printDataList[id][0]
        : id
      : id;
    console.log("LoadMachine ~~~requestResult ~~~~", requestResult);
    return requestResult;
  };
  useEffect(() => {
    get_current_list();
  }, []);

  const today = new Date();
  const chartColors = ["#ff6900", "#374352", "#BFBCBC", "#d60707"];  
  function secondsToDhms(seconds) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24))
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)
    // console.log(d, h, m, s)
    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
    let hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hr, ") : ""
    let mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min ") : ""
    //  let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : ""
    return dDisplay + hDisplay + mDisplay
  }
  return (
    <div className="MVPAnalyticsDashboard">
      <div className="dashboard-header">
        <div className="actionSpace"></div>
      </div>
      <LoadingComponent visible={loading} />

      <div>
       
        <div>
          <div className="bp-l-b-e"></div>
          <div className="bp-l-b-e-v">
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Select Printer
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(ev) => {
                    ev.stopPropagation();
                    setSelectedPrinter(ev.target.value as string);
                  }}
                  value={selectedPrinter}
                >
                  <MenuItem value={""}>Not Selected</MenuItem>
                  {recentElements
                    ? recentElements.map((printer,idx) => {
                      return (
                        <MenuItem key={idx} value={printer.GrafanaBundle}>
                          {printer.name}
                        </MenuItem>
                      );
                    })
                    : null}
                </Select>
              </FormControl>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date-picker-inline"
                  label="Start Date"
                  value={selectedSDate}
                  onChange={handleSDateChange}
                  maxDate={today.setDate(today.getDate() - 1)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date-picker-inline"
                  label="End Date"
                  value={selectedEDate}
                  onChange={handleEDateChange}
                  maxDate={today.setDate(today.getDate())}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <Button
                className="btn-primary"
                onClick={() => getPrinterStats()}
              >
                Printer Status
              </Button>
            </div>
          </div>
          <div></div>
          <div></div>
        </div>



        {stats && (
          <div className="custom-card">
            <div className="card-container">
             
              {printerData ? (
                <>
                  <div className="summary-header">Summary Statistics</div>
                  {selectedPrinterObj &&
                  <div className="top-container">
                    <div className="printer-details">
                      <div className="e-c-card-f-e">
                        <div>
                          <div>
                            <div className="label-printer">Printer Status</div>
                              <div className="value-printer">{selectedPrinterObj.currentStatus ? selectedPrinterObj.currentStatus : 'No Data'}</div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="label-printer">Print Name</div>
                              <div className="value-printer value-printer-print-name">{selectedPrinterObj.currentPrintNameOnPrinter ? selectedPrinterObj.currentPrintNameOnPrinter : 'No Data'}</div>
                          </div>
                        </div>
                      </div>
                      <div className="e-c-card-f-e">
                        <div>
                          <div>
                            <div className="label-printer">Remaining Print Time</div>
                              <div className="value-printer">{selectedPrinterObj.remainingPrintTime ? selectedPrinterObj.remainingPrintTime === "0" ? "00:00:00 hrs": secondsToDhms(selectedPrinterObj.remainingPrintTime) : 'No Data'}</div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="label-printer">Remaining Paste</div>
                            <div className="value-printer"> {selectedPrinterObj.remainingPasteConsumption ? `${selectedPrinterObj.remainingPasteConsumption} mL` : 'No Data'}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div style={{display:'flex'}}>
                      <div className="chart-container">


                        <PieChart width={400} height={400}>
                          <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={chartData}
                            cx={200}
                            cy={200}
                            outerRadius={150}
                            fill="#8884d8"
                            strokeWidth={2}
                          >
                            {chartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={chartColors[index % chartColors.length]}
                              />
                            ))}
                          </Pie>

                          <Tooltip />
                        </PieChart>
                      </div>
                      <div className="legend-container">
                      <div className="legend">
                        <div className="display-status running-status"></div>
                        <label className="display-text ">Running</label>
                        <MUIToolTip
                          title="Printer is printing."
                          placement="top"
                          arrow
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#606060"
                            viewBox="0 0 24 24"
                            width="20px"
                            height="20px"
                          >
                            <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                          </svg>
                        </MUIToolTip>
                        <label className="display-percentage">
                          {stats.utilization
                            ? `${stats.utilization}%`
                            : stats.utilization === 0
                              ? "0%"
                              : ""}
                        </label>
                      </div>

                      <div className="legend">
                        <div className="display-status stopped-status"></div>
                        <label className="display-text ">Stopped</label>
                        <MUIToolTip
                          title="Printer is ON but it’s not printing."
                          placement="top"
                          arrow
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#606060"
                            viewBox="0 0 24 24"
                            width="20px"
                            height="20px"
                          >
                            <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                          </svg>
                        </MUIToolTip>
                        <label className="display-percentage">
                          {stats.stoppedTotalTime
                            ? `${stats.stoppedTotalTime}%`
                            : stats.stoppedTotalTime === 0
                              ? "0%"
                              : ""}
                        </label>
                      </div>

                      <div className="legend">
                        <div className="display-status off-status"></div>
                        <label className="display-text ">Offline</label>
                        <MUIToolTip
                          title="Printer is not connected."
                          placement="top"
                          arrow
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#606060"
                            viewBox="0 0 24 24"
                            width="20px"
                            height="20px"
                          >
                            <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                          </svg>
                        </MUIToolTip>
                        <label className="display-percentage">
                          {stats.utilization == 0 && stats.stoppedTotalTime == 0 && stats.alarmTotalTime == 0 ? "100%" : `${stats.offlineTime}%`}
                        </label>
                      </div>
                      <div className="legend">
                        <div className="display-status alarm-status"></div>
                        <label className="display-text ">Alarm</label>
                        <MUIToolTip
                          title="Error in printer, user intervention required."
                          placement="top"
                          arrow
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#606060"
                            viewBox="0 0 24 24"
                            width="20px"
                            height="20px"
                          >
                            <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
                          </svg>
                        </MUIToolTip>
                        <label className="display-percentage">
                          {stats.alarmTotalTime
                            ? `${stats.alarmTotalTime}%`
                            : stats.alarmTotalTime === 0
                              ? "0%"
                              : ""}
                        </label>
                      </div>
                    </div>
                    </div>
                  </div>
}
                  <div className="bottom-container">
                   
                    <div className="stats">
                      <label className="label-header">MTBF</label>
                      <label className="label-value">

                        {parseInt(stats.MTBF)
                        }
                        :{Math.floor((parseFloat(stats.MTBF) - parseInt(stats.MTBF)) * 60)
                        }:
                        {Math.floor(((parseFloat(stats.MTBF) - parseInt(stats.MTBF)) * 60 - Math.floor((parseFloat(stats.MTBF) - parseInt(stats.MTBF)) * 60)) * 60)
                        } HRS 


                      </label>
                      <br></br>
                      <label>Mean time between failures.</label>
                    </div>
                    <div className="stats">
                      <label className="label-header">MTTR</label>
                      <label className="label-value">
                        {" "}
                        {parseInt(stats.MTTR)
                        }
                        :{Math.floor((parseFloat(stats.MTTR) - parseInt(stats.MTTR)) * 60)
                        }:
                        {Math.floor(((parseFloat(stats.MTTR) - parseInt(stats.MTTR)) * 60 - Math.floor((parseFloat(stats.MTTR) - parseInt(stats.MTTR)) * 60)) * 60)
                        } HRS
                      </label>
                      <br></br>
                      <label>Mean time to recover.</label>
                    </div>
                    <div className="summary-message">
                      {stats.alarmCount > 1
                        ? ""
                        : " No Failures during this date range"}
                    </div>
                  </div>
                </>
              ) : (
                <div className="error-msg">Please select a printer</div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        show={open}
        onHide={handleDialogClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="partsDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>New Printer</Modal.Title>
          <span className="close-btn"
            onClick={handleDialogClose}>
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="each-dialog-content">
            <div className="each-d-section">
              Enter the name for the new printer
            </div>
            <div className="each-d-section">
              <input
                className="f-w"
                type={"text"}
                value={currentName}
                onChange={(ev) => {
                  let v = ev.target.value;
                  setCurrentName(v);
                }}
              />
            </div>
            <div className="each-d-section">Enter the Grafana name</div>
            <div className="each-d-section">
              <input
                className="f-w"
                type={"text"}
                value={grafanaName}
                onChange={(ev) => {
                  let v = ev.target.value;
                  setGrafanaName(v);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="btn-primary"
            onClick={aaddPrint}
            autoFocus
          >
            Add Printer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MVPAnalyticsDashboard;
