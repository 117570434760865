import API, { graphqlOperation } from '@aws-amplify/api';

import { deleteFolders } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IFolder, IPart, IPlate, IState } from '../../../Interfaces';
import onUpdateBuildAfterDelete from './updateBuild';
import onUpdatePartAfterDelete from './updatePart';
import onUpdatePlateAfterDelete from './updatePlate';
import { NotificationManager } from "react-notifications";

const onDeleteFolderInit = () => {
    return {
        type: ACTION_TYPES.FOLDER.DELETE.INIT,
        payload: null,
    };
};

const onDeleteFolderSuccess = (deletedFolder: IFolder) => {
    return {
        type: ACTION_TYPES.FOLDER.DELETE.SUCCESS,
        payload: deletedFolder,
    };
};

const onDeleteFolderFail = (errorMessage: string) => {
    NotificationManager.error('Failure on deleting folder');
    console.error('Failure on deleting folder - ', errorMessage);
    return {
        type: ACTION_TYPES.FOLDER.DELETE.FAIL,
        payload: errorMessage,
    };
};


const onDeleteFolderDone = () => {
    return {
        type: ACTION_TYPES.FOLDER.DELETE.DONE,
        payload: null,
    };
};

const onDeleteFolder = (folderId) => {
    return async (dispatch, getState) => {
        dispatch(onDeleteFolderInit());
        try {
            const state = getState() as IState;
            const folderToBeDeleted = state.data.folders.loadedFolders.find(p => p.id === folderId) as IFolder;
            const variables = {
                input: {
                    id: folderToBeDeleted.id,
                    _version: folderToBeDeleted._version,
                },
            };
            const result = await API.graphql(graphqlOperation(deleteFolders, variables));
            const deletedFolder = ((result as any)?.data?.deleteFolders) as IFolder;
            dispatch(onDeleteFolderSuccess(deletedFolder));

            const partsToBeUpdated = state.data.parts.loadedParts.filter(lp => lp.folders && lp.folders.some(folder => JSON.parse(folder)['name'] === deletedFolder.id)) as IPart[];
            partsToBeUpdated.forEach(part => dispatch(onUpdatePartAfterDelete(part, deletedFolder)));

            const platesToBeUpdated = state.data.plates.loadedPlates.filter(lp => lp.folders && lp.folders.some(folder => JSON.parse(folder)['name'] === deletedFolder.id)) as IPlate[];
            platesToBeUpdated.forEach(plate => dispatch(onUpdatePlateAfterDelete(plate, deletedFolder)));
            
            const buildsToBeUpdated = state.data.builds.loadedBuilds.filter(lb => lb.folders && lb.folders.some(folder => JSON.parse(folder)['name'] === deletedFolder.id)) as IBuild[];
            buildsToBeUpdated.forEach(build => dispatch(onUpdateBuildAfterDelete(build, deletedFolder)));    
        }
        catch (graphqlError) {
            const errorMessage = `Delete folder failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onDeleteFolderFail(errorMessage));
        }
        dispatch(onDeleteFolderDone());
    };
};

export default onDeleteFolder;