import React from "react";
// import ReactLoading from "react-loading";
import "./LoadingComponent.css";

export interface ILoadingComponentProps {
  visible: boolean;
}

function LoadingComponent(props: ILoadingComponentProps) {
  if (props.visible) {
    return (
      <div className="loadingComponentBackground">
        <div className="loadingComponentContainer">
          {
          /* <ReactLoading
            type="cubes"
            color="#ff0000"
            width="100%"
            height="100%"
          /> */
          }
          <img src="/img/loader.svg" alt="loading" />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default LoadingComponent;
