import { IState } from "../../../Interfaces";

const onNewBuildOpenDialogReducer = (state: IState, action) => {
    
    const newState = { ...state } as IState;
    newState.data.modals.newBuildModalStatus = {
        ...newState.data.modals.newBuildModalStatus,
        inputText: '',
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onNewBuildOpenDialogReducer;