import { IState } from "../../../Interfaces";
import { getPartInfo } from '../../../Instances';

const onRemovePartFromAddedPartsReducer = (state: IState, action) => {
    const partIndex = action.payload as number;

    const newState = { ...state } as IState;
    newState.data.workspace.addedParts.splice(partIndex, 1);
    newState.data.workspace.state.build.parts.splice(partIndex, 1);
    newState.data.workspace.state.build.parts.forEach((p: any, i) => {
      p.customLabel = `Part ${i + 1}`;
    });
    newState.data.workspace.addedPartsSelectedPartConfigurations.splice(partIndex, 1);
    newState.data.workspace.addedPartsSelectedPartSlicerConfigurations.splice(partIndex, 1);
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    newState.data.workspace.addedPartsSelectedPartIndices =[0]
    
    return newState;
};

export default onRemovePartFromAddedPartsReducer;
