import { IState } from "../../../Interfaces";

const onNewPlateFailReducer = (state: IState, action) => {
    const errorMessage = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.modals.newPlateModalStatus = {
        ...newState.data.modals.newPlateModalStatus,
        hasError: true,
        errorMessage: errorMessage,
    };
    
    return newState;
};

export default onNewPlateFailReducer;