import ACTION_TYPES from "../../actionTypes";

const onResetSearchResults = () => {
    return {
        type: ACTION_TYPES.SEARCH.RESET_RESULTS,
        payload: null,
    };
};

const isSearchEnabled = (status) => {
    return {
        type: ACTION_TYPES.SEARCH.IS_SEARCH_ENABLED,
        payload: status,
    };
};

const onResetSearch = () => {
    return async (dispatch, getState) => {
        dispatch(onResetSearchResults())
        dispatch(isSearchEnabled(false))

    }
}

export default onResetSearch;