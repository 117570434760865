import { IState, IPart } from "../../../Interfaces";

const onUpdatePartReducer = (state: IState, action) => {
    const updatedPart = action.payload as IPart;
    const indexToBeReplaced = state.data.parts.loadedParts.findIndex(lp => lp.id === updatedPart.id);
    const isPartExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPartExists) newState.data.parts.loadedParts.splice(indexToBeReplaced, 1, updatedPart);

    newState.data.parts.selectedPartIds = newState.data.parts.selectedPartIds.filter(id => id !== updatedPart.id);

    return newState;
};

export default onUpdatePartReducer;