import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onUnarchiveParts, onArchiveParts, onDeleteParts, onUnselectParts } from '../../../Actions/Parts';
import { onDownloadParts } from '../../../Actions/Home';
import { IState } from '../../../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const props = {
        selectedPartIds: state.data.parts.selectedPartIds as string[],
    };

    return props;
};

const Actions = {
    onDownloadParts: onDownloadParts,
    onUnarchiveParts: onUnarchiveParts,
    onArchiveParts: onArchiveParts,
    onDeleteParts: onDeleteParts,
    onUnselectParts: onUnselectParts,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                {
                    (this.props.showOptions) ?
                        (
                            <>
                                <div className='options right-justify'>
                                    <div className='download option'>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='icon-download'>Download</Tooltip>} >
                                            <img className='icon download' alt='download' onClick={() => this.props.onDownloadParts(this.props.selectedPartIds)} />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='unarchive option'>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='icon-unarchive'>Unarchive</Tooltip>} >
                                            <img className='icon unarchive' alt='unarchive' onClick={() => this.props.onUnarchiveParts(this.props.selectedPartIds)} />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='archive option'>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='icon-archive'>Archive</Tooltip>} >
                                            <img className='icon archive' alt='archive' onClick={() => this.props.onArchiveParts(this.props.selectedPartIds)} />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='delete option'>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='icon-delete'>Delete</Tooltip>} >
                                            <img className='icon delete' alt='delete' onClick={() => this.props.onDeleteParts(this.props.selectedPartIds)} />
                                        </OverlayTrigger>
                                    </div>
                                    <div className='clear option'>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='icon-clear'>Clear</Tooltip>} >
                                            <img className='icon clear' alt='clear' onClick={() => this.props.onUnselectParts()} />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </>
                        ) :
                        (null)
                }
            </>
        );
    }
}

const DisplayPartsOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayPartsOptions;