import { IState, } from '../../../Interfaces';

const onAddPartNameChangeReducer = (state: IState, action) => {
    const partNameToBeAdded = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.addPartModalStatus = {
        ...newState.data.modals.addPartModalStatus,
        inputText: partNameToBeAdded,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onAddPartNameChangeReducer;