import React, { useEffect } from "react";
import { Auth, Storage } from "aws-amplify";
import "../css/App.css";
import { Storage as s3 } from "@aws-amplify/storage";
import saveFile from "../utils";
import { decorators, Treebeard } from "react-treebeard";
import Button from "react-bootstrap/Button";
// import { Button } from "@material-ui/core";
import JSZip from "jszip";
import "./BuildElementControl.css";
import Modal from "react-bootstrap/Modal";
import awsmobile from "../aws-exports";
import AWS from "aws-sdk";


class S3FolderProps{
    name: string = ""
    children?: S3FolderProps[]|undefined
    object?: any
    mesh?: any
    toggled?: boolean
    active?: boolean
    key_data: string = ""
    onclick?: any
    isOpened?: boolean
}


class S3ObjectTreeProps{
    path!: string
    prefix: string = ""
}


export const S3ObjectTree: React.FunctionComponent<S3ObjectTreeProps> = (props)=> {
    const [state, setState] = React.useState<S3FolderProps>({
        name: "root",
        key_data: props.path,
        isOpened: false
    });
    let zip = new JSZip();
    const handleFileClick = (val) => {
        s3.get(val, {download:true}).then((content) => {
            let file = val.replace(/^.*[\\\/]/, "")
            let to_save = [props.prefix, file].join('_')
            saveFile((content as any).Body, to_save)
        });
    }

    const processStateChange =(ev, node)=>{
        const recursiveSelect= (node, state)=>{
            node.children?.map((value) => {
                value.active = state;
                if(value?.children){
                    recursiveSelect(value, value.active)
                }
            })
            node['active'] = state
        }
        ev.stopPropagation()
        recursiveSelect(node, !node.active)
        setState({...state})
    }

    useEffect( () => {
        if (!props.path)
            return
        let newPath =  `public/${props.path}`
        function getListingS3(prefix) {
            return new Promise(async(resolve, reject) => {

                const credentials = await Auth.currentCredentials();
                const s3 = new AWS.S3({credentials: credentials});
                try {
                    let params: any = {
                        Bucket: awsmobile.aws_user_files_s3_bucket,
                        MaxKeys: 1000,
                        Prefix: prefix,
                        Delimiter: prefix
                    };
                    const allKeys: any[]= [];
                    const listAllKeys = () =>{
                        s3.listObjectsV2(params, function (err, data) {
                            if (err) {
                                reject(err)
                            } else {
                                const contents = data.Contents;
                                contents?.forEach(function (content) {
                                    allKeys.push(content.Key?.slice("public/".length));
                                });

                                if (data.IsTruncated) {
                                    params.ContinuationToken = data.NextContinuationToken;
                                    listAllKeys();
                                } else {
                                    resolve(allKeys);
                                }
                            }
                        });
                    }
                    listAllKeys();
                } catch (e) {
                    reject(e);
                }

            });
        }
        const promise  = getListingS3(newPath)
       promise.then(objectsList => {
            let folderStack: S3FolderProps []= [ {
                name: "root",
                key_data: props.path,
                toggled: true,
                children: []
            }]
           const o = objectsList as any[]
            for (let i = 0; i < o?.length; i++) {
                let key = o[i]
                let currentFolder = folderStack[folderStack.length - 1]

                while (key?.indexOf(folderStack[folderStack.length - 1].key_data) !== 0) {
                    currentFolder.children = currentFolder.children?.sort((a, b)=>{
                        if (a.children && b.children == undefined)
                            return -1;
                        if (b.children && a.children == undefined)
                            return 1
                        return a.name.localeCompare(b.name)
                            })
                    folderStack.pop()
                    currentFolder = folderStack[folderStack.length - 1]
                }

                let restKey  = key.substring(currentFolder.key_data.length);
                let splitKey = restKey.split('/')

                let currentKey = folderStack[folderStack.length - 1].key_data
                for (let i = 0; i < splitKey.length - 1; i++ ) {
                    currentKey += splitKey[i] + '/'
                    let newFolder: S3FolderProps = {
                        name: splitKey[i],
                        key_data: currentKey,
                        children: []
                    }
                    currentFolder.children?.push(newFolder)
                    folderStack.push(newFolder)
                    currentFolder = newFolder
                }
                if (splitKey[splitKey.length - 1].length > 0)
                {

                    let newFile: S3FolderProps = {
                        name: splitKey[splitKey.length - 1],
                        key_data: key.toString(),
                        onclick: handleFileClick
                    }
                    currentFolder.children?.push(newFile)
                }
                folderStack[0].children = folderStack[0].children?.sort((a, b)=>{
                    if (a.children && b.children == undefined)
                        return -1;
                    if (b.children && a.children == undefined)
                        return 1
                    return a.name.localeCompare(b.name)
                })
            }
            setState(folderStack[0])
        })
    }, [props.path])

    let GCodeTreeDecorator = decorators


    GCodeTreeDecorator.Container = (props) =>{
        const {
            style,
            decorators,
            terminal,
            onClick,
            node,
        } = props;
        return (
            <div style={style.link}>
                <span onClick={onClick}>
                    {
                        !terminal
                            ? React.createElement(decorators.Toggle, {
                                style: style.toggle,
                            })
                            : null
                    }
                </span>
                <span>
                    <decorators.Header node={node} style={style.header} />
                </span>
            </div>
        );
    }


    GCodeTreeDecorator.Header = (props) =>{
        const { node, style } = props;
        return (
            <div style={style.base}>
                <div style={style.title}>
                    <span>
                        {node.name}
                        <input type="checkbox" onChange={ ev => {processStateChange(ev, node)}} checked={!!node.active} />
                    </span>
                </div>
            </div>
        );
    }

    const downloadFiles = async ()=>{
        let promises: Promise<{name: string; data: any}>[]= [];
        const recursiveAdder = (node)=>{
            if (node.children){
                node.children.map( value => {
                    recursiveAdder(value)
                    })
            }
            else{
                if (!node.active)
                    return
                promises.push( new Promise((resolve, reject)=> {
                    Storage.get(node.key_data, {download: true}).then( result => {
                            resolve({name: node.key_data, data:(result as any).Body})
                        })
                        .catch(reason=>{
                            reject(reason)
                        }
                    )
                }))
            }
        }
        recursiveAdder(state)
        const results = await Promise.all(promises)

        results.map( ( val) => {
            const filePath = val.name.slice(props.path.length)
            zip.file( filePath, val.data)
        })
        zip.generateAsync({type: "blob"}).then((content: any) => {
            // consider using FileSaver.js
            const name = (props.prefix.split('/').shift() as string) + '.zip'
            saveFile(
                content,
                name,
                "application/zip"
            );
            zip = new JSZip()
        });
    }

    return !state.isOpened?
        <>
            <Button className="btn btn-secondary" onClick={() => {
                setState({...state, isOpened: true})
            }}>Download</Button>
        </>:
        <Modal show={state.isOpened} centered={true} size={"lg"} onHide={()=>{setState({...state, isOpened: false})}}>
            <Modal.Header closeButton>
                <Modal.Title>Download</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
            <Treebeard
                data={state}
                onToggle={(node: any, toggled: boolean) => {
                    if (node.children) {
                        node.toggled = toggled;
                    }
                    setState({ ...state })
                }}
                decorators={GCodeTreeDecorator}
            />
            <Modal.Footer className="justify-content-center">
                <Button className="btn btn-secondary" onClick={() => {setState({...state, isOpened: false})}}>Close</Button>
                <Button className="btn btn-primary" onClick={downloadFiles}>Download</Button>
            </Modal.Footer>
        {/*<div className="downloads" style={{padding: "10px" }}>*/}
        {/*    <Button className="downloads-header" onClick={downloadFiles}>Download</Button>*/}
        {/*</div>*/}
    </Modal>
}
