import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AddedPart, AddedPartsOptions } from '.';
import { IState, IAddedPart } from '../Interfaces';
import { getAddedParts } from '../Mappers';

const Props = (state: IState, ownProps: any) => {

    const addedParts = getAddedParts(state) as IAddedPart[];

    const props = {
        addedParts: addedParts as IAddedPart[],
    };

    return props;
};

const Actions = {

};

class Component extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            showItems: true,
        };
    }

    public onToggle = () => {
        this.setState({
            showItems: !this.state.showItems,
        });
    };

    public render() {
        return (
            <>
                {
                    (this.props.addedParts && this.props.addedParts.length > 0) && (this.props.addedParts.filter(x => x === null).length != this.props.addedParts.length) ?
                        (
                            <>
                                <dl className="m-0">
                                    <dt>
                                        <div className='options left-justify'>
                                            <>
                                                {
                                                    (!this.state.showItems) ?
                                                        (
                                                            <div className='collapse option'>
                                                                <img className='icon right-arrow'
                                                                    alt=''
                                                                    onClick={() => this.onToggle()} />
                                                                <label>{this.props.label}</label>
                                                                <span>{` listing ${this.props.addedParts.length - this.props.addedParts.filter(x => x === null).length} parts`}</span>
                                                            </div>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                            <>
                                                {
                                                    (this.state.showItems) ?
                                                        (
                                                            <div className='expand option'>
                                                                <img className='icon down-arrow'
                                                                    alt=''
                                                                    onClick={() => this.onToggle()} />
                                                                <label>{this.props.label}</label>
                                                                <span>{` listing ${this.props.addedParts.length - this.props.addedParts.filter(x => x === null).length} parts`}</span>
                                                            </div>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                        </div>
                                        <AddedPartsOptions showOptions={this.props.showOptions} />
                                    </dt>
                                    <dd className='ml-0'>
                                        {
                                            (this.state.showItems) ?
                                                (
                                                    this.props.addedParts.map((ap, i) => (<AddedPart key={i} addedPart={ap} />))
                                                ) :
                                                (null)
                                        }
                                    </dd>
                                </dl>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const AddedParts = withRouter(connect(Props, Actions)(Component));

export default AddedParts;