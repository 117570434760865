import { IBuild, IState } from '../../../Interfaces';

const onLoadBuildsUpdatedReducer = (state: IState, action) => {
    const updated = action.payload;
    const newState = { ...state } as IState;

    if (!updated.moho_version) {
      delete updated.moho_version;
    }
    newState.data.builds.loadedBuilds = state.data.builds.loadedBuilds.
                                          map(val => val.id === updated.id ? {...val, ...updated} : val).
                                            filter(a => !a._deleted);

    newState.data.builds.loadedBuilds.forEach((lb, i) => {
        switch (lb.current_status) {
            case 'fail':
                newState.data.builds.loadedBuilds[i]['current_status'] = 'failed';
                break;
            default:
                break;
        }
    });

    return newState;
};

export default onLoadBuildsUpdatedReducer;
