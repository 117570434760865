import API, { graphqlOperation } from "@aws-amplify/api";

import { createParts } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import { ICopyPartModalStatus, IPart, IState } from "../../../Interfaces";
import { onNavigateToHomePage } from "../../Home";
import { onDisplayLoader, onResetSearch } from "../../Search";

import { NotificationManager } from "react-notifications";
import { getParts } from "../../../../graphql/queries";

const onCopyPartInit = () => {
  return {
    type: ACTION_TYPES.PART.COPY.INIT,
    payload: null,
  };
};

const onCopyPartSuccess = (copiedPart) => {
  return {
    type: ACTION_TYPES.PART.COPY.SUCCESS,
    payload: copiedPart,
  };
};

const onCopyPartFail = (errorMessage: string) => {
  NotificationManager.error("Failure on copying part");
  console.error("Failure on copying part - ", errorMessage);
  return {
    type: ACTION_TYPES.PART.COPY.FAIL,
    payload: errorMessage,
  };
};

const onCopyPartDone = () => {
  return {
    type: ACTION_TYPES.PART.COPY.DONE,
    payload: null,
  };
};

const onFolderItemActionStatus = (status) => {
  return {
    type: ACTION_TYPES.FOLDER.ITEM_ACTION_STATUS.ACTION,
    payload: status,
  };
};
const onCopyPart = (history) => {
  return async (dispatch, getState) => {
    dispatch(onCopyPartInit());

    const state = getState() as IState;
    if (state.data.search.isSearchEnabled) {
      dispatch(onDisplayLoader(true));
    }
    const copyPartModalStatus = state.data.modals
      .copyPartModalStatus as ICopyPartModalStatus;

    const isPartNameEmpty = !copyPartModalStatus.inputText as boolean;
    let isPartNameExists = false;

    if (
      !state.data.parts.loadedParts.some(
        (el) => el.name === copyPartModalStatus.inputText
      )
    ) {
      try {
        const getSelectedPart = await API.graphql(
          graphqlOperation(getParts, {
            id: copyPartModalStatus.inputText,
          })
        );
        const getSelectedPartResult = (getSelectedPart as any).data.getParts;
        if (getSelectedPartResult !== null) {
          isPartNameExists = true;
        } else {
          isPartNameExists = false;
        }
      } catch (graphqlError) {
        const errorMessage = `${Object(
          (graphqlError as any)?.errors?.[0]?.message
        ).toString()}`;
        console.error(errorMessage);
      }
    } else {
      isPartNameExists = true;
    }

    if (isPartNameEmpty) {
      const errorMessage = "Part Name is mandatory." as string;
      dispatch(onCopyPartFail(errorMessage));
      dispatch(onCopyPartDone());
      return;
    }
    if (isPartNameExists) {
      const errorMessage = "Part Name already exists." as string;
      dispatch(onCopyPartFail(errorMessage));
      dispatch(onCopyPartDone());
      return;
    }

    try {
      const state = getState() as IState;
      const folderName = state.data.folders.highlightedFolderId;
      const partToBeCopied = !state.data.search.isSearchEnabled
        ? (state.data.parts.loadedParts.find(
            (p) => p.id === state.data.parts.highlightedPartId
          ) as IPart)
        : (state.data.search.highlightedPartData as IPart);

      partToBeCopied.id =
        folderName === ""
          ? copyPartModalStatus.inputText
          : `${folderName}/${copyPartModalStatus.inputText}`;
      partToBeCopied.name = copyPartModalStatus.inputText;
      partToBeCopied.parts_search_string =
        folderName === ""
          ? copyPartModalStatus.inputText.toLowerCase()
          : `${folderName.toLowerCase()}/${copyPartModalStatus.inputText.toLowerCase()}`;

      delete partToBeCopied._deleted;
      delete partToBeCopied.archive;
      delete partToBeCopied._version;
      delete partToBeCopied._lastChangedAt;
      delete partToBeCopied.createdAt;
      delete partToBeCopied.updatedAt;
      partToBeCopied.hasBuilds = false;
      partToBeCopied.hasPlates = false;
      partToBeCopied.builds = [];
      partToBeCopied.plates = [];
      partToBeCopied.creator = state.creator as string;
      partToBeCopied.created_at = new Date().toISOString();
      partToBeCopied.modifier = state.creator as string;
      partToBeCopied.modified_at = new Date().toISOString();
      partToBeCopied.dumb = 1;
      const variables = {
        input: partToBeCopied,
      };
      const result = await API.graphql(
        graphqlOperation(createParts, variables)
      );
      const copiedPart = (result as any)?.data?.createParts as IPart;
      dispatch(onCopyPartSuccess(copiedPart));
      dispatch(onCopyPartDone());

      if (state.data.search.isSearchEnabled) {
        dispatch(onDisplayLoader(false));
        dispatch(onNavigateToHomePage(history));
        //history.push('/dashboard/home');
        dispatch(onResetSearch());
      }
      if(state.data.folders.highlightedFolder!== "") {
        dispatch(onFolderItemActionStatus(true));
      }
    } catch (graphqlError) {
      const errorMessage = `Copy part failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onCopyPartFail(errorMessage));
      dispatch(onCopyPartDone());
    }
  };
};

export default onCopyPart;
