import { ITag, IState } from "../../../../Interfaces";

const onNewTagSuccessReducer = (state: IState, action) => {
    const addedTag = action.payload as ITag;
    
    const newState = { ...state } as IState;
    newState.data.tags.loadedTags.splice(0, 0, addedTag);
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    newState.data.modals.newTagModalStatus = {
        ...newState.data.modals.newTagModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onNewTagSuccessReducer;