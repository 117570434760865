import {IPart, IState} from '../../../Interfaces';

const onLoadPartsDeletedReducer = (state: IState, action) => {

    const deletedPart = action.payload as IPart;
    const newState = { ...state } as IState;
    newState.data.parts.loadedParts = state.data.parts.loadedParts.filter(a => a.id !== deletedPart.id);
		// Also delete if it was in the list of selected
    newState.data.parts.selectedParts = state.data.parts.selectedParts.filter(a => a.id !== deletedPart.id);
    newState.data.parts.selectedPartIds = state.data.parts.selectedPartIds.filter(a => a !== deletedPart.id);

    return newState;
};

export default onLoadPartsDeletedReducer;
