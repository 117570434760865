import { IState } from "../../../../Interfaces";

const onNewTagFailReducer = (state: IState, action) => {
    const errorMessage = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.modals.newTagModalStatus = {
        ...newState.data.modals.newTagModalStatus,
        hasError: true,
        errorMessage: errorMessage,
    };
    
    return newState;
};

export default onNewTagFailReducer;