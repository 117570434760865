import { SortDirection } from '../../../Enums';
import { IState, ITag } from '../../../Interfaces';

const onLoadTagsStartedReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.tags = {
        ...newState.data.tags,
        loadedTags: [] as ITag[],
        highlightedTagId: '' as string,
        selectedTagIds: [] as string[],
        isLoadingStarted: true as boolean,
        isLoadingInProgress: false as boolean,
        isLoadingComplete: false as boolean,
        sortDirection: SortDirection.DESC as SortDirection,
    };

    return newState;
};

export default onLoadTagsStartedReducer;