import ACTION_TYPES from '../../../actionTypes';
import { NotificationManager } from "react-notifications";
import { onDeletePart } from '..';
import { onDisplayLoader, onResetSearch } from '../../Search';
import { onNavigateToHomePage } from '../../Home';
import { IState } from '../../../Interfaces';

const onDeletePartsInit = () => {
    return {
        type: ACTION_TYPES.PARTS.DELETE.INIT,
        payload: null,
    };
};

const onDeletePartsSuccess = () => {
    return {
        type: ACTION_TYPES.PARTS.DELETE.SUCCESS,
        payload: null,
    };
};

const onDeletePartsFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Delete Part');
    console.error('Failure on Delete Part - ', errorMessage);
    return {
        type: ACTION_TYPES.PARTS.DELETE.FAIL,
        payload: errorMessage,
    };
};

const onDeletePartsDone = () => {
    return {
        type: ACTION_TYPES.PARTS.DELETE.DONE,
        payload: null,
    };
};

const onDeleteParts = (partIds, history) => {
    return async (dispatch, getState) => {
        const state = getState() as IState;
        if (state.data.search.isSearchEnabled) {
          dispatch(onDisplayLoader(true))
        }
        dispatch(onDeletePartsInit());
        try {
            const deletePromises = partIds.map(id => onDeletePart(id, history)(dispatch, getState));
            await Promise.allSettled(deletePromises);
            dispatch(onDeletePartsSuccess());
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))   
                dispatch(onNavigateToHomePage(history))
                dispatch(onResetSearch())
                }
        } catch (graphqlError) {
            const errorMessage = `Delete parts failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onDeletePartsFail(errorMessage));
            const state = getState() as IState;

            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))
                dispatch(onResetSearch())
        
              }
        }
        dispatch(onDeletePartsDone());
    };
};

export default onDeleteParts;