import { IState, IConfiguration } from '../../../Interfaces';

const onLoadConfigurationsInProgressReducer = (state: IState, action) => {
    
    const newlyLoadedConfigurations = action.payload as IConfiguration[];
    const newState = { ...state } as IState;
    newState.data.configurations.loadedConfigurations.splice(newState.data.configurations.loadedConfigurations.length, 0, ...newlyLoadedConfigurations);
    newState.data.configurations.isLoadingInProgress = true;
    
    return newState;
};

export default onLoadConfigurationsInProgressReducer;