export enum ActionTypes {
  Add,
  Delete,
  Update,
}

interface IAddSignature {
  type: ActionTypes.Add;
  cargo: {
    tableName: string;
    data: Object;
  };
}

interface IDeleteSignature {
  type: ActionTypes.Delete;
  cargo: {
    tableName: string;
    id: string;
  };
}

interface IUpdateSignature {
  type: ActionTypes.Update;
  cargo: {
    tableName: string;
    id: string;
    data: Object;
  };
}

export type IActionType = IAddSignature | IDeleteSignature | IUpdateSignature;

function reducer(state: any, action: IActionType) {
  // switch (action.type) {
  //   case ActionTypes.Add:
  //     state.dataStore[action.cargo.tableName].push(action.cargo.data);
  //     return { ...state };
  //   case ActionTypes.Delete:
  //     state.dataStore[action.cargo.tableName] = state.dataStore[
  //       action.cargo.tableName
  //     ].filter((val) => val.id != action.cargo.id);
  //     return { ...state };
  //   case ActionTypes.Update:
  //     let index = state.dataStore[action.cargo.tableName].findIndex(
  //       (val) => val.id == action.cargo.id
  //     );
  //     state.dataStore[action.cargo.tableName][index] = action.cargo.data;
  //     return { ...state };
  // }
  return { ...state };
}

export default reducer;
