import { IState } from "../../../Interfaces";

const onDownloadInProgressReducer = (state: IState, action) => {
    const processedLength = action.payload as number;

    const newState = { ...state } as IState;
    newState.downloadInProgressReceivedLength = state.downloadInProgressReceivedLength + processedLength;

    return newState;
};

export default onDownloadInProgressReducer;