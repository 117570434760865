import { IState } from "../../../Interfaces";

const onBuildStatusFilterChangeReducer = (state: IState, action) => {
    const bulidStatus = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.builds.selectedBuildStatus = bulidStatus as string;
    // newState.data.builds.searchText = "" as string;
    
    return newState;
};

export default onBuildStatusFilterChangeReducer;