import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    onAddPart,
    onAddPartToAddedParts,
    onClearAddedParts,
    onSelectPart,
    onUnselectPart
} from '../../../Actions/Parts';
import { IConfiguration, IState } from '../../../Interfaces';
import DisplayPartOptions from './DisplayPartOptions';
import {
    onNavigateToPartReviewPage,
    onNavigateToReviewPage
} from '../../../Actions/Home';
import {
    getDefaultPrintConfiguration,
    getDefaultMillConfiguration,
    getDefaultMaterialConfiguration,
    getDefaultRecipeConfiguration,
    getDefaultPartConfiguration,
    getDefaultPartSlicerConfiguration
} from '../../../DefaultConfigurations';
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {
    const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;
    const props = {
        // partsSearchResults: state.data.search?.searchPartsResults,
        selectedPartIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]] as string[],
        ids: {
            partIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]].filter(Boolean) as string[],
            partConfigurationIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]].filter(Boolean).map(id => getDefaultPartConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            partSlicerConfigurationIds: [state.data.parts.selectedPartIds[state.data.parts.selectedPartIds.length - 1]].filter(Boolean).map(id => getDefaultPartSlicerConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
        },
    };

    return props;
};

const Actions = {
    onSelectPart: onSelectPart,
    onUnselectPart: onUnselectPart,
    onClearAddedParts: onClearAddedParts,
    onNavigateToPartReviewPage: onNavigateToPartReviewPage,
};

class Component extends React.Component<any, any> {

    public render() {
        { console.log("Part Selected   : " + this.props.partsSearchResult.isSelected) }
        return (
            <>
                {this.props.partsSearchResult ? (
                    <div
                        className={`display-card is-selected-${this.props.partsSearchResult.isSelected} position-relative`}
                        id={`part-${this.props.partsSearchResult.index}`}
                        key={`${this.props.partsSearchResult.index}`}
                        onClick={() =>
                            !this.props.partsSearchResult.isSelected
                                ? this.props.onSelectPart(this.props.partsSearchResult.id)
                                : null
                        }
                        onDoubleClick={() => {
                            this.props.onNavigateToPartReviewPage(
                                this.props.ids,
                                this.props.history,
                                true
                            );
                        }}
                    >
                        <div className='display-card-select-action pt-2 pl-2 position-absolute select-part'>
                        <div className='options right-justify'>
                            <>
                                {
                                    this.props.partsSearchResult.isSelected ?
                                        (
                                            <>
                                                <div className='check option'>
                                                    <img
                                                        className='icon check'
                                                        alt=''
                                                        style={{ margin: '0px', padding: '4px' }}
                                                        onClick={() =>
                                                            this.props.onUnselectPart(
                                                                this.props.partsSearchResult.id,
                                                                this.props.onClearAddedParts()
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            <div className='check option'>
                                                <img className='icon uncheck' style={{ margin: '0px', padding: '4px' }} alt=''></img>
                                            </div>
                                        )
                                }
                            </>
                        </div>
                        </div>
                        <div>
                            <div className='thumbnail-preview'>
                                <img src='/part-thumbnail.svg' className='default-thumb-image' alt="PartImage" />
                            </div>
                            <div className='display-card-row'>
                                <div className='display-card-header'>
                                    <label title={this.props.partsSearchResult.id}>{this.props.partsSearchResult.id}</label>
                                </div>
                            </div>
                            <div className='display-card-row'>
                                <div className='display-card-sub-header'>
                                    <div className='details'>
                                        <label>Created Date:</label>
                                        <span>{this.props.partsSearchResult.created_at ? moment(new Date(this.props.partsSearchResult.created_at)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span>
                                    </div>
                                    <div className='details'>
                                        <label>Created By:</label>
                                        <span>{this.props.partsSearchResult.created_by}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )}
            </>
        );
    }
}

const SearchReviewPartCardView = withRouter(connect(Props, Actions)(Component));

export default SearchReviewPartCardView;
