import { IState } from '../../../Interfaces';

const onMovePlateFailReducer = (state: IState, action) => {
    const movePlateErrorMessage = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.movePlateModalStatus = {
        ...newState.data.modals.movePlateModalStatus,
        hasError: true,
        errorMessage: movePlateErrorMessage,
    };

    return newState;
};

export default onMovePlateFailReducer;