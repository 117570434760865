import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayParts, DisplayPartCardView, DisplayPartListView, AddPart, CopyPart, RenamePart, MovePart, } from '../..';
import { onUnselectFolder } from '../../../Actions/Home';
import { onShowRecentParts, onHideRecentParts, onShowAllParts, onHideAllParts, onShowArchivedParts, onHideArchivedParts, onHideSelectedParts, onShowSelectedParts, onSelectPart, onUnselectPart } from '../../../Actions/Parts';
import { SortDirection } from '../../../Enums';
import { IDisplayFolder, IDisplayPart, IState } from '../../../Interfaces';
import { getDisplayFolders, getDisplayParts, getDisplayPart } from '../../../Mappers';
import DisplayMoreParts from './DisplayMoreParts';

const Props = (state: IState, ownProps: any) => {

	const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

	const totalFolders = (
		(state.data.folders.sortDirection === SortDirection.ASC) ?
			displayFolders.reverse() :
			displayFolders
	) as IDisplayFolder[];

	const filteredFolders = (totalFolders.filter(df => df.isFiltered)) as IDisplayFolder[];

	const selectedFolders = (filteredFolders.filter(ff => ff.isSelected)) as IDisplayFolder[];

	const recentFolders = (filteredFolders.filter(ff => !ff.isSelected && ff.isRecent)) as IDisplayFolder[];

	const allFolders = (filteredFolders.filter(ff => !ff.isSelected && !ff.isArchived)) as IDisplayFolder[];

	const archivedFolders = (filteredFolders.filter(ff => !ff.isSelected && ff.isArchived)) as IDisplayFolder[];

	const hasSelectedFolders = Boolean(selectedFolders.length > 0) as boolean;

	const displayParts = getDisplayParts(state) as IDisplayPart[];

	const totalParts = (
		(state.data.builds.sortDirection === SortDirection.ASC) ?
			displayParts.reverse() :
			displayParts
	) as IDisplayPart[];

	const filteredParts = (
		totalParts.filter(dp => dp.isFiltered)
	) as IDisplayPart[];

	const selectedDisplayParts = state.data.parts.selectedParts.map((a) => getDisplayPart(a, state)) as IDisplayPart[];

	const selectedParts = (
		state.data.parts.selectedPartIds.map(id => selectedDisplayParts.find(lp => lp.id === id))
	) as IDisplayPart[];

	const recentParts = (
		filteredParts.filter(fp => !fp.isArchived && fp.isRecent)
	) as IDisplayPart[];

	const allParts = (
		filteredParts.filter(fp => !fp.isArchived)
	) as IDisplayPart[];

	const archivedParts = (
		filteredParts.filter(fp => fp.isArchived)
	) as IDisplayPart[];

	const hasSelectedParts = Boolean(selectedParts.length > 0) as boolean;

	const showSelectedParts = Boolean(state.data.sections.selectedParts.showItems) as boolean;
	const showRecentParts = Boolean(state.data.sections.recentParts.showItems) as boolean;
	const showAllParts = Boolean(state.data.sections.allParts.showItems) as boolean;
	const showArchivedParts = Boolean(state.data.sections.archivedParts.showItems) as boolean;

	const props = {
		showListView: state.data.info.showListView as boolean,
		filteredFolders: filteredFolders as IDisplayFolder[],
		selectedFolders: selectedFolders as IDisplayFolder[],
		recentFolders: recentFolders as IDisplayFolder[],
		allFolders: allFolders as IDisplayFolder[],
		archivedFolders: archivedFolders as IDisplayFolder[],
		hasSelectedFolders: hasSelectedFolders as Boolean,
		filteredParts: filteredParts as IDisplayPart[],
		selectedParts: selectedParts as IDisplayPart[],
		recentParts: recentParts as IDisplayPart[],
		allParts: allParts as IDisplayPart[],
		archivedParts: archivedParts as IDisplayPart[],
		hasSelectedParts: hasSelectedParts as Boolean,
		showSelectedParts: showSelectedParts as Boolean,
		showRecentParts: showRecentParts as Boolean,
		showAllParts: showAllParts as Boolean,
		showArchivedParts: showArchivedParts as Boolean,
	};

	return props;
};

const Actions = {
	onUnselectFolder: onUnselectFolder,
	onShowSelectedParts: onShowSelectedParts,
	onHideSelectedParts: onHideSelectedParts,
	onShowRecentParts: onShowRecentParts,
	onHideRecentParts: onHideRecentParts,
	onShowAllParts: onShowAllParts,
	onHideAllParts: onHideAllParts,
	onShowArchivedParts: onShowArchivedParts,
	onHideArchivedParts: onHideArchivedParts,
	onSelectPart: onSelectPart,
	onUnselectPart: onUnselectPart,
};

class Component extends React.Component<any, any> {

	public partListView() {
		return (
			<>
				<dl className='grid-view'>
					<dd>
						<>
							<div className='display-list'>
								<div className='display-list-row'>
									<div className='display-list-header'>
										<label>Type</label>
									</div>
									<div className='display-list-header'>
										<label>Name</label>
									</div>
									<div className='display-list-header'>
										<label>Imported Date</label>
									</div>
									<div className='display-list-header'>
										<label>Owner</label>
									</div>
									<div className='display-list-header'>
										<label>No of Plates</label>
									</div>
									<div className='display-list-header'>
										<label>Folder</label>
									</div>
									<div className='display-list-header'>
										<label>Actions</label>
									</div>
								</div>
							</div>
						</>
						<>
							{
								this.props.filteredParts.map((fp, i) => {
									return (
										<>
											<DisplayPartListView
												{...{
													key: i,
													index: i,
													displayPart: fp,
													isSelected: this.props.isSelected,
													onUnselect: this.props.onUnselect,
													onSelect: this.props.onSelect,
												}}
											/>
										</>
									)
								})
							}
						</>
					</dd>
				</dl>
			</>
		)
	}

	public partCardView() {
		return (
			<>
				<>
					<DisplayParts
						{...{
							label: 'Selected Parts',
							displayFolders: [],
							displayParts: this.props.selectedParts,
							showOptions: true,
							showItems: this.props.showSelectedParts,
							onShowItems: this.props.onShowSelectedParts,
							onHideItems: this.props.onHideSelectedParts,
							isSelected: true,
							onUnselect: this.props.onUnselectPart,
							onSelect: this.props.onSelectPart,
						}}
					/>
				</>
				<>
					{
						this.props.hasSelectedFolders ?
							this.props.selectedFolders.map(selectedFolder => this.selectedFolderView(selectedFolder)) :
							this.partsView()
					}
				</>
			</>
		)
	}

	public selectedFolderView(selectedFolder) {
		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item
						onClick={() => this.props.onUnselectFolder(selectedFolder['id'])}
					>
						Parts
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{selectedFolder['name']}
					</Breadcrumb.Item>
				</Breadcrumb>
				<dl className={`${this.props.showListView ? 'grid-view' : ''}`}>
					<dd>
						{
							this.props.showListView ?
								(
									<>
										<div className='display-list'>
											<div className='display-list-row'>
												<div className='display-list-header'>
													<label>Type</label>
												</div>
												<div className='display-list-header'>
													<label>Name</label>
												</div>
												<div className='display-list-header'>
													<label>Imported Date</label>
												</div>
												<div className='display-list-header'>
													<label>Owner</label>
												</div>
												<div className='display-list-header'>
													<label>No of Plates</label>
												</div>
												<div className='display-list-header'>
													<label>Folder</label>
												</div>
												<div className='display-list-header'>
													<label>Actions</label>
												</div>
											</div>
										</div>
									</>
								)
								:
								(
									null
								)
						}
						{
							selectedFolder['displayParts']
								.filter(dp => dp.folders.some(f => f === selectedFolder['name']))
								.map((dp, i) => this.props.showListView ? <DisplayPartListView key={i} displayPart={dp} /> : <DisplayPartCardView key={i} displayPart={dp} />)
						}
					</dd>
				</dl>
			</>
		)
	}

	public partsView() {
		return (
			<>
				<DisplayMoreParts
					{...{
						label: false,
						displayFolders: this.props.allFolders,
						displayParts: this.props.allParts,
						showOptions: false,
						showItems: true,
						onShowItems: this.props.onShowAllParts,
						onHideItems: this.props.onHideAllParts,
						isSelected: false,
						onUnselect: this.props.onUnselectPart,
						onSelect: this.props.onSelectPart,
						parentCallback: this.props.parentCallback,
					}}
				/>
				{/* <DisplayParts
					{...{
						label: 'Recently Imported Parts',
						displayFolders: this.props.recentFolders,
						displayParts: this.props.recentParts,
						showOptions: false,
						showItems: this.props.showRecentParts,
						onShowItems: this.props.onShowRecentParts,
						onHideItems: this.props.onHideRecentParts,
						isSelected: false,
						onUnselect: this.props.onUnselectPart,
						onSelect: this.props.onSelectPart,
					}}
				/>
				<DisplayParts
					{...{
						label: 'All Parts',
						displayFolders: this.props.allFolders,
						displayParts: this.props.allParts,
						showOptions: false,
						showItems: this.props.showAllParts,
						onShowItems: this.props.onShowAllParts,
						onHideItems: this.props.onHideAllParts,
						isSelected: false,
						onUnselect: this.props.onUnselectPart,
						onSelect: this.props.onSelectPart,
					}}
				/>
				<DisplayParts
					{...{
						label: 'Archived Parts',
						displayFolders: this.props.archivedFolders,
						displayParts: this.props.archivedParts,
						showOptions: false,
						showItems: this.props.showArchivedParts,
						onShowItems: this.props.onShowArchivedParts,
						onHideItems: this.props.onHideArchivedParts,
						isSelected: false,
						onUnselect: this.props.onUnselectPart,
						onSelect: this.props.onSelectPart,
					}}
				/> */}
			</>
		)
	}

	public render() {
		return (
			<>
				{
					this.props.showListView ?
						this.partListView() :
						this.partCardView()
				}
				<AddPart />
				<RenamePart />
				<CopyPart />
				<MovePart />
			</>
		);
	}
}

const PartsSection = withRouter(connect(Props, Actions)(Component));

export default PartsSection;
