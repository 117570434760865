import { setPlateConfigurations } from "../../../Instances";
import { IState, IConfiguration } from "../../../Interfaces";

const onSelectPlateConfigurationReducer = (state: IState, action) => {
    const configurationId = action.payload as string;
    const configuration = state.data.configurations.loadedConfigurations.find(lc => lc.id === configurationId) as IConfiguration;
    const indexToBeReplaced = state.data.workspace.selectedConfigurations.findIndex(sc => sc?.type === configuration?.type);
    const correctedIndexToBeReplaced = (indexToBeReplaced !== -1) ? indexToBeReplaced : state.data.workspace.selectedConfigurations.length;

    const newState = { ...state } as IState;
    newState.data.workspace.selectedConfigurations[correctedIndexToBeReplaced] = configuration;

    const newStateAfterAddedConfigurations = setPlateConfigurations(newState, newState.data.workspace.selectedConfigurations.map(c => c?.id), false);

    newStateAfterAddedConfigurations.data.workspace.isLoadingInProgress = !newStateAfterAddedConfigurations.data.workspace.isLoadingInProgress;
    
    return newStateAfterAddedConfigurations;
};

export default onSelectPlateConfigurationReducer;