import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { onRenamePlateCloseDialog, onRenamePlateNameChange, onRenamePlate } from '../../../Actions/Plates';
import { IState, IRenamePlateModalStatus } from '../../../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const renamePlateModalStatus = state.data.modals.renamePlateModalStatus as IRenamePlateModalStatus;

    const props = {
        isModalOpen: renamePlateModalStatus.isOpen,
        inputText: renamePlateModalStatus.inputText,
        hasError: renamePlateModalStatus.hasError,
        errorMessage: renamePlateModalStatus.errorMessage,
        isValid: renamePlateModalStatus.isValid,
    };

    return props;
};

const Actions = {
    onRenamePlateCloseDialog: onRenamePlateCloseDialog,
    onRenamePlateNameChange: onRenamePlateNameChange,
    onRenamePlate: onRenamePlate,
};

class Component extends React.Component<any, any> {

    private onRenamePlateNameChange(event) {
        const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join('');
        this.props.onRenamePlateNameChange(value);
    }

    public render() {
        return (
            <>
                {
                    (this.props.isModalOpen) ?
                        (
                            <>
                                <Modal
                                    show={this.props.isModalOpen}
                                    onHide={() => this.props.onRenamePlateCloseDialog()}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='platesDialog'
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Rename</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='each-dialog-content'>
                                            <div className='each-d-section'>
                                                Enter the name for the new plate
                                            </div>
                                            <div className='each-d-section'>
                                                <input className='f-w'
                                                    type='text'
                                                    value={this.props.inputText}
                                                    onChange={(event) => this.onRenamePlateNameChange(event)}
                                                />
                                            </div>
                                            <div className='hint-msg'>
                                                Name can contain only a-z, A-Z, 0-9, ._-
                                            </div>
                                            <div className='invalid-feedback d-block'
                                                hidden={!(this.props.hasError)}>
                                                {this.props.errorMessage}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <Button
                                           className='btn-primary'
                                            onClick={() => this.props.onRenamePlate(this.props.history)}
                                            autoFocus>
                                            Rename Plate
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const RenamePlate = withRouter(connect(Props, Actions)(Component));

export default RenamePlate;