import { IState } from "../../Interfaces";

const onCollapseAccordionsReducer = (state: IState, action) => {

    const newState = { ...state } as IState;

    newState.data.sections.selectedParts = {
        ...newState.data.sections.selectedParts,
        showItems: false,
    };
    newState.data.sections.recentParts = {
        ...newState.data.sections.recentParts,
        showItems: false,
    };
    newState.data.sections.allParts = {
        ...newState.data.sections.allParts,
        showItems: false,
    };
    newState.data.sections.archivedParts = {
        ...newState.data.sections.archivedParts,
        showItems: false,
    };

    newState.data.sections.recentPlates = {
        ...newState.data.sections.recentPlates,
        showItems: false,
    };
    newState.data.sections.allPlates = {
        ...newState.data.sections.allPlates,
        showItems: false,
    };
    newState.data.sections.archivedPlates = {
        ...newState.data.sections.archivedPlates,
        showItems: false,
    };

    newState.data.sections.recentBuilds = {
        ...newState.data.sections.recentBuilds,
        showItems: false,
    };
    newState.data.sections.allBuilds = {
        ...newState.data.sections.allBuilds,
        showItems: false,
    };
    newState.data.sections.archivedBuilds = {
        ...newState.data.sections.archivedBuilds,
        showItems: false,
    };

    return newState;
};

export default onCollapseAccordionsReducer;