import { SortDirection } from '../../../Enums';
import { IState, IConfiguration } from '../../../Interfaces';

const onLoadConfigurationsStartedReducer = (state: IState, action) => {
    
    const newState = { ...state } as IState;
    newState.data.configurations = {
        ...newState.data.configurations,
        loadedConfigurations: [] as IConfiguration[],
        highlightedConfigurationId: '' as string,
        isLoadingStarted: true as boolean,
        isLoadingInProgress: false as boolean,
        isLoadingComplete: false as boolean,
        sortDirection: SortDirection.DESC as SortDirection,
    };
    
    return newState;
};

export default onLoadConfigurationsStartedReducer;