import { IState } from "../../../Interfaces";

const onShowRecentBuildsReducer = (state: IState, action) => {

    const newState = { ...state } as IState;

    newState.data.sections.recentBuilds = {
        ...newState.data.sections.recentBuilds,
        showItems: true,
    };

    return newState;
};

export default onShowRecentBuildsReducer;