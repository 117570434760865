import { IState, IFolder } from '../../../Interfaces';

const onLoadFoldersInProgressReducer = (state: IState, action) => {
    
    const newlyLoadedFolders = action.payload as IFolder[];
    const newState = { ...state } as IState;
    newState.data.folders.loadedFolders.splice(newState.data.folders.loadedFolders.length, 0, ...newlyLoadedFolders);
    newState.data.folders.isLoadingInProgress = true;
    
    return newState;
};

export default onLoadFoldersInProgressReducer;