import { IState } from "../../../Interfaces";

const onUnselectPartsOnAddedPartsReducer = (state: IState, action) => {
    const partIndices = action.payload as number[];
    const partIndex = action.payload as number;

    const newState = { ...state } as IState;
    // newState.data.workspace.addedPartsSelectedPartIndices = newState.data.workspace.addedPartsSelectedPartIndices.filter(index => partIndices.every(i => i !== index));
    newState.data.workspace.addedPartsSelectedPartIndices = [partIndex[0]];
    newState.data.workspace.isAllAddedPartsSelected = false;
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    return newState;
};

export default onUnselectPartsOnAddedPartsReducer;