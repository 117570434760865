import getDefaultWorkspaceState from '../../../Instances/getDefaultWorkspaceState';
import { IConfiguration, IPart, IState } from '../../../Interfaces';
import { TabIndex } from '../../../Enums';
import { Search } from 'brace';

const onNavigateToHomePageReducer = (state: IState, action) => {

    const newState = { ...state } as IState;

    newState.data.workspace.state = getDefaultWorkspaceState() as any;
    newState.data.workspace.isNewPlate = true as boolean;
    newState.data.workspace.isEditPlate = false as boolean;
    newState.data.workspace.isReviewPart = false as boolean;
    newState.data.workspace.isLoadingStarted = false as boolean;
    newState.data.workspace.isLoadingInProgress = false as boolean;
    newState.data.workspace.isLoadingComplete = false as boolean;
    newState.data.workspace.addedParts = [] as IPart[];
    newState.data.workspace.isAllAddedPartsSelected = false as boolean;
    newState.data.workspace.addedPartsSelectedPartIndices = [] as number[];
    newState.data.workspace.addedPartsSelectedPartConfigurations = [] as IConfiguration[];
    newState.data.workspace.addedPartsSelectedPartSlicerConfigurations = [] as IConfiguration[];
    newState.data.workspace.selectedConfigurations = [] as IConfiguration[];
    newState.data.info.homePageTabIndex = TabIndex.ALL;
    newState.data.search.displayLoader = newState.data.search.displayLoader === true ? false : newState.data.search.displayLoader;
    return newState;
};

export default onNavigateToHomePageReducer;