import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { onRenamePartCloseDialog, onRenamePartNameChange, onRenamePart } from '../../../Actions/Parts';
import { IState, IRenamePartModalStatus } from '../../../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const renamePartModalStatus = state.data.modals.renamePartModalStatus as IRenamePartModalStatus;

    const props = {
        isModalOpen: renamePartModalStatus.isOpen,
        inputText: renamePartModalStatus.inputText,
        hasError: renamePartModalStatus.hasError,
        errorMessage: renamePartModalStatus.errorMessage,
        isValid: renamePartModalStatus.isValid,
    };

    return props;
};

const Actions = {
    onRenamePartCloseDialog: onRenamePartCloseDialog,
    onRenamePartNameChange: onRenamePartNameChange,
    onRenamePart: onRenamePart,
};

class Component extends React.Component<any, any> {

    private onRenamePartNameChange(event) {
        const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join('');
        this.props.onRenamePartNameChange(value);
    }

    public render() {
        return (
            <>
                {
                    (this.props.isModalOpen) ?
                        (
                            <>
                                <Modal
                                    show={this.props.isModalOpen}
                                    onHide={() => this.props.onRenamePartCloseDialog()}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='partsDialog'
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Rename</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='each-dialog-content'>
                                            <div className='each-d-section'>
                                                Enter the name for the new part
                                            </div>
                                            <div className='each-d-section'>
                                                <input className='f-w'
                                                    type='text'
                                                    value={this.props.inputText}
                                                    onChange={(event) => this.onRenamePartNameChange(event)}
                                                />
                                            </div>
                                            <div className='hint-msg'>
                                                Name can contain only a-z, A-Z, 0-9, ._-
                                            </div>
                                            <div className='invalid-feedback d-block'
                                                hidden={!(this.props.hasError)}>
                                                {this.props.errorMessage}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <Button
                                            className='btn-primary'
                                            onClick={() => this.props.onRenamePart()}
                                            autoFocus>
                                            Rename Part
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const RenamePart = withRouter(connect(Props, Actions)(Component));

export default RenamePart;