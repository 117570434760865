import { IConfiguration, IState } from '../../../Interfaces';

const onCopyConfigurationOpenDialogReducer = (state: IState, action) => {
    const configurationTobeCopied = state.data.configurations.loadedConfigurations.find(p => p.id === state.data.configurations.highlightedConfigurationId) as IConfiguration;
    const configurationNameToBeCopied = `${configurationTobeCopied.name}_duplicate` as string;

    const newState = { ...state } as IState;
    newState.data.modals.copyConfigurationModalStatus = {
        ...newState.data.modals.copyConfigurationModalStatus,
        inputText: configurationNameToBeCopied,
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    return newState;
};

export default onCopyConfigurationOpenDialogReducer;