import { IState, } from "../../../../Interfaces";

const onNewTagCloseDialogReducer = (state: IState, action) => {
    
    const newState = { ...state } as IState;
    newState.data.modals.newTagModalStatus = {
        ...newState.data.modals.newTagModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onNewTagCloseDialogReducer;