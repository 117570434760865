import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onClearAddedParts } from '../Actions/Parts';
import { IState } from '../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const props = {
        isLoadingComplete: state.data.builds.isLoadingComplete as boolean,
    };

    return props;
};

const Actions = {
    onClearAddedParts: onClearAddedParts,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                <div className='options right-justify'>
                    <div className='clear option'>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='icon-clear'>Clear</Tooltip>} >
                            <img className='icon clear'
                                alt='clear'
                                onClick={() => this.props.onClearAddedParts()} />
                        </OverlayTrigger>
                    </div>
                </div>
            </>
        );
    }
}

const AddedPartsOptions = withRouter(connect(Props, Actions)(Component));

export default AddedPartsOptions;