import { IState } from '../../../Interfaces';
import { TabIndex } from "../../../Enums";

const onNavigateToPlatesPageReducer = (state: IState, action) => {
    
    const isNewPlate = action.payload as boolean;

    const newState = { ...state } as IState;
    newState.data.workspace.isNewPlate = isNewPlate;
    newState.data.workspace.isEditPlate = true;
    newState.data.workspace.isReviewPart = !isNewPlate; 
    newState.data.info.homePageTabIndex = TabIndex.PARTS;


    return newState;
};

export default onNavigateToPlatesPageReducer;