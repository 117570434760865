import { IState } from '../../Interfaces';

const onSearchEnabledReducer = (state: IState, action) => {
    const status = action.payload as boolean;
    const newState = { ...state } as IState;
    newState.data.search = {
        ...newState.data.search,
        isSearchEnabled: status,
    };

    return newState;
};

export default onSearchEnabledReducer;
