import { IBuild, IState } from "../../Interfaces";

const onGetHighlightPlateStatusReducer = (state: IState, action) => {
    const highlightPlateStatus = action.payload as '';
    const newState = { ...state } as IState;
    newState.data.search = {
        ...newState.data.search,
        highlightPlateStatus: highlightPlateStatus,
    };

    return newState;
};

export default onGetHighlightPlateStatusReducer;