import IPartInfo from "../../interfaces/IPartInfo";
import { getDefaultPartConfiguration, getDefaultPartSlicerConfiguration } from "../DefaultConfigurations";
import { IConfiguration, IState } from "../Interfaces";

const setPartsConfigurations = (state: IState, partConfigurationIds: string[], partSlicerConfigurationIds: string[], resetPlate: boolean = false): IState => {

    const partConfigurations = partConfigurationIds.map(id => state.data.configurations.loadedConfigurations.find(lc => lc.id === id)) as IConfiguration[];
    const partSlicerConfigurations = partSlicerConfigurationIds.map(id => state.data.configurations.loadedConfigurations.find(lc => lc.id === id)) as IConfiguration[];

    const newState = { ...state } as IState;

    if (resetPlate) {
        newState.data.workspace.addedPartsSelectedPartConfigurations = [] as IConfiguration[];
        newState.data.workspace.addedPartsSelectedPartSlicerConfigurations = [] as IConfiguration[];

        const defaultPartConfiguration = getDefaultPartConfiguration(state) as IConfiguration;
        partConfigurations.forEach((configuration, index) => {
            configuration = defaultPartConfiguration;
            newState.data.workspace.addedPartsSelectedPartConfigurations[index] = defaultPartConfiguration;
        });

        const defaultPartSlicerConfiguration = getDefaultPartSlicerConfiguration(state) as IConfiguration;
        partConfigurations.forEach((configuration, index) => {
            configuration = defaultPartSlicerConfiguration;
            newState.data.workspace.addedPartsSelectedPartSlicerConfigurations[index] = defaultPartSlicerConfiguration;
        });
    }

    if (newState.data.workspace.addedPartsSelectedPartIndices.length === 1 && 
        newState.data.workspace.addedPartsSelectedPartIndices[0] === 0 && partConfigurations.length ===1)
    {
        newState.data.workspace.addedPartsSelectedPartConfigurations.splice(newState.data.workspace.addedPartsSelectedPartConfigurations.length, 0, ...partConfigurations);
        newState.data.workspace.addedPartsSelectedPartSlicerConfigurations.splice(newState.data.workspace.addedPartsSelectedPartSlicerConfigurations.length, 0, ...partSlicerConfigurations);
    } else 
    {
        newState.data.workspace.addedPartsSelectedPartConfigurations = partConfigurations;
        newState.data.workspace.addedPartsSelectedPartSlicerConfigurations = partSlicerConfigurations;
    }
    (newState.data.workspace.state.build.parts as IPartInfo[]).forEach((partInfo, index) => {
            if(newState.data.workspace.addedPartsSelectedPartConfigurations[index] !== undefined){
            try {
                    partInfo.properties.PartConfig = {
                        originalJson: JSON.parse(newState.data.workspace.addedPartsSelectedPartConfigurations[index]['data']),
                        resultJson: JSON.parse(newState.data.workspace.addedPartsSelectedPartConfigurations[index]['data']),
                    };
            }
            catch (err) {

            }
        }
    });

    (newState.data.workspace.state.build.parts as IPartInfo[]).forEach((partInfo, index) => {
        if(newState.data.workspace.addedPartsSelectedPartSlicerConfigurations[index] !== undefined){
            try {
                    partInfo.properties.SlicerConfig = {
                        originalJson: JSON.parse(newState.data.workspace.addedPartsSelectedPartSlicerConfigurations[index]['data']),
                        resultJson: JSON.parse(newState.data.workspace.addedPartsSelectedPartSlicerConfigurations[index]['data']),
                    };
            }
            catch (err) {

            }
        }
    });

    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;

    return newState;
};

export default setPartsConfigurations;