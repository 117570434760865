import ACTION_TYPES from '../../../actionTypes';

import onDownloadPart from './downloadPart';

const onDownloadPartsRequest = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PARTS.REQUEST,
        payload: null,
    };
};

const onDownloadPartsDone = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PARTS.DONE,
        payload: null,
    };
};

const onDownloadParts = (partIds) => {
    return async (dispatch, getState) => {
        dispatch(onDownloadPartsRequest());
        const downloadPromises = partIds.map(id => onDownloadPart(id)(dispatch, getState));
        await Promise.allSettled(downloadPromises);
        dispatch(onDownloadPartsDone());
    };
};

export default onDownloadParts;