import { Button } from "@material-ui/core";
import React, {useState} from "react";
import SideBar from "../SideBar/SideBar";

export default function MatrixSizeEditing (props) {

    return (
        <SideBar>
            <div>
                Based on: 
                { "test" }
                <img src="/list-sharp.svg"
                    style={{width:"32px", height:"32px"}}
                />
            </div>
            <div style={{display : "flex", justifyContent: "space-around"}}>
            <div style={{display : "flex"}}>
                Columns:
                <input type="text"
                    defaultValue={props.selectedMatrix.columns}
                    onChange={ (ev : React.ChangeEvent<HTMLInputElement>) => {
                        let value = parseFloat(ev.target.value);
                        if( !Number.isNaN( value ) && Number.isInteger( value )){
                            props.selectedMatrix!.columns = value;
                            props.dispatch({
                                type:"updateSelectedMatrix",
                                cargo:{
                                    updatedMatrix: props.selectedMatrix
                                }
                            });

                        }
                    }}
                    style={{ width:32,
                        borderStyle:"none"
                    }}
                />
            </div>
            <div style={{display : "flex"}}>
                ColumnGap:
                <input type="text"
                    defaultValue={props.selectedMatrix.columnGap}
                    onChange={ (ev : React.ChangeEvent<HTMLInputElement>) => {
                        let value = parseFloat(ev.target.value);

                        if( !Number.isNaN( value )){
                            props.selectedMatrix!.columnGap = value;
                            props.dispatch({
                                type:"updateSelectedMatrix",
                                cargo:{
                                    updatedMatrix: props.selectedMatrix
                                }
                            });

                        }
                    }}
                    style={{ width:32,
                        borderStyle:"none"
                    }}
                />
            </div>
            </div>
            <div style={{display : "flex", justifyContent: "space-around"}}>
            <div style={{display : "flex"}}>
                Rows:
                <input type="text"
                    defaultValue={props.selectedMatrix.rows}
                    onChange={ (ev : React.ChangeEvent<HTMLInputElement>) => {
                        let value = parseFloat(ev.target.value);

                        if( !Number.isNaN( value ) && Number.isInteger( value )){
                            props.selectedMatrix!.rows = value;
                            props.dispatch({
                                type:"updateSelectedMatrix",
                                cargo:{
                                    updatedMatrix: props.selectedMatrix
                                }
                            });

                        }
                    }}
                    style={{ width:32,
                        borderStyle:"none"
                }}
                />
            </div>
            <div style={{display : "flex"}}>
                RowGap:
                <input type="text"
                    defaultValue={props.selectedMatrix.rowGap}
                    onChange={ (ev : React.ChangeEvent<HTMLInputElement>) => {
                        let value = parseFloat(ev.target.value);

                        if( !Number.isNaN( value ) ){
                            props.selectedMatrix!.rowGap = value;
                            props.dispatch({
                                type:"updateSelectedMatrix",
                                cargo:{
                                    updatedMatrix: props.selectedMatrix
                                }
                            });

                        }
                    }}
                    style={{ width:32,
                        borderStyle:"none"
                }}
                />
            </div>
            </div>
            <div style={{ display:"flex", justifyContent:"space-around"}}>
                <Button variant="contained"
                    color="secondary"
                >
                    Cancel
                </Button>

                <Button variant="contained"
                    color="primary"
                    onClick={()=>{
                        props.setSizeEditing(false)
                    }}
                >
                    Continue
                </Button>
                {/* <Button variant="contained"
                    color="primary"
                    onClick={()=>{
                        props.dispatch({
                            type:"saveMatrix",
                        });
                    }}
                >
                    Continue
                </Button> */}
            </div>
            

        </SideBar>
    );
}