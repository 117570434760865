import { SortDirection } from '../../../Enums';
import { IState, IFolder } from '../../../Interfaces';

const onLoadFoldersStartedReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.folders = {
        ...newState.data.folders,
        loadedFolders: [] as IFolder[],
        highlightedFolderId: '' as string,
        selectedFolderIds: [] as string[],
        isLoadingStarted: true as boolean,
        isLoadingInProgress: false as boolean,
        isLoadingComplete: false as boolean,
        sortDirection: SortDirection.DESC as SortDirection,
    };

    return newState;
};

export default onLoadFoldersStartedReducer;