import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DisplayBuildCardView, DisplayBuildListView, DisplayBuildsOptions, DisplayFolder } from "../..";
import { IState } from "../../../Interfaces";
import React from "react";

const Props = (state: IState, ownProps: any) => {

	const hasDisplayItems =
		Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
		Boolean(ownProps.displayBuilds && ownProps.displayBuilds.length > 0) as boolean;

	const props = {
		hasDisplayItems: hasDisplayItems as boolean,
		showListView: state.data.info.showListView as boolean,
		isLoadingComplete: state.data.builds.isLoadingComplete as boolean,
		displayFolders: ownProps.displayFolders,
		displayBuilds: ownProps.displayBuilds,
		showItems: ownProps.showItems,
		onShowItems: ownProps.onShowItems,
		onHideItems: ownProps.onHideItems,
	};

	return props;
};

const Actions = {

};

class Component extends React.Component<any, any> {

	// componentDidUpdate(prevProps) {
	// 	if (this.props.isLoadingComplete && this.props.displayBuilds.length > prevProps.displayBuilds.length) {
	// 		if (this.props.displayBuilds.every(db => db.isSelected)) {
	// 			this.props.onShowItems();
	// 		}
	// 		if (this.props.displayBuilds.every(db => db.isRecent)) {
	// 			this.props.onShowItems();
	// 		}
	// 	}
	// }

	public render() {
		return (
			<>
				<>
					{
						this.props.hasDisplayItems ?
							(
								<>
									<dl className={`${this.props.showListView ? "grid-view" : ""}`}>
										<dt>
											<div className="options left-justify">
												<div
													className={`${this.props.showItems ? "expand" : "collapse"} option`}
												>
													<img
														className={`icon ${this.props.showItems ? "down-arrow" : "right-arrow"}`}
														alt=""
														onClick={() => this.props.showItems ? this.props.onHideItems() : this.props.onShowItems()}
													/>
													<label>{this.props.label}</label>
													{/* <span>
														{` listing ${this.props.displayBuilds.length} builds, listing ${this.props.displayFolders.length} folders`}
													</span> */}
												</div>
											</div>
											<DisplayBuildsOptions
												showOptions={this.props.showOptions}
											/>
										</dt>
										<>
											{
												this.props.showItems ?
													(
														<dd>
															{
																<>
																	{
																		this.props.displayFolders.map((df, i) => (
																			<DisplayFolder
																				key={i}
																				displayFolder={df}
																				showParts={false}
																				showPlates={false}
																				showBuilds={true}
																			/>
																		))
																	}
																</>
															}
														</dd>
													)
													:
													(
														null
													)
											}
										</>
										<>
											{
												this.props.showItems ?
													(
														<dd>
															{
																<>
																	{
																		this.props.showListView ?
																			(
																				<>
																					<div className="display-list">
																						<div className="display-list-row">
																							<div className="display-list-header">
																								<label>Type</label>
																							</div>
																							<div className="display-list-header">
																								<label>Name</label>
																							</div>
																							<div className="display-list-header">
																								<label>Build Date</label>
																							</div>
																							<div className="display-list-header">
																								<label>Owner</label>
																							</div>
																							<div className="display-list-header">
																								<label>Status</label>
																							</div>
																							<div className="display-list-header">
																								<label>Folder</label>
																							</div>
																							<div className="display-list-header">
																								<label>Actions</label>
																							</div>
																						</div>
																					</div>
																				</>
																			)
																			:
																			(
																				null
																			)
																	}
																	{
																		this.props.displayBuilds.map((dp, i) => (this.props.showListView ? <DisplayBuildListView key={i} displayBuild={dp} /> : <DisplayBuildCardView key={i} displayBuild={dp} />))
																	}
																</>
															}
														</dd>
													)
													:
													(
														null
													)
											}
										</>
									</dl>
								</>
							)
							:
							(
								null
							)
					}
				</>
			</>
		);
	}
}

const DisplayBuilds = withRouter(connect(Props, Actions)(Component));

export default DisplayBuilds;
