import {IPart, IState} from '../../../Interfaces';

const onLoadPartsCreatedReducer = (state: IState, action) => {

    const newlyLoadedParts = action.payload as IPart;
    const newState = { ...state } as IState;
    newState.data.parts.loadedParts = [newlyLoadedParts, ...state.data.parts.loadedParts]

    return newState;
};

export default onLoadPartsCreatedReducer;
