import React from "react";
import { Treebeard, decorators } from "react-treebeard"
import { NotificationManager } from 'react-notifications'

import IPartInfo from "../interfaces/IPartInfo"
import IMatrix from "../interfaces/IMatrix"

import EditJsonModal from "../EditJsonModal"
import CatalogModal from "../Catalog/CatalogModal"
import {EditSlicerModal} from "./EditSlicerModal"


Treebeard.defaultProps.style.tree.base.backgroundColor =
  "rgba(200, 200, 200, 0.6)";
Treebeard.defaultProps.style.tree.base.color = "black";
Treebeard.defaultProps.style.tree.node.header.base.color = "black";

interface IPartsTreeProps {
  parts: (IPartInfo | IMatrix)[];
  dispatch: any;
  dataStore: any;
}

interface ISelectedJson {
  json: Object;
  dataType: string;
  partUUID: string;
  diff: any;
}

interface ISelectedSlicer {
    slicerId: string;
    json: Object | null;
    partUUID?: string;
}

interface ISelectedCatalog {
  dbName: string;
  dataType?: string;
  partUUID?: string;
  isPartCatalog: boolean;
}

const PartsTree: React.FunctionComponent<IPartsTreeProps> = (props) => {
  const [selectedJson, changeSelectedJson] = React.useState<
  	ISelectedJson | null
  >( null );

    const [selectedSlicer, changeSelectedSlicer] = React.useState<ISelectedSlicer>({
        slicerId: "",
        json: {}
    });

  const [selectedCatalog, changeSelectedCatalog] = React.useState<
    ISelectedCatalog | null
  >( null );

  function createPartTreeEntry( part : IPartInfo, inMatrix : boolean ) {
    const slicerConfig = part.properties.SlicerConfig;
    const partConfig = part.properties.PartConfig?.resultJson;
    const diff = part.properties.PartConfig?.diff;
    return Object({
		name: (part.matrixAbbreviature || "") + (part.customLabel || " labelNotAssigned"),
	  showButtons: () => {
		return <div>
			{createButton( "/close-sharp.svg",
               () => props.dispatch({
                type: "deletePart",
                cargo: {
                  entityUUID: part.UUID,
                },
              }))
			}

      {createButton( "/pencil-sharp.svg",
               () => changeSelectedCatalog({
                dbName: "Parts",
                isPartCatalog: true,
                partUUID: part.UUID,
              }))
			}

			{!inMatrix 
				?		
					createButton( "/apps-sharp.svg",
            		   () => {
						   if( typeof part.properties.SlicerConfig === 'undefined' ){
							   NotificationManager.error("Can't create matrix because SlicerConfig is undefined");
							   return;
						   }
							if( typeof part.properties.PartConfig === 'undefined' ){
							   NotificationManager.error("Can't create matrix because PartConfig is undefined");
							   return;
						   }

						   props.dispatch({
							   type: "createMatrixFromPart",
							   cargo:{
								   entityUUID : part.UUID
							   }
						   });
					})
				:
					null
			}
		</div>
	  },
      toggled: !inMatrix,
      children: [
        {
          name:
            "SlicerConfig: " + (slicerConfig ? slicerConfig.id : "undefined"),
		  showButtons: () => {
			  return <div>
                  {
                      createButton("/list-sharp.svg",
                      () => changeSelectedCatalog({
                          dbName: "SlicerConfigs",
                          dataType: "SlicerConfig",
                          partUUID: part.UUID,
                          isPartCatalog: false,
                      }))
                  }
                  {

                      createButton( "/pencil-sharp.svg",
                          () => changeSelectedSlicer({
                              json: partConfig,
                              slicerId: slicerConfig?.id,
                              partUUID: part.UUID,
                          })
                      )
                  }
			  </div>
            },
        },
        {
          name: "PartConfig: " + (partConfig ? partConfig.id : "undefined"),
          showButtons: () => {
			  return <div>
			  	{createButton( "/list-sharp.svg",
						() => changeSelectedCatalog({
                			dbName: "PartRecipes",
                			dataType: "PartConfig",
                			partUUID: part.UUID,
							isPartCatalog : false,
              			}))
				}
				{
					createButton( "/pencil-sharp.svg",
						() => changeSelectedJson({
						 dataType: "PartConfig",
               			  json: partConfig,
                      partUUID: part.UUID,
                      diff: diff,
              			})              			
					)
				}
			  </div>
            },
        },
      ],
    }
  )
  }

  const entitiesTree = props.parts.map(( entity: IPartInfo | IMatrix ) => {
	  if( "children" in entity ){
		  const matrix = entity as IMatrix;
		  const matrixNumber = matrix.matrixIndex?.toString() || "";
		  return {
			  name: "Matrix " + matrixNumber,
			  children: matrix.children.map( (part : IPartInfo) => createPartTreeEntry( part, true )),
			  showButtons: () => createButton( "/close-sharp.svg",
               () => props.dispatch({
                type: "deletePart",
                cargo: {
                  entityUUID: entity.UUID,
                },
              })),
			  toggled: false,
		  };
	  }else{
		  const part = entity as IPartInfo;
		  return createPartTreeEntry( part, false );
	  }
  });

  const InitialPartsTree = {
    name: "Parts",
	showButtons: () => {
		return createButton( "/add-circle-outline.svg", 
				() => changeSelectedCatalog({
                	dbName: "Parts",
                	isPartCatalog: true,
              	}));
	},
    children: entitiesTree,
    toggled: true,
  };

  const [partsTree, setPartsTree] = React.useState(InitialPartsTree);

  React.useEffect(() => {
    InitialPartsTree.toggled = partsTree.toggled;

    InitialPartsTree.children.forEach((updatedChild: any) => {
      let previous = partsTree.children.find(
        (child: any) => child.name === updatedChild.name
      );
      if (previous !== undefined) {
        updatedChild.toggled = previous.toggled;
      }
    });
    setPartsTree(InitialPartsTree);
  }, [JSON.stringify(props.parts)]);

  const customHeader = ({ node }) =>{
      return (
		  <div style={{display:"flex", alignItems:"center"}}>
            {node.name}
            {node.showButtons()}
		</div>
      );
  };

  class CustomContainer extends decorators.Container{
	  render(){
		  const {style, decorators, terminal, onClick, node } = (this as any).props;
		  return(
			  <div style={{
				  display:"flex", 
				  alignItems:"center",
				  cursor:"pointer"
				}}
			  onClick={onClick}>
				  {!terminal? (this as any).renderToggle() : null}
				  <decorators.Header node = {node}/>
				</div>
		  )
	  }

  }

  let customDecorator = { ...decorators };
  customDecorator.Header = customHeader;
  customDecorator.Container = CustomContainer;

  // const dataStore =
  //   selectedCatalog === null
  //     ? null
  //     : props.dataStore[selectedCatalog.dbName];
  return (
    <div className={"sideBarParamsBlock"}>
      <Treebeard
        data={partsTree}
        onToggle={(node: any, toggled: boolean) => {
          if (node.children) {
            node.toggled = toggled;
          }
          setPartsTree({ ...partsTree });
        }}
        decorators={customDecorator}
      />

      <EditJsonModal
        jsonToEdit={selectedJson?.json || null}
        diff={selectedJson?.diff}
        modalCloser={() => changeSelectedJson( null )}
        updateJson={(updatedJson: Object) => {
          props.dispatch({
            type: `update${selectedJson!.dataType}`,
            cargo: {
              entityUUID: selectedJson!.partUUID,
              item: updatedJson,
            },
          });
        }}
        schemaName={selectedJson?.dataType}
      />

      <EditSlicerModal
            slicerId={selectedSlicer.slicerId}
            patchJson={ (selectedSlicer.json as any)?.SlicerPatch}
            modalCloser={() => changeSelectedSlicer({slicerId:"", json: {}} )}
            updateJson={ (newPatch) => {
                const updatedJson = Object.assign({},selectedSlicer.json, {SlicerPatch: newPatch})
                props.dispatch({
                    type: `updatePartConfig`,
                    cargo: {
                        entityUUID: selectedSlicer!.partUUID,
                        item: updatedJson,
                    },
                });
                changeSelectedSlicer({slicerId:"", json: {}} )
            }}
      />

      <CatalogModal
        dataType={selectedCatalog?.dbName || ""}
        modalCloser={() => changeSelectedCatalog(null)}
        onCatalogEntrySelected={(selectedConfig: Object) => {
          if (selectedCatalog!.isPartCatalog) {
  
            if (selectedCatalog!.partUUID) {
              props.dispatch({
                  type: "changeMesh",
                  cargo: {
                    PartID: (selectedConfig as any).id,
                    entityUUID: selectedCatalog?.partUUID
                  },
                });
            } else {

            props.dispatch({
              type: "addPart",
              cargo: {
                PartID: (selectedConfig as any).id,
              },
            });
            }
          }  else  {
            props.dispatch({
              type: `set${selectedCatalog!.dataType}`,
              cargo: {
                entityUUID: selectedCatalog!.partUUID,
                item: selectedConfig,
              },
            });
        }}

      }
        schemaName={selectedCatalog?.dataType}
      />
    </div>
  );
};

function createButton( imgSourcePath: string, callback : () => void ){
    const splatted = imgSourcePath.split("/")
    const name = splatted[splatted.length -1].split('.')[0]
    let extractedName = imgSourcePath.split("/")
	return <img
        src={ imgSourcePath }
        alt = {name}
        style={{ width: "32px", height: "32px" }}
        onClick={(e) => {
            e.stopPropagation();
            callback();
        }}
    />
}



export default PartsTree;
