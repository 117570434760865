import { API, graphqlOperation } from "aws-amplify";
import { IState } from "../../Interfaces";
import ACTION_TYPES from "../../actionTypes";
import {getBuild, getPlate} from "../../../graphql/queries";

const onHighlightPlateInit = () => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.INIT,
    payload: null,
  };
};

const onHighlightPlateSucess = (selectedPlate) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.SUCCESS,
    payload: selectedPlate,
  };
};
const onHighlightPlateFail = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.FAIL,
    payload: status,
  };
};
const onHighlightPlateDone = (status) => {
  return {
    type: ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.DONE,
    payload: status,
  };
};
const onHighlightPlateReset = () => {
    return {
      type: ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.RESET,
        payload: null,
    };
};

const onGetHighlightedPlate = (plateId) => {
  return async (dispatch, getState) => {
    const state = getState() as IState;
     // dispatch(onHighlightBuildReset())
    try {
      dispatch(onHighlightPlateInit())
      const getSelectedPlate = await API.graphql(
        graphqlOperation(getPlate, {
          id: plateId,
        })
      );
      const getSelectedPlateResult = (getSelectedPlate as any).data.getPlate;
      if (getSelectedPlateResult){
          dispatch(onHighlightPlateSucess(getSelectedPlateResult))
        }
    } catch (graphqlError) {
      const errorMessage = `${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onHighlightPlateFail('failed'))
      console.error(errorMessage);
    }
    dispatch(onHighlightPlateDone('completed'))
  };

};

export default onGetHighlightedPlate;
