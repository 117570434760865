import { IState } from "../../../Interfaces";

const onAddPartProgress = (state: IState, action) => {
  const addPartProgress = action.payload as boolean;

  const newState = { ...state } as IState;
  newState.data.modals.addPartModalStatus = {
    ...newState.data.modals.addPartModalStatus,

    addPartProgress: addPartProgress,
  };

  return newState;
};

export default onAddPartProgress;
