import { setPlate } from '../../../Instances';
import { IState } from '../../../Interfaces';

const onAddPartsToAddedPartsReducer = (state: IState, action) => {
   
    const ids = action.payload as any;
    if (ids !== null && ids?.partIds.length !== 0 )
    {
        const newState = setPlate(state, ids, false) as IState;
        return newState;
    } else
    return state;
};

export default onAddPartsToAddedPartsReducer;