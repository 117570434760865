import { IState, } from '../../../Interfaces';

const onAddPartFileChangeReducer = (state: IState, action) => {
    const partFileToBeAdded = action.payload as any;

    const newState = { ...state } as IState;
    newState.data.modals.addPartModalStatus = {
        ...newState.data.modals.addPartModalStatus,
        inputFile: partFileToBeAdded,
        inputText: partFileToBeAdded?.name || '',
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onAddPartFileChangeReducer;