import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IPartInfo from "../../../../interfaces/IPartInfo";
import { onNavigateToPlatesPage } from "../../../Actions/Home";
import { IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import { onHighlightPlate } from '../../../Actions/Plates';
import DisplayPlateOptions from "./DisplayPlateOptions";

const Props = (state: IState, ownProps: any) => {
	const props = {
		loadedPlates: state.data.plates.loadedPlates as IPlate[],
		displayPlate: ownProps.displayPlate as IDisplayPlate,
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
};

class Component extends React.Component<any, any> {
	createdDate = (new Date(this.props.displayPlate.created_at) as Date).toLocaleString() as string;
	onNavigateToPlatesPage = (plateId) => {

		this.props.onHighlightPlate(plateId);

		const plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;

		const parts = plateToBeEdited.parts.filter(p=>p).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(p=>p) as IPartInfo[];
		const partIds = parts.map(part => part.properties.PartID) as string[];
		const partConfigurationIds = parts.map(part => part.properties.PartConfig?.originalJson?.id).filter(c=>c);
		const partSlicerConfigurationIds = parts.map(part => part.properties.SlicerConfig?.originalJson?.id).filter(c=>c);
		const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

		const ids = {
			partIds,
			parts,
			partConfigurationIds,
			partSlicerConfigurationIds,
			plateConfigurationIds,
		};

		this.props.onNavigateToPlatesPage(ids, this.props.history, false);
	}

	public render() {
		return (
			<>
				<div
					className={`display-card is-selected-${this.props.displayPlate.isSelected}`}
					id={`plate-${this.props.displayPlate.index}`}
					key={`${this.props.displayPlate.index}`}
					onClick={(e) => { e.stopPropagation(); this.onNavigateToPlatesPage(this.props.displayPlate.id)} }
				>
					<div className="display-card-row">
						<div className="display-card-header">
							<label className="text-ellipsis-second-line">
								{this.props.displayPlate.name}
							</label>
						</div>
						<div className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}>
							<DisplayPlateOptions
								{...{ displayPlate: this.props.displayPlate }}
							/>
						</div>
					</div>
					<div className="display-card-row">
						<div className="display-card-sub-header">
							<label>{(new Date(this.props.displayPlate.created_at) as Date).toLocaleString()}</label>
							{/* <label>{this.props.displayPlate.createdDate ? this.props.displayPlate.createdDate : this.createdDate}</label> */}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const DisplayPlateCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayPlateCardView;
