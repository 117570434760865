import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IPartInfo from "../../../../interfaces/IPartInfo";
import { onNavigateToPlatesPage } from "../../../Actions/Home";
import {
  onHighlightPlate,
  onRenamePlateOpenDialog,
  onCopyPlateOpenDialog,
  onUnarchivePlate,
  onArchivePlate,
  onDeletePlate,
  onMovePlateOpenDialog,
} from "../../../Actions/Plates";
import { IState, IDisplayPlate, IPlate } from "../../../Interfaces";
import onGetHighlightedPlate from "../../../Actions/Search/highlightPlate";
import HorizontalLoader from "../../horizontalLoader";
import { NotificationManager } from "react-notifications";
import awsconfig from "../../../../aws-exports";
import { Amplify, Storage } from "aws-amplify";
import { Menu, MenuItem } from "@material-ui/core";
Amplify.configure(awsconfig);

const Props = (state: IState, ownProps: any) => {
  const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
  const highlightPlate = state.data.search.highlightedPlateData as IPlate;

  const props = {
    isLoadingComplete: state.data.plates.isLoadingComplete as boolean,
    loadedPlates: state.data.plates.loadedPlates as IPlate[],
    displayPlate: ownProps.displayPlate as IDisplayPlate,
    isSearchEnabled: isSearchEnabled as boolean,
    highlightPlate: highlightPlate as IPlate,
    creator:state.creator
  };

  return props;
};

const Actions = {
  onHighlightPlate: onHighlightPlate,
  onNavigateToPlatesPage: onNavigateToPlatesPage,
  onRenamePlateOpenDialog: onRenamePlateOpenDialog,
  onCopyPlateOpenDialog: onCopyPlateOpenDialog,
  onUnarchivePlate: onUnarchivePlate,
  onArchivePlate: onArchivePlate,
  onDeletePlate: onDeletePlate,
  onMovePlateOpenDialog: onMovePlateOpenDialog,
  onGetHighlightedPlate: onGetHighlightedPlate,
};

class Component extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    event.currentTarget && !this.props.isSearchEnabled
      ? this.props.onHighlightPlate(this.props.displayPlate.id)
      : this.props.onGetHighlightedPlate(this.props.displayPlate.id);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getPlate = async (id) => {
    await this.props.onGetHighlightedPlate(id);
  };

  onNavigateToPlatesPage = async (plateId) => {
    this.props.onHighlightPlate(plateId);
    this.props.onHighlightPlate(plateId);
    const plateToBeEdited = this.props.isSearchEnabled
      ? (this.props.highlightPlate as IPlate)
      : (this.props.loadedPlates.find((lp) => lp.id === plateId) as IPlate);
    if (
      plateToBeEdited &&
      plateToBeEdited.parts[0] &&
      JSON.parse(plateToBeEdited.parts[0]).Key ===
        `PlatePartsFiles/${plateToBeEdited.id}.json`
    ) {
      const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
      const AWSBucketParam = {
        Bucket: Storage["_config"]["AWSS3"]["bucket"],
        Key: path,
        CacheControl: "no-cache" // or 'max-age=0'
      };

      try {
        const getResult = await Storage.get(AWSBucketParam.Key, {
          download: true,
        });
        plateToBeEdited.parts = JSON.parse(
          await (getResult.Body as any).text()
        );
      } catch (error: any) {
        NotificationManager.error(
          "An error occurred during the plate editing process"
        );
        console.error(
          "An error occurred during the plate editing process:",
          error.message
        );
      }
    }
    const partIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.PartID)
      .filter(Boolean) as string[];

    const parts = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean) as IPartInfo[];
    console.log(JSON.stringify(parts, null, 4));

    const partConfigurationIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.PartConfig["originalJson"]["id"])
      .filter(Boolean);
    const partSlicerConfigurationIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.SlicerConfig["originalJson"]["id"])
      .filter(Boolean);
    const plateConfigurationIds = [
      plateToBeEdited.machineId,
      plateToBeEdited.millConfigId,
      plateToBeEdited.materialConfigId,
      plateToBeEdited.recipeId,
    ];
    const ids = {
      partIds,
      parts,
      partConfigurationIds,
      partSlicerConfigurationIds,
      plateConfigurationIds,
    };

    this.props.onNavigateToPlatesPage(ids, this.props.history, false);
  };

  public render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <div>
          <div
            className="display-list-more-action"
            aria-controls={`simple-menu ${this.props.displayPlate.id}`}
            aria-haspopup="true"
            id="dropdown-basic"
            onClick={this.handleClick}
            style={{ position: "relative" }}
          >
            <div className="options right-justify">
              <div className="more-check option">
                <img className="icon more-check" alt="" />
              </div>
            </div>
          </div>
          <Menu
            id={`simple-menu ${this.props.displayPlate.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            style={{
              position: "absolute",
              top: "38px",
            }}
            disableScrollLock={false}
          >
            {!this.props.isSearchEnabled && (
              <div>
                <MenuItem
                  onClick={() =>
                    this.onNavigateToPlatesPage(this.props.displayPlate.id)
                  }
                >
                  Edit
                </MenuItem>
                <MenuItem
                  // disabled={!this.props.isLoadingComplete}
                  disabled={true}
                  onClick={() =>
                    this.props.onRenamePlateOpenDialog(
                      this.props.displayPlate.id
                    )
                  }
                >
                  Rename
                </MenuItem>
                <MenuItem
                  disabled={!this.props.isLoadingComplete}
                  onClick={() => {
                    this.handleClose();
                    this.props.onCopyPlateOpenDialog(this.props.displayPlate.id);
                  }
                  }
                >
                  Duplicate
                </MenuItem>

                <MenuItem
                  disabled={
                    this.props.displayPlate &&
                    (
                      (this.props.displayPlate.builds && this.props.displayPlate.builds.length !== 0) ||
                      this.props.displayPlate.created_by !== this.props.creator
                    ) &&
                    !(
                      this.props.displayPlate.builds == null 
                    )
                  }
                  onClick={() =>
                    this.props.onDeletePlate(this.props.displayPlate.id)
                  }
                >
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    this.props.onMovePlateOpenDialog(this.props.displayPlate.id);
                  }
                  }
                >
                  Move
                </MenuItem>
              </div>
            )}

            {this.props.isSearchEnabled && (
              <div>
                {Object.keys(this.props.highlightPlate).length === 0 ? (
                  <MenuItem style={{ height: "200px" }}>
                    <HorizontalLoader></HorizontalLoader>
                  </MenuItem>
                ) : (
                  <div>
                    <MenuItem
                      onClick={() =>
                        this.onNavigateToPlatesPage(this.props.displayPlate.id)
                      }
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      // disabled={!this.props.isLoadingComplete}
                      disabled={true}
                      onClick={() =>
                        this.props.onRenamePlateOpenDialog(
                          this.props.displayPlate.id
                        )
                      }
                    >
                      Rename
                    </MenuItem>
                    <MenuItem
                      disabled={!this.props.isLoadingComplete}
                      onClick={() => {
                        this.handleClose();
                        this.props.onCopyPlateOpenDialog(
                          this.props.displayPlate.id
                        );
                      }
                      }
                    >
                      Duplicate
                    </MenuItem>
                    <MenuItem
                    disabled={
                      this.props.highlightPlate &&
                      (
                        (this.props.highlightPlate.builds && this.props.highlightPlate.builds.length !== 0) ||
                        this.props.displayPlate.created_by !== this.props.creator
                      ) &&
                      !(
                        this.props.highlightPlate.builds == null 
                      )
                    }
                      onClick={() =>
                        this.props.onDeletePlate(
                          this.props.displayPlate.id,
                          this.props.history
                        )
                      }
                    >
                      Delete
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.handleClose();
                        this.props.onMovePlateOpenDialog(
                          this.props.displayPlate.id
                        );
                      }
                      }
                    >
                      Move
                    </MenuItem>
                  </div>
               )} 
              </div>
            )}
          </Menu>
        </div>
      </div>
    );
  }
}

const DisplayPlateOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayPlateOptions;
