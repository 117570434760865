import { IState } from "../../Interfaces";

const onSubscriptionsSet = (state: IState, action) => {
    const subscriptions = action.payload;

    const newState = { ...state } as IState;
    try {
        for (const [key, value] of Object.entries(subscriptions)) {
            console.log("Checking key ", key);
            if (key in newState.subscriptions) {
                console.log("Setting value ", value);
                newState.subscriptions[key] = value;
            }
        }
    }
    catch (e){
        console.error(e)
    }

    return newState;
};

export default onSubscriptionsSet;
