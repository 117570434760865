import React from 'react'
import { Link } from "react-router-dom";

import { Button } from '@material-ui/core';

import SideBar from "../SideBar/SideBar";
import BundlesList from "./BundlesList";
import ConfigsDisplay from "./ConfigsDisplay";
import PartsTree from "./PartsTree";

import IBundle from "../interfaces/IBundle";
import IPartInfo from "../interfaces/IPartInfo";
import IMatrix from "../interfaces/IMatrix";
import IBuild from '../interfaces/IBuild';

import { IWorkSpace, WorkspaceViewType } from "./WorkSpace";
import SidebarParts from './SidebarParts';
import SidebarMatrix from './SidebarMatrix';

interface IProps {
	build: IBuild;
	selectedMatrix: IMatrix | null;
	dispatch: any;
	dataStore: any;
	workspace: IWorkSpace;
	viewType: WorkspaceViewType;
}

const WorkspaceSidebar: React.FunctionComponent<IProps> =
	(props) => {

		let parts = props.build.parts.flatMap((entity: (IPartInfo | IMatrix)) =>
			"children" in entity ? entity.children.map((part: IPartInfo) => part)
				: (entity as IPartInfo));

		if (props.selectedMatrix === null) {
			return (
				<SidebarParts
					build={props.build}
					dispatch={props.dispatch}
					dataStore={props.dataStore}
					workspace={props.workspace}
					viewType={props.viewType}
				/>)
		} else {
			return (
				<SidebarMatrix
					build={props.build}
					dispatch={props.dispatch}
					dataStore={props.dataStore}
					selectedMatrix={props.selectedMatrix}
					workspace={props.workspace}
				/>
			)
		}
	}

export default WorkspaceSidebar;
