import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PartConfiguration } from '.';
import { onSelectPartOnAddedParts, onSelectPartsOnAddedParts, onUnselectPartsOnAddedParts } from '../Actions/Parts';
import { IState, IAddedPart, IConfiguration } from '../Interfaces';
import { getConfigurationsBasedOnType } from '../Mappers';

const Props = (state: IState, ownProps: any) => {

    const addedParts = ownProps?.addedParts || [] as IAddedPart[];
    const selectedPart = ownProps?.addedParts.find(ap => state.data.workspace.addedPartsSelectedPartIndices.some(index => index === ap?.index));
    const isAllAddedPartsSelected = state.data.workspace.isAllAddedPartsSelected as boolean;
    const partConfigurations = getConfigurationsBasedOnType(state, 'partConfig') as IConfiguration[];
    const partSlicerConfigurations = getConfigurationsBasedOnType(state, 'partSlicerConfig') as IConfiguration[];

    const selectedPartConfigurationIndex = (selectedPart ? selectedPart.index : -1);
    const selectedPartSlicerConfigurationIndex = (selectedPart ? selectedPart.index : -1);

    const selectedPartConfiguration = state.data.workspace.addedPartsSelectedPartConfigurations[selectedPartConfigurationIndex] as IConfiguration;
    const selectedPartSlicerConfiguration = state.data.workspace.addedPartsSelectedPartSlicerConfigurations[selectedPartSlicerConfigurationIndex] as IConfiguration;

    const props = {
        isLoadingComplete: state.data.configurations.isLoadingComplete as boolean,
        addedParts: addedParts as IAddedPart[],
        selectedPart: selectedPart as IAddedPart,
        isAllAddedPartsSelected: isAllAddedPartsSelected as boolean,
        selectedPartLabel: isAllAddedPartsSelected ? 'All' : (selectedPart ? selectedPart.label : 'None') as string,
        partConfigurations: partConfigurations as IConfiguration[],
        partSlicerConfigurations: partSlicerConfigurations as IConfiguration[],
        selectedPartConfiguration: selectedPartConfiguration as IConfiguration,
        selectedPartSlicerConfiguration: selectedPartSlicerConfiguration as IConfiguration,
    };

    return props;
};

const Actions = {
    onSelectPartOnAddedParts: onSelectPartOnAddedParts,
    onSelectPartsOnAddedParts: onSelectPartsOnAddedParts,
    onUnselectPartsOnAddedParts: onUnselectPartsOnAddedParts,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                <div className='options'>
                    <>
                        {
                            (this.props.isAllAddedPartsSelected) ?
                                (
                                    <>
                                        <div className='check option'>
                                            <img className='icon check mx-0 pl-0' alt='' onClick={() => this.props.onUnselectPartsOnAddedParts(this.props.addedParts.map(ap => ap?.index))} />
                                            <span>Apply configuration to all the parts</span>
                                        </div>
                                    </>
                                ) :
                                (<>
                                    <div className='uncheck option'>
                                        <img className='icon uncheck mx-0 pl-0' alt='' onClick={() => this.props.onSelectPartsOnAddedParts(this.props.addedParts.map(ap => ap?.index))} />
                                        <span>Apply configuration to all the parts</span>
                                    </div>
                                </>)
                        }
                    </>
                    {/* <>
                        {
                            (!this.props.isAllAddedPartsSelected) ?
                                (
                                    <>
                                        <div className='uncheck option'>
                                            <img className='icon uncheck mx-0 pl-0' alt='' onClick={() => this.props.onSelectPartsOnAddedParts(this.props.addedParts.map(ap => ap?.index))} />
                                            <span>Apply configuration to all the parts</span>
                                        </div>
                                    </>
                                ) :
                                (null)
                        }
                    </> */}
                </div>
                <div className='options'>
                    <div className='plate-configuration option ml-0'>
                        <label>{this.props.label}</label>
                        <DropdownButton id='dropdown-basic-button'
                            disabled={this.props.isAllAddedPartsSelected}
                            title={this.props.selectedPartLabel}>
                            <>
                                {
                                    this.props.addedParts.map(ap => {
                                        return (
                                            <React.Fragment key={ap?.index}>
                                                <Dropdown.Item
                                                    onClick={() => this.props.onSelectPartOnAddedParts(ap?.index)}
                                                    onMouseEnter={() => this.props.setPartHoveredForConfiguration(ap?.index ?? -1)}
                                                    onMouseLeave={() => this.props.setPartHoveredForConfiguration(-1)}>
                                                    {ap?.label}
                                                </Dropdown.Item>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </>
                        </DropdownButton>
                    </div>
                </div>
                <PartConfiguration {...{ label: 'Additive Strategy', loadedConfigurations: this.props.partConfigurations, selectedConfiguration: this.props.selectedPartConfiguration }} />
                <PartConfiguration {...{ label: 'Additive Paths', loadedConfigurations: this.props.partSlicerConfigurations, selectedConfiguration: this.props.selectedPartSlicerConfiguration }} />
            </>
        );
    }
}

const PlateParts = withRouter(connect(Props, Actions)(Component));

export default PlateParts;
