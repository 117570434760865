import awsmobile from "./aws-exports";
import { Auth } from "aws-amplify";

import * as THREE from "three";
import {FontLoader} from "three/examples/jsm/loaders/FontLoader";
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry"


const saveFile = (
  content: any,
  fileName: string,
  contentType: string = "application/octet-stream"
) => {
  const anchorTag = document.createElement("a");
  const file = new Blob([content], { type: contentType });
  anchorTag.href = URL.createObjectURL(file);
  anchorTag.download = fileName;
  anchorTag.click();
};

export const patchSlicer = (slicerConfig: string, patch: Object | null)=>{
  if (!patch)
    return slicerConfig;

  let patched_slicer=""
  slicerConfig.split('\n').map(  line  => {
    let splitted = line.split("=")
    const rString = line[line.length -1] === '\r' ? '\r' : ''
    let splitted_key =splitted[0].trimLeft().trimRight()
    let replaced = false
    for (const [key, value] of Object.entries(patch)) {
      if (splitted_key === key){
        patched_slicer += `${splitted_key} = ${value}${rString}\n`
        replaced = true
        break;
      }
    }
    if (!replaced){
      patched_slicer += line + '\n';
    }
  })
  return patched_slicer;
}


export async function listAllKeys(prefix) {
  prefix = `public/${prefix}`
  const AWS = require('aws-sdk');
  let allKeys: any[] = [];
  AWS.config.region = awsmobile.aws_user_files_s3_bucket_region;
  let params: any = {
    Bucket: awsmobile.aws_user_files_s3_bucket,
    Prefix: prefix
  }
  AWS.config.credentials = await new Promise<any>((resolve, reject) => {
    Auth.currentCredentials().then((credentials) => {
      resolve(credentials)
    }).catch((reason) => {
      reject(reason)
    })
  })
  do {
    let data = await new AWS.S3().listObjectsV2(params).promise()
    let contents = data.Contents;
    contents.forEach(function (file) {
      allKeys.push(file.Key.substr("public/".length));
    });
    if (data.IsTruncated) {
      params.ContinuationToken = data.NextContinuationToken;
    } else {
      delete params.ContinuationToken
    }
  }
  while (params.ContinuationToken);
  return allKeys;
}

export function makeRequest (method, url, data?):Promise<ArrayBuffer> {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    if (data){
      xhr.send(JSON.stringify(data))
    }
    else{
      xhr.send();
    }
  });
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')
export function getBuildVolumeGridAndVisualizer(scene, buildAreaVolume: THREE.Vector3) {
  // Add grid for vizual assistance
  function addSegment(p1, p2, vertices) {
    let vec1 = new THREE.Vector3(p1.x, p1.y, p1.z);
    let vec2 = new THREE.Vector3(p2.x, p2.y, p2.z);
    vertices.push(vec1, vec2);
  }

  // Remove added grid
  ["gridVisualizer", "volumeVisualizer"].forEach(vis => {
    let object = scene?.scene?.getObjectByName(vis)
    if (object) {
      scene?.scene?.remove(object);
    }
  })


  let buildAreaSize = new THREE.Vector3();
  buildAreaVolume.getSize(buildAreaSize);
  let grid = new THREE.Group();
  grid.verticies = [];

  const step = 5; // make an 10mm grid
  const sectionsX = Math.floor(buildAreaSize.x / step);
  const sectionsY = Math.floor(buildAreaSize.y / step);

  {}  let gridlines = [], ticklines = [];
  for (let ind = 1; ind <= sectionsX / 2; ind++) {
    addSegment(
      { x: ind * step, y: buildAreaVolume.min.y, z: 0 },
      { x: ind * step, y: buildAreaVolume.min.y + buildAreaSize.y, z: 0 },
      ind % 5 ? gridlines : ticklines);
    addSegment(
      { x: -ind * step, y: buildAreaVolume.min.y, z: 0 },
      { x: -ind * step, y: buildAreaVolume.min.y + buildAreaSize.y, z: 0 },
      ind % 5 ? gridlines : ticklines);
  }

  for (let ind = 1; ind <= sectionsY / 2; ind++) {
    addSegment(
      { x: buildAreaVolume.min.x, y: ind * step, z: 0 },
      { x: buildAreaVolume.min.x + buildAreaSize.x, y: ind * step, z: 0 },
      ind % 5 ? gridlines : ticklines);
    addSegment(
      { x: buildAreaVolume.min.x, y: -ind * step, z: 0 },
      { x: buildAreaVolume.min.x + buildAreaSize.x, y: -ind * step, z: 0 },
      ind % 5 ? gridlines : ticklines);
  }


  grid.add(
    new THREE.LineSegments(
      new THREE.BufferGeometry().setFromPoints(gridlines, 3), new THREE.LineBasicMaterial({ color: 0xd0d0d0 })
    ),
    new THREE.LineSegments(
      new THREE.BufferGeometry().setFromPoints(ticklines, 3), new THREE.LineBasicMaterial({ color: 0x909090 })
    )
  );

  // Add vizualization box

  const material = new THREE.LineBasicMaterial({ color: 0xa9a9a9 });
  let geometry = new THREE.BufferGeometry();

  let volumePoints = new Float32Array([
    //top
    -0.5,
    -0.5,
    1,
    -0.5,
    0.5,
    1,
    0.5,
    0.5,
    1,
    0.5,
    -0.5,
    1,
    //legs
    -0.5,
    -0.5,
    0,
    -0.5,
    0.5,
    0,
    0.5,
    0.5,
    0,
    0.5,
    -0.5,
    0,
    //bottom
    -0.5,
    -0.5,
    0,
    -0.5,
    0.5,
    0,
    0.5,
    0.5,
    0,
    0.5,
    -0.5,
    0,
  ]);
  geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(volumePoints, 3)
  );

  geometry.setIndex([
    //top
    0,
    1,
    1,
    2,
    2,
    3,
    3,
    0,
    //legs
    0,
    4,
    1,
    5,
    2,
    6,
    3,
    7,
    //bottom
    8,
    9,
    9,
    10,
    10,
    11,
    11,
    8
  ]);

  let buildVolumeVisualization = new THREE.LineSegments(geometry, material);
  let buildVolumeScale = new THREE.Matrix4().makeScale(
    buildAreaSize.x,
    buildAreaSize.y,
    buildAreaSize.z
  );
  buildVolumeVisualization.applyMatrix4(buildVolumeScale);
  buildVolumeVisualization.translateX(buildAreaVolume.min.x + buildAreaSize.x / 2);
  buildVolumeVisualization.translateY(buildAreaVolume.min.y + buildAreaSize.y / 2);

	let xarrow = 
		new THREE.ArrowHelper(new THREE.Vector3(1*buildAreaSize.x, 0, 0), new THREE.Vector3(buildAreaVolume.min.x, 0, 0), buildAreaSize.x+3, 0x0000ff, 3, 3);
	xarrow.cone.material.opacity = 0.5;
	xarrow.cone.material.transparent = true;
	xarrow.line.material.opacity = 0.5;
	xarrow.line.material.transparent = true;
  grid.add(xarrow);
	let yarrow = 
		new THREE.ArrowHelper(new THREE.Vector3(0, 1*buildAreaSize.y, 0) , new THREE.Vector3(0, buildAreaVolume.min.y,  0), buildAreaSize.y+3, 0x00ff00, 3, 3);
	yarrow.cone.material.opacity = 0.5;
	yarrow.cone.material.transparent = true;
	yarrow.line.material.opacity = 0.5;
	yarrow.line.material.transparent = true;
  grid.add(yarrow);

  const loader = new FontLoader();

  loader.load( '/fonts/helvetiker_regular.typeface.json', function ( font ) {
      const parameters = {
        font: font,
        size: 8,
        height: -0.01,
        curveSegments: 10,
      };


    const x = new THREE.Mesh(new TextGeometry( 'X', parameters ), [
      new THREE.MeshPhongMaterial( { color: 0x0000ff, flatShading: true } ), // front
      new THREE.MeshPhongMaterial( { color: 0x0000ff } ) // side
    ])
    x.position.y = 1.5
    x.position.x = buildAreaVolume.max.x+0.3;
    grid.add(x);

    const y = new THREE.Mesh(new TextGeometry( 'Y', parameters ), [
      new THREE.MeshPhongMaterial( { color: 0x00ff00, flatShading: true } ), // front
      new THREE.MeshPhongMaterial( { color: 0x0ff000 } ) // side
    ])
    y.position.y = buildAreaVolume.max.y;
    grid.add(y);

    scene?.render()
  },
	(xhr)=>{
    console.log(`loadiing: ${xhr.loaded}`)
    },
    (err )=>{
    console.error(err)
  });


  grid.name = "gridVisualizer"
  scene?.scene?.add(grid);
  buildVolumeVisualization.name = "volumeVisualizer"
  scene?.scene?.add(buildVolumeVisualization);

  scene?.render();
}

export default saveFile;
