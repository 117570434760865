import { v4 as uuidv4 } from 'uuid';
import IPartInfo from "../../interfaces/IPartInfo";
import { IState } from "../Interfaces";

const getPartInfo = (addedPart, addedPartIndex, state: IState) => {

    const partInfo = ({
        customLabel: `Part ${addedPartIndex + 1}`,
        buildErrors: false,
        UUID: uuidv4(),
        properties: {
            PartID: addedPart.id,
            PartConfig: undefined,
            SlicerConfig: undefined,
        },
    } as IPartInfo);

    return partInfo;
};

export default getPartInfo;