import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onSelectFolder, onUnselectFolder } from "../../../Actions/Home";
import onHighlightFolder from "../../../Actions/Folders/Highlight/highlightFolder"
import onHighlightFolderId from "../../../Actions/Folders/Highlight/highlightFolderId"
import { IState } from "../../../Interfaces";
import { DisplayPartCardView, DisplayPartListView, DisplayPlateCardView, DisplayPlateListView, DisplayBuildCardView, DisplayBuildListView, DisplayFolderOptions } from "../..";
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {

	const showFolderView = Boolean(state.data.info.showFolderView) as boolean;
	const showListView = Boolean(state.data.info.showListView) as boolean;

	const props = {
		displayFolder: ownProps.displayFolder,
		showParts: ownProps.showParts,
		showPlates: ownProps.showPlates,
		showBuilds: ownProps.showBuilds,
		showFolderView: showFolderView,
		showListView: showListView,
        enableMultiSelect: state.enableBatchDelete
	};

	return props;
};

const Actions = {
	onSelectFolder: onSelectFolder,
	onUnselectFolder: onUnselectFolder,
	onHighlightFolder:onHighlightFolder,
	onHighlightFolderId:onHighlightFolderId
};

class Component extends React.Component<any, any> {
	createdDate = this.props.displayFolder?.createdDate;
    formatedDate = navigator.userAgent.indexOf('Mac OS X') != -1 ? 
	this.createdDate.substring(3, 5) + "/" + this.createdDate.slice(0, 3) + this.createdDate.substring(5, this.createdDate.length) 
	: this.props.displayFolder.createdDate;
	

	public render() {
		return (
			<>
				{
					!this.props.showFolderView ?
						(
							<>
								{/* <OverlayTrigger
									placement="top"
									overlay={
										<Tooltip id="icon-display-card">
											<>
												<div className="display-card">
													<div className="display-card-row">
														<div className="display-card-sub-header">
															<label>created date:</label>
															<label>
																{this.props.displayFolder.createdDate}
															</label>
															<label>created by:</label>
															<label>{this.props.displayFolder.creator}</label>
														</div>
													</div>
												</div>
											</>
										</Tooltip>
									}
								> */}
									<div
										className={`display-card is-selected-${this.props.displayFolder.isSelected}`}
										id={`folder-${this.props.displayFolder.index}`}
										key={`${this.props.displayFolder.index}`}
										onClick={() => {
											if (!this.props.displayFolder.isSelected) {
												this.props.onSelectFolder(this.props.displayFolder.id);
												this.props.onHighlightFolder(this.props.displayFolder.id);
												this.props.onHighlightFolderId(this.props.displayFolder.id);
											} else {
												this.props.onUnselectFolder(this.props.displayFolder.id);
												this.props.onHighlightFolder("");
												this.props.onHighlightFolderId("");
											}
										}}
									>
										<div className="img-col folder-img">
											<img src="/folder-thumbnail.svg" alt="thumbnail image" className='thumbnail-img' />
										</div>
										<div className='display-card-row'>
											<div className='display-card-header'>
												<label title={this.props.displayFolder.name}>{this.props.displayFolder.name} </label>
											</div>
											<div className="display-card-more-action">
												<DisplayFolderOptions
													{...{ displayFolder: this.props.displayFolder }}
												/>
											</div>
										</div>
										<div className='display-card-row'>
											<div className='display-card-sub-header'>
												<>
													<div className='details'>
														<label>Created date:</label>
														<span>{this.createdDate ? moment(new Date(this.formatedDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span>
													</div>
													<div className='details'>
														<label>Created by:</label>
														<span>{this.props.displayFolder.creator}</span>
													</div>
												</>
											</div>
										</div>
									</div>
								{/* </OverlayTrigger> */}
								<>
									{this.props.displayFolder.isSelected ? (
										<>
											<>
												{this.props.showParts ? (
													<>
														{
															this.props.showListView ?
																(
																	<>
																		<div className="display-list">
																			<div className="display-list-row">
																				<div className="display-list-header">
																					<label>Type</label>
																				</div>
																				<div className="display-list-header">
																					<label>Name</label>
																				</div>
																				<div className="display-list-header">
																					<label>Imported Date</label>
																				</div>
																				<div className="display-list-header">
																					<label>Owner</label>
																				</div>
																				<div className="display-list-header">
																					<label>No of Plates</label>
																				</div>
																				<div className="display-list-header">
																					<label>Folder</label>
																				</div>
																				<div className="display-list-header">
																					<label>Actions</label>
																				</div>
																			</div>
																		</div>
																	</>
																)
																:
																(
																	null
																)
														}
														{
															this.props.displayFolder.displayParts
																.filter(dp => dp.folders.some(f => f.name === this.props.displayFolder.name))
																.map((dp, i) => (this.props.showListView ? <DisplayPartListView key={i} displayPart={dp} /> : <DisplayPartCardView key={i} displayPart={dp} isSelected={!this.props.enableMultiSelect ? true : false} />))
														}
													</>
												) : null}
											</>
											<>
												{this.props.showPlates ? (
													<>
														{
															this.props.showListView ?
																(
																	<>
																		<div className="display-list">
																			<div className="display-list-row">
																				<div className="display-list-header">
																					<label>Type</label>
																				</div>
																				<div className="display-list-header">
																					<label>Name</label>
																				</div>
																				<div className="display-list-header">
																					<label>Imported Date</label>
																				</div>
																				<div className="display-list-header">
																					<label>Owner</label>
																				</div>
																				<div className="display-list-header">
																					<label>No of Builds</label>
																				</div>
																				<div className="display-list-header">
																					<label>Folder</label>
																				</div>
																				<div className="display-list-header">
																					<label>Actions</label>
																				</div>
																			</div>
																		</div>
																	</>
																)
																:
																(
																	null
																)
														}
														{
															this.props.displayFolder.displayPlates
																.filter((dp) => dp.folders.some(f => f.name === this.props.displayFolder.name))
																.map((dp, i) => (this.props.showListView ? <DisplayPlateListView key={i} displayPlate={dp} /> : <DisplayPlateCardView key={i} displayPlate={dp} />))
														}
													</>
												) : null}
											</>
											<>
												{this.props.showBuilds ? (
													<>
														{
															this.props.showListView ?
																(
																	<>
																		<div className="display-list">
																			<div className="display-list-row">
																				<div className="display-list-header">
																					<label>Type</label>
																				</div>
																				<div className="display-list-header">
																					<label>Name</label>
																				</div>
																				<div className="display-list-header">
																					<label>Build Date</label>
																				</div>
																				<div className="display-list-header">
																					<label>Owner</label>
																				</div>
																				<div className="display-list-header">
																					<label>Status</label>
																				</div>
																				<div className="display-list-header">
																					<label>Folder</label>
																				</div>
																				<div className="display-list-header">
																					<label>Actions</label>
																				</div>
																			</div>
																		</div>
																	</>
																)
																:
																(
																	null
																)
														}
														{
															this.props.displayFolder.displayBuilds
																.filter((db) => db.folders.some(f => f.name === this.props.displayFolder.name))
																.map((db, i) => (this.props.showListView ? <DisplayBuildListView key={i} displayBuild={db} /> : <DisplayBuildCardView key={i} displayBuild={db} />))
														}
													</>
												) : null}
											</>
										</>
									) : null}
								</>
							</>
						)
						:
						null
				}
			</>
		);
	}
}

const DisplayFolder = withRouter(connect(Props, Actions)(Component));

export default DisplayFolder;
