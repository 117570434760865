import IPartInfo from "../../../../interfaces/IPartInfo";
import { IState, IPart, IConfiguration } from "../../../Interfaces";

const onClearAddedPartsReducer = (state: IState, action) => {
    
    const newState = { ...state } as IState;
    newState.data.workspace.addedParts = [] as IPart[];
    newState.data.workspace.state.build.parts = [] as IPartInfo[];
    newState.data.workspace.addedPartsSelectedPartConfigurations = [] as IConfiguration[];
    newState.data.workspace.addedPartsSelectedPartSlicerConfigurations = [] as IConfiguration[];
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    return newState;
};

export default onClearAddedPartsReducer;