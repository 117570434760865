import ACTION_TYPES from '../../../actionTypes';
import { IPart, IState } from '../../../Interfaces';

import onDownload from './download';
import onUnselectPart from '../../Parts/Unselect/unselectPart';
import { NotificationManager } from "react-notifications";
const onDownloadPartRequest = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PART.REQUEST,
        payload: null,
    };
};

const onDownloadPartStart = (contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PART.START,
        payload: contentLength,
    };
};

const onDownloadPartInProgress = (processedLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PART.IN_PROGRESS,
        payload: processedLength,
    };
};

const onDownloadPartSuccess = (receivedLength: number, contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PART.SUCCESS,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadPartFail = (receivedLength: number, contentLength: number) => {
    NotificationManager.error('Failure on downloading part');
    console.error('Failure on downloading part - receivedLength', receivedLength, 'contentLength', contentLength);
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PART.FAIL,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadPartDone = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.PART.DONE,
        payload: null,
    };
};

const onDownloadPart = (partId, key="data") => {
    return async (dispatch, getState) => {
        const state = getState() as IState;
        // const selectedPart = state.data.parts.loadedParts.find(p => p.id === partId) as IPart;
        const plateIndex = state.data.parts.loadedParts.find(p => p.id === partId);
        const selectedPart =  plateIndex ? { ...state.data.parts.loadedParts.find(p => p.id === partId) } as IPart : state.data.search.highlightedPartData;
        const files = JSON.parse(selectedPart.files);
        const storagePath = files?.[key]?.replace('public/', '').replace(/[{}]/g, '') || '' as string;
        const saveAsFileName = storagePath.replace(/^.*[\\/]/, '')  || 'download_error.txt' as string;
        const dispatchables = {
            onDownloadRequest: onDownloadPartRequest,
            onDownloadStart: onDownloadPartStart,
            onDownloadInProgress: onDownloadPartInProgress,
            onDownloadSuccess: onDownloadPartSuccess,
            onDownloadFail: onDownloadPartFail,
            onDownloadDone: onDownloadPartDone,
        };
        await onDownload(storagePath, saveAsFileName, dispatchables)(dispatch, getState);
        dispatch(onUnselectPart(partId));
    };
};

export default onDownloadPart;