import { IPart, IState } from '../../../Interfaces';

const onMovePartSuccessReducer = (state: IState, action) => {
    const movedPart = action.payload as IPart;
    const indexToBeReplaced = state.data.parts.loadedParts.findIndex(lp => lp.id === movedPart.id);
    const isPartExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPartExists) newState.data.parts.loadedParts.splice(indexToBeReplaced, 1, movedPart);

    newState.data.modals.movePartModalStatus = {
        ...newState.data.modals.movePartModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onMovePartSuccessReducer;