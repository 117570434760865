import API, { graphqlOperation } from '@aws-amplify/api';
import { union } from 'lodash';

import { configurationByCreatedAt } from '../../../../graphql/queries';

import ACTION_TYPES from '../../../actionTypes';
import { IConfiguration } from '../../../Interfaces';

const getAvailableConfigurations = (configurations: IConfiguration[] = []) => {
    return configurations.filter(c => Boolean(c && !c._deleted));
};

const onLoadConfigurationsStarted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.STARTED,
        payload: null,
    };
};

const onLoadConfigurationsInProgress = (newlyLoadedConfigurations) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.IN_PROGRESS,
        payload: newlyLoadedConfigurations,
    };
};

const onLoadConfigurationsCompleted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.COMPLETED,
        payload: null,
    };
};

const loadConfigurations = async (dispatch, getState, response: { loadedConfigurations: IConfiguration[], nextToken: any } = { loadedConfigurations: [] as IConfiguration[], nextToken: undefined }) => {
    try {
        const variables = {
            limit: 50,
            dumb: 1,
            sortDirection: 'DESC',
            nextToken: response.nextToken,
        };
        const result = await API.graphql(graphqlOperation(configurationByCreatedAt, variables)) as any;
        const loadedConfigurations = getAvailableConfigurations(response?.loadedConfigurations as IConfiguration[]);
        const newlyLoadedConfigurations = getAvailableConfigurations((result as any)?.data?.configurationByCreatedAt?.items || [] as IConfiguration[]);
        dispatch(onLoadConfigurationsInProgress(newlyLoadedConfigurations));

        const configurations = getAvailableConfigurations(union(loadedConfigurations, newlyLoadedConfigurations) as IConfiguration[]);
        const nextToken = (result as any)?.data?.configurationByCreatedAt?.nextToken || undefined;
        const newResponse = {
            loadedConfigurations: configurations,
            nextToken: nextToken,
        };
        if (nextToken) await loadConfigurations(dispatch, getState, newResponse);
        return Promise.resolve();
    } catch (graphqlError) {
        return Promise.resolve();
    }
};

const onLoadConfigurations = () => {
    return async (dispatch, getState) => {
        dispatch(onLoadConfigurationsStarted());
        await loadConfigurations(dispatch, getState);
        dispatch(onLoadConfigurationsCompleted());
    };
};

export default onLoadConfigurations;