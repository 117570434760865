import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingComponent from "../../../LoadingComponent/LoadingComponent";
import { IDisplayPart, IState } from "../../Interfaces";
import SearchBuildCardView from "./SearchBuildCardView"
import SearchPartCardView from "./SearchPartCardView"
import SearchPlateCardView from "./SearchPlateCardView";
import { SortDirection, TabIndex } from "../../Enums";
import { onHideSelectedParts, onSelectPart, onShowSelectedParts, onUnselectPart } from "../../Actions/Parts";
import { getDisplayParts } from "../../Mappers";
import DisplayDashboardParts from "../Prepare/Parts/DisplayDashboardParts";
import DisplayParts from "../Prepare/Parts/DisplayParts";
import RenamePlate from "../Prepare/Plates/RenamePlate";
import CopyPlate from "../Prepare/Plates/CopyPlate";
import MovePlate from "../Prepare/Plates/MovePlate";
import NewBuild from "../NewBuild";
import MoveBuild from "../Prepare/Builds/MoveBuild";
import RenamePart from "../Prepare/Parts/RenamePart";
import CopyPart from "../Prepare/Parts/CopyPart";
import MovePart from "../Prepare/Parts/MovePart";
import NewPlate from "../NewPlate";
import NewBuildSuccess from "../NewBuildSuccess";

const Props = (state: IState, ownProps: any) => {
    const displayParts = getDisplayParts(state) as IDisplayPart[];

    const totalParts = (
        (state.data.builds.sortDirection === SortDirection.ASC) ?
            displayParts.reverse() :
            displayParts
    ) as IDisplayPart[];

    const filteredParts = (
        totalParts.filter(dp => dp.isFiltered)
    ) as IDisplayPart[];

    const selectedParts = (
        state.data.parts.selectedPartIds.map(id => filteredParts.find(lp => lp.id === id))
    ) as IDisplayPart[];
    const props = {
        searchKey: state.data.search.searchString,
        selectedTabIndex: state.data.info.homePageTabIndex as TabIndex,
        partsSearchResults: state.data.search?.searchPartsResults,
        platesSearchResults: state.data.search?.searchPlatesResults,
        buildsSearchResults: state.data.search?.searchBuildsResults,
        selectedParts: selectedParts as IDisplayPart[],
        filteredParts: filteredParts as IDisplayPart[],
        displayParts: ownProps.displayParts,
        ...ownProps,
    };

    return props;
};
const Actions = {
    onSelectPart: onSelectPart,
    onUnselectPart: onUnselectPart,
    onShowSelectedParts: onShowSelectedParts,
    onHideSelectedParts: onHideSelectedParts,
};

const DisplaySearchResults = (props) => {
    const partCardView = () => {
        return (
            <>
                <>
                    <DisplayDashboardParts
                        {...{
                            label: 'Selected Parts',
                            displayParts: props.selectedParts,
                            showOptions: true,
                            showItems: true,
                            onShowItems: props.onShowSelectedParts,
                            onHideItems: props.onHideSelectedParts,
                            isSelected: true,
                            onUnselect: props.onUnselectPart,
                            onSelect: props.onSelectPart,
                        }}
                    />
                </>
            </>
        )
    };

    const [searchResults, setSearchResults] = useState(() => {
        switch (props.selectedTabIndex) {
            case TabIndex.ALL:
                return [...new Set([...props.partsSearchResults, ...props.platesSearchResults, ...props.buildsSearchResults])];
            case TabIndex.PARTS:
                return props.partsSearchResults;
            case TabIndex.PLATES:
                return props.platesSearchResults;
            case TabIndex.BUILDS:
                return props.buildsSearchResults;
            default:
                return [];
        }
    });

    return (
        <>
               {
                    partCardView()
               }
            <dl>
                <dd>
                    {
                        searchResults && searchResults.length > 0 &&
                        (
                            searchResults.sort((a: any, b: any) => b.created_at.localeCompare(a.created_at)).map((el, idx) =>
                            (<div key={idx} className="display-card pt-0">
                                {"build_search_string" in el && <SearchBuildCardView key={el.id} build={el}></SearchBuildCardView>}
                                {"parts_search_string" in el && <SearchPartCardView
                                    key={el.id}
                                    part={el}
                                    isSelected={`false`}
                                    onUnselect={`${props.onUnselectPart}`}
                                    onSelect={`${props.onSelectPart}`}

                                />}
                                {"plate_search_string" in el && <SearchPlateCardView key={el.id} plate={el}></SearchPlateCardView>}
                            </div>)
                            )
                        )
                    }
                    {
                        searchResults && searchResults.length === 0 &&
                        (
                            <div className="no-result-found">
                                <img src="/no-result-found.svg" alt="no-search-result" className="icon-small"/>
                                <p className="justify-content-center p-4">No result found in the name of <b>{` ${props.searchKey}`}</b></p>
                            </div>
                        )
                    }
                </dd>
            </dl>
            <RenamePlate />
            <CopyPlate />
            <MovePlate />
            <RenamePart />
            <CopyPart />
            <MovePart />
            <NewBuild />
            <MoveBuild />
            <NewPlate />
            <NewBuildSuccess />
            </>
    );
};

const ListSearchResults = withRouter(
    connect(Props, Actions)(DisplaySearchResults)
);

export default ListSearchResults;
