import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPlateListView, DisplayPlateCardView, DisplayPlatesOptions } from '../..';
import { IState } from '../../../Interfaces';
import DisplayFolder from '../Common/DisplayFolder';

const Props = (state: IState, ownProps: any) => {

    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
        Boolean(ownProps.displayPlates && ownProps.displayPlates.length > 0) as boolean;

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        showListView: state.data.info.showListView as boolean,
        isLoadingComplete: state.data.plates.isLoadingComplete as boolean,
        displayFolders: ownProps.displayFolders,
        displayPlates: ownProps.displayPlates,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
    };

    return props;
};

const Actions = {

};

class Component extends React.Component<any, any> {

    componentDidUpdate(prevProps) {
        if (this.props.isLoadingComplete && this.props.displayPlates.length > prevProps.displayPlates.length) {
            if (this.props.displayPlates.every(dp => dp.isSelected)) {
                this.props.onShowItems();
            }
            if (this.props.displayPlates.every(dp => dp.isRecent)) {
                this.props.onShowItems();
            }
        }
    }

    public render() {
        return (
            <>
                <>
                    {
                        (this.props.hasDisplayItems) ?
                            (
                                <>
                                    <dl className={`${this.props.showListView ? "grid-view" : ""}`}>
                                        <dt>
                                            <div className='options left-justify'>
                                                <div className={`${this.props.showItems ? 'expand' : 'collapse'} option`}>
                                                    <img className={`icon ${this.props.showItems ? 'down-arrow' : 'right-arrow'}`}
                                                        alt=''
                                                        onClick={() => this.props.showItems ? this.props.onHideItems() : this.props.onShowItems()} />
                                                    <label>
                                                        {this.props.label}
                                                    </label>
                                                    <span>
                                                        {` listing ${this.props.displayPlates.length} plates, listing ${this.props.displayFolders.length} folders`}
                                                    </span>
                                                </div>
                                            </div>
                                            <DisplayPlatesOptions showOptions={this.props.showOptions} />
                                        </dt>
                                        <>
                                            {
                                                this.props.showItems ?
                                                    (
                                                        <dd>
                                                            {
                                                                this.props.displayFolders.map((df, i) => (<DisplayFolder key={i} displayFolder={df} showParts={false} showPlates={true} showBuilds={false} />))
                                                            }
                                                        </dd>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </>
                                        <>
                                            {
                                                this.props.showItems ?
                                                    (
                                                        <dd>
                                                            {
                                                                <>
                                                                    {
                                                                        this.props.showListView ?
                                                                            (
                                                                                <>
                                                                                    <div className="display-list">
                                                                                        <div className="display-list-row">
                                                                                            <div className="display-list-header">
                                                                                                <label>Type</label>
                                                                                            </div>
                                                                                            <div className="display-list-header">
                                                                                                <label>Name</label>
                                                                                            </div>
                                                                                            <div className="display-list-header">
                                                                                                <label>Imported Date</label>
                                                                                            </div>
                                                                                            <div className="display-list-header">
                                                                                                <label>Owner</label>
                                                                                            </div>
                                                                                            <div className="display-list-header">
                                                                                                <label>No of Builds</label>
                                                                                            </div>
                                                                                            <div className="display-list-header">
                                                                                                <label>Folder</label>
                                                                                            </div>
                                                                                            <div className="display-list-header">
                                                                                                <label>Actions</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                    {
                                                                        this.props.displayPlates.map((dp, i) => (this.props.showListView ? <DisplayPlateListView key={i} displayPlate={dp} /> : <DisplayPlateCardView key={i} displayPlate={dp} />))
                                                                    }
                                                                </>
                                                            }
                                                        </dd>
                                                    ) :
                                                    (
                                                        null
                                                    )
                                            }
                                        </>
                                    </dl>
                                </>
                            ) :
                            (null)
                    }
                </>
            </>
        );
    }
}

const DisplayPlates = withRouter(connect(Props, Actions)(Component));

export default DisplayPlates;