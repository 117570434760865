import React from "react";
import { Link } from "react-router-dom";
import { version } from "../../package.json";
import * as build from "../build.json";
import "./SideBar.css";
import {MohoVersion} from "../Distributions/MohoVersion";

const SideBar: React.FunctionComponent = (props) => {
  return (
    <div className={"sideBarContainer"}>
      <Link to={"/"}>
        <img
          style={{ width: "100%", padding: "10px" }}
          alt={"mantle3d"}
          src="/logo.png"
        />
      </Link>

      {props.children}

      <div className={"sideBarVersionBlock"}>
        version: {version}
        <br />
        build: {build.build}
        <MohoVersion/>
      </div>
    </div>
  );
};

export default SideBar;
