import { IState } from '../../../Interfaces';

const onHighlightConfigurationTypeReducer = (state: IState, action) => {
    
    const configurationType = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.configurations.highlightedConfigurationId = '';
    newState.data.configurations.highlightedConfigurationType = configurationType;
    
    return newState;
};

export default onHighlightConfigurationTypeReducer;