import { setPartsConfigurations } from "../../../Instances";
import { IState, IConfiguration } from "../../../Interfaces";

const onSelectPartConfigurationReducer = (state: IState, action) => {
    const configurationId = action.payload as string;
    const configuration = state.data.configurations.loadedConfigurations.find(lc => lc.id === configurationId) as IConfiguration;

    const newState = { ...state } as IState;
    switch (configuration.type) {
        case 'partConfig':
            newState.data.workspace.addedPartsSelectedPartIndices.forEach(partIndex => {
                newState.data.workspace.addedPartsSelectedPartConfigurations[partIndex] = configuration;
            });
            break;
        case 'partSlicerConfig':
            newState.data.workspace.addedPartsSelectedPartIndices.forEach(partIndex => {
                newState.data.workspace.addedPartsSelectedPartSlicerConfigurations[partIndex] = configuration;
            });
            break;
        default:
            break;
    }

    const newStateAfterAddedConfigurations = setPartsConfigurations(newState, newState.data.workspace.addedPartsSelectedPartConfigurations.map(c => c.id), newState.data.workspace.addedPartsSelectedPartSlicerConfigurations.map(c => c.id), false) as IState;
    
    newStateAfterAddedConfigurations.data.workspace.isLoadingInProgress = !newStateAfterAddedConfigurations.data.workspace.isLoadingInProgress;
    
    return newStateAfterAddedConfigurations;
};

export default onSelectPartConfigurationReducer;
