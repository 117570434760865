import { IState } from '../../../Interfaces';

const onCopyConfigurationCloseDialogReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.modals.copyConfigurationModalStatus = {
        ...newState.data.modals.copyConfigurationModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onCopyConfigurationCloseDialogReducer;