import {IBuild, IState} from '../../../Interfaces';
import getDisplayBuild from "../../../Mappers/mapBuildToDisplayBuild";

const onLoadBuildsCreatedReducer = (state: IState, action) => {

    const newlyLoadedBuilds = action.payload as IBuild;
    const newState = { ...state } as IState;
    newState.data.builds.loadedBuilds = [newlyLoadedBuilds, ...state.data.builds.loadedBuilds]

    return newState;
};

export default onLoadBuildsCreatedReducer;
