import API, { graphqlOperation } from '@aws-amplify/api';

import { updateBuild } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IState } from '../../../Interfaces';
import { onNavigateToHomePage } from "../../Home";
import onResetSearch from "../../Search/reset"
import onGetHighlightedBuild from "../../Search/highlightBuild";
import { onDisplayLoader } from '../../Search';

const onCancelBuildInit = () => {
    return {
        type: ACTION_TYPES.BUILD.CANCEL.INIT,
        payload: null,
    };
};

const onCancelBuildDone = (canceledBuild) => {
    return {
        type: ACTION_TYPES.BUILD.CANCEL.DONE,
        payload: canceledBuild,
    };
};

const onCancelBuild = (buildId,history) => {
    return async (dispatch, getState) => {
        dispatch(onCancelBuildInit());
        const state = getState() as IState;
        if (state.data.search.isSearchEnabled) {
            dispatch(onDisplayLoader(true))
            await dispatch(onGetHighlightedBuild(buildId))
        }

        const buildToBeCanceled = !state.data.search.isSearchEnabled ? state.data.builds.loadedBuilds.find(b => b.id === buildId) as IBuild : state.data.search.highlightedBuildData as IBuild
        const initiator = state.creator as string;

        buildToBeCanceled.current_status = 'canceled';
        buildToBeCanceled.canceled_by = initiator;
        delete buildToBeCanceled._deleted;
        delete buildToBeCanceled._lastChangedAt;
        delete buildToBeCanceled.createdAt;
        delete buildToBeCanceled.updatedAt;

        const variables = {
            input: buildToBeCanceled,
        };
        const result = await API.graphql(graphqlOperation(updateBuild, variables));
        const canceledBuild = ((result as any)?.data?.updateBuild) as IBuild;
        dispatch(onCancelBuildDone(canceledBuild));
        if (state.data.search.isSearchEnabled) {
            dispatch(onDisplayLoader(false))           
            dispatch(onNavigateToHomePage(history));
            dispatch(onResetSearch())
        }
        
    };
};

export default onCancelBuild;