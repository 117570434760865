import React, { useEffect, useState, FunctionComponent } from "react";

type BundleBuildLogProps={
    logStreamName: String
}

const AWS = require('aws-sdk');
// Set the region
AWS.config.update({region: 'us-east-1'});

// Create CloudWatch service object
const cwl = new AWS.CloudWatchLogs();

const BundleBuildLog: FunctionComponent<BundleBuildLogProps> = (props: BundleBuildLogProps)=>{
    const [nextKey, setNextKey] = useState<any>(null)
    const [logs, setLogs] = useState<any[]>([])
    const getLogs = async()=>{
        let needContinue
        const limit = 100
        do{
            let response = await cwl.getLogEvents({
                logGroupName: "/lava-bv/builds",
                logStreamName: props.logStreamName,
                limit:limit,
                nextToken: nextKey
            }).promise()
            setLogs(logs.concat(response.data))
            setNextKey(response.nextForwardToken)
            needContinue = response.data.size() === limit
        }while(needContinue)
    }

    useEffect(()=>{
        getLogs().catch(reason => {console.error(reason)})
    }, [])

   return <>
   <table>
       <tr><th>TimeStamp</th><th>Message</th></tr>
       {logs.map( val =>{
           return <tr><td>{new Date(val.timestamp).toLocaleString()}</td><td>{val.message}</td></tr>
       })}
   </table>
   </>
}
export default BundleBuildLog

