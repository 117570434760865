import { IState } from '../../../Interfaces';

const onCopyConfigurationFailReducer = (state: IState, action) => {
    const copyConfigurationErrorMessage = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.copyConfigurationModalStatus = {
        ...newState.data.modals.copyConfigurationModalStatus,
        hasError: true,
        errorMessage: copyConfigurationErrorMessage,
    };

    return newState;
};

export default onCopyConfigurationFailReducer;