import { TabIndex } from '../../../Enums';
import { IState, } from '../../../Interfaces';

const onHomePageTabChangeReducer = (state: IState, action) => {
    const selectedTabIndex = action.payload as TabIndex;

    const newState = { ...state } as IState;
    newState.data.info.homePageTabIndex = selectedTabIndex as TabIndex;
    newState.data.folders.selectedFolderIds = [] as string[];
    newState.data.search.isSearchEnabled =false as boolean;
    newState.data.search.searchString = '' as string;
    newState.data.search.searchCategory = '' as string;
    newState.data.search.searchPartsResults = [] as string[];
    newState.data.search.searchPlatesResults = [] as string[];
    newState.data.search.searchBuildsResults = [] as string[];
    newState.data.search.partsSearchCompleted = false as boolean;
    newState.data.search.platesSearchCompleted = false as boolean;
    newState.data.search.buildsSearchCompleted = false as boolean;

    return newState;
};

export default onHomePageTabChangeReducer;