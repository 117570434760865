import React, { useEffect, useState, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { version } from "../../package.json";
import * as build from "../build.json";
import "./MVPBuilds.scss";
import { MohoVersion } from "../Distributions/MohoVersion";
import { updateBuild } from "../graphql/mutations";

import { createParts } from "../graphql/mutations";
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import API, { graphqlOperation } from "@aws-amplify/api";
import { listBuilds, buildByCreatedAt, buildByInitiated } from "../graphql/queries";
import Amplify, { Auth, Storage } from "aws-amplify";
import { createBuild } from "../graphql/mutations";
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import ProgressBar from 'react-bootstrap/ProgressBar'
import useInterval from "@use-it/interval";
import { connect } from "react-redux";
import { IAddConfigurationModalStatus, IState } from "../Beta/Interfaces";
import {
	onAddConfiguration,
	onAddConfigurationCloseDialog,
	onAddConfigurationDataChange,
	onAddConfigurationNameChange, onAddConfigurationTypeChange
} from "../Beta/Actions/Configurations";
import { floor } from "lodash";
const Props = (state: IState, ownProps: any) => {

	const props = {
		builds: state.data.builds.loadedBuilds
	};

	return props;
};

const Actions = {
};

class MVPBuilds extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			yourBuilds: [],
			allBuilds: [],
		};
		this.get_current_list();
	}

	public onFileChange = (event) => {
		// Update the state
		this.setState({ selectedFile: event.target.files[0] });

	};

	public get_current_list = async () => {
		const initiator = (await Auth.currentUserInfo()).attributes.email;
		this.setState({
			yourBuilds: this.props.builds.filter(a =>
			{
				return a.created_by == initiator
			}),
			others: this.props.builds.filter(a => a.created_by != initiator)
		});
	};

	componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
		this.get_current_list().catch(reason => console.log(reason))
	}

	public cancel = async (myValue) => {
		const initiator = (await Auth.currentUserInfo()).attributes.email;
		myValue.current_status = "canceled";
		myValue.canceled_by = initiator;
		delete myValue._deleted;
		delete myValue._lastChangedAt;
		delete myValue.createdAt;
		delete myValue.updatedAt;
		try {
			const lst = await API.graphql(
				graphqlOperation(updateBuild, {
					input: myValue,
				})
			);
			console.log(lst);
		} catch (ex: any) {
			console.log(
				`Exception ${ex.toString()} updating failed`
			);
		}
	}

	public render() {
		const { pageView } = this.props;

		//  console.log('MVPBuilds~~~', this.props, pageView);
		return (
			<div className='MVPBuilds'>
				<div className='b-header'>
					Your Builds
				</div>
				<div className='your-build'>
					{
						this.state.yourBuilds.slice(0, 2).map((item: any, index) => {
							const labelId = `checkbox-build-label-${item.name}`;
							let percentage = ["completed", "failed", "canceled"].indexOf(item.current_status) !== -1? 100 :
								item.current_status.indexOf("Progress ")!==0?
									item.build_time < 100?1:100:
									item.current_status.substring("Progress ".length, item.current_status.indexOf("%"))
							percentage = Math.max(1, percentage)
							let secondsLeft = item.build_time / percentage * (100-percentage)
							const hoursLeft = floor(secondsLeft/3600)
							const minutesLeft = floor((secondsLeft - hoursLeft*3600)/60)
							secondsLeft = floor(secondsLeft%60)
							const hoursString = hoursLeft?`${hoursLeft}H `:""
							const formattedRemainTime = `${hoursString}${minutesLeft}M ${secondsLeft}S`
							return (
								<div className='each-b-section'>
									<div className='t-s'>
										<div className='t-s-b'>{item.name}</div>
										<div className='t-s-n'>
											Started on {item.created_at}
										</div>
										<div className='t-s-bu'>{item.current_status === "completed" ? (null) : (<Button variant="outline-secondary" className='outline-secondarytextactive-btn' onClick={() => {
											this.cancel(item);
										}}>Stop</Button>)}</div>
									</div>
									<div className='b-s'>
										<div className='b-s-l'>Lava is slicing your part</div>
										<div className='b-s-p'>
											<div className='b-s-p-t'>
												<div className='b-s-p-t-p'>Completed: {percentage}%</div>
												<div className='b-s-p-t-t'>{formattedRemainTime}</div>
												<div className='b-s-p-t-s'>...</div>
											</div>
											<div className='b-s-p-b'>
												<ProgressBar now={percentage} />
											</div>
										</div>
									</div>
								</div>
							);
						})
					}
				</div>
				<div className='b-header'>
					Build Pipeline
				</div>
				<div className="buildTable">
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Build Name</th>
								<th>Date & Time</th>
								{/* <th>Completion Time</th> */}
								<th>Status</th>
								<th>Owner</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								this.props.builds.map((item: any) => {
									const labelId = `checkbox-build-label-${item.name}`;
									return (
										<tr>
											<td>{item.name}</td>
											<td>{item.created_at}</td>
											{/* <td></td> */}
											<td>{item.current_status}</td>
											<td>{item.created_by}</td>
											<td>{item.current_status === "completed" ? (null) : (<Button variant="outline-secondary" className='outline-secondarytextgrey-btn' onClick={() => {
												this.cancel(item);
											}}>Stop</Button>)}</td>
										</tr>
									);
								})
							}

						</tbody>
					</Table>
				</div>
			</div>
		)
	}
}

export default withRouter(connect(Props, Actions)(MVPBuilds));
