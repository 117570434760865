import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {Vector3} from "three";

const backgroundColor = new THREE.Color("#FFFFFF");
const buildPlateColor = new THREE.Color("steelblue");

const STLLoader = require("three-stl-loader")(THREE);
const stlLoader = new STLLoader();

export interface IScene {
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  cameraControls: OrbitControls;

  render(): void;
  updateBuildPlateSize(newSize: THREE.Box3): void;
}

class Scene implements IScene {
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
  cameraControls: OrbitControls;

  buildPlatePosition: THREE.Box3;
  plate?: THREE.Mesh;
  isOrtho: boolean;

  public setCameraMode(mode) {
    this.isOrtho = mode;
    this.updateCamera();
  }

  private updateCamera() {
    let canvas = this.renderer.domElement;
    let width = canvas.clientWidth;
    let height = canvas.clientHeight;
    let wasOrtho = false;
    let distance = 400;
    let zoom = 1;

    if (this.camera !== undefined) {
      wasOrtho = (this.camera instanceof THREE.OrthographicCamera);

      let target = this.cameraControls.target.clone();
      target.sub(this.camera.position);
      distance = target.length();
      zoom = this.camera.zoom;

      this.scene.remove(this.camera);
    }

    // Z is up for objects intended to be 3D printed.
    if (this.isOrtho){
      this.camera = new THREE.OrthographicCamera(width / -8, width / 8, height / 8, height / -8, 1, 1000);
    }
    else {
      this.camera = new THREE.PerspectiveCamera(35, width / height, 1, 1000);
    }

    this.camera.up.set(0, 0, 1);
    this.camera.position.set(0, 0, 400);
    this.camera.updateProjectionMatrix();

    this.camera.add(new THREE.PointLight(0xffffff, 0.8));

    this.scene.add(this.camera);

    if (this.cameraControls) {
      this.cameraControls.saveState();
      this.cameraControls.object = this.camera;
      this.cameraControls.reset();

      // Since Orthographic and Projection cameras use different scale mode, we need
      // to restore scale as it was for previous mode
      let target = this.cameraControls.target.clone();
      let cam = this.camera.position.clone();
      cam.sub(target);

      if (!wasOrtho && this.isOrtho) { // Switching from projection to ortho
        zoom = zoom * (400 / distance);
        distance = 400;
      }
      if (wasOrtho && !this.isOrtho) { // Switching from ortho to projection
        distance = distance / zoom;
        zoom = 1;
      }

      target.addScaledVector(cam, distance / cam.length());
      this.camera.position.copy(target);
      this.camera.zoom = zoom;
      this.camera.updateProjectionMatrix();
      this.cameraControls.zoomChanged = true;

      this.cameraControls.update();
    }
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  updateBuildPlateSize(buildPlateSize: THREE.Box3) {
    this.buildPlatePosition = buildPlateSize;
    this.rescaleBuildPlate();
  }

  private rescaleBuildPlate() {
    if (this.plate) {
      let plateCurrentSize = new THREE.Vector3();
      new THREE.Box3().setFromObject(this.plate).getSize(plateCurrentSize);

      let plateSize =  new THREE.Vector3();
      this.buildPlatePosition.getSize(plateSize)
      let plateScale = new THREE.Matrix4().makeScale(
          plateSize.x / plateCurrentSize.x,
          plateSize.y / plateCurrentSize.y,
          1
      );

      this.plate!.applyMatrix4(plateScale);

      let plateBB = new THREE.Box3().setFromObject(this.plate);
      this.plate.translateX(this.buildPlatePosition.min.x-plateBB.min.x);
      this.plate.translateY(this.buildPlatePosition.min.y-plateBB.min.y);
      this.render();
    }
  }

  constructor(renderContainer: HTMLDivElement) {
    const width = renderContainer.clientWidth;
    const height = renderContainer.clientHeight;

    this.render = this.render.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.updateBuildPlateSize = this.updateBuildPlateSize.bind(this);

    window.addEventListener("resize", this.onWindowResize, false);

    this.scene = new THREE.Scene();
    this.scene.background = backgroundColor;
    this.scene.add(new THREE.AmbientLight(0x999999));
    const loader = new THREE.TextureLoader();
    // const bgTexture = loader.load('/img/workspace-bg.png');
    // this.scene.background = bgTexture;

    // Create Renderer
    this.renderer = new THREE.WebGLRenderer({preserveDrawingBuffer: true});
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height, false);
    this.renderer.setClearColor(0xffffff);

    // Wrap Renderer into container
    renderContainer.appendChild(this.renderer.domElement);
    this.updateCamera();

    this.buildPlatePosition = new THREE.Box3(new Vector3(-75, -75, 0), new Vector3(75,75,10));

    // Add Plate model
		/*
    stlLoader.load("/data/plate-bottommm.stl", (stlobject: THREE.Geometry) => {
      const material = new THREE.MeshLambertMaterial({ color: "#a9a9a9" });
      material.transparent = true;
      material.opacity = 0.5;
      const mesh = new THREE.Mesh(stlobject, material);

      let printer = new THREE.Group();
      printer.add(mesh);

      stlLoader.load("/data/plate-top.stl", (stlobject: THREE.Geometry) => {
        const material = new THREE.MeshLambertMaterial();
        material.color = buildPlateColor;
        material.transparent = true;
        material.opacity = 0.8;
        this.plate = new THREE.Mesh(stlobject, material);
        printer.add(this.plate);

        let plateBB = new THREE.Box3().setFromObject(this.plate);
        let plateSize = new THREE.Vector3();
        plateBB.getSize(plateSize);
        printer.translateX(-(plateBB.min.x + plateSize.x / 2));
        printer.translateY(-(plateBB.min.y + plateSize.y / 2));

        const printerBoundingBox = new THREE.Box3().setFromObject(printer);
        printer.position.z =
          printer.position.z - printerBoundingBox.max.z - 0.01;
        this.scene.add(printer);

        this.rescaleBuildPlate();
      });
    });
		*/

    //Set up cameraControls
    this.cameraControls = new OrbitControls(
      this.camera,
      this.renderer.domElement
    );
    this.isOrtho = false
    this.cameraControls.addEventListener("change", () => this.render());
    this.cameraControls.target.set(0, 0, 0);
    this.cameraControls.maxDistance = 800;
    this.cameraControls.minDistance = 20;

    this.cameraControls.update();

    this.render();
  }

  private setCamera() {
    let canvas = this.renderer.domElement;
    let width = canvas.clientWidth;
    let height = canvas.clientHeight;
    if (this.isOrtho){
      this.camera = new THREE.OrthographicCamera(35, width / height, 1, 1000);
    }
    else {
      this.camera = new THREE.PerspectiveCamera(35, width / height, 1, 1000);
    }

    this.camera.up.set(0, 0, 1);
    this.camera.position.set(0, 0, 400);
    this.camera.updateProjectionMatrix();

    this.camera.add(new THREE.PointLight(0xffffff, 0.8));

    this.scene.add(this.camera);
  }

  public switchViewDirection(direction) {
		console.log("Switching camera direction: ", direction);
    if (this.camera !== undefined) {
      let target = this.cameraControls.target.clone();
      target.sub(this.camera.position);
      let multiplier = target.length() / direction.length();
      target.copy(this.cameraControls.target);
      target.addScaledVector(direction, multiplier);
      this.camera.position.copy(target);
      this.camera.updateProjectionMatrix();

      this.cameraControls.update();
      this.render();
    }
  }

  public onWindowResize() {
    let canvas = this.renderer.domElement;
    let width = canvas.clientWidth;
    let height = canvas.clientHeight;
    if (width !== canvas.width || height !== canvas.height) {
      this.renderer.setSize(width, height, false);

      this.camera!.left = width / -8;
      this.camera!.right = width / 8;
      this.camera!.bottom = height / -8;
      this.camera!.top = height / 8;

      this.camera!.aspect = width / height;
      this.camera!.updateProjectionMatrix();

      this.render();
    }
  }
}

export default Scene;
