import { IState } from "../../../Interfaces";

const onHideArchivedBuildsReducer = (state: IState, action) => {

    const newState = { ...state } as IState;

    newState.data.sections.archivedBuilds = {
        ...newState.data.sections.archivedBuilds,
        showItems: false,
    };

    return newState;
};

export default onHideArchivedBuildsReducer;