import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
	onMoveBuildCloseDialog,
	onMoveBuildNameChange,
	onMoveBuild,
} from "../../../Actions/Builds";
import { IState, IMoveBuildModalStatus } from "../../../Interfaces";

const Props = (state: IState, ownProps: any) => {
	const moveBuildModalStatus = state.data.modals
		.moveBuildModalStatus as IMoveBuildModalStatus;

	const props = {
		folders: state.data.folders.loadedFolders,
		isModalOpen: moveBuildModalStatus.isOpen,
		inputText: moveBuildModalStatus.inputText,
		hasError: moveBuildModalStatus.hasError,
		errorMessage: moveBuildModalStatus.errorMessage,
		isValid: moveBuildModalStatus.isValid,
	};

	return props;
};

const Actions = {
	onMoveBuildCloseDialog: onMoveBuildCloseDialog,
	onMoveBuildNameChange: onMoveBuildNameChange,
	onMoveBuild: onMoveBuild,
};

class Component extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { selectedDropdownValue: "" };
	}
	public render() {
		return (
			<>
				{this.props.isModalOpen ? (
					<>
						<Modal
							show={this.props.isModalOpen}
							onHide={() => (
								this.props.onMoveBuildCloseDialog(),
								this.setState({
									selectedDropdownValue: "",
								})
							)}
							backdrop="static"
							keyboard={false}
							dialogClassName="buildsDialog"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header>
								<Modal.Title>Move</Modal.Title>
								<span className="close-btn"
									onClick={() =>
										this.props.onMoveBuildCloseDialog()
									}>
								</span>
							</Modal.Header>
							<Modal.Body>
								<div className="each-dialog-content">
									<div className="options">
										<div className="plate-folders option">
											<label>Select the folder</label>
											<DropdownButton id="dropdown-basic-button" title="Select">
												<Dropdown.Item
													onClick={() => this.props.onMoveBuildNameChange("")}
												>
													none
												</Dropdown.Item>
												{this.props.folders.map((folder) => {
													return (
														<>
															<Dropdown.Item
																onClick={() => {
																	this.props.onMoveBuildNameChange(folder.name);
																	this.setState({
																		selectedDropdownValue: folder.name,
																	});
																}}
															>
																{folder.name}
															</Dropdown.Item>
														</>
													);
												})}
											</DropdownButton>
										</div>
									</div>
									<div className="margin-t-10">
										Name : {this.state.selectedDropdownValue}
									</div>
									<div
										className="invalid-feedback d-block"
										hidden={!this.props.hasError}
									>
										{this.props.errorMessage}
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer className="justify-content-center">
								<Button
									className='btn-primary'
									onClick={() => this.props.onMoveBuild()}
									autoFocus
								>
									Move Build
								</Button>
							</Modal.Footer>
						</Modal>
					</>
				) : null}
			</>
		);
	}
}

const MoveBuild = withRouter(connect(Props, Actions)(Component));

export default MoveBuild;
