import { IState } from "../../../Interfaces";

const onUnselectPartOnAddedPartsReducer = (state: IState, action) => {
    const partIndex = action.payload as number;

    const newState = { ...state } as IState;
    newState.data.workspace.addedPartsSelectedPartIndices = newState.data.workspace.addedPartsSelectedPartIndices.filter(index => index !== partIndex);
    newState.data.workspace.isAllAddedPartsSelected = false;
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    return newState;
};

export default onUnselectPartOnAddedPartsReducer;