import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IState } from '../../../Interfaces';
import DisplayPartOptions from './DisplayPartOptions';

const Props = (state: IState, ownProps: any) => {

	const props = {

	};

	return props;
};

const Actions = {

};

class Component extends React.Component<any, any> {
	public render() {
		return (
			<>
				<div className='display-list'>
					<div className='display-list-row'>
						<div className='display-list-sub-header'>
							<div className='options left-justify'>
								<div className='part option'>
									<img className={this.props.displayPart.isMovedToFolder ? 'icon folder' : 'icon part'} alt='part' />
								</div>
							</div>
						</div>
						<div className='display-list-sub-header'>
							<label>{this.props.displayPart.name}</label>
						</div>
                        <div className='display-list-sub-header'>
							<label>{this.props.displayPart.fileSize}</label>
						</div>
						<div className='display-list-sub-header'>
							<label>{this.props.displayPart.createdDate}</label>
						</div>
						
						<div className='display-list-sub-header'>
							<div className='display-list-more-action'>
								<DisplayPartOptions {...{ displayPart: this.props.displayPart }} />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const DisplayReviewPartListView = withRouter(connect(Props, Actions)(Component));

export default DisplayReviewPartListView;
