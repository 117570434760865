import React, {FunctionComponent, useEffect, useState} from "react";

import {Auth} from 'aws-amplify';
import '../WorkSpace/BundlesList.css'
import useInterval from "@use-it/interval";
import {getDistributionsList} from "./MohoVersion";
const AWS = require('aws-sdk')


interface IDistributionListProps{
}

interface Distribution{
    product_id: any
    build_id: any
    release_note: any
    InstallerBucket: any
    InstallerBucketPath: any
    TestStatus: any
    deployed_at: any
}

// type Distribution = Omit<Exclude<APIt.GetDistributionQuery['getDistribution'], null>,
//     '__typename'>;

 const Distributions: FunctionComponent<IDistributionListProps> = ( props ) => {
    const [distributions, setDistribution] = useState< Distribution[]>([]);

    let get_currentlist_dynamoDb = async ()=>{
        const distrs:any = await getDistributionsList()
        setDistribution(distrs)           // successful response
    }

    useInterval(get_currentlist_dynamoDb, 3000)

    useEffect(()=>{
            Auth.currentCredentials().then( credentials =>{
                AWS.config.credentials=credentials
                get_currentlist_dynamoDb().then( () => {
                    console.log(`Got current list`)
                })
            })
    }, [])

    return (<div>
            <table className={"bundles"}>
                <tr className={"bundles"}>
                    <th className={"bundles"}>Name</th>
                    <th className={"bundles"}>Deployed</th>
                    <th className={"bundles"}>Comments</th>
                    <th className={"bundles"}>TestStatus</th>
                </tr>
                {distributions?distributions.map(
                    (myValue: Distribution) => {
                        return <tr className={"bundles"}>
                            <td className={"bundles"}>{myValue.build_id.S}</td>
                            <td className={"bundles"}>{myValue.deployed_at.S}</td>
                            <td className={"bundles"}>{myValue.release_note.S}</td>
                            {myValue.TestStatus?<td className={"bundles"}>{myValue.TestStatus.S}</td>:<td/>}
                        </tr>
                    }):""
                }
            </table>
        </div>
    )
}

export default Distributions