import { IConfiguration, IState } from "../../../Interfaces";

const onDeleteConfigurationReducer = (state: IState, action) => {
    const deletedConfiguration = action.payload as IConfiguration;
    const indexToBeReplaced = state.data.configurations.loadedConfigurations.findIndex(lp => lp.id === deletedConfiguration.id);
    const isConfigurationExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isConfigurationExists) newState.data.configurations.loadedConfigurations.splice(indexToBeReplaced, 1);

    newState.data.configurations.selectedConfigurationIds = newState.data.configurations.selectedConfigurationIds.filter(id => id !== deletedConfiguration.id);
    newState.data.configurations.deletedConfigurations.splice(
      0,
      0,
      deletedConfiguration
    );

    
    return newState;
};

export default onDeleteConfigurationReducer;