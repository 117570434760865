import { IState, IBuild } from "../../../Interfaces";

const onRebuildSuccessReducer = (state: IState, action) => {
  const addedBuild = action.payload as IBuild;

  const newState = { ...state } as IState;
  newState.data.builds.loadedBuilds.splice(0, 0, addedBuild);

  return newState;
};

export default onRebuildSuccessReducer;
