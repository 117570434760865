import { IConfiguration, IState } from '../../../Interfaces';

const onAddConfigurationSuccessReducer = (state: IState, action) => {
    const addedConfiguration = action.payload as IConfiguration;

    const newState = { ...state } as IState;
    newState.data.configurations.loadedConfigurations.splice(0, 0, addedConfiguration);
    
    newState.data.modals.addConfigurationModalStatus = {
        ...newState.data.modals.addConfigurationModalStatus,
        inputText: '',
        configurationType: '',
        data: {},
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onAddConfigurationSuccessReducer;