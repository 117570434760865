import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteBuildModal(props) {
  const { showModal, hideModal, highlightedBuildId, handleDelete } = props;
  return (
    <Modal centered show={showModal} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>Delete Build</Modal.Title>
        <span className="close-btn"
						onClick={hideModal}>
					</span>
      </Modal.Header>
      <Modal.Body className="modal-body text-center">
        <h6> Are you certain you want to delete this Build?</h6>
        <h6>{highlightedBuildId}</h6>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="primary">
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteBuildModal;
