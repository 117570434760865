import ACTION_TYPES from '../../../actionTypes';
import { onClearAddedParts } from '../../Parts';

const onNavigateToHomePageInit = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.HOME.INIT,
        payload: null,
    };
};

const onNavigateToHomePageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.HOME.DONE,
        payload: null,
    };
};

const onNavigateToHomePage = (history) => {
    return async (dispatch, getState) => {
        if (!history || typeof history.push !== 'function') {
            console.error("Invalid history object");
            return;
        }
        
        dispatch(onNavigateToHomePageInit());
        await dispatch(onClearAddedParts());
        history.push('/dashboard/home');
        dispatch(onNavigateToHomePageDone());
    };
};

export default onNavigateToHomePage;
