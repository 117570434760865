import { IState } from "../../../Interfaces";
import { SortDirection } from "../../../Enums";

const onSortBuildsDescReducer = (state: IState, action) => {

		console.log("CHANGING SORT DIRECTION TO DESC", );
    const newState = { ...state } as IState;
    newState.data.builds.sortDirection = SortDirection.DESC;

    return newState;
};

export default onSortBuildsDescReducer;
