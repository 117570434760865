// import { setPlateConfigurations, setPlateParts, setPartsConfigurations, setReviewPlateParts } from ".";
// import { IState } from "../Interfaces";

// const setPlate = (state: IState, ids: any, resetPlate: boolean = false): IState => {

//     const newState = { ...state } as IState;

//     const newStateAfterAddedParts = setReviewPlateParts(newState, ids.partIds, ids.parts, resetPlate) as IState;
//     return newStateAfterAddedParts;
// };

// export default setPlate;


import { setPlateConfigurations, setPlateParts, setPartsConfigurations } from ".";
import { IState } from "../Interfaces";

const setPlate = (state: IState, ids: any, resetPlate: boolean = false): IState => {

    const newState = { ...state } as IState;

    const newStateAfterAddedParts = setPlateParts(newState, ids.partIds, ids.parts, resetPlate) as IState;
    const newStateAfterPartConfigurations = setPartsConfigurations(newStateAfterAddedParts, ids.partConfigurationIds, ids.partSlicerConfigurationIds, resetPlate) as IState;
    const newStateAfterAddedConfigurations = setPlateConfigurations(newStateAfterPartConfigurations, ids.plateConfigurationIds, resetPlate) as IState;

    return newStateAfterAddedConfigurations;
};

export default setPlate;