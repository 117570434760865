import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DisplayBuildOptions } from "../..";
import { IState } from "../../../Interfaces";
import ProgressBar from "react-bootstrap/ProgressBar";
import { onNavigateToBuildReviewPage, onNavigateToBuildPreviewPage } from "../../../Actions/Home";
import { onSelectPart } from "../../../Actions/Parts";
import { Auth } from "aws-amplify";
import moment from 'moment';
import { OverlayTrigger,Tooltip } from 'react-bootstrap';
import { onSelectBuild, onUnselectBuild } from '../../../Actions/Builds';
import { NotificationManager } from "react-notifications";



const Props = (state: IState, ownProps: any) => {
	const props = {
		creator:state.creator,
		selectedBuildIds: state.data.builds.selectedBuildIds,
        enableBatchDelete: state.enableBatchDelete
	};

	return props;
};

const Actions = {
	onNavigateToBuildReviewPage: onNavigateToBuildReviewPage,
	onNavigateToBuildPreviewPage: onNavigateToBuildPreviewPage,
	onSelectPart: onSelectPart,
	onSelectBuild: onSelectBuild,
    onUnselectBuild: onUnselectBuild,
};

class Component extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			isBuildSelected: false,
		};
	}
	
	parts = (Boolean(this.props.displayBuild.parts)) as unknown as string[];
	isConfigDisabled = false;
	displayFolderview = this.props.displayBuild?.current_status !== "processing" ?
		this.props.displayBuild?.current_status : this.props.displayBuild.build_result;
	componentDidMount() {
		Auth.currentAuthenticatedUser().then((user) => {
			const groups =
				user.signInUserSession.accessToken.payload["cognito:groups"] || [];
			if (groups[0] == "Operator") {
				this.isConfigDisabled = true;
			} else {
				this.isConfigDisabled = false;
			}
		});
		// if(this.props.selectedBuildIds.some((item) => item === this.props.displayBuild.id)) {
		// 	this.setState({ isBuildSelected: true });
		// }
	}
	componentDidUpdate(): void {
		if(this.props.isDoneBatchDelete && this.state.isBuildSelected){
			this.setState({ isBuildSelected: false });
			this.props.resetBatchDelete();
		}
	}

	componentWillReceiveProps() : void {
		if(!this.props.selectedBuildIds.some((item) => item === this.props.displayBuild.id)) {
			this.setState({ isBuildSelected: false });
		}
		
        if(!this.props.enableBatchDelete && this.state.isBuildSelected && !this.props.selectedBuildIds.length) {
            this.setState({ isBuildSelected: false });
        }
        if(this.props.selectedBuildIds.length === 0 && this.props.enableBatchDelete && this.state.isBuildSelected) {
            this.setState({ isBuildSelected: false });
        }
	}
	onToggleSelectBuild = (id, checkedStatus) => {
		if (this.props.displayBuild?.created_by !== null || this.props.displayBuild?.created_by !== undefined || this.props.displayBuild?.created_by !== "") {
			if (this.props.displayBuild?.created_by !== this.props.creator) {
				NotificationManager.error('You lack the authorization to delete builds created by other users.');
			}
			else {
				this.setState({ isBuildSelected: !checkedStatus })
				!checkedStatus
					? this.props.onSelectBuild(id)
					: this.props.onUnselectBuild(id);
			}
		}
	}
	public render() {
		let buildProgress = 0;
		if ((this.props.displayBuild.current_status === "processing") && this.props.displayBuild.build_result) {
			buildProgress = parseFloat(this.props.displayBuild.build_result.replace("%", "").split(" ")[1]);
			if (isNaN(buildProgress)) {
				buildProgress = 0;
			}
		}
		return (
			<>
				<div
					className="display-card build-card-view position-relative p-0"
					id={`build-${this.props.displayBuild.index}`}
					key={`${this.props.displayBuild.index}`}
					onClick={() =>
						this.props.displayBuild?.current_status &&
							(
								(this.props.displayBuild?.current_status.toLowerCase()?.includes('completed')) ||
								(this.props.displayBuild?.current_status.toLowerCase()?.includes('fail') && !this.isConfigDisabled)
							) ?
							(this.props.onNavigateToBuildReviewPage(this.props.displayBuild.id, this.props.history)) : (null)
					}
				>
					
				
					
					{this.props.enableBatchDelete && <div className='display-card-select-action pt-2 pl-2 position-absolute' >
						<div className='options right-justify'>
							<>
								<div className='check option'>

									<img
										className={`icon ${this.state.isBuildSelected || this.props.selectedBuildIds.some((item) => item === this.props.displayBuild.id) ? 'check' : 'uncheck'}`}
										alt=''
										style={{ margin: '0px', padding: '4px' }}
										onClick={(e) => { e.stopPropagation(); this.onToggleSelectBuild(this.props.displayBuild.id, this.state.isBuildSelected || this.props.selectedBuildIds.some((item) => item === this.props.displayBuild.id)) }}
									/>
								</div>
							</>
						</div>
					</div>}

					<div className='img-col plate-thumb'>

						{
							this.props.displayBuild.icon ?
								<img src={this.props.displayBuild.icon} alt="" className='thumbnail-img' />
								: <img src="/build-thumbnail.svg" alt="" className='thumbnail-img' />
						}
					</div>
					<div className="display-card-row text-center pt-0">
						<div className="display-card-sub-header status-display">
							{this.props.displayBuild.moho_version ? (<label>v{this.props.displayBuild.moho_version}</label>) : ((<label className="invisible">_</label>))}

							{(this.props.displayBuild?.current_status === "completed" ||
								this.props.displayBuild?.status === "completed") && (
									<span className="capitalise-text font-weight-bold">

										<img src="/img/slicing-completed.svg" alt="Completed Status Icon" />
										{" " + "Slicing Completed"}
									</span>
								)}

							{(this.props.displayBuild?.current_status === "failed" ||
								this.props.displayBuild?.status === "failed") && (
									<span className="capitalise-text font-weight-bold">
										<img src="/img/slicing-failed.svg" alt="Failed Status Icon" />
										{" " + "Slicing Failed"}
									</span>
								)}
							{(this.props.displayBuild?.current_status === "canceled" ||
								this.props.displayBuild?.status === "canceled") && (
									<span className="capitalise-text font-weight-bold">
										<img src="/img/slicing-failed.svg" alt="Canceled Status Icon" />
										{" " + "Slicing Canceled"}
									</span>
								)}
							{(this.props.displayBuild?.current_status === "processing" || this.props.displayBuild?.current_status === "waiting" ||	
								this.props.displayBuild?.status === "processing" || this.props.displayBuild?.status === "waiting" || this.props.displayBuild?.current_status?.includes("Progress")) && (	
									<div className="px-3 w-50">	
										{this.props.displayBuild?.current_status === "processing" || this.props.displayBuild?.current_status?.includes("Progress")?
											(
												<label className="pl-0">{`${buildProgress}%`}</label>
											) : (
												<label className="pl-0">Initializing Build</label>
											)}
										<ProgressBar
											variant="success"
											now={buildProgress}
										/>
									</div>
								)}
							{
								(this.props.displayBuild.build_messages !== null && (this.props?.displayBuild?.build_messages && JSON.parse(this.props.displayBuild.build_messages).warnings?.length > 0)) ?
									(
										<OverlayTrigger
											placement="auto"
											trigger={["hover", "hover","focus"]}
											rootClose
											overlay={<Tooltip className="warning-popup" id='icon-clear'>
												<div className="warning" style={{ textAlign: 'left' }}>
													{

														JSON.parse(this.props.displayBuild.build_messages).warnings.map((el, index) => (<p key={index}>{el.slice(el.search('[warning]') + 8)}</p>))
													}
												</div>
											</Tooltip>}
										><img src="/img/warning.svg" alt="Warning Status Icon" className="mr-4" />
											
										</OverlayTrigger>
									) : (<label className="invisible">_</label>)
								
								// (<OverlayTrigger
								// 		placement='top'
								// 		overlay={<Tooltip id='icon-clear warning-popup'>
								// 			<div className="warning" style={{textAlign:'left'}}>
								// 				{
													
								// 					JSON.parse(this.props.displayBuild.build_messages).warnings.map((el, index) => (<p key={index}>{el.slice(el.search('[warning]') + 8, el.length - 3)}</p>))
								// 				}
								// 			</div>
								// 		</Tooltip>} >
										
								// 	</OverlayTrigger>
								// 	) : (<label className="invisible">_</label>)
							}
						</div>

					</div>
					<div className="display-card-row align-items-center pt-0">
						<div className="display-card-header">
							<label title={this.props.displayBuild.name}>{this.props.displayBuild.name}</label>
						</div>
						<div
							className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}
						>
							<DisplayBuildOptions displayBuild={this.props.displayBuild} />
						</div>
					</div>
					<div className='display-card-row'>
						<div className='display-card-sub-header'>
							<>
							<div className='details'>
                                    <label>Created Date:</label>
									<span className="data-format" title={this.props.displayBuild.created_at || this.props.displayBuild?.createdDate ? moment(new Date(this.props.displayBuild.created_at || this.props.displayBuild?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}>{this.props.displayBuild.created_at || this.props.displayBuild?.createdDate ? moment(new Date(this.props.displayBuild.created_at || this.props.displayBuild?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}</span>
                                </div>
								<div className='details'>
									<label>Parts Plated</label>
									<span>{this.props.displayBuild.noOfParts}</span>
								</div>
								{/* <div className='details'>
									<label>File Size:</label>
									<span>34MB</span>
								</div>
								<div className='details mb-4'>
									<label>Dimensions:</label>
									<span>50mm x 50mm x 16mm</span>
								</div> */}
							</>
						</div>
					</div>

					{
						/*
						<div className="display-card-row">
							<div className="display-card-sub-header">
								<>
									{this.props.displayBuild.isMovedToFolder ? (
										<>
											{this.props.displayBuild.folders.map((folder) => (
												<label>{folder}</label>
											))}
										</>
									) : 
									(
										<label></label>
									)
								}
								</>
							</div>
						</div>
						*/
					}
				</div>
			</>
		);
	}
}

const DisplayBuildCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayBuildCardView;
