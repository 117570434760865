import { IBuild, IState } from "../../Interfaces";

const onGetHighlightBuildStatusReducer = (state: IState, action) => {
    const highlightBuildStatus = action.payload as '';
    const newState = { ...state } as IState;
    newState.data.search = {
        ...newState.data.search,
        highlightBuildStatus: highlightBuildStatus,
    };

    return newState;
};

export default onGetHighlightBuildStatusReducer;