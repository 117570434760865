import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onAddPartToAddedParts, onRemovePartFromAddedParts } from '../Actions/Parts';
import { getDefaultPrintConfiguration, getDefaultMillConfiguration, getDefaultMaterialConfiguration, getDefaultRecipeConfiguration, getDefaultPartConfiguration, getDefaultPartSlicerConfiguration } from '../DefaultConfigurations';
import { IConfiguration, IState } from '../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;

    const addedPartPartConfiguration = state.data.workspace.addedPartsSelectedPartConfigurations[ownProps.addedPart.index] as IConfiguration;
    const addedPartPartSlicerConfiguration = state.data.workspace.addedPartsSelectedPartSlicerConfigurations[ownProps.addedPart.index] as IConfiguration;
    
    const props = {
        ids: {
            partIds: [ownProps.addedPart].filter(Boolean).map(part => part.id).filter(Boolean) as string[],
            partConfigurationIds: [addedPartPartConfiguration?.['id']] as string[],
            partSlicerConfigurationIds: [addedPartPartSlicerConfiguration?.['id']] as string[],
            plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
        },
        state: state
    };

    return props;
};

const Actions = {
    onAddPartToAddedParts: onAddPartToAddedParts,
    onRemovePartFromAddedParts: onRemovePartFromAddedParts,
};

class Component extends React.Component<any, any> {
    udpatePlates =  () => {
		const payload = this.props.ids;
		payload.plateConfigurationIds = this.props.state.data.workspace.selectedConfigurations.map(item => item.id)
		this.props.onAddPartToAddedParts(payload)
	}
    public render() {
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        variant="light"
                        className="pr-0">
                        <div className='options right-justify'>
                            <div className='more-check option'>
                                <img className='icon more-check'
                                    alt='' />
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                        // onClick={() => this.props.onAddPartToAddedParts(this.props.ids)}
                        onClick={()=> {this.udpatePlates()}}>
                            Duplicate
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => this.props.onRemovePartFromAddedParts(this.props.addedPart.index)}>
                            Remove
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }
}

const AddedPartOptions = withRouter(connect(Props, Actions)(Component));

export default AddedPartOptions;