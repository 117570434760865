import React, {useState} from "react";
import { Link } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import BundlesList from "./BundlesList";
import ConfigsDisplay from "./ConfigsDisplay";
import PartsTree from "./PartsTree";

import IBuild from '../interfaces/IBuild'
import {IWorkSpace, WorkspaceViewType} from './WorkSpace'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
interface IProps{
	build: IBuild;
	dispatch: any;
	dataStore: any;
	workspace: IWorkSpace;
	viewType: WorkspaceViewType;
}

const SidebarParts : React.FunctionComponent<IProps> = (props) => {
  const [buildName, setBuildName] = useState('');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleDialogClose = () => {
    setOpen(false);
    setBuildName('');
  };
  const updateName = () => {
    props.workspace.sendBundle(buildName)
    setOpen(false);
  }
  const showDialog = () => {
    setOpen(true);
    setBuildName('');
  };
  const bundleNameChange = (data) => {
    props.workspace.updateName(data);
  }

    return (
        <SideBar>
            <ConfigsDisplay
              machineConfig={props.build.machineConfig}
              recipeConfig={props.build.recipe}
              millConfig={props.build.millConfig}
              materialConfig={props.build.materialConfig}
              configChangerDispatch={props.dispatch}
              dataStore={props.dataStore}
            />

            <PartsTree
              parts={props.build.parts}
              dispatch={props.dispatch}
              dataStore={props.dataStore}
            />
            <br />
            <br />
            <div className="btn-group-vertical">
              <button
                className="btn btn-light"
                onClick={() => {
                  props.workspace.getResultJson(new Date().toISOString()).then(
                    (jsonToSend: Object) => {
                      let combinedConfig = (jsonToSend as any).Combined_Config;
                      combinedConfig.id = `Combined Config for ${props.build.id}`;
                      props.workspace.openJsonInEditor( combinedConfig );
                    }
                  );
                }}
              >
                Check Configuration
              </button>
              <button
                className="btn btn-light"
                onClick={props.workspace.downloadBundle}
              >
                Download bundle
              </button>

              <button
                className="btn btn-light"
                onClick={showDialog}
              >
                Process Bundle
              </button>

			  <button
                className="btn btn-light"
				  onClick={() => props.dispatch({ type:"switchView"})}
              >
				  { props.viewType === WorkspaceViewType.GrafanaView 
				? "Hide Print Monitor" 
				: "Show Print Monitor" }
              </button>


              <button className="btn btn-light">
                <Link to={"/bundlesQueue"}>Bundles Queue</Link>
                <br />
              </button>
            </div>
            <br />
            <BundlesList build={props.build} bundleNameChange = {bundleNameChange}/>

              <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleDialogClose}
              aria-labelledby="responsive-dialog-title"
              className= "catalog-dialog"
              >
              <DialogTitle id="responsive-dialog-title">{"Process Bundle" }</DialogTitle>
              <DialogContent>
              <div className="each-content">Optional Bundle Notes</div>
              <div className="each-content"><textarea
                            style={{ minWidth: "300px"}}
                            value={buildName}
                            onChange={(ev) => {
                              let v = ev.target.value;
                              setBuildName(v);
                            }}
                          ></textarea></div>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={updateName} color="primary" autoFocus>
                Send
                </Button>
              </DialogActions>
            </Dialog>
          </SideBar>
    )
}

export default SidebarParts;
