import { IState } from "../../../Interfaces";

const onSelectPartReducer = (state: IState, action) => {
    const partObj = action.payload ;
    
    const newState = { ...state } as IState;
	const isPartAvaialble = newState.data.parts.loadedParts.some((part) => part.id === partObj.id)
	if (!isPartAvaialble){
		newState.data.parts.loadedParts = [partObj, ...newState.data.parts.loadedParts]
	}
	if (!newState.data.parts.selectedParts.find((i) => i.id === partObj.id)) {
		const part = newState.data.parts.loadedParts.find((i) => i.id === partObj.id)

		if (!part) {
				return newState;
			}

		newState.data.parts.selectedParts = newState.data.parts.selectedParts.concat(part);
		}

	newState.data.parts.selectedPartIds = newState.data.parts.selectedPartIds.concat(partObj.id);
    
    return newState;
};

export default onSelectPartReducer;
