import { setPart } from '../../../Instances';
import { IState } from '../../../Interfaces';

const onAddPartToReviewPageReducer = (state: IState, action) => {
   
    const ids = action.payload as any;
    const newState = setPart(state, ids, false) as IState;
    return newState;
};

export default onAddPartToReviewPageReducer;