import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Storage} from "aws-amplify";
import {DynamoDB} from "aws-sdk";
import {render} from "react-dom";


const AWS = require('aws-sdk');

AWS.config.update({region: 'us-east-1'});


const awsExports = require('../aws-exports')
const REGEX = /.*-(\w+)/
const env = awsExports.default.aws_user_files_s3_bucket.match(REGEX)[1]

export const getCurrentEnvironment= ():String =>
{
    return env
}


export let getDistributionsList = async ()=>{
    const params:any={
        TableName: 'distribution'
    }
    if (env === 'dev') {
        params.ExpressionAttributeValues = {':e': {S: 'develop'}}
        params.KeyConditionExpression = 'product_id = :e'
    }
    if (env === 'qa'){
        params.ExpressionAttributeValues = {':e':{S:'QA'}}
        params.KeyConditionExpression = 'product_id = :e'
    }
    const dynamodb = new AWS.DynamoDB();
    const queryResult = await dynamodb.query(params).promise()
    return queryResult.Items           // successful response
}

export let getMohoVersion = async ()=>{
    const params:any={
        TableName: 'environments',
        ExpressionAttributeValues: {':e': {S: env}},
        KeyConditionExpression: 'id = :e'
    }

    const dynamodb = new AWS.DynamoDB();
    const queryResult = await dynamodb.query(params).promise()
    return queryResult.Items           // successful response
}


export const MohoVersion = ()=>{
    let [state, setState] = useState("")

    useEffect(()=>{
        getMohoVersion().then( items => {
            setState(items[0]['build_id']['S'])
        }).catch(
            ex =>{
                console.log(ex)
            }
        )
    }, [])
    return (<div><Link to={"/distributions"}>{state?<p>moho: {state}</p>:<p></p>}</Link></div>)
}