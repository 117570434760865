/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBuild = /* GraphQL */ `
  subscription OnCreateBuild($filter: ModelSubscriptionBuildFilterInput) {
    onCreateBuild(filter: $filter) {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBuild = /* GraphQL */ `
  subscription OnUpdateBuild($filter: ModelSubscriptionBuildFilterInput) {
    onUpdateBuild(filter: $filter) {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBuild = /* GraphQL */ `
  subscription OnDeleteBuild($filter: ModelSubscriptionBuildFilterInput) {
    onDeleteBuild(filter: $filter) {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateParts = /* GraphQL */ `
  subscription OnCreateParts($filter: ModelSubscriptionPartsFilterInput) {
    onCreateParts(filter: $filter) {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateParts = /* GraphQL */ `
  subscription OnUpdateParts($filter: ModelSubscriptionPartsFilterInput) {
    onUpdateParts(filter: $filter) {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteParts = /* GraphQL */ `
  subscription OnDeleteParts($filter: ModelSubscriptionPartsFilterInput) {
    onDeleteParts(filter: $filter) {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateConfigurations = /* GraphQL */ `
  subscription OnCreateConfigurations(
    $filter: ModelSubscriptionConfigurationsFilterInput
  ) {
    onCreateConfigurations(filter: $filter) {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateConfigurations = /* GraphQL */ `
  subscription OnUpdateConfigurations(
    $filter: ModelSubscriptionConfigurationsFilterInput
  ) {
    onUpdateConfigurations(filter: $filter) {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteConfigurations = /* GraphQL */ `
  subscription OnDeleteConfigurations(
    $filter: ModelSubscriptionConfigurationsFilterInput
  ) {
    onDeleteConfigurations(filter: $filter) {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateConfigurationsAllHistory = /* GraphQL */ `
  subscription OnCreateConfigurationsAllHistory(
    $filter: ModelSubscriptionConfigurationsAllHistoryFilterInput
  ) {
    onCreateConfigurationsAllHistory(filter: $filter) {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateConfigurationsAllHistory = /* GraphQL */ `
  subscription OnUpdateConfigurationsAllHistory(
    $filter: ModelSubscriptionConfigurationsAllHistoryFilterInput
  ) {
    onUpdateConfigurationsAllHistory(filter: $filter) {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteConfigurationsAllHistory = /* GraphQL */ `
  subscription OnDeleteConfigurationsAllHistory(
    $filter: ModelSubscriptionConfigurationsAllHistoryFilterInput
  ) {
    onDeleteConfigurationsAllHistory(filter: $filter) {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrint = /* GraphQL */ `
  subscription OnCreatePrint($filter: ModelSubscriptionPrintFilterInput) {
    onCreatePrint(filter: $filter) {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrint = /* GraphQL */ `
  subscription OnUpdatePrint($filter: ModelSubscriptionPrintFilterInput) {
    onUpdatePrint(filter: $filter) {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrint = /* GraphQL */ `
  subscription OnDeletePrint($filter: ModelSubscriptionPrintFilterInput) {
    onDeletePrint(filter: $filter) {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrinter = /* GraphQL */ `
  subscription OnCreatePrinter($filter: ModelSubscriptionPrinterFilterInput) {
    onCreatePrinter(filter: $filter) {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrinter = /* GraphQL */ `
  subscription OnUpdatePrinter($filter: ModelSubscriptionPrinterFilterInput) {
    onUpdatePrinter(filter: $filter) {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrinter = /* GraphQL */ `
  subscription OnDeletePrinter($filter: ModelSubscriptionPrinterFilterInput) {
    onDeletePrinter(filter: $filter) {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage($filter: ModelSubscriptionImageFilterInput) {
    onCreateImage(filter: $filter) {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage($filter: ModelSubscriptionImageFilterInput) {
    onUpdateImage(filter: $filter) {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage($filter: ModelSubscriptionImageFilterInput) {
    onDeleteImage(filter: $filter) {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePlate = /* GraphQL */ `
  subscription OnCreatePlate($filter: ModelSubscriptionPlateFilterInput) {
    onCreatePlate(filter: $filter) {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePlate = /* GraphQL */ `
  subscription OnUpdatePlate($filter: ModelSubscriptionPlateFilterInput) {
    onUpdatePlate(filter: $filter) {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePlate = /* GraphQL */ `
  subscription OnDeletePlate($filter: ModelSubscriptionPlateFilterInput) {
    onDeletePlate(filter: $filter) {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrinterUtilization = /* GraphQL */ `
  subscription OnCreatePrinterUtilization(
    $filter: ModelSubscriptionPrinterUtilizationFilterInput
  ) {
    onCreatePrinterUtilization(filter: $filter) {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrinterUtilization = /* GraphQL */ `
  subscription OnUpdatePrinterUtilization(
    $filter: ModelSubscriptionPrinterUtilizationFilterInput
  ) {
    onUpdatePrinterUtilization(filter: $filter) {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrinterUtilization = /* GraphQL */ `
  subscription OnDeletePrinterUtilization(
    $filter: ModelSubscriptionPrinterUtilizationFilterInput
  ) {
    onDeletePrinterUtilization(filter: $filter) {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFolders = /* GraphQL */ `
  subscription OnCreateFolders($filter: ModelSubscriptionFoldersFilterInput) {
    onCreateFolders(filter: $filter) {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFolders = /* GraphQL */ `
  subscription OnUpdateFolders($filter: ModelSubscriptionFoldersFilterInput) {
    onUpdateFolders(filter: $filter) {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFolders = /* GraphQL */ `
  subscription OnDeleteFolders($filter: ModelSubscriptionFoldersFilterInput) {
    onDeleteFolders(filter: $filter) {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTags = /* GraphQL */ `
  subscription OnCreateTags($filter: ModelSubscriptionTagsFilterInput) {
    onCreateTags(filter: $filter) {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTags = /* GraphQL */ `
  subscription OnUpdateTags($filter: ModelSubscriptionTagsFilterInput) {
    onUpdateTags(filter: $filter) {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTags = /* GraphQL */ `
  subscription OnDeleteTags($filter: ModelSubscriptionTagsFilterInput) {
    onDeleteTags(filter: $filter) {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrintFeedback = /* GraphQL */ `
  subscription OnCreatePrintFeedback(
    $filter: ModelSubscriptionPrintFeedbackFilterInput
  ) {
    onCreatePrintFeedback(filter: $filter) {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      imageUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrintFeedback = /* GraphQL */ `
  subscription OnUpdatePrintFeedback(
    $filter: ModelSubscriptionPrintFeedbackFilterInput
  ) {
    onUpdatePrintFeedback(filter: $filter) {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      imageUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrintFeedback = /* GraphQL */ `
  subscription OnDeletePrintFeedback(
    $filter: ModelSubscriptionPrintFeedbackFilterInput
  ) {
    onDeletePrintFeedback(filter: $filter) {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      imageUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDistribution = /* GraphQL */ `
  subscription OnCreateDistribution(
    $filter: ModelSubscriptionDistributionFilterInput
  ) {
    onCreateDistribution(filter: $filter) {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDistribution = /* GraphQL */ `
  subscription OnUpdateDistribution(
    $filter: ModelSubscriptionDistributionFilterInput
  ) {
    onUpdateDistribution(filter: $filter) {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDistribution = /* GraphQL */ `
  subscription OnDeleteDistribution(
    $filter: ModelSubscriptionDistributionFilterInput
  ) {
    onDeleteDistribution(filter: $filter) {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEnvironments = /* GraphQL */ `
  subscription OnCreateEnvironments(
    $filter: ModelSubscriptionEnvironmentsFilterInput
  ) {
    onCreateEnvironments(filter: $filter) {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEnvironments = /* GraphQL */ `
  subscription OnUpdateEnvironments(
    $filter: ModelSubscriptionEnvironmentsFilterInput
  ) {
    onUpdateEnvironments(filter: $filter) {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEnvironments = /* GraphQL */ `
  subscription OnDeleteEnvironments(
    $filter: ModelSubscriptionEnvironmentsFilterInput
  ) {
    onDeleteEnvironments(filter: $filter) {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePartsSearch = /* GraphQL */ `
  subscription OnCreatePartsSearch(
    $filter: ModelSubscriptionPartsSearchFilterInput
  ) {
    onCreatePartsSearch(filter: $filter) {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePartsSearch = /* GraphQL */ `
  subscription OnUpdatePartsSearch(
    $filter: ModelSubscriptionPartsSearchFilterInput
  ) {
    onUpdatePartsSearch(filter: $filter) {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePartsSearch = /* GraphQL */ `
  subscription OnDeletePartsSearch(
    $filter: ModelSubscriptionPartsSearchFilterInput
  ) {
    onDeletePartsSearch(filter: $filter) {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePlateSearch = /* GraphQL */ `
  subscription OnCreatePlateSearch(
    $filter: ModelSubscriptionPlateSearchFilterInput
  ) {
    onCreatePlateSearch(filter: $filter) {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePlateSearch = /* GraphQL */ `
  subscription OnUpdatePlateSearch(
    $filter: ModelSubscriptionPlateSearchFilterInput
  ) {
    onUpdatePlateSearch(filter: $filter) {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePlateSearch = /* GraphQL */ `
  subscription OnDeletePlateSearch(
    $filter: ModelSubscriptionPlateSearchFilterInput
  ) {
    onDeletePlateSearch(filter: $filter) {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBuildSearch = /* GraphQL */ `
  subscription OnCreateBuildSearch(
    $filter: ModelSubscriptionBuildSearchFilterInput
  ) {
    onCreateBuildSearch(filter: $filter) {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBuildSearch = /* GraphQL */ `
  subscription OnUpdateBuildSearch(
    $filter: ModelSubscriptionBuildSearchFilterInput
  ) {
    onUpdateBuildSearch(filter: $filter) {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBuildSearch = /* GraphQL */ `
  subscription OnDeleteBuildSearch(
    $filter: ModelSubscriptionBuildSearchFilterInput
  ) {
    onDeleteBuildSearch(filter: $filter) {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrinterCommand = /* GraphQL */ `
  subscription OnCreatePrinterCommand(
    $filter: ModelSubscriptionPrinterCommandFilterInput
  ) {
    onCreatePrinterCommand(filter: $filter) {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrinterCommand = /* GraphQL */ `
  subscription OnUpdatePrinterCommand(
    $filter: ModelSubscriptionPrinterCommandFilterInput
  ) {
    onUpdatePrinterCommand(filter: $filter) {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrinterCommand = /* GraphQL */ `
  subscription OnDeletePrinterCommand(
    $filter: ModelSubscriptionPrinterCommandFilterInput
  ) {
    onDeletePrinterCommand(filter: $filter) {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateHelp = /* GraphQL */ `
  subscription OnCreateHelp($filter: ModelSubscriptionHelpFilterInput) {
    onCreateHelp(filter: $filter) {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateHelp = /* GraphQL */ `
  subscription OnUpdateHelp($filter: ModelSubscriptionHelpFilterInput) {
    onUpdateHelp(filter: $filter) {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteHelp = /* GraphQL */ `
  subscription OnDeleteHelp($filter: ModelSubscriptionHelpFilterInput) {
    onDeleteHelp(filter: $filter) {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      firstName
      lastName
      email
      phoneNo
      roleId
      status
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      firstName
      lastName
      email
      phoneNo
      roleId
      status
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      firstName
      lastName
      email
      phoneNo
      roleId
      status
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      notificationType
      notificationMsg
      sendText
      sendEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      notificationType
      notificationMsg
      sendText
      sendEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      notificationType
      notificationMsg
      sendText
      sendEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRole = /* GraphQL */ `
  subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
    onCreateRole(filter: $filter) {
      id
      roleName
      permissions {
        roleChange
        userManagement
        advancedFeatures
      }
      advancedFeatures {
        id
        name
        enabled
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRole = /* GraphQL */ `
  subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
    onUpdateRole(filter: $filter) {
      id
      roleName
      permissions {
        roleChange
        userManagement
        advancedFeatures
      }
      advancedFeatures {
        id
        name
        enabled
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRole = /* GraphQL */ `
  subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
    onDeleteRole(filter: $filter) {
      id
      roleName
      permissions {
        roleChange
        userManagement
        advancedFeatures
      }
      advancedFeatures {
        id
        name
        enabled
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
