import { IState } from '../../../Interfaces';

const onRenameFolderNameChangeReducer = (state: IState, action) => {
    const folderNameToBeRenamed = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.renameFolderModalStatus = {
        ...newState.data.modals.renameFolderModalStatus,
        inputText: folderNameToBeRenamed,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onRenameFolderNameChangeReducer;