import { IState, IPlate } from '../../../Interfaces';

const onLoadPlatesInProgressReducer = (state: IState, action) => {
    const newlyLoadedPlates = action.payload.plates as IPlate[];
    const nextToken = action.payload.nextToken;
    const newState = { ...state } as IState;

    newState.data.plates.loadedPlates.splice(0, newState.data.plates.loadedPlates.length, ...newlyLoadedPlates);
    newState.data.plates.isLoadingInProgress = true;
    newState.data.plates.nextToken = nextToken;
    
    return newState;
};

export default onLoadPlatesInProgressReducer;
