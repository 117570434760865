export default interface IRule {
    propertyPath: string;
    name: string;
    configType: ConfigType;
    UUID: string;
    rows: number;
    columns: number;
    values: any [];
}
export enum ConfigType {
 SlicerConfig,
 PartConfig
}