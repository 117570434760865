import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import {
	onMovePlateCloseDialog,
	onMovePlateNameChange,
	onMovePlate,
} from "../../../Actions/Plates";
import { IState, IMovePlateModalStatus } from "../../../Interfaces";

const Props = (state: IState, ownProps: any) => {
	const movePlateModalStatus = state.data.modals
		.movePlateModalStatus as IMovePlateModalStatus;

	const props = {
		folders: state.data.folders.loadedFolders,
		isModalOpen: movePlateModalStatus.isOpen,
		inputText: movePlateModalStatus.inputText,
		hasError: movePlateModalStatus.hasError,
		errorMessage: movePlateModalStatus.errorMessage,
		isValid: movePlateModalStatus.isValid,
	};

	return props;
};

const Actions = {
	onMovePlateCloseDialog: onMovePlateCloseDialog,
	onMovePlateNameChange: onMovePlateNameChange,
	onMovePlate: onMovePlate,
};

class Component extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { selectedDropdownValue: "" };
	}

	public render() {
		return (
			<>
				{this.props.isModalOpen ? (
					<>
						<Modal
							show={this.props.isModalOpen}
							onHide={() => (
								this.props.onMovePlateCloseDialog(),
								this.setState({
									selectedDropdownValue: "",
								})
							)}
							backdrop="static"
							keyboard={false}
							dialogClassName="platesDialog"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header>
								<Modal.Title>Move</Modal.Title>
								<span className="close-btn"
									onClick={() => this.props.onMovePlateCloseDialog()}>
								</span>
							</Modal.Header>
							<Modal.Body>
								<div className="each-dialog-content">
									<div className="options">
										<div className="plate-folders option">
											<label>Select the folder</label>

											<DropdownButton id="dropdown-basic-button" title="Select">
												<Dropdown.Item
													onClick={() => this.props.onMovePlateNameChange("")}
												>
													none
												</Dropdown.Item>
												{this.props.folders.map((folder) => {
													return (
														<>
															<Dropdown.Item
																onClick={() => {
																	this.props.onMovePlateNameChange(folder.name);
																	this.setState({
																		selectedDropdownValue: folder.name,
																	});
																}}
															>
																{folder.name}
															</Dropdown.Item>
														</>
													);
												})}
											</DropdownButton>
										</div>
									</div>
									<div className="margin-t-10">
										Name : {this.state.selectedDropdownValue}
									</div>
									<div
										className="invalid-feedback d-block"
										hidden={!this.props.hasError}
									>
										{this.props.errorMessage}
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer className="justify-content-center">
								<Button
									className='btn-primary'
									onClick={() => (
										this.props.onMovePlate(),
										this.setState({
											selectedDropdownValue: "",
										})
									)}
									autoFocus
								>
									Move Plate
								</Button>
							</Modal.Footer>
						</Modal>
					</>
				) : null}
			</>
		);
	}
}

const MovePlate = withRouter(connect(Props, Actions)(Component));

export default MovePlate;
