import { IState } from "../../../../Interfaces";

const onNewFolderNameChangeReducer = (state: IState, action) => {
    const folderNameToBeCreated = action.payload as string;

    const newState = { ...state } as IState;
    newState.data.modals.newFolderModalStatus = {
        ...newState.data.modals.newFolderModalStatus,
        inputText: folderNameToBeCreated,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    return newState;
};

export default onNewFolderNameChangeReducer;