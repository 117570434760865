import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onSelectPartConfiguration } from '../Actions/Plates';
import { IState, IConfiguration } from '../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const selectedConfiguration = ownProps?.selectedConfiguration as IConfiguration;

    const props = {
        isLoadingComplete: state.data.configurations.isLoadingComplete as boolean,
        loadedConfigurations: ownProps?.loadedConfigurations || [] as IConfiguration[],
        selectedConfigurationName: (selectedConfiguration ? selectedConfiguration.name : 'None') as string,
    };

    return props;
};

const Actions = {
    onSelectPartConfiguration: onSelectPartConfiguration,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                <div className='options'>
                    <div className='plate-configuration option ml-0'>
                        <label>{this.props.label}</label>
                        <DropdownButton id='dropdown-basic-button'
                            disabled={!this.props.isLoadingComplete}
                            title={this.props.selectedConfigurationName}>
                            <>
                                {
                                    this.props.loadedConfigurations.map(lc => {
                                        return (
                                            <React.Fragment key={lc.id}>
                                                <Dropdown.Item
                                                    onClick={() => this.props.onSelectPartConfiguration(lc.id)}>
                                                    {lc.name}
                                                </Dropdown.Item>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </>
                        </DropdownButton>
                    </div>
                </div>
            </>
        );
    }
}

const PartConfiguration = withRouter(connect(Props, Actions)(Component));

export default PartConfiguration;
