import API, { graphqlOperation } from '@aws-amplify/api';

import { updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPlate, IMovePlateModalStatus, IState } from '../../../Interfaces';
import { onDisplayLoader } from '../../Search';

import { NotificationManager } from "react-notifications";
const onMovePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.MOVE.INIT,
        payload: null,
    };
};

const onMovePlateSuccess = (movedPlate) => {
    return {
        type: ACTION_TYPES.PLATE.MOVE.SUCCESS,
        payload: movedPlate,
    };
};

const onMovePlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Move Plate');
    console.error('Failure on Move Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.MOVE.FAIL,
        payload: errorMessage,
    };
};

const onMovePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.MOVE.DONE,
        payload: null,
    };
};

const onMovePlate = () => {
    return async (dispatch, getState) => {

        dispatch(onMovePlateInit());

        const state = getState() as IState;

        const movePlateModalStatus = state.data.modals.movePlateModalStatus as IMovePlateModalStatus;

        const isFolderNameEmpty = (!movePlateModalStatus.inputText) as boolean;

        try {
            const state = getState() as IState;
            const plateToBeMoved = !state.data.search.isSearchEnabled ? ({ ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId) } as IPlate) : state.data.search.highlightedPlateData as IPlate
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(true))
            }
           // const plateToBeMoved = { ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId) } as IPlate;
            const folderName = JSON.stringify({ name: movePlateModalStatus.inputText }) as string;
            plateToBeMoved.folders = isFolderNameEmpty ? [] : [folderName] as string[];
            delete plateToBeMoved._deleted;
            delete plateToBeMoved._lastChangedAt;
            delete plateToBeMoved.createdAt;
            delete plateToBeMoved.updatedAt;
            const variables = {
                input: plateToBeMoved,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const movedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            dispatch(onMovePlateSuccess(movedPlate));
            dispatch(onMovePlateDone());
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))
            }
        } catch (graphqlError) {
            const errorMessage = `Move plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onMovePlateFail(errorMessage));
            dispatch(onMovePlateDone());
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))
            }
        }
    };
};

export default onMovePlate;