import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoadedPart } from '.';
import { IState } from '../Interfaces';
import { onLoadParts } from "../Actions/Home";
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import SearchEditPlateCardView from './Search/SearchEditPlateCardView';

const Props = (state: IState, ownProps: any) => {

    const props = {
        nextToken: state.data.parts.nextToken,
        loadedParts: state.data.parts.loadedParts,
        isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
        loading: !(state.data.parts.isLoadingComplete as boolean),
        isPartsSearchCompleted: state.data.search.partsSearchCompleted as boolean,
        isSearchEnabled: state.data.search.isSearchEnabled as boolean,
        partsSearchResultsLength: state.data.search.partsSearchCompleted ? state.data.search.searchPartsResults.length : 0,
        partsSearchResults: state.data.search?.searchPartsResults,
    };

    return props;
};

const Actions = {
    onLoadParts: onLoadParts,
};

class Component extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            showItems: true,
        };
    }

    public onToggle = () => {
        this.setState({
            showItems: !this.state.showItems,
        });
    };

    public render() {
        return (
            <>
            {!this.props.isSearchEnabled && (
                <>
                {
                    (this.props.loadedParts.length) ?
                        (
                            <>
                                <dl className="m-0">
                                    <dt>
                                        <div className='options left-justify'>
                                            <>
                                                {
                                                    (!this.state.showItems) ?
                                                        (
                                                            <>
                                                                <div className='collapse option'>
                                                                    <img className='icon right-arrow'
                                                                        alt=''
                                                                        onClick={() => this.onToggle()} />
                                                                    <label>{this.props.label}</label>
                                                                    <span>{` listing ${this.props.loadedParts.length} parts`}</span>
                                                                </div>
                                                            </>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                            <>
                                                {
                                                    (this.state.showItems) ?
                                                        (
                                                            <>
                                                                <div className='expand option'>
                                                                    <img className='icon down-arrow'
                                                                        alt=''
                                                                        onClick={() => this.onToggle()} />
                                                                    <label>{this.props.label}</label>
                                                                    <span>{` listing ${this.props.loadedParts.length} parts`}</span>
                                                                </div>
                                                            </>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                        </div>
                                    </dt>
                                    <>
                                        {
                                            (this.state.showItems) ?
                                                (
                                                    <>
                                                        <dd className='ml-0'>
                                                            {
                                                                this.props.loadedParts.map((dp, i) => (<LoadedPart key={i} loadedPart={dp} />))
                                                            }
                                                        </dd>
                                                        {
                                                          this.props.nextToken ?
                                                            (
                                                                <div className="d-block w-100 text-center pb-3">
                                                                  <button onClick={() => this.props.onLoadParts()} className="btn btn-primary justify-content-center">
                                                                    Load More Parts<span className="btn-right-arrow"></span></button>
                                                                </div>
                                                            ) :
                                                            (
                                                              null
                                                            )
                                                        }
                                                    </>
                                                ) :
                                                (null)
                                        }
                                    </>
                                </dl>
                            </>
                        ) :
                        (null)
                }
                </>
             )}
             {this.props.isSearchEnabled && (
                <div>
                    <dl>
                    <dt>
                                        <div className='options left-justify'>
                                            <>
                                                {
                                                    (!this.state.showItems) ?
                                                        (
                                                            <>
                                                                <div className='collapse option'>
                                                                    <img className='icon right-arrow'
                                                                        alt=''
                                                                        onClick={() => this.onToggle()} />
                                                                    <label>{this.props.label}</label>
                                                                    <span>{` listing ${this.props.partsSearchResultsLength} parts`}</span>
                                                                </div>
                                                            </>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                            <>
                                                {
                                                    (this.state.showItems) ?
                                                        (
                                                            <>
                                                                <div className='expand option'>
                                                                    <img className='icon down-arrow'
                                                                        alt=''
                                                                        onClick={() => this.onToggle()} />
                                                                    <label>{this.props.label}</label>
                                                                    <span>{` listing ${this.props.partsSearchResultsLength} parts`}</span>
                                                                </div>
                                                            </>
                                                        ) :
                                                        (null)
                                                }
                                            </>
                                        </div>
                                    </dt>
                        <dd>
                            {this.props.isPartsSearchCompleted ? (
                                this.props.partsSearchResultsLength !== 0 ? (
                                    <>
                                        {this.props.partsSearchResults.map((dp, i) => {
                                      return (
                                        <SearchEditPlateCardView
                                          {...{
                                            key: i,
                                            index: i,
                                            partsSearchResult: dp,
                                            isSelected: this.props.isSelected,
                                            onUnselect: this.props.onUnselect,
                                            onSelect: this.props.onSelect,
                                          }}
                                        />
                                      );
                                    })}
                                    </>
                                ) : (
                                    <p>No search results available.</p>
                                )
                            ) : (
                                <LoadingComponent visible={true} />
                            )}
                        </dd>
                    </dl>
                </div>
            )}
            </>
        );
    }
}

const LoadedParts = withRouter(connect(Props, Actions)(Component));

export default LoadedParts;
