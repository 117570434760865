import API, { graphqlOperation } from '@aws-amplify/api';

import { updateFolders } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IFolder } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";

const onArchiveFolderInit = () => {
    return {
        type: ACTION_TYPES.FOLDER.ARCHIVE.INIT,
        payload: null,
    };
};

const onArchiveFolderSuccess = (archivedFolder: IFolder) => {
    return {
        type: ACTION_TYPES.FOLDER.ARCHIVE.SUCCESS,
        payload: archivedFolder,
    };
};

const onArchiveFolderFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Archiving Folder');
    console.error('Failure on on Archiving Folder - ', errorMessage);
    return {
        type: ACTION_TYPES.FOLDER.ARCHIVE.FAIL,
        payload: errorMessage,
    };
};


const onArchiveFolderDone = () => {
    return {
        type: ACTION_TYPES.FOLDER.ARCHIVE.DONE,
        payload: null,
    };
};

const onArchiveFolder = (folderId) => {
    return async (dispatch, getState) => {
        dispatch(onArchiveFolderInit());
        try {
            const state = getState();
            const folderToBeArchived = state.data.folders.loadedFolders.find(p => p.id === folderId) as IFolder;
            folderToBeArchived.archive = true;
            delete folderToBeArchived._deleted;
            delete folderToBeArchived._lastChangedAt;
            delete folderToBeArchived.createdAt;
            delete folderToBeArchived.updatedAt;
            folderToBeArchived.modifier = state.creator as string;
            folderToBeArchived.modified_at = new Date().toISOString();
            const variables = {
                input: folderToBeArchived,
            };
            const result = await API.graphql(graphqlOperation(updateFolders, variables));
            const archivedFolder = ((result as any)?.data?.updateFolders) as IFolder;
            dispatch(onArchiveFolderSuccess(archivedFolder));
        }
        catch (graphqlError) {
            const errorMessage = `Archive folder failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onArchiveFolderFail(errorMessage));
        }
        dispatch(onArchiveFolderDone());
    };
};

export default onArchiveFolder;