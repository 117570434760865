import { IPlate, IState } from "../../../Interfaces";

const onDeletePlateReducer = (state: IState, action) => {
    const deletedPlate = action.payload as IPlate;
    const indexToBeReplaced = state.data.plates.loadedPlates.findIndex(lp => lp.id === deletedPlate.id);
    const isPlateExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPlateExists) newState.data.plates.loadedPlates.splice(indexToBeReplaced, 1);

    newState.data.plates.selectedPlateIds = newState.data.plates.selectedPlateIds.filter(id => id !== deletedPlate.id);
    
    return newState;
};

export default onDeletePlateReducer;