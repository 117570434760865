import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPartCardView, DisplayPartsOptions, DisplayFolder } from '../..';
import { IState } from '../../../Interfaces';
import React from 'react';

const Props = (state: IState, ownProps: any) => {

	const hasDisplayItems =
		Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
		Boolean(ownProps.displayParts && ownProps.displayParts.length > 0);

	const props = {
		hasDisplayItems: hasDisplayItems as boolean,
		isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
		label: ownProps.label,
		displayFolders: ownProps.displayFolders,
		displayParts: ownProps.displayParts,
		showOptions: ownProps.showOptions,
		showItems: ownProps.showItems,
		onShowItems: ownProps.onShowItems,
		onHideItems: ownProps.onHideItems,
		isSelected: ownProps.isSelected,
		onUnselect: ownProps.onUnselect,
		onSelect: ownProps.onSelect,
		hasPlates: ownProps.hasPlates,
		hasBuilds: ownProps.hasBuilds,
	};

	return props;
};

const Actions = {};

class Component extends React.Component<any, any> {
	componentDidUpdate(prevProps) {
		if (
			this.props.isLoadingComplete &&
			this.props.displayParts.length > prevProps.displayParts.length
		) {
			if (this.props.displayParts.every((dp) => dp.isSelected)) {
				this.props.onShowItems();
			}
			if (this.props.displayParts.every((dp) => dp.isRecent)) {
				this.props.onShowItems();
			}
		}
	}

	public render() {
		return (
			<>
				<>
					{
						this.props.hasDisplayItems ?
							(
								<>
									<dl>
										<>
											{
												<dt>
													<div className='options left-justify'>
														<div
															className={`${this.props.showItems ? 'expand' : 'collapse'} option`}
														>
															<img
																className={`icon ${this.props.showItems ? 'down-arrow' : 'right-arrow'}`}
																alt=''
																onClick={() =>
																	this.props.showItems
																		? this.props.onHideItems()
																		: this.props.onShowItems()
																}
															/>
															<label>{this.props.label}</label>
															<span>
																{` listing ${this.props.displayParts.length} parts, listing ${this.props.displayFolders.length} folders`}
															</span>
														</div>
													</div>
													<DisplayPartsOptions showOptions={this.props.showOptions} />
												</dt>
											}
										</>
										<>
											{
												this.props.showItems ?
													(
														<>
															<dd>
																{
																	this.props.displayFolders
																		.map((df) => {
																			return <DisplayFolder
																				key={df.id}
																				displayFolder={df}
																				showParts={true}
																				showPlates={false}
																				showBuilds={false}
																			/>
																		})
																}
															</dd>
															<dd>
																{
																	this.props.displayParts
																		.map((dp, i) => {
																			return (
																				<DisplayPartCardView
																					{...{
																						key: i,
																						displayPart: dp,
																						isSelected: this.props.isSelected,
																						onUnselect: this.props.onUnselect,
																						onSelect: this.props.onSelect,
																					}}
																				/>
																			)
																		})
																}
															</dd>
														</>
													)
													:
													null
											}
										</>
									</dl>
								</>
							)
							:
							null
					}
				</>
			</>
		);
	}
}

const DisplayParts = withRouter(connect(Props, Actions)(Component));

export default DisplayParts;
