import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IState } from '../Interfaces';
import { onSearchPartsTextChange, onSortPartsAsc, onSortPartsDesc } from '../Actions/Parts';
import { onSearchPlatesTextChange, onSortPlatesAsc, onSortPlatesDesc } from '../Actions/Plates';
import { onSearchBuildsTextChange, onSortBuildsAsc, onSortBuildsDesc } from '../Actions/Builds';
import { SortDirection } from '../Enums';
import { onShowListView ,onShowOwnedByParts,onShowOwnedByPlates } from '../Actions/Prepare';
import { onLoadParts, onLoadBuilds, onLoadPlates } from "../Actions/Home";
import { TabIndex } from "../Enums";
import { onResetSearch } from '../Actions/Search';
import { onPartPlateSearch } from '../Actions/Search/search';


const Props = (state: IState, ownProps: any) => {
  let searchText = {};
  searchText[TabIndex.ALL] = state.data.parts.searchText;
  searchText[TabIndex.PARTS] = state.data.parts.searchText;
  searchText[TabIndex.PLATES] = state.data.plates.searchText;
  searchText[TabIndex.BUILDS] = state.data.builds.searchText;
  let sortDirection = {};
  sortDirection[TabIndex.PARTS] = state.data.parts.sortDirection;
  sortDirection[TabIndex.PLATES] = state.data.plates.sortDirection;
  sortDirection[TabIndex.BUILDS] = state.data.builds.sortDirection;
  let selectedTabIndex = state.data.info.homePageTabIndex as number;


  let isLoadingComplete = state.data.parts.isLoadingComplete &&
    state.data.plates.isLoadingComplete &&
    state.data.builds.isLoadingComplete;

  const props = {
    selectedTabIndex: selectedTabIndex,
    isLoadingComplete: isLoadingComplete as boolean,
    searchText: searchText,
    sortDirection: sortDirection,
    showOwnedByParts :state.data.info.showOwnedByParts,
    showOwnedByPlates :state.data.info.showOwnedByPlates,


  };

  return props;
};

const Actions = {
  onSearchPartsTextChange: onSearchPartsTextChange,
  onSearchPlatesTextChange: onSearchPlatesTextChange,
  onSearchBuildsTextChange: onSearchBuildsTextChange,
  onSortBuildsAsc: onSortBuildsAsc,
  onSortBuildsDesc: onSortBuildsDesc,
  onSortPartsAsc: onSortPartsAsc,
  onSortPartsDesc: onSortPartsDesc,
  onSortPlatesAsc: onSortPlatesAsc,
  onSortPlatesDesc: onSortPlatesDesc,
  onShowListView: onShowListView,
  onLoadParts: onLoadParts,
  onLoadBuilds: onLoadBuilds,
  onLoadPlates: onLoadPlates,
  onSearch: onPartPlateSearch,
  onResetSearch: onResetSearch,
  onShowOwnedByParts:onShowOwnedByParts,
  onShowOwnedByPlates:onShowOwnedByPlates
};

class Component extends React.Component<any, any> {
  public constructor(props) {
    super(props);
    this.state = {
      searchText: this.props.searchText[this.props.selectedTabIndex] ? this.props.searchText[this.props.selectedTabIndex] : "",
      sortDirection: this.props.sortDirection[this.props.selectedTabIndex],
      isSortDirectionDesc: this.props.sortDirection[this.props.selectedTabIndex] === SortDirection.DESC,
      isSortDirectionAsc: this.props.sortDirection[this.props.selectedTabIndex] === SortDirection.ASC,
      searchInputValue: "",
    };
  }
  handleSearchInputChange = (event) => { this.setState({ searchInputValue: event?.target?.value }) }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.searchInputValue && this.state.searchInputValue.length >= 3) {
      this.props.onSearch(this.state.searchInputValue.toLowerCase(), this.props.selectedTabIndex)
    }
  };
  handleClear = () => {
    this.props.onResetSearch()
    this.setState({ searchInputValue: '' })
  }

  public componentDidUpdate(oldProps) {
    let reloadProperties = ["searchText", "sortDirection"];
    let tabIndex = this.props.selectedTabIndex;
    if (tabIndex !== oldProps.selectedTabIndex) {
      this.setState({
        searchText: this.props.searchText[tabIndex],
        sortDirection: this.props.sortDirection[tabIndex],
        isSortDirectionDesc: this.props.sortDirection[tabIndex] === SortDirection.DESC,
        isSortDirectionAsc: this.props.sortDirection[tabIndex] === SortDirection.ASC,
      });
      console.log("TAB SWITCH !");
      return;
    }
    reloadProperties.every((e) => {
      if (oldProps[e][tabIndex] == this.props[e][tabIndex]) {
        return true;
      }
      if (this.state[e] !== this.props[e][tabIndex]) {
        let state = { ...this.state };
        state[e] = this.props[e][tabIndex];
        state.isSortDirectionDesc = (state.sortDirection === SortDirection.DESC);
        state.isSortDirectionAsc = (state.sortDirection === SortDirection.ASC);
        this.setState(state);
      }
      switch (this.props.selectedTabIndex) {
        case TabIndex.ALL:
          this.props.onLoadParts(true);
          break;
        case TabIndex.PARTS:
          this.props.onLoadParts(true);
          break;
        case TabIndex.PLATES:
          this.props.onLoadPlates(true);
          break;
        case TabIndex.BUILDS:
          this.props.onLoadBuilds(true);
          break;
      }
      return false;
    });
  }

  public handleInput(event) {
    console.log("INPUT VALUE: ", event?.target.value);
    let value = event?.target.value;
    this.setState({
      ...this.state,
      searchText: value,
    });
  }

  public handleChange(event) {
    const value = event?.target?.value;
    if ((event?.key === 'Enter' && value.length >= 3) || value === "") {
      switch (this.props.selectedTabIndex) {
        case TabIndex.ALL:
          this.props.onSearchPartsTextChange(value);
          break;
        case TabIndex.PARTS:
          this.props.onSearchPartsTextChange(value);
          break;
        case TabIndex.PLATES:
          this.props.onSearchPlatesTextChange(value);
          break;
        case TabIndex.BUILDS:
          this.props.onSearchBuildsTextChange(value);
          break;
      }
    }
  }

  public setSortOrder(order) {
    if (order === SortDirection.DESC) {
      switch (this.props.selectedTabIndex) {
        case TabIndex.PARTS:
          this.props.onSortPartsDesc();
          break;
        case TabIndex.PLATES:
          this.props.onSortPlatesDesc();
          break;
        case TabIndex.BUILDS:
          this.props.onSortBuildsDesc();
          break;
      }
    }
    else {
      switch (this.props.selectedTabIndex) {
        case TabIndex.PARTS:
          this.props.onSortPartsAsc();
          break;
        case TabIndex.PLATES:
          this.props.onSortPlatesAsc();
          break;
        case TabIndex.BUILDS:
          this.props.onSortBuildsAsc();
          break;
      }
    }
  }

  public render() {
    return (
      <>
        <header>
          <div className='menu bar options'>

            <div className='search option '>
              <form onSubmit={this.handleSubmit} className='d-flex'>
                <input className='form-control search-box advanced-search-box'
                  type='text'
                  required
                  placeholder='Search'
                  disabled={!this.props.isLoadingComplete}
                  value={this.state.searchInputValue} onChange={this.handleSearchInputChange} />
                  <span className="close-icon" onClick={this.handleClear}></span> 
              </form>
            </div>
            <div className='check option'>
              {this.props.selectedTabIndex === 1 && (
                <>
                  <img
                    className={this.props.showOwnedByParts ? 'icon check' : 'icon uncheck'}
                    alt=''
                    onClick={(e) => {this.props.onShowOwnedByParts(); this.props.onLoadParts(true)}}
                  />
                  <span>Owned By</span>
                </>
              )}
                {this.props.selectedTabIndex === 2 && (
                <>
                  <img
                    className={this.props.showOwnedByPlates ? 'icon check' : 'icon uncheck'}
                    alt=''
                    onClick={(e) => {this.props.onShowOwnedByPlates(); this.props.onLoadPlates(true)}}
                  />
                  <span>Owned By</span>
                </>
              )}

            </div>
          </div>
        </header>
      </>
    );
  }
}

const PartsHeaderMinified = withRouter(connect(Props, Actions)(Component));

export default PartsHeaderMinified;
