import React from "react";
import Button from "react-bootstrap/Button";
import API, { graphqlOperation } from "@aws-amplify/api";
import { connect } from "react-redux";
import { IConfiguration, IState } from "../Interfaces";
import { ConfigurationByGroupIdType } from "../../graphql/queries";
import {
  onHighlightConfiguration,
  onHighlightConfigurationType,
  onAddConfigurationOpenDialog,
  onCopyConfigurationOpenDialog,
  onDeleteConfiguration,
  onRenameConfigurationOpenDialog,
  onUpdateConfiguration,
  onEditConfigurationData,
  onFetchDeletedConfigurations,
  onResetConfigurationScreen,
  onRestoreHighlightConfiguration,
  onRestoreConfigurationInit
} from "../Actions/Configurations";
import {
  AddConfiguration,
  CopyConfiguration,
  RenameConfiguration,
  JSONEditor,
} from "../Components";
import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import { queries } from "@testing-library/react";
import { filter } from "jszip";
import { ConsoleLogger } from "@aws-amplify/core";
import ReactJsonViewCompare from "react-json-view-compare";
import DeleteConfirmationModal from "./ConfigurePageDeleteModal";
import HorizontalLoader from "./HorizontalLoader";
import Toast from "react-bootstrap/Toast";

const Props = (state: IState, ownProps: any) => {
  const configurations = state.data.configurations.loadedConfigurations.filter(
    (lc) => lc && !lc._deleted
  ) as IConfiguration[];
  const configurationTypes = state.data.configurationTypes as string[];
  const configurationLabels = configurationTypes.map((type) => {
    let name: string = "";
    switch (type) {
      case "printConfig":
        name = "Printer Config";
        break;
      case "millConfig":
        name = "Mill Config";
        break;
      case "recipe":
        name = "Recipe";
        break;
      case "material":
        name = "Material";
        break;
      case "partSlicerConfig":
        name = "Part Slicer Config";
        break;
      case "partConfig":
        name = "Part Config";
        break;
    }
    return {
      type: type,
      name: name,
    };
  });

  const highlightedConfigurationId = state.data.configurations
    .highlightedConfigurationId as string;
  const highlightedConfiguration =
    state.data.configurations.loadedConfigurations.find(
      (lc) => lc.id === state.data.configurations.highlightedConfigurationId
    ) as IConfiguration;
  const highlightedConfigurationType = state.data.configurations
    .highlightedConfigurationType as string;
  const highlightedConfigurationData = state.data.configurations
    .highlightedConfigurationData as string;
  const highlightedConfigurationDataJSON = highlightedConfigurationData
    ? JSON.parse(highlightedConfigurationData)
    : ({} as object);

  const props = {
    highlightedConfigurationId: highlightedConfigurationId as string,
    configurations: configurations as IConfiguration[],
    configurationTypes: configurationTypes as string[],
    configurationLabels: configurationLabels as any[],
    highlightedConfiguration: highlightedConfiguration as IConfiguration,
    highlightedConfigurationType: highlightedConfigurationType as string,
    highlightedConfigurationData: highlightedConfigurationData as string,
    deletedConfigurations: state.data.configurations
      .deletedConfigurations as IConfiguration[],
    isLoadingComplete: state.data.configurations.isLoadingComplete as boolean,
    highlightedConfigurationDataJSON:
      highlightedConfigurationDataJSON as object,
  };

  return props;
};

const Actions = {
  onHighlightConfiguration: onHighlightConfiguration,
  onHighlightConfigurationType: onHighlightConfigurationType,
  onAddConfigurationOpenDialog: onAddConfigurationOpenDialog,
  onCopyConfigurationOpenDialog: onCopyConfigurationOpenDialog,
  onRenameConfigurationOpenDialog: onRenameConfigurationOpenDialog,
  onUpdateConfiguration: onUpdateConfiguration,
  onDeleteConfiguration: onDeleteConfiguration,
  onEditConfigurationData: onEditConfigurationData,
  onFetchDeletedConfigurations: onFetchDeletedConfigurations,
  onResetConfigurationScreen: onResetConfigurationScreen,
  onRestoreHighlightConfiguration:onRestoreHighlightConfiguration,
  onRestoreConfigurationInit:onRestoreConfigurationInit
};

class Component extends React.Component<any, any> {
  [x: string]: any;
  constructor(props) {
    super(props);
    this.updateButton = React.createRef();

    this.state = {
      selectedConfigType: "",
      selectedConfigName: "",
      selectedDeletedConfiguration: "",
      selectedDeletedConfigurationData: {},
      highlightedConfigurationVersions: [],
      highlightedConfigurationSelectedVersion: "",
      highlightedConfigurationVersionData: {},
      configLoader: false,
      showDeleteConfirmationModal: false,
      copyShowToast: false,
      configurationsFetched: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.highlightedConfigurationId !==
      nextProps.highlightedConfigurationId ||
      this.props.deletedConfigurations !== nextProps.deletedConfigurations ||
      this.props.highlightedConfigurationType !==
      nextProps.highlightedConfigurationType ||
      this.props.isLoadingComplete !== nextProps.isLoadingComplete ||
      this.state.highlightedConfigurationVersions !==
      nextState.highlightedConfigurationVersions ||
      this.state.highlightedConfigurationVersionData !==
      nextState.highlightedConfigurationVersionData ||
      this.state.highlightedConfigurationSelectedVersion !==
      nextState.highlightedConfigurationSelectedVersion ||
      this.state.showDeleteConfirmationModal !==
      nextState.showDeleteConfirmationModal ||
      this.state.copyShowToast !== nextState.copyShowToast ||
      this.state.selectedDeletedConfigurationData !==
      nextState.selectedDeletedConfigurationData
    );
  }

  handleDropdownClick = () => {
    if (!this.state.configurationsFetched) {
      this.props.onFetchDeletedConfigurations();
      this.setState({ configurationsFetched: true });
    }
  };
  handleDisplayDeletedConfig = (itemId) => {
    const seletedConfig = this.props.deletedConfigurations.filter(
      (el) => el.id === itemId
    );
    this.setState(
      {
        selectedDeletedConfiguration: seletedConfig[0].id,
        selectedDeletedConfigurationData: JSON.parse(seletedConfig[0].data),
      },
      () => console.log(JSON.stringify(this.state))
    );
    this.props.onResetConfigurationScreen();
  };

  handleDisplayConfigVersion = async () => {
    this.setState({
      configLoader: true,
      highlightedConfigurationSelectedVersion: "",
      highlightedConfigurationVersionData: {},
      highlightedConfigurationVersions: [],
      selectedDeletedConfiguration: "",
      selectedDeletedConfigurationData: {},
    });
    const variables: any = {
      ConfigurationGroupId:
        this.props.highlightedConfigurationId +
        "_" +
        this.props.highlightedConfigurationType,
      sortDirection: "DESC",
      limit: 50,
    };
    try {
      const result = (await API.graphql(
        graphqlOperation(ConfigurationByGroupIdType, variables)
      )) as any;
      if ((result as any)?.data?.ConfigurationByGroupIdType?.items) {
        this.setState({
          highlightedConfigurationVersions: (result as any)?.data
            ?.ConfigurationByGroupIdType?.items,
          configLoader: false,
        });
      } else {
        this.setState({ configLoader: false });
      }
    } catch (graphqlError) {
      Promise.resolve();
      this.setState({ configLoader: false });
    }
  };
  handleVersionFilter = (item) => {
    return this.state.highlightedConfigurationVersions.filter((el) =>
      el.id === item
        ? this.setState(
          { highlightedConfigurationVersionData: JSON.parse(el.data) },
          () => console.log(this.state.highlightedConfigurationVersionData)
        )
        : null
    );
  };
  handleResetVersions = () => {
    return this.setState({ highlightedConfigurationSelectedVersion: "" });
  };
  handleModalClose = () =>
    this.setState({ showDeleteConfirmationModal: false });
  handleModalShow = () => this.setState({ showDeleteConfirmationModal: true });

  render() {
    return (
      <div className="MVPConfigurationDashboard">
        <LoadingComponent
          visible={this.props.isLoadingComplete === false}
        ></LoadingComponent>

        <div className="bp-l">
          <div className="bp-l-h pl-4">
            <label title="Configuration">Configuration</label>
          </div>
          <div className="bp-l-b pt-4 pl-3">
            <label className="mb-0">Please select to copy the configuration</label>
            <div className="bp-l-b-e btstrp dropdown">
              <label className="dropdown-label">Configuration</label>
              <div className="bp-l-b-e-h"></div>
              <div className="btn-group bp-l-b-e-dd">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.highlightedConfigurationType ||
                    `Select the config type`}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <div className="dropdown-menu">
                  {this.props.configurationLabels.map((configurationLabel) => (
                    <div
                      id={configurationLabel.type}
                      className="dropdown-item"
                      onClick={() => {
                        this.props.onHighlightConfigurationType(
                          configurationLabel.type
                        );
                        this.setState({
                          isLoading: this.props.configurations.length === 0,
                          highlightedConfigurationVersions: [],
                        });
                      }}
                    >
                      <label>
                        {configurationLabel.type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <>
              {this.props.highlightedConfigurationType ? (
                <>
                  <div className="bp-l-b-e btstrp dropdown">
                    <label className="dropdown-label capitalise-text">{this.props.highlightedConfigurationType}</label>
                    <div className="bp-l-b-e-h">
                      {/*<label className="p-14 c-292929">
                        {this.props.highlightedConfigurationType}
                        </label>*/}
                    </div>
                    <div className="btn-group bp-l-b-e-dd">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {(this.props.highlightedConfiguration
                          ? this.props.highlightedConfiguration["name"]
                          : "") ||
                          `Select the ${this.props.highlightedConfigurationType}`}
                      </button>
                      <button
                        className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      />
                      <div className="dropdown-menu">
                        {this.props.configurations
                          .filter(
                            (configuration) =>
                              configuration.type ===
                              this.props.highlightedConfigurationType
                          )
                          .map((item) => (
                            <div
                              id={item.name}
                              className="dropdown-item"
                              onClick={() => {
                                this.props.onHighlightConfiguration(item.id);
                                this.setState(
                                  {
                                    selectedConfigName:
                                      this.props.onHighlightConfiguration(
                                        item.id
                                      ).payload,
                                    selectedConfigType:
                                      this.props.highlightedConfigurationType,
                                  },
                                  () => {
                                    this.handleDisplayConfigVersion();
                                  }
                                );
                              }}
                            >
                              <label className="p-14 c-292929">
                                {item.name}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
            {this.state.configLoader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <HorizontalLoader></HorizontalLoader>
              </div>
            ) : null}

            {this.props.highlightedConfigurationType &&
              this.state.highlightedConfigurationVersions.length !== 0 ? (
              <>
                <div className="bp-l-b-e btstrp dropdown">
                  <label className="dropdown-label">Version</label>
                  <div className="btn-group bp-l-b-e-dd">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.highlightedConfigurationSelectedVersion
                        ? this.state.highlightedConfigurationSelectedVersion
                          .split("_")
                          .pop()
                          .slice(0, 10) +
                        " " +
                        this.state.highlightedConfigurationSelectedVersion
                          .split("_")
                          .pop()
                          .slice(11, 19)
                        : "Select the config Version"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                    <div className="dropdown-menu">
                      {this.state.highlightedConfigurationVersions.map(
                        (item, index) => (
                          <div
                            id={item.id}
                            className="dropdown-item"
                            onClick={() => {
                              this.setState({
                                highlightedConfigurationSelectedVersion: item.id
                                  .split("_")
                                  .pop(),
                              });
                              this.handleVersionFilter(item.id);
                            }}
                          >
                            <label className="p-14 c-292929">
                              {item.id.split("_").pop().slice(0, 10) +
                                " " +
                                item.id.split("_").pop().slice(11, 19)}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            
              <div
                style={{ marginTop: "50px" }}
                className="bp-l-b-e btstrp dropdown"
              >
                <div className="bp-l-b-e-h">
                  <label className="pb-0">
                    Recently Deleted Configurations
                  </label>
                </div>
                <div className="btn-group bp-l-b-e-dd">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.state.selectedDeletedConfiguration
                      ? this.state.selectedDeletedConfiguration
                      : "Select the Configuration"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={this.handleDropdownClick}
                  />
                  <div className="dropdown-menu">
                  {
                    this.props.deletedConfigurations.length === 0 && (
                      <div style={{ display:'flex',justifyContent:'center',minHeight:'100px'}}>
                        <img width="30px" src="/img/loader.svg" alt="loading" />
                      </div>
                    )
                  }
                    {this.props.deletedConfigurations.map((item, index) => (
                      <div
                        id={item.name}
                        className="dropdown-item"
                        onClick={() => {
                          this.handleDisplayDeletedConfig(item.id);
                          this.handleResetVersions();
                        }}
                      >
                        <label>
                          {/* {item.id.substring(0, item.id.length - 14)} */}
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <label className="dropdown-label p-14">Note: You can view deleted configs only for 30 days</label>
              </div>
            
          </div>
          <div className="bp-l-f">
            <Button
              className="btn-primary"
              autoFocus
              onClick={() => this.props.onAddConfigurationOpenDialog()}
            >
              <span className='pr-2'> + </span> Add New Config
            </Button>
          </div>
        </div>
        <div className="bp-r">
          <>
            {this.props.highlightedConfigurationId ||
              this.state.selectedDeletedConfiguration !== "" ? (
              <>
                <div className="bp-r-h">
                  <div style={{ display: "flex", flexGrow: "1" }}>
                    <label
                      className="display-header-title"
                      style={{ width: "50%" }}
                    >
                      Selected:
                      <label className="display-header-value">
                        {this.props.highlightedConfigurationId
                          ? this.props.highlightedConfiguration["name"]
                          : this.state.selectedDeletedConfiguration}
                      </label>
                    </label>

                    {this.state.highlightedConfigurationSelectedVersion ? (
                      <div className="config-diff-header-right">
                        <label
                          title="ConfigurationVersion"
                          style={{}}
                          className="display-header-title"
                        >Version
                          <br></br>

                          <label className="display-header-value pl-0">
                            {this.state.highlightedConfigurationSelectedVersion
                              .split("_")
                              .pop()
                              .slice(0, 10) +
                              " " +
                              this.state.highlightedConfigurationSelectedVersion
                                .split("_")
                                .pop()
                                .slice(11, 19)}
                          </label>
                        </label>
                        <div>
                          <Button
                            onClick={() =>
                              navigator.clipboard
                                .writeText(
                                  JSON.stringify(
                                    this.state
                                      .highlightedConfigurationVersionData
                                  )
                                )
                                .then(() => {
                                  this.setState({ copyShowToast: true });
                                  this.handleResetVersions();
                                })
                            }
                            variant="btn-secondary"
                            className="btn outline-secondaryicon-btn"
                            autoFocus
                          >
                            <img src="/copy-icon.svg" className='pr-2 config-icons' alt="Copy Icon" /> Copy Previous Version
                          </Button>

                          <Button
                            onClick={() => this.handleResetVersions()}
                            variant="outline-secondary"
                            className=" btn-print-review-primary btn btn-primary"
                            autoFocus
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {this.state.highlightedConfigurationSelectedVersion === ""  && !this.state.selectedDeletedConfiguration ?  (
                    <div className="bp-r-h-options">
                      <Button
                        variant="btn-secondary"
                        disabled={!this.props.highlightedConfigurationId}
                        className="btn outline-secondaryicon-btn"
                        autoFocus
                        onClick={() =>
                          this.props.onRenameConfigurationOpenDialog()
                        }
                      >
                        <img src="/rename-icon.svg" className='pr-2 config-icons' alt="Rename Icon" /> Rename
                      </Button>
                      <Button
                        variant="btn-secondary"
                        className="btn outline-secondaryicon-btn"
                        autoFocus
                        disabled={!this.props.highlightedConfigurationId}
                        onClick={() =>
                          this.props.onCopyConfigurationOpenDialog()
                        }
                      >
                        <img src="/copy-icon.svg" className='pr-2 config-icons' alt="Copy Icon" /> Copy
                      </Button>
                      <Button
                        variant="btn-secondary"
                        disabled={!this.props.highlightedConfigurationId}
                        className="btn outline-secondaryicon-btn"
                        autoFocus
                        onClick={this.handleModalShow}
                      >
                        <img src="/delete-icon.svg" className='pr-2 config-icons' alt="Delete Icon" /> Delete
                      </Button>
                      <Button
                        ref={this.updateButton}
                        variant="outline-secondary"
                        disabled={!this.props.highlightedConfigurationId}
                        className={
                          this.props.highlightedConfigurationId
                            ? "btn-print-review-primary btn btn-primary"
                            : "outline-secondarytextgrey-btn"
                        }
                        autoFocus
                        onClick={() =>
                          this.props.onUpdateConfiguration(
                            JSON.parse(this.props.highlightedConfigurationData)
                          )
                        }
                      >
                        Update
                      </Button>


                    </div>
                  ) : null}
                  {this.state.selectedDeletedConfiguration && (
                    <div className="bp-r-h-options">
                      <Button
                        variant="outline-secondary"
                        autoFocus
                      onClick={() =>
                         this.props.onResetConfigurationScreen()
                      }
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="outline-secondary"
                        className="btn-print-review-primary btn btn-primary"
                        autoFocus
                      onClick={() =>
                        {
                          console.log('this.state',this.state)
                          this.props.onRestoreHighlightConfiguration(this.state.selectedDeletedConfiguration)
                          this.props.onRestoreConfigurationInit(true)
                          this.props.onRenameConfigurationOpenDialog()}
                      }
                      >
                        Restore
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div aria-live="polite" aria-atomic="true">
                    <Toast
                      style={{
                        position: "absolute",
                        top: 50,
                        right: 30,
                        zIndex: 10000,
                      }}
                      onClose={() => this.setState({ copyShowToast: false })}
                      show={this.state.copyShowToast}
                      delay={2000}
                      autohide
                      animation
                    >
                      <Toast.Body>Copied Latest Version</Toast.Body>
                    </Toast>
                  </div>
                  <section
                    style={{ width: "50%" }}
                    className="bp-r-b bp-r-b-only block"
                  >
                    <JSONEditor
                      value={
                        this.props.highlightedConfigurationId
                          ? JSON.parse(this.props.highlightedConfigurationData)
                          : this.state.selectedDeletedConfigurationData
                      }
                      onChange={(data) =>
                        this.props.onEditConfigurationData(JSON.stringify(data))
                      }
                      onValidationError={(error) => {
                        console.log("Error" + error);
                      }}
                    />
                  </section>
                  {Object.keys(this.state.highlightedConfigurationVersionData)
                    .length !== 0 &&
                    this.state.highlightedConfigurationSelectedVersion && (
                      <section
                        className="bp-r-b bp-r-b-only block"
                        style={{ width: "50%" }}
                      >
                        <div className="diff-header">
                          Version:
                          {this.state.highlightedConfigurationSelectedVersion}
                        </div>
                        <ReactJsonViewCompare
                          oldData={this.props.highlightedConfigurationDataJSON}
                          newData={
                            this.state.highlightedConfigurationVersionData
                          }
                        />
                      </section>
                    )}
                </div>
              </>
            ) : null}
          </>
        </div>

        <div className="add-new-mp">
          <AddConfiguration />
        </div>
        <CopyConfiguration />
        <RenameConfiguration />

        <DeleteConfirmationModal
          showModal={this.state.showDeleteConfirmationModal}
          hideModal={this.handleModalClose}
          highlightedConfigurationId={this.props.highlightedConfigurationId}
          handleDelete={() => {
            this.props.onDeleteConfiguration();
            this.handleModalClose();
          }}
        ></DeleteConfirmationModal>
      </div>
    );
  }
}

const ConfigurePage = withRouter(connect(Props, Actions)(Component));

export default ConfigurePage;
