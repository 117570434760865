import React from 'react';


const HorizontalLoader =(props)=>{
    const { color = '#aeaeae'} =props
    return(
       <>

           
            <svg style={{marginLeft:'25%', width:'100%', height:'55px'}} version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" >
               
                <circle fill={color} stroke="none" cx="6" cy="50" r="3">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.1" />
                </circle>
                <circle fill={color} stroke="none" cx="26" cy="50" r="3">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.2" />
                </circle>
                <circle fill={color} stroke="none" cx="46" cy="50" r="3">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.3" />
                </circle>
                <circle fill={color} stroke="none" cx="66" cy="50" r="3">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.3" />
                </circle>
                
            </svg>
       </>)
}

export default HorizontalLoader











