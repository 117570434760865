import { IState, IBuild } from '../../../Interfaces';

const onLoadBuildsInProgressReducer = (state: IState, action) => {
    const newlyLoadedBuilds = action.payload.builds as IBuild[];
    const nextToken = action.payload.nextToken;
    const newState = { ...state } as IState;

		newState.data.builds.loadedBuilds.splice(0, newState.data.builds.loadedBuilds.length, ...newlyLoadedBuilds);
    newState.data.builds.isLoadingInProgress = true;
    newState.data.builds.nextToken = nextToken;

    newState.data.builds.loadedBuilds.forEach((lb, i) => {
        switch (lb.current_status) {
            case 'fail':
                newState.data.builds.loadedBuilds[i]['current_status'] = 'failed';
                break;
            default:
                break;
        }
    });

    return newState;
};

export default onLoadBuildsInProgressReducer;
