import React, {useState} from "react";
import MatrixParamsEditing from "./MatrixParamsEditing";
import MatrixSizeEditing from "./MatrixSizeEditing";

export default function SidebarMatrix (props) {
    const [isSizeEditing , setSizeEditing ] = useState(true);
    if(isSizeEditing) {
        return (
            <div>
                <MatrixSizeEditing 
                    build={ props.build }
                    dispatch={props.dispatch}
                    dataStore={props.dataStore}
                    selectedMatrix={props.selectedMatrix}
                    workspace={props}
                    setSizeEditing={setSizeEditing}
                    />
            </div>
        )
    } else {
        return (
            <div>
                <MatrixParamsEditing 
                    dispatch={props.dispatch}
                    selectedMatrix={props.selectedMatrix}
                    setSizeEditing={setSizeEditing}
                    />
            </div>
        )
    }
}