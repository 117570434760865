import { IState } from '../../../Interfaces';

const onLoadCurrentUserInfoReducer = (state: IState, action) => {
    const creator = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.creator = creator;
    
    return newState;
};

export default onLoadCurrentUserInfoReducer;