import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPartCardView, DisplayPartsOptions, DisplayFolder, DisplayReviewPartCardView } from '../..';
import SearchReviewPartCardView from "./SearchReviewPartCardView"
import { IState } from '../../../Interfaces';
import React from 'react';
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import { onLoadParts } from "../../../Actions/Home";

const Props = (state: IState, ownProps: any) => {

    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
        Boolean(ownProps.displayParts && ownProps.displayParts.length > 0);

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
        label: ownProps.label,
        displayFolders: ownProps.displayFolders,
        displayParts: ownProps.displayParts,
        showOptions: ownProps.showOptions,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
        isSelected: ownProps.isSelected,
        onUnselect: ownProps.onUnselect,
        onSelect: ownProps.onSelect,
        hasPlates: ownProps.hasPlates,
        hasBuilds: ownProps.hasBuilds,
        nextToken: state.data.parts.nextToken,
        loading: !(state.data.parts.isLoadingComplete as boolean),
        searchText: state.data.parts.searchText as string,
        isPartsSearchCompleted: state.data.search.partsSearchCompleted as boolean,
        isSearchEnabled: state.data.search.isSearchEnabled as boolean,
        partsSearchResultsLength: state.data.search.partsSearchCompleted ? state.data.search.searchPartsResults.length : 0,
        partsSearchResults: state.data.search?.searchPartsResults.map(item => state.data.parts.selectedPartIds.some((id) => id === item.id) ? ({...item, isSelected: true}) :( {...item, isSelected: false}) ),
    };

    return props;
};

const Actions = {
    onLoadParts: onLoadParts,
};

const DisplayReviewPartsMethod = (props) => {
    const loading = props.loading;
    const searchResultNotFound = () => {
        if (props.displayParts.length === 0 && loading !== true) {
            return (
                <div className="d-block w-100 text-center">
                    <img src="/no-result-found.svg" alt="no-search-result" className="icon-small" />
                    <p className="justify-content-center p-4">No Parts in the name of <b>{props.searchText}</b></p>
                </div>
            )

        }
    }

    

    return (
      <>
        {!props.isSearchEnabled && (
          <>
            <>
              {props.hasDisplayItems ? (
                <>
                  <LoadingComponent visible={!props.isLoadingComplete} />
                  <dl>
                    <dd>
                      {props.displayParts.map((dp, i) => {
                        return (
                          <DisplayReviewPartCardView
                            {...{
                              key: i,
                              index: i,
                              displayPart: dp,
                              isSelected: props.isSelected,
                              onUnselect: props.onUnselect,
                              onSelect: props.onSelect,
                            }}
                          />
                        );
                      })}
                    </dd>
                    {searchResultNotFound()}
                    {props.nextToken ? (
                      <div className="d-block w-100 text-center pb-3">
                        <button
                          onClick={() => props.onLoadParts()}
                          className="btn btn-primary justify-content-center"
                        >
                          Load More Parts
                          <span className="btn-right-arrow"></span>
                        </button>
                      </div>
                    ) : null}
                  </dl>
                </>
              ) : null}
            </>
          </>
        )}
            
            {props.isSearchEnabled && (
                <div>
                    <dl>
                        <dd>
                            {props.isPartsSearchCompleted ? (
                                props.partsSearchResultsLength !== 0 ? (
                                    <>
                                        {props.partsSearchResults.map((dp, i) => {
                                      return (
                                        <SearchReviewPartCardView
                                          {...{
                                            key: i,
                                            index: i,
                                            partsSearchResult: dp,
                                            isSelected: props.isSelected,
                                            onUnselect: props.onUnselect,
                                            onSelect: props.onSelect,
                                          }}
                                        />
                                      );
                                    })}
                                        {/* // (
                                        //     <SearchReviewPartCardView key={i} partsSearchResult={el} />
                                        // ) */}
                                        
                                    </>
                                ) : (
                                    <p>No search results available.</p>
                                )
                            ) : (
                                <LoadingComponent visible={true} />
                            )}
                        </dd>
                    </dl>
                </div>
            )}
      </>
    );
}

const DisplayReviewParts = withRouter(connect(Props, Actions)(DisplayReviewPartsMethod));

export default DisplayReviewParts;
