import React from "react";
import Modal from "react-modal";
import Catalog from "./Catalog";
import "../css/customJsonEditor.scss";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



const CatalogFolder = (props) => {
    const [value, setValue] = React.useState(0);
    const [showFilesInFolder, setShowFilesInFolder] = React.useState(false);
    const [buildNameList, setBuildNameList] = React.useState([]);
    const tabHandleChange = (event, newValue) => {
        setValue(newValue);
        //   if(newValue === 1) {
        //     routeChange(`/buildFolderss`);
        //   } else {
        //     routeChange(`/builds`);
        //   }
    };
    const onFolderClick = (item) => {
        console.log('onFolderClick~~~', item);
        setBuildNameList(item['builds']);

    };
    const onBackFolderClick = (item) => {
        console.log('onBackFolderClick~~~', item);
        setBuildNameList([]);
    };

    return (

        <React.Fragment>
            <Paper square>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={tabHandleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Builds" />
                    <Tab label="Folders" />
                </Tabs>
            </Paper>
            {value === 0 ? (
                <Catalog
                /*onNewObjectLink="/printers"*/ onSelectObjectLink="/item"
                    dataType={"Builds"}
                    elementsPerPage={20}
                    onClick={() => {
                    }}
                    initFunc={(build: any) => {
                        build.Max_Layer = -1;
                        build.Max_Slab = -1;
                        build.Contouring = true;
                        build.overrides = [];
                        build.machineConfig = {};
                        build.millConfig = {};
                        build.materialConfig = {};
                        build.recipe = {};
                        build.parts = [];
                    }}
                    schemaName={"Build"}
                />
            ) : (<React.Fragment>
                {buildNameList.length>0 ? (
                    <Catalog
                /*onNewObjectLink="/printers"*/ onSelectObjectLink="/item"
                        dataType={"Builds"}
                        filesInFolders = {buildNameList}
                        openFromFolder = {true}
                        elementsPerPage={20}
                        onClick={(item: any) => {
                            onBackFolderClick(item);
                            // dispatch({
                            //     type: "setBuild",
                            //     cargo: {
                            //         build: build
                            //     }
                            // })
                        }}
                        schemaName={"Build"}
                    />
                ) : (<Catalog
                    /*onNewObjectLink="/printers" 
                    onSelectObjectLink="/item"*/
                    dataType={"BuildFolders"}
                    fileDataType={"Builds"}
                    elementsPerPage={20}
                    onClick={(item: any) => {
                        onFolderClick(item);
                      }}
                    initFunc={(buildFolder: any) => {
                        buildFolder.builds = [];
                    }}
                    schemaName={"BuildFolders"}
                />)}
            </React.Fragment>)}
        </React.Fragment>
    );
};

export default CatalogFolder;
