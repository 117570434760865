import ACTION_TYPES from '../../../actionTypes';

import { onUnarchivePart } from '..';
import { NotificationManager } from "react-notifications";
const onUnarchivePartsInit = () => {
    return {
        type: ACTION_TYPES.PARTS.UNARCHIVE.INIT,
        payload: null,
    };
};

const onUnarchivePartsSuccess = () => {
    return {
        type: ACTION_TYPES.PARTS.UNARCHIVE.SUCCESS,
        payload: null,
    };
};

const onUnarchivePartsFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Unarchive Parts');
    console.error('Failure on Unarchive Parts - ', errorMessage);
    return {
        type: ACTION_TYPES.PARTS.UNARCHIVE.FAIL,
        payload: errorMessage,
    };
};

const onUnarchivePartsDone = () => {
    return {
        type: ACTION_TYPES.PARTS.UNARCHIVE.DONE,
        payload: null,
    };
};

const onUnarchiveParts = (partIds) => {
    return async (dispatch, getState) => {
        dispatch(onUnarchivePartsInit());
        try {
            const unarchivePromises = partIds.map(id => (onUnarchivePart(id)(dispatch, getState)));
            await Promise.allSettled(unarchivePromises);
            dispatch(onUnarchivePartsSuccess());
        } catch (graphqlError) {
            const errorMessage = `Unarchive parts failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUnarchivePartsFail(errorMessage));
        }
        dispatch(onUnarchivePartsDone());
    };
};

export default onUnarchiveParts;