import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { onHelpPageTabChange } from '../Actions/Home';


const HelpPageTabMethod = (props) => {
    const { activeTab, setActiveTab, TabLists } = props;

    return (
        <>
            <div className='tab-bar'>
                <div className='options left-justify'>
                    {TabLists?.map((item, index) => <div key={index} onSelect={(selectedKey) => onHelpPageTabChange(selectedKey)}
                        className={`tab ${activeTab.key === item.key ? 'active' : ''}`}
                        onClick={() => setActiveTab(item)}>
                        <label>{item.label}</label>
                    </div>)}
                </div>
                <div className='flex-space'></div>
            </div>
        </>

    );
}


const HelpPageTabBar = withRouter(HelpPageTabMethod);

export default HelpPageTabBar; 