import { IState, IBuild } from "../../../Interfaces";

const onRebuildReducer = (state: IState, action) => {
  const buildToBeDeleted = action.payload as IBuild;
  const indexToBeReplaced = state.data.builds.loadedBuilds.findIndex(
    (lp) => lp.id === buildToBeDeleted.id
  );
  const isBuildAvailable = indexToBeReplaced !== -1;

  const newState = { ...state } as IState;
  if (isBuildAvailable)
    newState.data.builds.loadedBuilds.splice(indexToBeReplaced, 1);

  return newState;
};

export default onRebuildReducer;
