import { IState } from "../../../Interfaces";

const onDownloadDoneReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.totalDownloadCompleted = state.totalDownloadCompleted + 1;
    if (newState.totalDownloadCompleted === newState.totalDownloadRequested) {
        newState.totalDownloadCompleted = 0;
        newState.totalDownloadRequested = 0;
    }

    return newState;
};

export default onDownloadDoneReducer;