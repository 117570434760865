import { IState, IPart } from "../../../Interfaces";

const onUnarchivePartReducer = (state: IState, action) => {
    const unarchivedPart = action.payload as IPart;
    const indexToBeReplaced = state.data.parts.loadedParts.findIndex(lp => lp.id === unarchivedPart.id);
    const isPartExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPartExists) newState.data.parts.loadedParts.splice(indexToBeReplaced, 1, unarchivedPart);

    newState.data.parts.selectedPartIds = newState.data.parts.selectedPartIds.filter(id => id !== unarchivedPart.id);

    return newState;
};

export default onUnarchivePartReducer;