import { API, graphqlOperation } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPlateListView, DisplayPlateCardView, DisplayPlatesOptions } from '../..';
import { updatePlate } from '../../../../graphql/mutations';
import { plateByCreatedAt } from '../../../../graphql/queries';
import LoadingComponent from '../../../../LoadingComponent/LoadingComponent';
import { onSearchPlatesTextChange } from '../../../Actions/Plates';
import { onLoadPlates } from "../../../Actions/Home";
import { IPlate, IState } from '../../../Interfaces';
import DisplayFolder from '../Common/DisplayFolder';

const Props = (state: IState, ownProps: any) => {

    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
        Boolean(ownProps.displayPlates && ownProps.displayPlates.length > 0) as boolean;

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        showListView: state.data.info.showListView as boolean,
        isLoadingComplete: state.data.plates.isLoadingComplete as boolean,
        loading: !(state.data.plates.isLoadingComplete as boolean),
        nextToken: state.data.plates.nextToken,
        displayFolders: ownProps.displayFolders,
        displayPlates: ownProps.displayPlates,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
        loadedPlates: state.data.plates.loadedPlates,
        searchText: state.data.plates.searchText as string,
        sortDirection: state.data.plates.sortDirection,
    };

    return props;
};

const Actions = {
    onSearchPlatesTextChange: onSearchPlatesTextChange,
    onLoadPlates: onLoadPlates,
};

const DisplayPlatesMethod = (props) => {
    const [plateResult, setPlateResult] = useState<any[]>([]);
    const [token, setToken] = useState<string | null>(null);
    const loading = props.loading;

    const loadMorePlates = async () => {
				props.onLoadPlates();
    };

    const getDisplayPlates = () => {
        if (props.loadedPlates.length === 0 && loading !== true) {
            return (
                <div className="d-block w-100 text-center">
                    <p className="justify-content-center p-4">No Plates in the name of <b>{props.searchText}</b></p>
                </div>
            )

        }

        if (props.showPlates) {
            return (props.displayPlates.map((dp, i) => (props.showListView ? <DisplayPlateCardView key={i} displayPlate={dp} /> :
                <DisplayPlateCardView key={i} displayPlate={dp} visible={loading} />
            )))
        }
        else {
            return (props.loadedPlates.map((dp, i) => (
							props.showListView ? 
								<DisplayPlateListView key={i} displayPlate={dp} /> :
                <DisplayPlateCardView key={i} displayPlate={dp} />
						)))
        }
    }

    useEffect(() => {
        props.parentCallback(props.loadedPlates.length);
    }, [props.loadedPlates]);

    return (
        <>
					{
						(props.hasDisplayItems) ?
							(
								<>
										<LoadingComponent visible={loading} />
										<dl className={`${props.showListView ? "grid-view" : ""}`}>
											{
												props.showItems ?
														(
															<dd>
																{
																		props.displayFolders.map((df, i) => (<DisplayFolder key={i} displayFolder={df} showParts={false} showPlates={true} showBuilds={false} />))
																}
															</dd>
														) : ( null)
											}
											{
												props.showItems ?
													(
														<dd>
															{
																<>
																	{
																		props.showListView ?
																				(
																						<>
																								<div className="display-list">
																										<div className="display-list-row">
																												<div className="display-list-header">
																														<label>Type</label>
																												</div>
																												<div className="display-list-header">
																														<label>Name</label>
																												</div>
																												<div className="display-list-header">
																														<label>Imported Date</label>
																												</div>
																												<div className="display-list-header">
																														<label>Owner</label>
																												</div>
																												<div className="display-list-header">
																														<label>No of Builds</label>
																												</div>
																												<div className="display-list-header">
																														<label>Folder</label>
																												</div>
																												<div className="display-list-header">
																														<label>Actions</label>
																												</div>
																										</div>
																								</div>
																						</>
																				)
																				:
																				(
																						null
																				)
																	}
																	{getDisplayPlates()}

																	{
																		props.nextToken ?
																			(
																				<>
																					<div className="d-block w-100 text-center">
																						<button onClick={loadMorePlates} className="btn btn-primary justify-content-center">
																								Load More Plates<span className="btn-right-arrow"></span></button>
																					</div>
																				</>

																			) : (null)
																	}
																</>
															}
														</dd>
													) : ( null)
											}
										</dl>
								</>
							) :
							(
								<div className="d-block w-100 text-center">
										<p className="justify-content-center p-4">
												Please wait while the page is loading ...</p>
								</div>
							)
					}
        </>
    );
}

const DisplayMorePlates = withRouter(connect(Props, Actions)(DisplayPlatesMethod));

export default DisplayMorePlates;
