import { IPlate, IState } from '../../../Interfaces';

const onLoadPlatesUpdatedReducer = (state: IState, action) => {
    const updated = action.payload;
    const newState = { ...state } as IState;

    newState.data.plates.loadedPlates = state.data.plates.loadedPlates.
                                          map(val => val.id === updated.id ? {...val, ...updated} : val).
                                            filter(a => !a._deleted);

    return newState;
};

export default onLoadPlatesUpdatedReducer;
