enum DownloadStatus {
    CLEAR = 'cleared',
    REQUEST = 'requested',
    START = 'started',
    IN_PROGRESS = 'in progress',
    SUCCESS = 'completed',
    FAIL = 'failed',
    DONE = 'done',
};

export default DownloadStatus;