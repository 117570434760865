import {IPlate, IState} from '../../../Interfaces';

const onLoadPlatesCreatedReducer = (state: IState, action) => {

    const newlyLoadedPlates = action.payload as IPlate;
    const newState = { ...state } as IState;
    newState.data.plates.loadedPlates = [newlyLoadedPlates, ...state.data.plates.loadedPlates]

    return newState;
};

export default onLoadPlatesCreatedReducer;
