import { IState } from '../../../Interfaces';

const onLoadFoldersCompletedReducer = (state: IState, action) => {
    
    const newState = { ...state } as IState;
    newState.data.folders = {
        ...newState.data.folders,
        isLoadingStarted: false as boolean,
        isLoadingInProgress: false as boolean,
        isLoadingComplete: true as boolean,
    };
    
    return newState;
};

export default onLoadFoldersCompletedReducer;