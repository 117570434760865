import { getDefaultMillConfiguration, getDefaultPrintConfiguration, getDefaultRecipeConfiguration, getDefaultMaterialConfiguration } from "../DefaultConfigurations";
import { IConfiguration, IState } from "../Interfaces";

const setPlateConfigurations = (state: IState, plateConfigurationIds: string[], resetPlate: boolean = false): IState => {

    const newState = { ...state } as IState; 
    
    newState.data.workspace.selectedConfigurations = plateConfigurationIds.map(id => state.data.configurations.loadedConfigurations.find(lc => lc.id === id)) as IConfiguration[];
    
    if (resetPlate) {

        newState.data.workspace.selectedConfigurations = [] as IConfiguration[];

        const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
        newState.data.workspace.selectedConfigurations.push(defaultPrintConfiguration);

        const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
        newState.data.workspace.selectedConfigurations.push(defaultMillConfiguration);

        const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;
        newState.data.workspace.selectedConfigurations.push(defaultRecipeConfiguration);

        const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
        newState.data.workspace.selectedConfigurations.push(defaultMaterialConfiguration);
    }

    newState.data.workspace.selectedConfigurations.forEach(configuration => {
        switch (configuration?.type) {
            case 'printConfig':
                newState.data.workspace.state.build.machineConfig = {
                    originalJson: JSON.parse(configuration['data']),
                    resultJson: JSON.parse(configuration['data']),
                };
                break;
            case 'millConfig':
                newState.data.workspace.state.build.millConfig = {
                    originalJson: JSON.parse(configuration['data']),
                    resultJson: JSON.parse(configuration['data']),
                };
                break;
            case 'recipe':
                newState.data.workspace.state.build.recipe = {
                    originalJson: JSON.parse(configuration['data']),
                    resultJson: JSON.parse(configuration['data']),
                };
                break;
            case 'material':
                newState.data.workspace.state.build.materialConfig = {
                    originalJson: JSON.parse(configuration['data']),
                    resultJson: JSON.parse(configuration['data']),
                };
                break;
            default:
                break;
        }
    });

    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    return newState;
};

export default setPlateConfigurations;