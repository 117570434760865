import React from "react";

import EditJsonModal from "../EditJsonModal";
import CatalogModal from "../Catalog/CatalogModal";

interface IConfigsDisplayProps {
  machineConfig: any;
  millConfig: any;
  recipeConfig: any;
  materialConfig: any;
  dataStore: any;
  configChangerDispatch: any;
}

interface ISelectedJson {
  json: Object | null;
  diff: Object | undefined;
  dataType: string;
}

interface ISelectedCatalog {
  dbName: string;
  dataType: string;
}

const ConfigsDisplay: React.FunctionComponent<IConfigsDisplayProps> = (
  props
) => {
  const emptyJsonSelection: ISelectedJson = {
    json: null,
    dataType: "",
    diff: {},
  };

  const [selectedJson, changeSelectedJson] = React.useState<ISelectedJson>(
    emptyJsonSelection
  );

  const emptyCatalogSelection: ISelectedCatalog = {
    dbName: "",
    dataType: ""
  };
  const [selectedCatalog, changeSelectedCatalog] = React.useState<
    ISelectedCatalog
  >(emptyCatalogSelection);

  const configsList = ["Machine", "Mill", "Recipe", "Material"].map(
    (configName: string) => {
      const config = props[configName.toLowerCase() + "Config"]?.resultJson;
      const diff = props[configName.toLowerCase() + "Config"]?.diff;
      const configIsSelected = config && config.id;
      const label = configIsSelected
        ? config.id
        : `${configName} isn't selected`;
      const dbName =
        configName +
        (configName === "Machine" || configName === "Mill" || configName === "Material" ? "Configs" : "s");

      const editButton = !configIsSelected ? null : (
        <img
          src={"/pencil-sharp.svg"}
          style={{ width: "32px", height: "32px" }}
          onClick={() =>
            changeSelectedJson({
              json: config,
              dataType: configName,
              diff: diff,
            })
          }
        />
      );

      return (
        <div className={"sideBarParamsBlock"}>
          {label}

          {editButton}
          <img
            src={"/list-sharp.svg"}
            style={{ width: "32px", height: "32px" }}
            onClick={() => {
              changeSelectedCatalog({
                dbName: dbName,
                dataType: configName
              });
            }}
          />
        </div>
      );
    }
  );
      
  return (
    <div>
      Configs:
      {configsList}
      <EditJsonModal
        jsonToEdit={selectedJson.json}
        modalCloser={() => changeSelectedJson(emptyJsonSelection)}
        diff={selectedJson.diff}
        updateJson={(updatedJson: Object) => {
          props.configChangerDispatch({
            type: `update${selectedJson.dataType}`,
            cargo: {
              item: updatedJson,
            },
          });
        }}
        schemaName={selectedJson.dataType}
      />
      <CatalogModal
        dataType={selectedCatalog.dbName}
        schemaName={selectedCatalog.dataType}
        modalCloser={() => changeSelectedCatalog(emptyCatalogSelection)}
        onCatalogEntrySelected={(selectedConfig: Object) => {
          props.configChangerDispatch({
            type: `set${selectedCatalog.dataType}`,
            cargo: {
              item: selectedConfig,
            },
          });
        }}
      />
    </div>
  );
};

export default ConfigsDisplay;
