
import { IState, IPart } from '../../Interfaces';

const onResetHighlightedPartReducer = (state: IState, action) => {
    const newState = { ...state } as IState;

    newState.data.search = {
        ...newState.data.search,
        highlightPartStatus: "",
        highlightedPartData: {} as IPart
    };
    
    return newState;
};

export default onResetHighlightedPartReducer;