import IPartInfo from "../../../../interfaces/IPartInfo";
import { IConfiguration, IState } from "../../../Interfaces";

const onSelectPartsOnAddedPartsReducer = (state: IState, action) => {
    
    const partIndices = action.payload as number[];

    const newState = { ...state } as IState;
    //set selected config to all 
    const selectedConfig = state.data.workspace.addedPartsSelectedPartConfigurations[state.data.workspace.addedPartsSelectedPartIndices[0]];
    for (let i=0 ; i < state.data.workspace.addedPartsSelectedPartConfigurations.length; i++)
    {
        newState.data.workspace.addedPartsSelectedPartConfigurations[i] = selectedConfig
    }

    (newState.data.workspace.state.build.parts as IPartInfo[]).forEach((partInfo, index) => {
        if(newState.data.workspace.addedPartsSelectedPartConfigurations[index] !== undefined){
        try {
                partInfo.properties.PartConfig = {
                    originalJson: JSON.parse(newState.data.workspace.addedPartsSelectedPartConfigurations[index]['data']),
                    resultJson: JSON.parse(newState.data.workspace.addedPartsSelectedPartConfigurations[index]['data']),
                };
        }
        catch (err) {
            console.error("Error while updating part config", err)
        }
    }
    });
    //End set selected config to all 

    newState.data.workspace.addedPartsSelectedPartIndices = partIndices;
    newState.data.workspace.isAllAddedPartsSelected = true;
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    return newState;
};

export default onSelectPartsOnAddedPartsReducer;