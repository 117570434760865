import { IState } from '../../../Interfaces';

const navigateToPartReviewPageReducer = (state: IState, action) => {
    
    const isNewPlate = action.payload as boolean;

    const newState = { ...state } as IState;
    newState.data.workspace.isNewPlate = isNewPlate;
    newState.data.workspace.isEditPlate = !isNewPlate;
    newState.data.workspace.isReviewPart = !isNewPlate;


    return newState;
};

export default navigateToPartReviewPageReducer;