import { IPart, IState } from '../../../Interfaces';

const onRenamePartSuccessReducer = (state: IState, action) => {
    const renamedPart = action.payload as IPart;
    const indexToBeReplaced = state.data.parts.loadedParts.findIndex(lp => lp.id === renamedPart.id);
    const isPartExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPartExists) newState.data.parts.loadedParts.splice(indexToBeReplaced, 1, renamedPart);

    newState.data.modals.renamePartModalStatus = {
        ...newState.data.modals.renamePartModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onRenamePartSuccessReducer;