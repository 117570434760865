import { IState } from '../../Interfaces';

const onGetSearchStringReducer = (state: IState, action) => {
    const searchString = action.payload as string;
    const newState = { ...state } as IState;
    newState.data.search = {
        ...newState.data.search,
        searchString: searchString.toLowerCase(),
    };

    return newState;
};

export default onGetSearchStringReducer;
