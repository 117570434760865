import { API, graphqlOperation } from 'aws-amplify';
import { createFolders } from '../../../../../graphql/mutations';
import ACTION_TYPES from '../../../../actionTypes';
import { getNewFolder } from '../../../../Instances';
import { INewFolderModalStatus, IFolder, IState } from '../../../../Interfaces';
import { onNavigateToHomePage } from '../../../Home';
import { NotificationManager } from "react-notifications";
const onNewFolderSuccess = (addedFolder) => {
    return {
        type: ACTION_TYPES.HOME.NEW.FOLDER.SUCCESS,
        payload: addedFolder,
    };
};

const onNewFolderFail = (errorMessage: string) => {
    NotificationManager.error('Failure on adding new folder');
    console.error('Failure on adding new folder - ', errorMessage);
    return {
        type: ACTION_TYPES.HOME.NEW.FOLDER.FAIL,
        payload: errorMessage,
    };
};

const onNewFolder = (history) => {
    return async (dispatch, getState) => {

        const state = getState() as IState;

        const newFolderModalStatus = state.data.modals.newFolderModalStatus as INewFolderModalStatus;

        const isFolderNameEmpty = (!newFolderModalStatus.inputText) as boolean;
        const isFolderNameExists = state.data.folders.loadedFolders.some(p => p.name === newFolderModalStatus.inputText) as boolean;

        if (isFolderNameEmpty) {
            const errorMessage = 'Folder Name is mandatory.' as string;
            dispatch(onNewFolderFail(errorMessage));
            return;
        }
        if (isFolderNameExists) {
            const errorMessage = 'Folder Name already exists.' as string;
            dispatch(onNewFolderFail(errorMessage));
            return;
        }

        try {
            const state = getState() as IState;
            const newFolder = getNewFolder(state);
            const variables = {
                input: newFolder,
            };
            const result = await API.graphql(graphqlOperation(createFolders, variables));
            const addedFolder = ((result as any)?.data?.createFolders) as IFolder;
            dispatch(onNavigateToHomePage(history));
            dispatch(onNewFolderSuccess(addedFolder));
        } catch (graphqlError) {
            const errorMessage = `Create folder failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onNewFolderFail(errorMessage));
        }
    };
};

export default onNewFolder;