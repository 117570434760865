import { ITag, IState } from "../Interfaces";

const getNewPlate = (state: IState) => {

    const newTag = ({
        id: state.data.modals.newTagModalStatus.inputText,
        name: state.data.modals.newTagModalStatus.inputText,
        hasParts: false,
        noOfParts: 0,
        hasPlates: false,
        noOfPlates: 0,
        hasBuilds: false,
        noOfBuilds: 0,
        creator: state.creator,
        created_at: new Date().toISOString(),
        modifier: state.creator,
        modified_at: new Date().toISOString(),
        dumb: 1,
    } as unknown as ITag);

    return newTag;
};

export default getNewPlate;