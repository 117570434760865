import { IPlate, IState } from '../../../Interfaces';

const onMovePlateSuccessReducer = (state: IState, action) => {
    const movedPlate = action.payload as IPlate;
    const indexToBeReplaced = state.data.plates.loadedPlates.findIndex(lp => lp.id === movedPlate.id);
    const isPlateExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isPlateExists) newState.data.plates.loadedPlates.splice(indexToBeReplaced, 1, movedPlate);

    newState.data.modals.movePlateModalStatus = {
        ...newState.data.modals.movePlateModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onMovePlateSuccessReducer;