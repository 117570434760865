import { Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';

const onDownload = (storagePath: string, saveAsFileName: string, dispatchables: any) => {
    return async (dispatch, getState) => {
        let receivedLength = 0;
        let processedLength = 0;
        let contentLength = 0;
        dispatch(dispatchables.onDownloadRequest());
        try {
            const url = await Storage.get(storagePath) as string;
            const data = await fetch(url).then(async (response: any) => {
                contentLength = +response.headers.get('Content-Length');
                dispatch(dispatchables.onDownloadStart(contentLength));
                const chunks = [] as any[];
                const reader = response.body.getReader();
                while (true) {
                    const data = await reader.read() as any;
                    if (data.done) break;
                    chunks.push(data.value);
                    processedLength = +data.value.length;
                    receivedLength += processedLength;
                    dispatch(dispatchables.onDownloadInProgress(processedLength));
                }
                return new Blob(chunks);
            });
            saveAs(data, saveAsFileName);
            dispatch(dispatchables.onDownloadSuccess(receivedLength, contentLength));
        }
        catch (error) {
            dispatch(dispatchables.onDownloadFail(receivedLength, contentLength));
        }
        dispatch(dispatchables.onDownloadDone());
    };
};

export default onDownload;
