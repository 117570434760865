import API, { graphqlOperation } from '@aws-amplify/api';

import { updateBuild } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onUpdateBuildInit = () => {
    return {
        type: ACTION_TYPES.BUILD.UPDATE.INIT,
        payload: null,
    };
};

const onUpdateBuildSuccess = (updatedBuild: IBuild) => {
    return {
        type: ACTION_TYPES.BUILD.UPDATE.SUCCESS,
        payload: updatedBuild,
    };
};

const onUpdateBuildFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Update Build');
    console.error('Failure on Update Build - ', errorMessage);
    return {
        type: ACTION_TYPES.BUILD.UPDATE.FAIL,
        payload: errorMessage,
    };
};


const onUpdateBuildDone = () => {
    return {
        type: ACTION_TYPES.BUILD.UPDATE.DONE,
        payload: null,
    };
};

const onUpdateBuildAfterDelete = (buildToBeUpdated, deletedPlate) => {
    return async (dispatch, getState) => {
        dispatch(onUpdateBuildInit());
        try {
            const state = getState();

            const parsedPlates = buildToBeUpdated.plates && buildToBeUpdated.plates.length > 0 ? buildToBeUpdated.plates.map(strigifiedPlate => JSON.parse(strigifiedPlate)) : [];
            const updatedParsedPlates = parsedPlates.filter(parsedPlate => parsedPlate.name !== deletedPlate.name);

            const updatedStrigifiedPlates = updatedParsedPlates.map(parsedPlate => JSON.stringify(parsedPlate));
            const hasPlates = updatedStrigifiedPlates && updatedStrigifiedPlates.length > 0;

            buildToBeUpdated.hasPlates = hasPlates;
            buildToBeUpdated.plates = hasPlates ? updatedStrigifiedPlates : null;

            delete buildToBeUpdated._deleted;
            delete buildToBeUpdated._lastChangedAt;
            delete buildToBeUpdated.createdAt;
            delete buildToBeUpdated.updatedAt;
            buildToBeUpdated.modifier = state.creator as string;
            buildToBeUpdated.modified_at = new Date().toISOString();

            const variables = {
                input: buildToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updateBuild, variables));
            const updatedBuild = ((result as any)?.data?.updateBuild) as IBuild;
            dispatch(onUpdateBuildSuccess(updatedBuild));
        }
        catch (graphqlError) {
            const errorMessage = `Update build failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdateBuildFail(errorMessage));
        }
        dispatch(onUpdateBuildDone());
    };
};

export default onUpdateBuildAfterDelete;