import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPlateListView, DisplayReviewPlateCardView, DisplayPlatesOptions } from '../..';
import { IState } from '../../../Interfaces';
import DisplayFolder from '../Common/DisplayFolder';
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import { onLoadPlates } from "../../../Actions/Home";
import SearchReviewPlateCardView from "../../Search/SearchReviewPlateCardView"

const Props = (state: IState, ownProps: any) => {

    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
        Boolean(ownProps.displayPlates && ownProps.displayPlates.length > 0) as boolean;

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        showListView: state.data.info.showListView as boolean,
        isLoadingComplete: state.data.plates.isLoadingComplete as boolean,
        displayFolders: ownProps.displayFolders,
        displayPlates: ownProps.displayPlates,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
        isSelected: ownProps.isSelected,
        onUnselect: ownProps.onUnselect,
        onSelect: ownProps.onSelect,
        nextToken: state.data.plates.nextToken,
        loading: !(state.data.plates.isLoadingComplete as boolean),
        searchText: state.data.plates.searchText as string,
        isPlatesSearchCompleted: state.data.search.platesSearchCompleted as boolean,
        isSearchEnabled: state.data.search.isSearchEnabled as boolean,
        platesSearchResultsLength: state.data.search.platesSearchCompleted ? state.data.search.searchPlatesResults.length : 0,
        platesSearchResults: state.data.search?.searchPlatesResults,
    };

    return props;
};

const Actions = {
    onLoadPlates: onLoadPlates,
};

const DisplayReviewPlatesMethod = (props) => {

    const loading = props.loading;

    const searchResultNotFound = () => {
        if (props.displayPlates.length === 0 && loading !== true) {
            return (
                <div className="d-block w-100 text-center">
                    <img src="/no-result-found.svg" alt="no-search-result" className="icon-small" />
                    <p className="justify-content-center p-4">No Plates in the name of <b>{props.searchText}</b></p>
                </div>
            )

        }
    }


    return (
      <>
            {!props.isSearchEnabled && (
        <>
          <>
            {props.hasDisplayItems ? (
              <>
                <LoadingComponent visible={!props.isLoadingComplete} />
                <dl className={`${props.showListView ? "grid-view" : ""}`}>
                  <dd>
                    {props.displayPlates.map((dp, i) => {
                      return (
                        <DisplayReviewPlateCardView
                          {...{
                            key: i,
                            index: i,
                            displayPlate: dp,
                            isSelected: props.isSelected,
                            onUnselect: props.onUnselect,
                            onSelect: props.onSelect,
                          }}
                        />
                      );
                    })}
                  </dd>
                  {searchResultNotFound()}
                  {props.nextToken ? (
                    <>
                      <div className="d-block w-100 text-center pb-3">
                        <button
                          onClick={() => props.onLoadPlates()}
                          className="btn btn-primary justify-content-center"
                        >
                          Load More Plates
                          <span className="btn-right-arrow"></span>
                        </button>
                      </div>
                    </>
                  ) : null}
                </dl>
              </>
            ) : null}
          </>
        </>
            )}

            {props.isSearchEnabled && (
                <div>
                    <dl>
                        <dd>
                            {props.isPlatesSearchCompleted ? (
                                props.platesSearchResultsLength !== 0 ? (
                                    <>
                                        {props.platesSearchResults?.map((el, i) => (
                                            <SearchReviewPlateCardView key={i} plateSearchResult={el} isSelected={props.isSelected} onSelect={props.onSelect} />
                                        ))}
                                    </>
                                ) : (
                                    <p>No search results available.</p>
                                )
                            ) : (
                                <LoadingComponent visible={true} />
                            )}
                        </dd>
                    </dl>
                </div>
            )}
      </>
    );
}

const DisplayReviewPlates = withRouter(connect(Props, Actions)(DisplayReviewPlatesMethod));

export default DisplayReviewPlates;
