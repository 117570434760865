import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from 'react-bootstrap';
import {
	WorkspaceSection,
	NewPlate,
} from "../Components";
import {
	IState,
	IConfiguration,
	IAddedPart,
	IPlate,
} from "../Interfaces";
import {
	getAddedParts,
} from "../Mappers";
import { EditPlatePhase } from "../Enums";
import { onClearAddedParts, onAddPartToAddedParts, onSelectPart } from "../Actions/Parts";
import { onNavigateToHomePage, onNavigateToPlatesPage } from "../Actions/Home";
import { getDefaultPrintConfiguration, getDefaultMillConfiguration, getDefaultMaterialConfiguration, getDefaultRecipeConfiguration, getDefaultPartConfiguration, getDefaultPartSlicerConfiguration } from "../DefaultConfigurations";
import IPartInfo from "../../interfaces/IPartInfo";

const Props = (state: IState, ownProps: any) => {
	const addedParts = getAddedParts(state) as IAddedPart[];
	const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;

    const props = {
		addedParts: addedParts as IAddedPart[],
        isLoadingComplete: state.data.folders.isLoadingComplete as boolean,
        selectedTabIndex: state.data.info.homePageTabIndex as number,
        selectedPartIds: state.data.parts.selectedPartIds as string[],
		selectedConfigurations: state.data.workspace.selectedConfigurations || [] as IConfiguration[],

        ids: {
            partIds: [...state.data.parts.selectedPartIds].filter(Boolean) as string[],
            partConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            partSlicerConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartSlicerConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
            plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
        },
    };
	return props;
};

const Actions = {
	onClearAddedParts: onClearAddedParts,
	onNavigateToHomePage: onNavigateToHomePage,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
	onAddPartToAddedParts: onAddPartToAddedParts,
	onSelectPart: onSelectPart,
};

class Component extends React.Component<any, any> {
	componentWillUnmount() {
		this.props.onClearAddedParts();
	}

	public render() {
		return (
			<>
				<section className="plates page">
					<header>
						<NewPlate />
						<div className="tab-bar">
							<div className="options left-justify">
								<div className="back option">
									<img
										className="icon back"
										alt="back"
										onClick={() =>
											this.props.onNavigateToHomePage(this.props.history)
										}
									/>
								</div>
								<div className="plate-status info">
									<label>Review Part</label>
									{this.props.addedParts && this.props.addedParts[0] && this.props.addedParts[0].name && (
										<label className="plate-name">
											{this.props.addedParts[0].name}
										</label>
									)}
								</div>
							</div>
							<div className="flex-space"></div>
							<div className="options right-justify">
								<div className="create-new-plate option">
									<Button className='active'
										onClick={() => 
											this.props.onNavigateToPlatesPage(this.props.ids, this.props.history, true)
										}>
										<span>Create Plate</span>
									</Button>
								</div>
							</div>
						</div>
					</header>
					<section className="plate view">
						<div className="right-view">
							<WorkspaceSection />
						</div>
					</section>
				</section>
			</>
		);
	}
}

const PartReviewPage = withRouter(
	connect(Props, Actions)(Component)
);

export default PartReviewPage;
