import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";

import { Storage } from "aws-amplify";
import {patchSlicer} from "../utils";
import {TextField} from "@material-ui/core";

interface IEditSlicerModalProps {
    slicerId: string;
    patchJson: any;
    modalCloser: () => void;
    updateJson?: (arg0: any) => void;
}

interface EditSlicerState {
    slicerSource: string;
    slicerPatched: string
}

export const EditSlicerModal: React.FunctionComponent<IEditSlicerModalProps> = (props) => {
    const [state, setState] = useState<EditSlicerState>({slicerSource: "", slicerPatched:""})

    const updateFunc = async()=>{
        try {
            if (props.slicerId) {
                const result = await Storage.get(`FileStorage/SlicerConfigs/${props.slicerId}_data.cfg`, {download: true})
                const slicerText = await new Response((result as any).Body).text()
                setState({slicerSource: slicerText, slicerPatched: patchSlicer(slicerText, props.patchJson)})
            }
        }
        catch (e:any){
            NotificationManager.error(`Error loading slicer.cfg: ${e.toString()}`)
        }
    }

    useEffect( () => {
        updateFunc().then( () => {
            console.log("Slicer done")
        })
    }, [props.slicerId, props.patchJson])

    const handleChange = (event )=>{
        setState( { slicerSource: state.slicerSource, slicerPatched: event.target.value})
    }


    const calculateDiff = (text, soutceText) => {
        const toJson = (textToParse) =>
        {
            const lines = textToParse.split('\n');
            let resultObject ={}
            for (let i = 0; i < lines.length; i++)
            {
                let line = lines[i].replace('\r', '')
                let pair = line.split('=')
                const paramName = pair[0].trimStart().trimEnd()
                const paramValue = pair[1]?.trimStart().trimEnd()
                if (pair.length>0){
                    resultObject[paramName]=paramValue
                }
            }
            return resultObject
        }
        const resultJson = toJson(text)
        const sourceJson = toJson(soutceText)
        let diff = {}
        for (const [key, value] of Object.entries(resultJson)) {
            if (sourceJson[key] !== value){
                diff[key] = value
            }
        }
        return diff;
    }

    return (
        <Modal
            isOpen={props.slicerId !== ""}
            onRequestClose={() => props.modalCloser()}
        >
            <TextField value={state.slicerPatched} onChange={handleChange} multiline/><br/>
            {
                props.updateJson ?
                    (<button onClick={ev =>
                    {
                        (props as any).updateJson(calculateDiff(state.slicerPatched, state.slicerSource))}}>
                        Save changes</button>) :

                    null
            }
            <button onClick={props.modalCloser}>close without saving</button>
        </Modal>
    );
}