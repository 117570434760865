import React, { useEffect, useState, FunctionComponent } from "react";

const PartConfigSelector: FunctionComponent<any> = (props: { selectedPart: any, configs: any[], configType: string, title: string, onConfigChanged: any, onConfigToBeEdited: any }) => {

    const [selectedConfig, setSelectedConfig] = useState<any>(null);

    const onConfigChanged = config => {
        setSelectedConfig(config);
        props.onConfigChanged(config);
    };

    useEffect(() => {
        if (!props.selectedPart) return;
        switch (props.configType) {
            case 'partConfig':
                const partConfigIndexIdentified = props.configs.map(c => JSON.parse(c['data'])).findIndex(dataJson => JSON.stringify(dataJson) === JSON.stringify(props.selectedPart.properties.PartConfig.originalJson));
                const partConfigToBeSelected = props.configs[partConfigIndexIdentified];
                setSelectedConfig(partConfigToBeSelected);
                break;
            case 'partSlicerConfig':
                const partSlicerConfigIndexIdentified = props.configs.map(c => JSON.parse(c['data'])).findIndex(dataJson => JSON.stringify(dataJson) === JSON.stringify(props.selectedPart.properties.SlicerConfig.originalJson));
                const partSlicerConfigToBeSelected = props.configs[partSlicerConfigIndexIdentified];
                setSelectedConfig(partSlicerConfigToBeSelected);
                break;
            default:
                break;
        }

    }, [props.selectedPart]);

    return (<div className="bp-l-b-e btstrp dropdown">
        <div className='bp-l-b-e-h'>
            <label className='p-14 c-292929'>{props.title || 'Config'}</label>
            {
                selectedConfig ?
                    <div className='edit-a-b-i' onClick={() => props.onConfigToBeEdited(selectedConfig)} />
                    : null
            }
        </div>
        <div className="btn-group bp-l-b-e-dd">
            <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {selectedConfig ? selectedConfig['name'] : `Select the ${props.title || 'Config'}`}
            </button>
            <button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
            <div className="dropdown-menu">
                {
                    props.configs.map(config => <div id={config.id} className="dropdown-item" onClick={() => { onConfigChanged(config) }}> <label className='p-14 c-292929'>{config.name}</label> </div>)
                }
            </div>
        </div>
    </div>);
};

export default PartConfigSelector;