import { IPlate, IState } from '../../../Interfaces';

const onRenamePlateOpenDialogReducer = (state: IState, action) => {
    const plateIdToBeRenamed = action.payload as string;
    const plateTobeRenamed = state.data.plates.loadedPlates.find(p => p.id === plateIdToBeRenamed) as IPlate;
    const plateNameToBeRenamed = plateTobeRenamed.name as string;

    const newState = { ...state } as IState;
    newState.data.modals.renamePlateModalStatus = {
        ...newState.data.modals.renamePlateModalStatus,
        inputText: plateNameToBeRenamed,
        isOpen: true,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    return newState;
};

export default onRenamePlateOpenDialogReducer;