
import { IState, IBuild } from '../../Interfaces';

const onResetHighlightedBuildReducer = (state: IState, action) => {
    const newState = { ...state } as IState;

    newState.data.search = {
        ...newState.data.search,
        highlightBuildStatus: "",
        highlightedBuildData: {} as IBuild
    };
    
    return newState;
};

export default onResetHighlightedBuildReducer;