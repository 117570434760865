import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Storage as s3 } from "aws-amplify";
import "./BundlesList.css";
import dataStorage from "../S3DataStore/S3DataStore";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import * as _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

interface IBundlesListProps {
  build: any;
  bundleNameChange: any;
}

const BundlesList: React.FunctionComponent<IBundlesListProps> = (props) => {
  const [selectedBuild, setSelectedBuild] = useState(props.build);
  const [buildName, setBuildName] = useState('');
  const [selectedBundle, setSelectedBundle] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
  if( !_.isEqual(selectedBuild, props.build)){
    setSelectedBuild(props.build);
  }
  });
  const handleDialogClose = () => {
    setOpen(false);
    setBuildName('');
  };
  const updateName = () => {
    setOpen(false);
    const selected = selectedBuild.bundles.filter(a => a.name === selectedBundle['name']);
    if (selected.length > 0) {
      selected[0]['marker_name']=buildName
    }
      props.bundleNameChange(selectedBuild);
    
  };
  const showDialog = (value:any) => {
    setOpen(true);
    setSelectedBundle(value);
    setBuildName(value.marker_name?value.marker_name : value.name);
    
  };
  const [open, setOpen] = React.useState(false);

  if (props.build.bundles === undefined) {
    return null;
  }

  const deleteBundle = (value) => {
    s3.list(value.path.substring(7))
      .then(async (filesList) => {
        try {
          let promises = filesList.map(async (key) => await s3.remove((key as any)));
          let result = await Promise.all(promises);
          console.log(result);
          selectedBuild.bundles = selectedBuild.bundles.filter(
            (val) => val.path != value.path
          );
          dataStorage().objectStores["Builds"].update(selectedBuild);
        } catch (ex) {
          console.log(ex);
        }
      })
      .catch((reason) => console.log(reason.toString()));
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  
  const bundles = selectedBuild.bundles.map((value) => {
    const display_name = value.initiated_at
      ? new Date(value.initiated_at).toLocaleString()
      : value.name;
    let color = {
      backgroundColor:
        value.is_ok == undefined
          ? "white"
          : value.is_ok
          ? "rgba(180, 220, 220, 0.6)"
          : "rgba(220, 180, 180, 0.6)",
    };
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        <span className='bundle-list'>
        <img alt={"trash"}
          src={"/trash.svg"}
          style={{ width: "25px", height: "25px", cursor:"pointer"  }}
          title= 'del'
          onClick={(e) => {
            e.stopPropagation()
            if (window.confirm("Are you sure you wish to delete this item?"))
              deleteBundle(value);
          }}
        />
          {value.is_ok ? (
            <img
              alt={"Download gcode"}
            src={"/google.svg"}
            style={{ width: "25px", height: "25px", cursor:"pointer" }}
            title= 'gcd'
            onClick={() => {
              let gcodeFile =
                value.path.substring(7) + `${selectedBuild.id}_Lava.gcode`;
              s3.get(gcodeFile).then((content) => {
                let anchorTag = document.createElement("a");
                anchorTag.href = content as string;
                anchorTag.download = value.path.replace(/^.*[\\\/]/, "");
                anchorTag.click();
              });
            }}
          />
          ) : (
            <img
              alt={"Download log"}
          src={"/log-file-format.svg"}
          style={{ width: "25px", height: "25px", cursor:"pointer"  }}
          title= 'Log'
          onClick={() => {
            let logFile = value.path.substring(7) + "Log/log.txt";
            s3.get(logFile).then((content) => {
              let anchorTag = document.createElement("a");
              anchorTag.href = content as string;
              anchorTag.download = value.path.replace(/^.*[\\\/]/, "");
              anchorTag.click();
            });
          }}
        />
          )}
          <img
            alt={"Edit tag"}
          src={"/pencil-sharp.svg"}
          style={{ width: "25px", height: "25px", cursor:"pointer"  }}
          title= 'Update Note'
          onClick={() => {
            showDialog(value);
          }}
        />
          <Link
            to={{ pathname: `/build/${selectedBuild.id}/bundle/${value.name}` }}
            style={color}
          >
          {/* <Tooltip title={value.marker_name?`${display_name}`+ '\n' + `${value.marker_name}` : value.name}>
           <span className='ellipsis-text' > {value.marker_name ? value.marker_name : display_name} </span>
           </Tooltip> */}
            {value.marker_name ?
              (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <b>{display_name}</b> <br />
                      {value.marker_name}
                    </React.Fragment>
                  }
                >
                  <span className='ellipsis-text'> {value.marker_name} </span>
                </HtmlTooltip>)
              : (<HtmlTooltip
                title={
                  <React.Fragment>
                    <b>{value.name}</b>
                  </React.Fragment>
                }
              >
                <span> {display_name} </span>
              </HtmlTooltip>
              )}
          </Link>
        </span>
      </div>
    );
  });

  return (
    <div>
      <div>
        <p>Bundles</p>
      </div>
      <div className={"bundlesListContainer"}>{bundles};</div>
      <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="responsive-dialog-title"
      className= "catalog-dialog"
    >
      <DialogTitle id="responsive-dialog-title">{"Bundle Notes" }</DialogTitle>
      <DialogContent>
      <div className="each-content"><textarea
                    style={{ minWidth: "300px"}}
                    value={buildName}
                    onChange={(ev) => {
                      let v = ev.target.value;
                      setBuildName(v);
                    }}
                  /></div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={updateName} color="primary" autoFocus>
        Update
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default BundlesList;
