import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import IPartInfo from '../../interfaces/IPartInfo';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import Scene from '../../Scene';
import { IWorkSpaceState } from '../../WorkSpace/WorkSpace';

import { IState } from '../Interfaces';
import WorkspaceReducer from './WorkspaceReducer';
import WorkspaceScene from './WorkspaceScene';

const Props = (state: IState, ownProps: any) => {

    const workspace = state.data.workspace as any;

    const props = {
        state: workspace.state as IWorkSpaceState,
        parts: workspace.state.build.parts as IPartInfo[],
        machineConfig: workspace.state.build?.machineConfig?.resultJson,
        millConfig: workspace.state.build?.millConfig?.resultJson,
        recipe: workspace.state.build?.recipe?.resultJson,
        materialConfig: workspace.state.build?.materialConfig?.resultJson,
        isLoadingStarted: workspace.isLoadingStarted,
        isLoadingInProgress: workspace.isLoadingInProgress,
        isLoadingComplete: workspace.isLoadingComplete,
        creator: state.creator,
        partHoveredForConfiguration: ownProps.partHoveredForConfiguration,
    };
    return props;
};

const Actions = {

};

class Component extends React.Component<any, any> {
    dispatch: (action: any) => void;
    scene: Scene | null = null;
    isComponentMounted: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            showLoading: ['hideLoading'],
        };
        this.dispatch = (action: any) => {
            if (action?.type === "addLoading") {
                if (this.isComponentMounted) {
                this.state.showLoading[0] = 'showLoading';
                }
            } else if (action?.type === "removeLoading") {
                if (this.isComponentMounted) {
                this.state.showLoading[0] = 'hideLoading';
                }
            }
            const newState = WorkspaceReducer(this.props.state, action);
            if(this.isComponentMounted) {
                this.setState(newState);
            }
        };
    }

    componentDidMount(): void {
        this.isComponentMounted = true;
    }

    componentWillUnmount(): void {
        this.isComponentMounted = false;
    }

    public setScene = (scene: Scene) => {
        this.scene = scene
    }

    public render() {
        return (
            <>
                <LoadingComponent visible={this.state.showLoading[0] === 'showLoading'} />
                <WorkspaceScene
                    refProcessor={scene => (this.setScene(scene))}
                    dispatch={this.dispatch}
                    parts={this.props.parts}
                    machineConfig={this.props.machineConfig}
                    millConfig={this.props.millConfig}
                    recipe={this.props.recipe}
                    materialConfig={this.props.materialConfig}
                    screenName={'workspace-section-plates-screen'}
                    isLoadingStarted={this.props.isLoadingStarted}
                    isLoadingInProgress={this.props.isLoadingInProgress}
                    isLoadingComplete={this.props.isLoadingComplete}
                    partHoveredForConfiguration={this.props.partHoveredForConfiguration}
                />
            </>
        );
    }
}

const WorkspaceSection = withRouter(connect(Props, Actions)(Component));

export default WorkspaceSection;