
import { IPart, IState } from "../../../Interfaces";

const onHighlightedPartReducer = (state: IState, action) => {
    const part = action.payload as IPart;
    
    const newState = { ...state } as IState;
    newState.data.parts.highlightedPart = part;
    
    return newState;
};

export default onHighlightedPartReducer;