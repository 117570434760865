import { IState } from '../../../Interfaces';

const onAddPartFailReducer = (state: IState, action) => {
    const addPartErrorMessage = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.modals.addPartModalStatus = {
        ...newState.data.modals.addPartModalStatus,
        hasError: true,
        errorMessage: addPartErrorMessage,
    };
    
    return newState;
};

export default onAddPartFailReducer;